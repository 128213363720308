import DataTable from "../../../components/data-table/data-table.component";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, message, Modal, Result} from "antd";
import TreatmentModal from "./components/treatment.modal";
import ESignForm from "./components/e-sign.form";
import {approveTreatmentPlan, deleteDraftTreatment} from "../service";
import {removeDraftPlan, setCurrentTreatmentPlan} from "../../../stores/patient-treatment-plan.store";
import moment from "moment/moment";

const TreatmentReviewPage = () => {
    const dispatch = useDispatch();
    const {current, past, pending} = useSelector(state => state.patientTreatmentPlan);
    const currentPatient = useSelector(state => state.currentPatient);
    const currentLabResult = useSelector(state => state.currentLabResult);
    const treatmentCheatsheet = useSelector(state => state.treatmentCheatsheet);

    const [currentTreatmentTableData, setCurrentTreatmentTableData] = useState([]);
    const [currentTreatmentTableSetting, setCurrentTreatmentTableSetting] = useState(null);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [viewingPastTreatmentTableData, setViewingPastTreatmentTableData] = useState(null);
    const [viewingPastTreatmentTableSetting, setViewingPastTreatmentTableSetting] = useState(null);
    const [approvalModalStage, setApprovalModalStage] = useState('sign');
    const [approvingTreatment, setApprovingTreatment] = useState(null);
    const [modifyingDraft, setModifyingDraft] = useState(null);

    const [pastTreatmentsTableSetting, setPastTreatmentsTableSetting] = useState(null);
    const [pendingTreatmentsTableSetting, setPendingTreatmentsTableSetting] = useState(null);

    const approveTreatment = async (signatureLink) => {
        try {
            await approveTreatmentPlan(currentPatient, approvingTreatment, signatureLink);
            dispatch(setCurrentTreatmentPlan(approvingTreatment));
        } catch (e) {
            message.error('Unable to approve treatment');
            throw e;
        }
    }

    const setTreatmentTableDataAndSetting = (plan, setTableData, setTableSetting) => {
        const treatmentDataToTableDataMapper = (p, i) => {
            if (i === 0) {
                return p;
            } else {
                return {
                    level: '',
                    product: p.product,
                    dosage: p.dosage,
                    price: p.price,
                    refill: p.refill,
                    quantity: p.quantity,
                };
            }
        };

        const plans = [...(plan?.forLabResult || []), ...(plan?.forSymptomsAndGoals || [])];

        const basePlan = plans.filter(p => p.level === 'Base');
        const correctionalPlan = plans.filter(p => p.level === 'Correctional');
        const electivePlan = plans.filter(p => p.level === 'Elective');
        const otherPlan = plans.filter(p => p.level === 'Other');

        const baseRowSpan = basePlan.length;
        const correctionalRowSpan = correctionalPlan.length;
        const electiveRowSpan = electivePlan.length;
        const otherRowSpan = otherPlan.length;

        const basePlanTableData = basePlan.map(treatmentDataToTableDataMapper);
        const correctionalPlanTableData = correctionalPlan.map(treatmentDataToTableDataMapper);
        const electivePlanTableData = electivePlan.map(treatmentDataToTableDataMapper);
        const otherPlanTableData = otherPlan.map(treatmentDataToTableDataMapper);

        const rowSpan = {
            '': 0,
            'Base': baseRowSpan,
            'Correctional': correctionalRowSpan,
            'Elective': electiveRowSpan,
            'Other': otherRowSpan
        }

        setTableData([...basePlanTableData, ...correctionalPlanTableData, ...electivePlanTableData, ...otherPlanTableData])
        setTableSetting({
            pagination: false,
            bordered: true,
            columns: [
                {
                    title: 'Therapy',
                    dataIndex: 'level',
                    onCell: (record) => ({
                        rowSpan: rowSpan[record.level]
                    })
                },
                {
                    title: 'Medication / Product',
                    dataIndex: ['product', 'name']
                },
                {
                    title: 'Quantity',
                    dataIndex: 'quantity'
                },
                {
                    title: 'Refill',
                    dataIndex: 'refill',
                },
                {
                    title: 'Dosage',
                    dataIndex: 'dosage',
                }
            ]
        })
    }

    useEffect(() => {
        setCurrentTreatmentTableSetting({
            pagination: false,
            bordered: true,
            columns: [
                {
                    title: 'Therapy',
                    dataIndex: 'level',
                },
                {
                    title: 'Medication / Product',
                    dataIndex: ['product', 'name']
                },
                {
                    title: 'Quantity',
                    dataIndex: 'quantity'
                },
                {
                    title: 'Refill',
                    dataIndex: 'refill',
                },
                {
                    title: 'Dosage',
                    dataIndex: 'dosage',
                }
            ]
        });

        setPastTreatmentsTableSetting({
            pagination: true,
            bordered: true,
            columns: [
                {
                    title: 'Date',
                    dataIndex: 'createdDate',
                    render: (text) => moment(text).tz('UTC').format('MMM DD, YYYY - hh:mma - z')
                },
                {
                    title: 'Created By',
                    dataIndex: 'createdBy'
                },
            ],
            customActions: [
                {
                    text: 'View',
                    whenPerform: async (record) => {
                        setTreatmentTableDataAndSetting(record, setViewingPastTreatmentTableData, setViewingPastTreatmentTableSetting);
                        setIsViewModalOpen(true);
                    },
                    shallNotRender: (record) => record.isDraft
                },
                {
                    needConfirm: false,
                    text: 'Edit',
                    whenPerform: async (record) => {
                        setModifyingDraft(record);
                        setIsCreateModalOpen(true);
                    },
                    shallNotRender: (record) => !record.isDraft
                },
                {
                    needConfirm: {
                        text: () => 'Do you want to delete this draft?'
                    },
                    text: 'Delete',
                    whenPerform: async (record) => {
                        try {
                            await deleteDraftTreatment(record._id);
                            dispatch(new removeDraftPlan(record));
                        } catch (e) {
                            message.error('Unable to remove draft plan');
                        }
                    },
                    shallNotRender: (record) => !record.isDraft
                }
            ],
            operationColumnTitle: 'Action'
        });

        setPendingTreatmentsTableSetting({
            pagination: true,
            bordered: true,
            columns: [
                {
                    title: 'Date',
                    dataIndex: 'createdDate',
                    render: (text) => moment(text).tz('UTC').format('MMM DD, YYYY - hh:mma - z')
                },
                {
                    title: 'Created By',
                    dataIndex: 'createdBy'
                },
                {
                    title: 'Reviewed By',
                    dataIndex: 'approvedBy'
                },
                {
                    title: 'Status',
                    render: () => 'Pending Approval'
                },
            ],
            customActions: [
                {
                    text: 'View',
                    whenPerform: (record) => {
                        setApprovingTreatment(record);
                        setIsApprovalModalOpen(true);
                    }
                }
            ],
            operationColumnTitle: 'Action'
        });

    }, [])

    useEffect(() => {
        if (current) {
            setTreatmentTableDataAndSetting(current, setCurrentTreatmentTableData, setCurrentTreatmentTableSetting);
        }
    }, [current]);

    return (
        <div>
            <div className="mb-3">
                <div className="d-flex justify-content-between mb-1">
                    <h4>Current Treatment Plan</h4>
                    <div>
                        <Button type="primary" onClick={() => {
                            setModifyingDraft({...current, _id: null});
                            setIsCreateModalOpen(true);
                        }} style={{marginRight: 8}} disabled={!current}>Copy Treatment</Button>
                        <Button type="primary" onClick={() => setIsCreateModalOpen(true)}>Setup new Treatment</Button>
                    </div>
                </div>
                {
                    currentTreatmentTableSetting && <DataTable data={currentTreatmentTableData} settings={currentTreatmentTableSetting}/>
                }
            </div>
            {
                pastTreatmentsTableSetting &&
                <div className="mb-3">
                    <h5>Previous Treatment Plan</h5>
                    <DataTable data={[...past].sort((a, b) => Date.parse(b.createdDate) - Date.parse(a.createdDate))} settings={pastTreatmentsTableSetting}/>
                </div>
            }
            {
                pendingTreatmentsTableSetting &&
                <div className="mb-3">
                    <h5>Awaiting Approvals</h5>
                    <DataTable data={pending} settings={pendingTreatmentsTableSetting}/>
                </div>
            }
            <TreatmentModal patient={currentPatient} treatmentCheatsheet={treatmentCheatsheet}
                            existingTreatment={modifyingDraft} labResult={currentLabResult}
                            visible={isCreateModalOpen} title={<>Setup New Treatment<hr/></>}
                            onClose={() => {
                                setIsCreateModalOpen(false);
                                setModifyingDraft(null);
                            }}
            />
            <Modal centered title="Approve This Treatment" open={isApprovalModalOpen}
                   width={"95%"} footer={null} destroyOnClose={true}
                   onCancel={() => setIsApprovalModalOpen(false)}>
                {
                    approvalModalStage === 'sign' &&
                    <ESignForm approvingTreatment={approvingTreatment}
                               onSigning={approveTreatment}
                               afterSigning={() => setApprovalModalStage('success')} />
                }
                {
                    approvalModalStage === 'success' &&
                        <Result
                            status="success"
                            title="Successfully Approved Treatment Plan!"
                            extra={[
                                <Button type="primary" key="console" onClick={() => {
                                    setIsApprovalModalOpen(false);
                                    setApprovalModalStage('sign');
                                }}>
                                    Go Back To Treatment Review
                                </Button>
                            ]}
                        />
                }
            </Modal>
            <Modal centered title="Previous Treatment Plan View" open={isViewModalOpen}
                   width={"95%"} footer={null} destroyOnClose={true}
                   onCancel={() => setIsViewModalOpen(false)}>
                <DataTable data={viewingPastTreatmentTableData} settings={viewingPastTreatmentTableSetting}/>
            </Modal>
        </div>
    );
};

export default TreatmentReviewPage;
