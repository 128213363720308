import React, { useContext } from 'react';
import '../../styles/nav.css';
import { useLocation, useNavigate } from 'react-router-dom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GroupsIcon from '@mui/icons-material/Groups';
import NotificationsIcon from '@mui/icons-material/Notifications';
import BadgeIcon from '@mui/icons-material/Badge';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import { SettingsSuggest } from '@mui/icons-material';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import CampaignIcon from '@mui/icons-material/Campaign';
import TagIcon from '@mui/icons-material/Tag';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { UserContext } from '../../App';
import _ from 'lodash';
import { ApplicationRight } from '../../share/RightList';

const NavigationSider = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const NavItem = ({ icon, title, pathname, accessingRights }) => {
    const hasRight =
      accessingRights && accessingRights.length ? !!_.intersection(user.rightList, accessingRights).length : true;
    return hasRight ? (
      <div
        onClick={() => navigate(`${pathname}`)}
        className={`d-flex flex-row align-items-center nav-button ${
          pathname === location.pathname ? 'nav-active' : ''
        }`}
        style={{
          borderRadius: 4,
          marginBottom: 12,
          padding: 8,
          width: `180px`,
        }}
      >
        {icon}
        <span className="nav-title">{title}</span>
      </div>
    ) : (
      <></>
    );
  };

  const SiderNavSection = ({ title, navItems }) => {
    return (
      <div className="side-nav-section">
        <div style={{ padding: 12 }}>
          <span className="nav-mini">{title}</span>
          <div style={{ height: 6 }} />
          {navItems.map((item) => item)}
        </div>
        <div className="nav-seperate" />
      </div>
    );
  };

  return (
    <div className="nav-sider" style={{ position: 'relative' }}>
      <div style={{ height: 60 }}></div>
      <SiderNavSection
        title="Patient Management"
        navItems={[
          <NavItem
            pathname="/patient/search"
            icon={<GroupsIcon color="white" sx={{ color: 'white', width: `16px` }} />}
            title={'Patient Search'}
            accessingRights={[
              ApplicationRight.Lab_Info_Access,
              ApplicationRight.Patient_Detail_Access,
              ApplicationRight.Medical_Notes_Access,
              ApplicationRight.Treatment_Creation,
              ApplicationRight.Treatment_Signing,
              ApplicationRight.BioID_Brief_Access,
              ApplicationRight.Orders_Tracking,
              ApplicationRight.Engagement_Access,
              ApplicationRight.Payment_History_Access,
              ApplicationRight.Files_Access,
            ]}
          />,
        ]}
      />
      <SiderNavSection
        title="Appointment Calendar"
        navItems={[
          <NavItem
            pathname="/appointment-management/by-location"
            icon={<MonitorHeartIcon color="white" sx={{ color: 'white', width: `16px` }} />}
            title={'By Location'}
            accessingRights={[ApplicationRight.Appointment_Read_Access]}
          />,
          <NavItem
            pathname="/appointment-management/by-provider"
            icon={<BadgeIcon color="white" sx={{ color: 'white', width: `16px` }} />}
            title={'By Provider'}
            accessingRights={[ApplicationRight.Appointment_Read_Access]}
          />,
          <NavItem
            pathname="/staff/schedule"
            icon={<PunchClockIcon color="white" sx={{ color: 'white', width: `16px` }} />}
            title={'Staff Schedule'}
            accessingRights={[ApplicationRight.Staff_Schedule_Access]}
          />,
        ]}
      />

      <SiderNavSection
        title="Procurement"
        navItems={[
          <NavItem
            pathname="/procurement/shipment"
            icon={<RocketLaunchIcon color="white" sx={{ color: 'white', width: `16px` }} />}
            title={'Order'}
            accessingRights={[ApplicationRight.Orders_Tracking]}
          />,
        ]}
      />

      <SiderNavSection
        title="Engagement"
        navItems={[
          <NavItem
            pathname="/news-posts"
            icon={<CampaignIcon color="white" sx={{ color: 'white', width: `16px` }} />}
            title={'News Posts'}
            accessingRights={[ApplicationRight.News_Posts_Access]}
          />,
          <NavItem
            pathname="/announcement"
            icon={<AnnouncementIcon color="white" sx={{ color: 'white', width: `16px` }} />}
            title={'Announcement'}
            accessingRights={[ApplicationRight.Announcement_Access]}
          />,
          <NavItem
            pathname="/notification"
            icon={<NotificationsIcon color="white" sx={{ color: 'white', width: `16px` }} />}
            title={'Notifications'}
            accessingRights={[ApplicationRight.Engagement_Access]}
          />,
        ]}
      />

      <SiderNavSection
        title="System Content"
        navItems={[
          <NavItem
            pathname="/location"
            icon={<LocationOnIcon color="white" sx={{ color: 'white', width: `16px` }} />}
            title={'Location Management'}
            accessingRights={[ApplicationRight.Location_Management_Access]}
          />,
          <NavItem
            pathname="/staff/search"
            icon={<BadgeIcon color="white" sx={{ color: 'white', width: `16px` }} />}
            title={'Staff Management'}
            accessingRights={[ApplicationRight.Staff_Management_Access]}
          />,
          <NavItem
            pathname="/system-settings"
            icon={<SettingsSuggest color="white" sx={{ color: 'white', width: `16px` }} />}
            title={'System Setting'}
            accessingRights={[ApplicationRight.System_Setting_Access]}
          />,
          <NavItem
            pathname="/app-version-config"
            icon={<TagIcon color="white" sx={{ color: 'white', width: `16px` }} />}
            title={'App Version Config'}
            accessingRights={[ApplicationRight.App_Version_Config_Access]}
          />,
        ]}
      />
    </div>
  );
};

export default NavigationSider;
