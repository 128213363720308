import React from "react";
import { Col, Row } from "react-bootstrap";
import DataTable from "../../../components/data-table/data-table.component";
import {
  AutoComplete,
  Button,
  Input,
  message,
  Select,
  Space,
  Upload,
} from "antd";
import "./main.css";
import moment from "moment";
import { getShippingOptions, searchOrders } from "../service";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import TrackingNumberFormModal from "./TrackingNumberModal";
import { Link } from "react-router-dom";
import { UPLOAD_TRACKING_NUMBER_URL } from "../../../api/URLs";
import bioApi from "../../../api/bioApi";
import DatePickerWithMoment from "../../../components/date/DatePickerWithMoment";

const { Option } = Select;
const dateFormat = "MM/DD/YYYY";

function ShipmentPage() {
  const [shipmentInfo, setShipmentInfo] = React.useState([]);
  const [searchType, setSearchType] = React.useState("Search by OrderID");
  const [type, setType] = React.useState("orderID");
  const [loading, setLoading] = React.useState(true);
  const [searchOptions, setSearchOptions] = React.useState([]);
  const [sortType, setSortType] = React.useState("date");
  const [searchContent, setSearchContent] = React.useState("");
  const [rangeDate, setRangeDate] = React.useState([
    moment(),
    moment().add(1, "days"),
  ]);
  const [showTrackingNumberModal, setShowTrackingNumberModal] =
    React.useState(false);
  const tableSettings = {
    appendable: false,
    removable: false,
    bordered: true,
    size: "middle",
    operationColumnTitle: "Action",
    pagination: {
      showLessItems: true,
      pageSize: 10,
    },
    columns: [
      {
        title: "Create Date",
        dataIndex: "creationTS",
      },
      {
        title: "Order ID",
        dataIndex: "orderID",
      },
      {
        title: "Ship From",
        dataIndex: "shipFrom",
      },
      {
        title: "Patient Name",
        dataIndex: "patientName",
        render: (record, data) => {
          if (data.patientID) {
            return (
              <Link to={"/patient/" + data.patientID + "/details"}>
                {" "}
                {data.patientName}
              </Link>
            );
          } else {
            return <span> {data.patientName}</span>;
          }
        },
        width: "150px",
      },
      {
        title: "Status",
        dataIndex: "status",
      },
      {
        title: "Destination",
        dataIndex: "destination",
      },
    ],
    customActions: [
      {
        element: (record, data) => (
          <div className="d-flex">
            {record.pdfLink != null ? (
              <Button
                className="action-btn"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(record.pdfLink);
                }}
              >
                Print Packing List
              </Button>
            ) : (
              ""
            )}
            {record.trackingNumber === null ? (
              <TrackingNumberFormModal
                orderID={record.orderID}
                fetchData={fetchShipmentData}
              />
            ) : (
              ""
            )}
          </div>
        ),
      },
    ],
  };

  const updateSearchType = (e) => {
    setSearchOptions([]);
    setSearchType("Search By " + e);
    setType(e);
  };

  const showSearchOptions = async (value) => {
    if (value != "") {
      const allOptions = await getShippingOptions(type, value);
      setSearchOptions(allOptions.data);
    } else {
      setSearchOptions([]);
      fetchShipmentData();
    }
  };

  const updateSortTypeAndSearch = async (value) => {
    setLoading(true);
    setSortType(value);
    const patientDataRequest = await searchOrders(
      type,
      searchContent,
      value,
      rangeDate,
    );
    if (patientDataRequest.data.data) {
      setShipmentInfo(patientDataRequest.data.data);
    } else {
      setShipmentInfo();
    }
    setLoading(false);
  };
  const updateRangeDate = async (value) => {
    setLoading(true);
    setRangeDate(value);
    const patientDataRequest = await searchOrders(
      type,
      searchContent,
      sortType,
      value,
    );
    if (patientDataRequest.data.data) {
      setShipmentInfo(patientDataRequest.data.data);
    } else {
      setShipmentInfo();
    }
    setLoading(false);
  };

  const uploadProps = {
    name: "file",
    maxCount: 1,
    action: bioApi.defaults.baseURL + UPLOAD_TRACKING_NUMBER_URL,
    async onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} uploaded successfully`);
        fetchShipmentData();
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} upload failed.`);
      }
    },
    withCredentials: true,
  };

  const searchShipmentData = async () => {
    setLoading(true);
    let patientDataRequest;
    patientDataRequest = await searchOrders(
      type,
      searchContent,
      sortType,
      rangeDate,
    );

    if (patientDataRequest.data.data) {
      setShipmentInfo(patientDataRequest.data.data);
    } else {
      setShipmentInfo();
    }
    setLoading(false);
  };
  const fetchShipmentData = async () => {
    try {
      setLoading(true);
      const patientDataRequest = await searchOrders(
        type,
        searchContent,
        sortType,
        rangeDate,
      );
      if (patientDataRequest.data.data) {
        setShipmentInfo(patientDataRequest.data.data);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const handleSearchContent = (value) => {
    setSearchContent(value);
  };

  const handleEnterKeySearch = (e) => {
    if (e.key === "Enter") {
      searchShipmentData();
    }
  };

  React.useEffect(() => {
    fetchShipmentData();
  }, [searchContent]);

  return (
    <>
      <Row>
        <Col sm={12} className="pb-4">
          <Row className="align-items-end">
            <Col sm={6}>
              <Input.Group size="large" compact>
                <Select
                  defaultValue={type}
                  onSelect={updateSearchType}
                  size="large"
                  style={{
                    width: 150,
                    borderRadius: "4px",
                  }}
                >
                  <Option value="orderID">OrderID</Option>
                  <Option value="patientName">Patient Name</Option>
                </Select>
                <Space direction="vertical">
                  <AutoComplete
                    onSearch={showSearchOptions}
                    onSelect={searchShipmentData}
                    onChange={handleSearchContent}
                    style={{
                      width: 450,
                    }}
                    notFoundContent="No order found with searched key"
                    options={searchOptions}
                    onKeyDown={(e) => handleEnterKeySearch(e)}
                  >
                    <Input
                      size="large"
                      placeholder={searchType}
                      suffix={
                        <SearchOutlined onClick={() => searchShipmentData()} />
                      }
                      allowClear
                    />
                  </AutoComplete>
                </Space>
              </Input.Group>
            </Col>
            <Col sm={6}>
              <Row>
                <Col sm={3} className="sort-input">
                  <Input.Group compact>
                    <div
                      className="sort-label"
                      style={{ display: "inline-grid" }}
                    >
                      <label>Sort By</label>
                      <Select
                        defaultValue={sortType}
                        size="large"
                        onSelect={updateSortTypeAndSearch}
                        style={{
                          width: 150,
                        }}
                      >
                        <Option value="date">Date</Option>
                        <Option value="orderID">OrderID</Option>
                        <Option value="patientName">Patient Name</Option>
                        <Option value="shipFrom">Ship From</Option>
                        <Option value="status">Status</Option>
                        <Option value="destination">Destination</Option>
                      </Select>
                    </div>
                  </Input.Group>
                </Col>
                <Col sm={5} className="date-input">
                  <Input.Group size="large">
                    <div
                      className="date-label"
                      style={{ display: "inline-grid" }}
                    >
                      <label>Date</label>
                      <div className="date-input">
                        <DatePickerWithMoment.RangePicker
                          size={"large"}
                          defaultValue={rangeDate}
                          format={dateFormat}
                          placement="bottomLeft"
                          onChange={updateRangeDate}
                        />
                      </div>
                    </div>
                  </Input.Group>
                </Col>
                <Col
                  sm={4}
                  className="create-row d-flex justify-content-end align-items-end upload"
                >
                  <Upload {...uploadProps}>
                    <Button size="large" icon={<UploadOutlined />}>
                      Upload
                    </Button>
                  </Upload>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col sm={12}>
          <DataTable
            loading={loading}
            data={shipmentInfo}
            settings={tableSettings}
          />
        </Col>
      </Row>
    </>
  );
}

export default ShipmentPage;
