import bioApi from "../../api/bioApi";
import {
    LOCATION_LIST_URL, PATIENT_AUTO_COMPLETE_URL,
    PATIENT_SEARCH_ALL,
    PATIENT_SEARCH_URL,
    PATIENT_CREATE_URL,
    PROSPECT_PATIENT_SEARCH_URL,
    PROSPECT_PATIENT_COMPARE_URL
} from "../../api/URLs";

export const listAllLocations = () => {
    return bioApi.post(LOCATION_LIST_URL, {
        limit: 1000
    });
}

export const searchAllPatients = (location) => {
    return bioApi.get(PATIENT_SEARCH_ALL, { params: { location: location } });
}

export const searchPatients = (option, query, location, withProspectPatient = false) => {
    const url = PATIENT_SEARCH_URL.replace(':option', option).replace(':query', query || 'all');
    if (location && location !== '') {
        return bioApi.get(url, { params: { location: location } });
    } else {
        return bioApi.get(url, { params : { withProspectPatient }});
    }
}

export const autoCompletePatient = (option, query, location) => {
    const url = PATIENT_AUTO_COMPLETE_URL.replace(':option', option).replace(':query', query);
    if (location && location !== '') {
        return bioApi.get(url, { params: { location: location } });
    } else {
        return bioApi.get(url);
    }
}

export const createPatient = (patientData) => {
    const url = PATIENT_CREATE_URL;
    if (patientData) {
        return bioApi.post(url, patientData);
    }
}


export const getPropsectPatientOptions = (searchContent = "") => {
    return bioApi.post(PROSPECT_PATIENT_SEARCH_URL,{
        'content' : searchContent,
    });
}

export const compareProspectPatient = (data) => {
    return bioApi.post(PROSPECT_PATIENT_COMPARE_URL,data);
}