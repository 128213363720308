import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getOrders} from "../service";
import DataTable from "../../../components/data-table/data-table.component";
import {Button, Tooltip} from "antd";
import moment from "moment";

const OrdersPage = () => {
    const [tableData, setTableData] = useState([]);
    const currentPatient = useSelector(state => state.currentPatient);
    const tableSettings = {
        appendable: false,
        removable: false,
        bordered : true,
        size: 'small',
        operationColumnTitle: 'Action',
        pagination : {
            'showLessItems' :true,
            'pageSize' : 10,
        },
        columns: [
            {
                title: 'Create Date',
                dataIndex: 'creationTS',
                render: (text, record, index) => moment(text).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("MM/DD/YYYY")
            },
            {
                title: 'Order ID',
                dataIndex: 'orderID',
            },
            {
                title: 'Ship From',
                dataIndex: 'shipFrom',
            },
            {
                title: 'Status',
                dataIndex: 'status',
            },
            {
                title: 'ETA Delivery Date',
                dataIndex: 'deliveryDate',
            },
            {
                title: 'Carrier',
                dataIndex: 'carrier',
            },
            {
                title: 'Tracking Number',
                dataIndex: 'trackingNumber',
            },
            {
                title: 'Destination',
                dataIndex: 'destination',
            },
        ],
        customActions: [
            {
                element: (record, data) =>
                    <div className='d-flex'>
                        <Tooltip color="volcano" title={record.pdfLink ? '' : 'No packlist PDF is associated'}>
                            <Button className='action-btn' disabled={!record.pdfLink}
                                onClick={() => window.open(record.pdfLink, "_blank")}>View</Button>
                        </Tooltip>
                        <Tooltip color="volcano" title={record.trackingNumber ? '' : 'No Tracking Number'}>
                            <Button className='action-btn' disabled={!record.trackingNumber}>Track Order</Button>
                        </Tooltip>
                    </div>
            }
        ],
    }

    useEffect(() => {
        (async () => {
            const orders = await getOrders(currentPatient);
            setTableData(orders.data.sort((a, b) => moment(b.creationTS) - moment(a.creationTS)));
        })();
    }, [currentPatient]);

    return <>
        <DataTable data={tableData} settings={tableSettings}></DataTable>
    </>
}

export default OrdersPage;
