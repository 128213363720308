import React from 'react';
import { CloseButton, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import BoxLoader from '../../../components/loading/BoxLoader';
import { FormatDateToString } from '../../../share/StringHelper';

import '../../../styles/station.css';

function getCenterName(centerArray, centerid) {
    for (var i = 0; i < centerArray.length; i++) {
        var item = centerArray[i];
        if (item.stationId === centerid) {
            return item.name;
        }
    }
    return '';
}

class AnnouncementModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            published: {},
            unpublish: {},
            sendType: "plasma_message",
            loading: false,
            achecked: { '0': true },
            bchecked: { '0': true },
        };
    }

    handlebChange = (index) => {
        var checked = this.state.bchecked;

        if (checked[index + '']) {
            delete checked[index + '']
        } else {
            checked[index + ''] = true;
        }

        if (Object.keys(checked).length === 0) {
            checked = { "0": true };
        } else {
            delete checked['0'];
        }

        this.setState({
            bchecked: checked
        });
    };

    handleaChange = (index) => {
        var checked = this.state.achecked;

        if (checked[index + '']) {
            delete checked[index + '']
        } else {
            checked[index + ''] = true;
        }

        if (index === 0) {
            checked = { "0": true };
        } else {
            if (Object.keys(checked).length === 0 || this.props.centers.length === Object.keys(checked).length) {
                checked = { "0": true };
            } else {
                delete checked['0'];
            }
        }

        this.setState({
            achecked: checked
        });
    };

    render() {
        return (
            <Modal dialogClassName="modal-custom-publish-mobile" show={this.props.showReviewModal} onShow={() => {
            }} onHide={this.props.handleReviewModalClose} id="existing-user" className="modal fade">
                <Modal.Header padding={0} style={{ padding: 10, display: 'flex', justifyContent: 'space-between' }}>
                    <h3 className="modal-title text-center">Announcement review</h3>
                    <CloseButton onClick={this.props.handleReviewModalClose} />
                </Modal.Header>
                <Modal.Body padding={0} style={{ padding: 10, paddingTop: 0 }}>
                    <BoxLoader show={this.state.loading} />
                    <div>
                        <div style={{ margin: "0px 30px 10px" }}>
                            <div style={{ display: "flex", padding: "8px 0px" }}>
                                <span style={{ fontWeight: "bold", width: "25%" }}>Title&#8758; &nbsp;</span>
                                <span style={{ width: "25%" }}>{this.props.title}</span>
                            </div>
                            <div style={{ display: "flex", padding: "8px 0px" }}>
                                <span style={{ fontWeight: "bold", width: "25%" }}>Subtitle&#8758; &nbsp;</span>
                                <span style={{ width: "75%" }}>{this.props.subTitle}</span>
                            </div>
                            <div>
                                <div style={{ display: "flex", padding: "8px 0px" }}>
                                    <span style={{ fontWeight: "bold", width: "25%", marginTop: "auto", marginBottom: "auto" }}>Validate&#8758; &nbsp;</span>
                                    <span style={{ fontWeight: "normal", width: "75%", marginTop: "auto", marginBottom: "auto" }}>{FormatDateToString(this.props.validStart, true) + ' - ' + FormatDateToString(this.props.validEnd, true)}</span>
                                </div>
                            </div>
                            <div>
                                <div style={{ display: "flex", padding: "8px 0px" }}>
                                    <span style={{ fontWeight: "bold", marginTop: "auto", marginBottom: "auto" }}>Select Center(s)&#8758; &nbsp;</span>
                                </div>
                                <div style={{ display: "flex", padding: "8px 0px" }}>
                                    <div className="col-md-9" style={{ width: "100%", maxHeight: 100, overflowY: "scroll", paddingLeft: 0, paddingRight: 0 }}>
                                        {
                                            this.props.selectAll === true ? (
                                                this.props.centers.map((item, index) => {
                                                    return <div className={"vselected"} key={'fatimes' + index}>
                                                        {item.name}
                                                    </div>
                                                })
                                            ) : (
                                                    this.props.plasmaIDs.map((item, index) => {
                                                        return <div className={"vselected"} key={'fatimes' + index}>
                                                            {getCenterName(this.props.centers, item)}
                                                        </div>
                                                    })
                                                )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginBottom: 10, marginTop: 10, textAlign: "center" }}>
                            <button
                                className="btn btn-blue btn-md"
                                style={{ padding: "5px 30px" }}
                                onClick={this.props.handleReviewModalClose}>
                                Close
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
};

export default AnnouncementModal;
