import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import patientTreatmentPlan from "./patient-treatment-plan.store";
import treatmentProposal from "./treatment-proposal.store";
import treatmentCheatsheet from "./treatment-cheatsheet.store";
import currentPatient from "./current-patient.store";
import currentAppuser from "./current-appuser.store";
import currentLabResult from "./current-lab-result.store";
import currentStaff from "./current-staff.store";
import newsPosts from "./news-posts.store";
import authenticatedUser from "./authenticated-user.store";
import { listenerMiddleware } from "./middleware";

const authenticationState = JSON.parse(localStorage.getItem("user") || "null");

const reducer = combineReducers({
  authenticatedUser,
  patientTreatmentPlan,
  treatmentProposal,
  currentPatient,
  currentAppuser,
  treatmentCheatsheet,
  currentLabResult,
  currentStaff,
  newsPosts,
});

const store = configureStore({
  preloadedState: {
    authenticatedUser: authenticationState,
  },
  reducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    listenerMiddleware.middleware,
  ],
});

export default store;
