import React, {useEffect, useState} from "react";
import {Form, Input, InputNumber} from "antd";
import DataTable from "../../../../../components/data-table/data-table.component";
import bioApi from "../../../../../api/bioApi";
import {
    CREATE_AGE_GROUP_URL,
    FETCH_AGE_GROUP_URL,
    UPDATE_AGE_GROUP_STATUS_URL,
    UPDATE_AGE_GROUP_URL
} from "../../../../../api/URLs";

const AgeGroup = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const dataTableSettings = {
        tableHeader: <h4>Age Group</h4>,
        editable: true,
        removable: true,
        numbered: true,
        appendable: true,
        columns: [
            {
                title: 'Group Name',
                dataIndex: 'name',
                width: '40%',
                nameWhenAppending: ['newGroupName'],
                editRender: "input"
            },
            {
                title: 'Age Range',
                width: '40%',
                render: (_, record) => {
                    return (
                        <span>{record.rangeStart} - {record.rangeEnd}</span>
                    )
                },
                nameWhenEditing: [
                    ['rangeStart'],
                    ['rangeEnd']
                ],
                nameWhenAppending: [
                    'newRangeFrom',
                    'newRangeTo'
                ],
                editRender: (rowData, formRef) => {
                    const fromFormName = !rowData ? dataTableSettings.columns[1].nameWhenAppending[0] : dataTableSettings.columns[1].nameWhenEditing[0];
                    const toFormName = !rowData ? dataTableSettings.columns[1].nameWhenAppending[1] : dataTableSettings.columns[1].nameWhenEditing[1];
                    return (
                        <Input.Group compact>
                            <Form.Item
                                name={fromFormName}
                                style={{
                                    margin: 0,
                                    marginRight: 10,
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: `From age is required`
                                    },
                                    {
                                        validator: async (_, from, callback) => {
                                            const to = formRef.getFieldValue(toFormName);
                                            if (to != null) {
                                                if (to <= from) {
                                                    return Promise.reject(
                                                        "Must be less than To Age."
                                                    );
                                                }
                                            }
                                        }
                                    }
                                ]}
                            >
                                <InputNumber placeholder="from" onChange={() => formRef.validateFields([toFormName])}/>
                            </Form.Item>
                            <span style={{position: "relative", top: "5px"}}>-</span>
                            <Form.Item
                                name={toFormName}
                                style={{
                                    margin: 0,
                                    marginLeft: 10,
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: `To age is required`
                                    },
                                    {
                                        validator: async (_, to, callback) => {
                                            const from = formRef.getFieldValue(fromFormName);
                                            if (from != null) {
                                                if (to <= from) {
                                                    return Promise.reject(
                                                        "Must be greater than from Age."
                                                    );
                                                }
                                            }
                                        },
                                    }
                                ]}
                            >
                                <InputNumber placeholder="to" onChange={() => formRef.validateFields([fromFormName])}/>
                            </Form.Item>
                        </Input.Group>
                    )
                }
            }
        ],
        onNewRecordCreate: async (row) => {
            setLoading(true)
            try {
                const res = await bioApi.post(CREATE_AGE_GROUP_URL, {
                        name: row.newGroupName,
                        rangeStart: parseInt(row.newRangeFrom),
                        rangeEnd: parseInt(row.newRangeTo)
                    });
                const newData = [...data, {
                    _id: res.data.data._id,
                    name: row.newGroupName,
                    rangeStart: parseInt(row.newRangeFrom),
                    rangeEnd: parseInt(row.newRangeTo),
                    __newRecord__: true
                }];
                setData(newData);
                return Promise.resolve({
                    type: 'success',
                    title: 'Age Group Settings',
                    text: `New Age Group [${row.newGroupName}] has been added!`
                })
            } catch (reason) {
                console.error(reason);
                return Promise.reject({
                    type: 'error',
                    title: 'Age Group Settings',
                    text: 'Failed to add new Age Group'
                })
            } finally {
                setLoading(false)
            }
        },
        onRecordEdit: async (recordKey, row) => {
            setLoading(true);
            const newData = [...data];
            const index = newData.findIndex((item) => recordKey === item._id);

            const item = newData[index];
            try {
                await bioApi.patch(UPDATE_AGE_GROUP_URL + recordKey, {...row});
                newData.splice(index, 1, {...item, ...row});
                setData(newData);
                return Promise.resolve({
                    type: 'success',
                    title: 'Age Group Settings',
                    text: `Age Group [${item.name}] has been updated!`
                });
            } catch (reason) {
                console.error(reason);
                return Promise.reject({
                    type: 'error',
                    title: 'Age Group Settings',
                    text: `Failed to update Age Group [${item.name}]`
                })
            } finally {
                setLoading(false)
            }
        },
        onRecordDelete: async (key) => {
            setLoading(true);
            const deletedRecord = data.find(item => item._id === key);

            try {
                await bioApi.patch(UPDATE_AGE_GROUP_STATUS_URL + key, {status: 'invalid'});
                setData(data.filter(item => item._id !== key));
                return Promise.resolve({
                    type: 'success',
                    title: 'Age Group Settings',
                    text: `Age Group [${deletedRecord.name}] has been deleted!`
                });
            } catch (reason) {
                console.error(reason);
                return Promise.reject({
                    type: 'error',
                    title: 'Age Group Settings',
                    text: `Failed to delete Age Group [${deletedRecord.name}]`
                });
            } finally {
                setLoading(false)
            }
        },
    }

    useEffect(() => {
        requestData();
    }, []);

    const requestData = async () => {
        setLoading(true);
        const result = await bioApi.get(FETCH_AGE_GROUP_URL)
            .then(res => res.data)
            .catch(err => ({error: true, message: err.message}))

        setLoading(false);

        if (result.status === 1) {
            setData(result.data);
        } else {
            console.error(result);
        }
    }

    return (
        <DataTable data={data} settings={dataTableSettings} loading={loading}/>
    );
}

export default AgeGroup;