import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Oval } from "./Oval";

/**
 * @return {null}
 */
export default function Loader(props) {
    const [display, setDisplay] = useState(true);

    useEffect(() => {
        let timer;
        if (props.timeout && props.timeout > 0) {
            timer = setTimeout(() => {
                setDisplay(false);
            }, props.timeout);
        }

        return () => {
            if (timer) clearTimeout(timer);
        };
    });

    if (!props.visible || props.visible === "false") {
        return null;
    }

    return display ? (
        <div className={props.className} style={props.style}>
            {React.createElement(Oval, { ...props })}
        </div>
    ) : null;
}

Loader.propTypes = {
    style: PropTypes.objectOf(PropTypes.string),
    className: PropTypes.string,
    visible: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    timeout: PropTypes.number
};

Loader.defaultProps = {
    style: {},
    className: "",
    visible: true,
    timeout: 0
};