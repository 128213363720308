import { Autocomplete, Grid, TextField } from '@mui/material';
import { debounce } from '@mui/material/utils';
import { Avatar } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Dropdown, DropdownButton, Form, InputGroup, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DataTable from '../../components/data-table/data-table.component';
import BoxLoader from '../../components/loading/BoxLoader';
import { formatPhoneNumber } from '../../methods/data-formatter';
import { setCurrentStaff } from '../../stores/current-staff.store';
import { listAllLocations } from '../patient/patient-search.service';
import { getAppointmentAvailableServices } from '../patient/service';
import StaffDetailsModal from './staff-details.modal';
import './staff-search.css';
import { autoCompleteStaff, listAllStaff, searchStaff } from './staff-search.service';

function StaffSearchPage() {
  const STAFF_PAGE = 0;
  const STAFF_LIMIT = 100;

  const queryOptions = [
    {
      value: 'id',
      text: 'Staff ID',
    },
    {
      value: 'firstName',
      text: 'First Name',
    },
    {
      value: 'lastName',
      text: 'Last Name',
    },
    {
      value: 'phoneNumber',
      text: 'Phone Number',
    },
    {
      value: 'email',
      text: 'Email Address',
    },
  ];

  const [loading, setloading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [currentQuery, setCurrentQuery] = useState(queryOptions[0]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [inputOptions, setInputOptions] = useState([]);
  const [showStaff, setShowStaff] = useState(false);
  const dispatch = useDispatch();
  const currentStaff = useSelector((state) => state.currentStaff);
  const [allLocations, setAllLocations] = useState([]);
  const [allServices, setAllServices] = useState([]);

  const fetchInput = useMemo(
    () =>
      debounce((option, request, callback) => {
        autoCompleteStaff(option, request).then(callback);
      }, 400),
    []
  );

  useEffect(() => {
    initLoadStaff();
    fetchAllLocations();
    fetchAllServices();
  }, []);

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setInputOptions(value ? [value] : []);
      return undefined;
    }

    fetchInput(currentQuery?.value, inputValue, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results?.data) {
          newOptions = [...newOptions, ...results.data];
        }

        setInputOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetchInput]);

  const initLoadStaff = () => {
    setloading(true);
    listAllStaff(STAFF_PAGE, STAFF_LIMIT).then((response) => {
      if (response.data.status === 200) {
        formatStaffData(response.data?.data);
      } else {
        alert(response.data.message);
      }
      setloading(false);
    });
  };

  const fetchAllLocations = () => {
    listAllLocations().then((response) => {
      if (response.data.status !== 200) {
        alert(response.data.message);
      } else {
        setAllLocations(response.data.data);
      }
    });
  };

  const fetchAllServices = () => {
    getAppointmentAvailableServices().then((response) => {
      if (response.data.status !== 200) {
        alert(response.data.message);
      } else {
        setAllServices(response.data.data);
      }
    });
  };

  const displayStaff = (staff) => {
    setCurrentStaff(staff);
    setShowStaff(true);
  };

  const tableSettings = {
    appendable: false,
    removable: false,
    bordered: false,
    size: 'middle',
    pagination: {
      showLessItems: true,
      pageSize: 20,
    },
    columns: [
      {
        title: 'Email Address',
        dataIndex: 'email',
      },
      {
        title: 'Phone Number',
        dataIndex: ['phoneNumber'],
      },
      {
        title: 'Role',
        dataIndex: 'role',
      },
      {
        title: 'Staff ID',
        dataIndex: 'staffId',
      },
    ],
    customActions: [
      {
        element: (record, data) => (
          <div>
            <Avatar style={{ margin: '0 0.5em' }} size={50} src={record.coverImage} />
            <Link
              to="#"
              onClick={() => {
                dispatch(setCurrentStaff(record));
                displayStaff(record);
              }}
            >
              {record.name}
            </Link>
          </div>
        ),
      },
    ],
    operationColumnTitle: 'Name',
    actionColumnIndex: 0,
  };

  const updateQueryOption = (eventKey) => {
    const text = queryOptions.find((option) => eventKey === option.value)?.text;

    const update = {
      value: eventKey,
      text: text,
    };
    setCurrentQuery(update);
  };

  const submitSearch = async (e) => {
    e.preventDefault();
    let queryValue = e.target.querySelector('#search-complete').value;
    try {
      let staffData;
      if (queryValue && queryValue.trim() !== '') {
        staffData = await searchStaff(currentQuery?.value, queryValue.trim());

        if (staffData && staffData.status === 200) {
          formatStaffData(staffData.data);
        } else {
          alert('Error fetching staff data!');
        }
      } else {
        initLoadStaff();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const formatStaffData = (staffData) => {
    let staffDataCopy = JSON.parse(JSON.stringify(staffData));
    staffDataCopy.forEach((staff) => {
      staff.name = (staff.title ? staff.title + ' ' : '') + staff.firstName + ' ' + staff.lastName;
      if (staff.phone) {
        staff.phoneNumber = formatPhoneNumber(staff.phone.number);
      }
    });
    setSearchResult(staffDataCopy);
  };

  return (
    <>
      <BoxLoader show={loading} loadingStyle={2} />
      {showStaff && (
        <StaffDetailsModal
          show={showStaff}
          staffId={currentStaff?.staffId}
          handleClose={() => setShowStaff(false)}
          allLocations={allLocations}
          allServices={allServices}
        ></StaffDetailsModal>
      )}
      <div style={{ marginTop: '60px', background: '#F0F2F5 0% 0% no-repeat padding-box', padding: 32 }}>
        <div className="d-flex justify-content-between">
          <h2>Staff Management</h2>
          <Link to="/staff/create">
            <Button>Create New Staff</Button>
          </Link>
        </div>
        <br />
        <div className="d-flex justify-content-between">
          <Form onSubmit={submitSearch} id="search-form">
            <InputGroup className="mb-3" id="search-input-group">
              <DropdownButton
                variant="outline-secondary"
                title={currentQuery.text}
                id="search-input-group-dropdown"
                onSelect={updateQueryOption}
              >
                {queryOptions.map((option) => (
                  <Dropdown.Item href="#" eventKey={option.value}>
                    {option.text}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <Autocomplete
                id="search-complete"
                size="small"
                filterOptions={(x) => x}
                options={inputOptions}
                autoComplete
                includeInputInList
                freeSolo
                fullWidth
                filterSelectedOptions
                value={value}
                noOptionsText="No results"
                onChange={(event, newValue) => {
                  setInputOptions(newValue ? [newValue, ...inputOptions] : inputOptions);
                  setValue(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Search by Staff ID, Name, Phone Number..." fullWidth />
                )}
                renderOption={(props, option) => {
                  return (
                    <li {...props}>
                      <Grid container alignItems="center">
                        <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                          {option}
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
              />
              <button id="search-button" type="submit">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </button>
            </InputGroup>
          </Form>
        </div>
        <Row className="section">
          <Col sm={12}>
            <Row className="section-content">
              <DataTable data={searchResult} settings={tableSettings} />
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default StaffSearchPage;
