import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Tabs} from "antd";

const ProcurementOrderPage = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const tabs = [
        {
            key: '1',
            text: 'Order Awaiting Shipment',
            route: 'shipment',
            disable: false
        },
        {
            key: '2',
            text: 'Order Tracking',
            route: 'tracking',
            disable: false
        }
    ];

    return (
        <>
            {
                !loading &&
                <div style={{marginTop: "60px", background: '#F0F2F5 0% 0% no-repeat padding-box', padding: 32}}>
                <h2>Order List</h2>
                <Tabs activeKey={location.pathname.split("/")[2] || "procurement"}
                      onTabClick={async (key) => {
                          navigate(`${key}`);
                      }}
                      style={{marginTop: "12px", marginBottom: "20px"}}
                >
                    {
                        tabs.map((tab) => {
                            return (
                                <Tabs.TabPane key={tab.route} tab={tab.text} disabled={tab.disable}>
                                    <div style={{margin: "12px 0"}}>
                                        <Outlet />
                                    </div>
                                </Tabs.TabPane>
                            )
                        })
                    }
                </Tabs>
            </div>
            }
        </>
    )
}
export default ProcurementOrderPage;