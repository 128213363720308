import React from 'react';

import '../../styles/marketing/marketing.css'
import '../../styles/marketing/promotion.css'
import '../../styles/layout_custom.css';

// import BoxLoader from 'components/loading/BoxLoader'
import bioApi from "../../api/bioApi";

import Chatbox from './Chatbox';
import { getChatboxRefs } from './ChatboxRefs';
import BoxLoader from '../../components/loading/BoxLoader';
import { message } from 'antd';

class ChatbotPresetBox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            chatbox: '',
            showContent: true,
        };
    }

    handleCreate() {
        this.chatboxRef.handleAdd();
    }
    handleSave(e) {
        const results = [];
        let isHas = false;
        for (var key in getChatboxRefs()) {
            if(getChatboxRefs()[key]){
                if (getChatboxRefs().hasOwnProperty(key)) {
                    if(getChatboxRefs()[key].getResult().text.en === '' || getChatboxRefs()[key].getResult().text.es === '' || getChatboxRefs()[key].getResult().text.es === undefined ){
                        isHas = true
                        break;
                    }
                    results.push(getChatboxRefs()[key].getResult());
                }
            }
        }
        if(isHas || results.length <= 0){
            message.error("is empty!")
        } else {
            this.setState({
                loading: true
            });
            var pathstring = '/chat/preset/update';
            try {
                bioApi.post(pathstring, results).then(response => {
                    this.setState({ loading: false, showContent: false })
                    setTimeout(() => {
                        this.setState({showContent: true})
                    }, 100)

                }).catch((err) => {
                    this.setState({ loading: false })
                })
            } catch (err) {
                this.setState({ loading: false })
                message.error('Network error');
            }
        }
    }

    render() {       
        return (
            <div>
                <div className="box">
                    {
                        this.state.loading? <BoxLoader show={this.state.loading} /> : null
                    }
                    <div className="box-marketing-hd box-header"  style={{ padding: "20px 30px" }}>
                        <div style={{ display: "flex" }}>
                            <h3 className="box-title" style={{ display: "flex", fontSize: '16px' }}>
                                <div style={{
                                    backgroundColor: "#0ecea8",
                                    width: 8,
                                    height: 8,
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                    borderRadius: 999
                                }}></div>
                                <span style={{ marginTop: "auto", marginBottom: "auto" }}>&nbsp; Chatbox preset</span>
                            </h3>
                            <div style={{ flex: 1 }}></div>
                            <div>
                                <button className="btn btn-success" onClick={this.handleSave.bind(this)} style={{marginRight: '2px'}}>Save</button>
                                {/* <button className="btn btn-blue" onClick={this.handleCreate.bind(this)}>Add</button> */}
                            </div>
                        </div>
                    </div>

                    <div className="box">
                        <div className="box-marketing-bd box-body table-responsive " >
                                    
                            <ul class="tree">
                                {this.state.showContent ? <Chatbox 
                                    parent={null}
                                    ref={ref => {
                                        this.chatboxRef = ref;
                                    }}
                                /> : null}
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
};

export default ChatbotPresetBox;
