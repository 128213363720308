import React from "react";
import {Col, Row} from "react-bootstrap";
import SettingInput from "../../../components/setting/SettingInput";
import DataTable from "../../../components/data-table/data-table.component";
import './patient.css';

const PaymentPage = () => {
    const [creditCardInfo, setCreditCardInfo] = React.useState([]);
    const [addressInfo, setAddressInfo] = React.useState([]);
    const [paymentHistoryInfo, setPaymentHistoryInfo] = React.useState([]);

    const tableSettings = {
        appendable: false,
        removable: false,
        bordered : false,
        size: 'middle',
        pagination : {
            'showLessItems' :true,
            'pageSize' : 6,
        },
        columns: [
            {
                title: 'Date',
                dataIndex: 'date',
            },
            {
                title: 'Description',
                dataIndex: 'description',
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
            },
        ]
    }
    const fetchPatientPaymentInfo = () => {
        const creditCardData = [
            {
                'label': 'Card Type',
                'editable': true,
                'value': 'Mastercard',
                'editingRender': {'elementType': 'text'}
            },
            {
                'label': 'Card Name',
                'editable': true,
                'value': 'DAVID C SMITH',
                'editingRender': {'elementType': 'text'}
            },
            {
                'label': 'Credit Card Number',
                'editable': true,
                'value': '5377620000116037',
                'editingRender': {'elementType': 'text'}
            },
            {
                'label': 'Card Expiration Month',
                'editable': true,
                'value': '',
                'editingRender': {'elementType': 'text'}
            },
            {
                'label': 'Card Expiration Year',
                'editable': true,
                'value': '',
                'editingRender': {'elementType': 'text'}
            },
            {
                'label': 'Card Security Code',
                'editable': true,
                'value': '012',
                'editingRender': {'elementType': 'text'}
            }
        ];

        const addressData = [
            {
                'label': 'Billing Address',
                'editable': true,
                'value': '900 Melaleuca Rd Delray Beach, FL 33483 United States',
                'editingRender': {'elementType': 'text'}
            },
            {
                'label': 'Shipping Address',
                'editable': true,
                'value': '900 Melaleuca Rd Delray Beach, FL 33483 United States',
                'editingRender': {'elementType': 'text'},
            },
        ];
        const paymentData = [
            {
                _id:'1',
                date: '2/12/2022',
                description : 'IV Push',
                amount : '$0.00',
            },
            {
                _id:'2',
                date: '2/12/2022',
                description : 'IV Push',
                amount : '$0.00',
            },
            {
                _id:'3',
                date: '2/12/2022',
                description : 'IV Push',
                amount : '$0.00',
            },
        ]
        setCreditCardInfo(creditCardData);
        setAddressInfo(addressData);
        setPaymentHistoryInfo(paymentData);
    };

    React.useEffect(() => {
        fetchPatientPaymentInfo();
    }, []);

    return (
        <>
            <Row className="profile">
                <Col sm={12}>
                    <h2 className="section-title">Credit Card Information</h2>
                </Col>
                <Col sm={12}>
                    <Row className="section-content">
                        <Col className="section-column" sm={12}>
                            {
                                creditCardInfo.map((detail, i) => {
                                    return (
                                        <SettingInput
                                            key={i}
                                            label={detail.label}
                                            value={detail.value}
                                            editable={detail.editable}
                                            editingRender={detail.editingRender}
                                        />
                                    )
                                })}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="section">
                <Col sm={12}>
                    <h2 className="section-title">Address Information</h2>
                </Col>
                <Col sm={12}>
                    <Row className="section-content">
                        <Col className="section-column" sm={12}>
                            {
                                addressInfo.map((detail, i) => {
                                    return (
                                        <SettingInput
                                            key={i}
                                            label={detail.label}
                                            value={detail.value}
                                            editable={detail.editable}
                                            editingRender={detail.editingRender}
                                        />
                                    )
                                })}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="section">
                <Col sm={12}>
                    <h2 className="section-title">Payment History</h2>
                </Col>
                <Col sm={12}>
                    <Row className="section-content">
                         <DataTable data={paymentHistoryInfo} settings={tableSettings}/>
                    </Row>
                </Col>
            </Row>
        </>
    )
};

export default PaymentPage;