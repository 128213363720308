import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Collapse,
  Empty,
  message,
  Modal,
  notification,
  Popconfirm,
  Table,
  Typography,
} from 'antd';
import bioApi from '../../../../api/bioApi';
import { LIST_RIGHT_URL, LIST_ROLE_URL, MANAGE_RIGHT_URL, MANAGE_ROLE_URL } from '../../../../api/URLs';
import DataTable from '../../../../components/data-table/data-table.component';
import { DeleteTwoTone } from '@ant-design/icons';
import { UserContext } from '../../../../App';
import _ from 'lodash';
import Search from 'antd/lib/input/Search';

const RightsAndRolesPage = () => {
  const [allRights, setAllRights] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [originalAllRoles, setOriginalAllRoles] = useState([]);
  const [rightLoading, setRightLoading] = useState(true);
  const [roleLoading, setRoleLoading] = useState(true);
  const [roleTableColumns, setRoleTableColumns] = useState([]);
  const [roleTableData, setRoleTableData] = useState([]);
  const [defaultExpandedRowKeys, setDefaultExpandedRowKeys] = useState([]);
  const [noRoleIsChanged, setNoRoleIsChanged] = useState(true);
  const [applyingRoleChange, setApplyingRoleChange] = useState(false);

  const user = useContext(UserContext);

  const rightTableSetting = {
    editable: user.rightList.includes('Right_Upsert'),
    removable: user.rightList.includes('Right_Upsert'),
    appendable: user.rightList.includes('Right_Upsert'),
    columns: [
      {
        title: 'Right Name',
        dataIndex: 'name',
        width: '25%',
        nameWhenAppending: ['newName'],
        editRender: false,
        newRecordInputRender: 'input',
      },
      {
        title: 'Description',
        width: '45%',
        dataIndex: 'description',
        nameWhenAppending: ['newDescription'],
        editRender: 'input',
        render: (text) => <Typography.Text type={'secondary'}>{text}</Typography.Text>,
      },
      {
        title: 'Group',
        width: '20%',
        dataIndex: 'group',
        nameWhenAppending: ['newGroup'],
        editRender: 'input',
        render: (text) => <Typography.Text type={'secondary'}>{text}</Typography.Text>,
        filterable: true,
        sorter: (a, b) => (a.group === b.group ? 0 : a.group > b.group ? 1 : -1),
      },
    ],
    onNewRecordCreate: async (row) => {
      setRightLoading(true);
      try {
        const res = await bioApi.post(MANAGE_RIGHT_URL, {
          name: row.newName,
          group: row.newGroup,
          description: row.newDescription,
        });
        const newRightObj = {
          _id: res.data._id,
          name: row.newName,
          group: row.newGroup,
          description: row.newDescription,
          __newRecord__: true,
        };
        setAllRights([...allRights, newRightObj]);
        return Promise.resolve({
          type: 'success',
          text: `New right [${row.newName}] has been added!`,
        });
      } catch (reason) {
        console.error(reason);
        return Promise.reject({
          type: 'error',
          text: 'Failed to add new right',
        });
      } finally {
        setRightLoading(false);
      }
    },
    onRecordEdit: async (recordKey, row) => {
      setRightLoading(true);
      const index = allRights.findIndex((item) => recordKey === item._id);

      const item = allRights[index];
      try {
        await bioApi.put(MANAGE_RIGHT_URL + '/' + item.name, { ...row });
        allRights.splice(index, 1, { ...item, ...row });
        setAllRights([...allRights]);
        return Promise.resolve({
          type: 'success',
          text: `Right [${item.name}] has been updated!`,
        });
      } catch (reason) {
        console.error(reason);
        return Promise.reject({
          type: 'error',
          text: `Failed to update Right [${item.name}]`,
        });
      } finally {
        setRightLoading(false);
      }
    },
    onRecordDelete: async (recordKey) =>
      Promise.reject({
        type: 'info',
        text: `Uncheck this Right from all Roles and contact developer to remove this`,
      }),
  };

  function areArraysEqualIgnoreOrder(arr1, arr2) {
    const sortedArr1 = JSON.stringify(arr1.map(JSON.stringify)).split('').sort().join('');
    const sortedArr2 = JSON.stringify(arr2.map(JSON.stringify)).split('').sort().join('');

    // Compare the sorted JSON strings
    return sortedArr1 === sortedArr2;
  }

  const confirmDeleteRole = (e) => {
    setAllRoles(allRoles.filter((role) => role.name !== e));
  };

  const addNewRole = (value) => {
    if (
      !value ||
      value.toLowerCase() === 'developer' ||
      allRoles.find((role) => role.name.toLowerCase() === value.toLowerCase())
    ) {
      message.error('invalid or duplicated role name');
      return;
    }
    allRoles.push({ name: value, associatedRights: [] });
    setAllRoles([...allRoles]);
  };

  const roleChange = (roleName, rightName, checked) => {
    if (checked) {
      const associatedRights = allRoles.find((role) => role.name === roleName).associatedRights;
      if (!associatedRights.includes(rightName)) {
        allRoles.find((role) => role.name === roleName).associatedRights.push(rightName);
        setAllRoles([...allRoles]);
      }
    } else {
      const toRemovedIndex = allRoles
        .find((role) => role.name === roleName)
        .associatedRights.findIndex((right) => right === rightName);
      if (toRemovedIndex !== -1) {
        allRoles.find((role) => role.name === roleName).associatedRights.splice(toRemovedIndex, 1);
        setAllRoles([...allRoles]);
      }
    }
  };

  const selectOrUnselectAll = (checked, role, groupName) => {
    const thisGroupRights = allRights.filter((ar) => ar.group === groupName).map((r) => r.name);
    if (checked) {
      role.associatedRights = _.union(role.associatedRights, thisGroupRights);
    } else {
      role.associatedRights = role.associatedRights.filter((r) => !thisGroupRights.includes(r));
    }
    setAllRoles([...allRoles]);
  };

  const confirmRoleChange = () => {
    //setApplyingRoleChange(true);
    const newRoles = _.differenceBy(allRoles, originalAllRoles, (a) => a.name);
    const deletedRoles = _.differenceBy(originalAllRoles, allRoles, (a) => a.name);
    const changedRoles = [];
    const allRolesStayed = allRoles.filter((role) => originalAllRoles.find((or) => or.name === role.name));
    allRolesStayed.forEach((role) => {
      const correspondingOriginalRole = originalAllRoles.find((or) => or.name === role.name);
      if (!_.isEqual(_.sortBy(correspondingOriginalRole.associatedRights), _.sortBy(role.associatedRights))) {
        changedRoles.push(role);
      }
    });

    const payload = {
      newRoles,
      deletedRoles,
      changedRoles,
    };

    Modal.confirm({
      title: 'Confirm your changes',
      content: (
        <Collapse defaultActiveKey={['1', '2', '3']}>
          <Collapse.Panel
            header={
              <>
                Role(s) to be <span style={{ color: '#3f8600' }}>created:</span>
              </>
            }
            key="1"
          >
            <div>
              {newRoles.map((r) => r.name).join(', ') || (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />
              )}
            </div>
          </Collapse.Panel>
          <Collapse.Panel
            header={
              <>
                Role(s) to be <span style={{ color: '#cf1322' }}>deleted:</span>
              </>
            }
            key="2"
          >
            <div>
              {deletedRoles.map((r) => r.name).join(', ') || (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />
              )}
            </div>
          </Collapse.Panel>
          <Collapse.Panel
            header={
              <>
                Role(s) to be <span style={{ color: '#16c1f6' }}>modified:</span>
              </>
            }
            key="3"
          >
            <div>
              {changedRoles.map((r) => r.name).join(', ') || (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />
              )}
            </div>
          </Collapse.Panel>
        </Collapse>
      ),
      okText: 'Confirm Role Change',
      onOk: () =>
        bioApi
          .post(MANAGE_ROLE_URL, payload)
          .then(() => {
            setOriginalAllRoles(JSON.parse(JSON.stringify(allRoles)));
            notification.success({ message: 'Role changes have been applied', duration: 10 });
            return true;
          })
          .catch(() => {
            notification.error({ message: 'Unable to apply role changes!', duration: 10 });
            return true;
          }),
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const rightsResponse = await bioApi.get(LIST_RIGHT_URL);
        const rolesResponse = await bioApi.get(LIST_ROLE_URL);
        setAllRights(rightsResponse.data);
        setAllRoles(rolesResponse.data);
        setOriginalAllRoles(JSON.parse(JSON.stringify(rolesResponse.data)));
      } catch (e) {
        console.error('Unable to fetch rights');
      } finally {
        setRightLoading(false);
        setRoleLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const roleTableData = [];
    const rowKeys = [];
    allRights.forEach((right) => {
      allRoles.forEach((role) => {
        const thisRoleContainsThisRight = !!role.associatedRights.includes(right.name);
        const thisRightGroup = right.group;
        const existingGroup = roleTableData.find((d) => d.rightName === thisRightGroup);
        if (existingGroup) {
          const existingRight = existingGroup.children.find((d) => d._id === right._id);
          if (existingRight) {
            existingRight[role.name] = thisRoleContainsThisRight;
          } else {
            existingGroup.children.push({
              _id: right._id,
              key: right._id,
              rightName: right.name,
              [role.name]: thisRoleContainsThisRight,
            });
          }
        } else {
          roleTableData.push({
            _id: 'GROUP_' + thisRightGroup.replaceAll(' ', ''),
            key: 'GROUP_' + thisRightGroup.replaceAll(' ', ''),
            rightName: thisRightGroup,
            children: [
              {
                _id: right._id,
                key: right._id,
                rightName: right.name,
                [role.name]: thisRoleContainsThisRight,
              },
            ],
          });
          rowKeys.push('GROUP_' + thisRightGroup.replaceAll(' ', ''));
        }
      });
    });
    setDefaultExpandedRowKeys(rowKeys);
    setRoleTableData(roleTableData);
  }, [allRights, allRoles]);

  useEffect(() => {
    setNoRoleIsChanged(areArraysEqualIgnoreOrder(allRoles, originalAllRoles));
  }, [allRoles, originalAllRoles]);

  useEffect(() => {
    const roleTableColumns = [
      {
        title: (
          <>
            {allRoles.length ? (
              <Search placeholder="new role name" enterButton="Add Role" size="middle" onSearch={addNewRole} />
            ) : (
              <></>
            )}
          </>
        ),
        dataIndex: 'rightName',
        width: 300,
      },
    ];
    allRoles.forEach((role) => {
      roleTableColumns.push({
        dataIndex: role.name,
        title: (
          <>
            {role.name}{' '}
            <Popconfirm
              title="Delete Role"
              description="Are you sure to delete this role?"
              onConfirm={() => confirmDeleteRole(role.name)}
              okText="Yes"
              cancelText="No"
            >
              <Button danger type="text" shape="circle" icon={<DeleteTwoTone twoToneColor="#eb2f96" />} />
            </Popconfirm>
          </>
        ),
        render: (text, row) => {
          if (row.key.includes('GROUP_')) {
            return (
              <Checkbox
                indeterminate={(() => {
                  const group = row.rightName;
                  const thisGroupRights = allRights.filter((ar) => ar.group === group).map((r) => r.name);
                  const intersection = _.intersection(role.associatedRights, thisGroupRights);
                  if (intersection.length) {
                    return !_.isEqual(_.sortBy(intersection), _.sortBy(thisGroupRights));
                  }
                  return false;
                })()}
                checked={(() => {
                  const group = row.rightName;
                  const thisGroupRights = allRights.filter((ar) => ar.group === group).map((r) => r.name);
                  const intersection = _.intersection(role.associatedRights, thisGroupRights);
                  if (intersection.length) {
                    return _.isEqual(_.sortBy(intersection), _.sortBy(thisGroupRights));
                  }
                  return false;
                })()}
                onChange={(e) => selectOrUnselectAll(e.target.checked, role, row.rightName)}
              />
            );
          } else {
            return (
              <Checkbox
                checked={row[role.name]}
                onChange={() => roleChange(role.name, row.rightName, !row[role.name])}
              />
            );
          }
        },
      });
    });
    setRoleTableColumns(roleTableColumns);
  }, [allRoles.length, noRoleIsChanged]);

  return (
    <div className="pt-3 ps-3 pe-3 pb-0">
      <Card title="Right Management" style={{ marginBottom: 8 }}>
        <DataTable data={allRights} settings={rightTableSetting} loading={rightLoading} />
      </Card>
      <Card
        title="Role Configuration"
        extra={
          <Button disabled={noRoleIsChanged || applyingRoleChange} onClick={confirmRoleChange}>
            Confirm Changes
          </Button>
        }
      >
        <Table
          dataSource={roleTableData}
          columns={roleTableColumns}
          pagination={false}
          scroll={{ y: 480 }}
          loading={roleLoading || applyingRoleChange}
          expandable={{
            defaultExpandedRowKeys: defaultExpandedRowKeys,
          }}
          locale={{ emptyText: 'No Role' }}
        />
      </Card>
    </div>
  );
};

export default RightsAndRolesPage;
