let _chatboxRefs = [];

const addChatboxRef = (key, ref) => {
    _chatboxRefs[key] = ref;
}

const getChatboxRefs = () => {
    return _chatboxRefs;
}

export {
    addChatboxRef,
    getChatboxRefs
}