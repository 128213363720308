import React, {useEffect, useState} from "react";
import DataTable from "../../../../../components/data-table/data-table.component";
import bioApi from "../../../../../api/bioApi";

const PatientsGroup = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const dataTableSettings = {
        tableHeader: <h4>Patients Group</h4>,
        editable: true,
        removable: true,
        numbered: true,
        appendable: true,
        columns: [
            {
                title: 'Group Name',
                dataIndex: 'name',
                width: '40%',
                nameWhenAppending: ['newGroupName'],
                editRender: "input"
            },
            {
                title: 'Patients',
                width: '40%',
                dataIndex: 'patients',
                nameWhenAppending: [
                    'newPatients',
                ],
                editRender: 'input'
            }
        ],
        onNewRecordCreate: async (row) => {
            const newData = [{
                key: data.length,
                name: row.newRecordName,
                patients: row.newRecordPatients
            }, ...data];
            setData(newData);
            return Promise.resolve({
                type: 'success',
                text: ``
            })
        },
        onRecordEdit: async (recordKey, row) => {
            const newData = [...data];
            const index = newData.findIndex((item) => recordKey === item.key);

            const item = newData[index];
            newData.splice(index, 1, {...item, ...row});
            setData(newData);
            return Promise.resolve({
                type: 'success',
                text: ``
            })
        },
        onRecordDelete: async (record) => {
            setData(data.filter(item => item.key !== record._id));
            return Promise.resolve({
                type: 'success',
                text: ``
            })
        },
    }

    useEffect(() => {
        requestData();
    }, []);

    const requestData = async () => {
        setLoading(true);
        const result = await bioApi.get('')
            .then(res => res.data)
            .catch(err => ({error: true, message: err.message}))

        setLoading(false);

        if (result.status === 1) {
            setData(result.data);
        } else {
            console.error(result);
        }
    }

    return (
        <DataTable data={data} settings={dataTableSettings} loading={loading}/>
    );
}

export default PatientsGroup;