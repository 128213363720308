import React from "react";
import {Col, Row} from "react-bootstrap";
import SettingInput from "../../../components/setting/SettingInput";
import './patient.css';
import {useDispatch, useSelector} from "react-redux";
import {calculateAge, updatePatientRawData} from "../service";
import {updatePatientDetailsInStore} from "../../../stores/current-patient.store";

const DetailsPage = () => {
    const currentPatient = useSelector(state => state.currentPatient);
    const dispatch = useDispatch();

    const [patientDetails, setPatientDetails] = React.useState([]);
    const [ptNotes, setPtNotes] = React.useState([]);
    const [businessInfo, setBusinessInfo] = React.useState([]);
    const [systemInfo, setSystemInfo] = React.useState([]);
    
    const updatePatientData = async (field, value) => {
        await updatePatientRawData(currentPatient.patientId, field, value);
        await dispatch(updatePatientDetailsInStore({key: field, value}));
    }

    const patchPatientDetails = () => {
        const details = currentPatient.details;
        const profileData = [
            {
                'label': 'Account Type',
                'editable': true,
                'value': details.account_type,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('account_type', value)
                }
            },
            {
                'label': 'Account Owner',
                'editable': true,
                'value': details.ownerid,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('ownerid', value)
                }
            },
            {
                'label': 'Account Status',
                'editable': true,
                'value': details.account_status,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('account_status', value)
                }
            },
            {
                'label': 'Account Disposition',
                'editable': true,
                'value': details.account_disposition,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('account_disposition', value)
                }
            },
            {
                'label': 'Description',
                'editable': true,
                'value': details.description,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('description', value)
                }
            },
            {
                'label': 'Parent Account',
                'editable': true,
                'value': details.parentid,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('parentid', value)
                }
            },
            {
                'label': 'Title',
                'editable': true,
                'value': details.title,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('title', value)
                }
            },
            {
                'label': 'First Name',
                'editable': true,
                'value': currentPatient.firstName,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('firstName', value)
                }
            },
            {
                'label': 'Last Name',
                'editable': true,
                'value': currentPatient.lastName,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('lastName', value)
                }
            },
            {
                'label': 'Nickname',
                'editable': true,
                'value': details.nickname,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('nickname', value)
                }
            },
            {
                'label': 'Primary Service Location',
                'editable': true,
                'value': details.primary_service_location,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('primary_service_location', value)
                }
            },
            {
                'label': 'Referral Contact',
                'editable': true,
                'value': details.referral_contact,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('referral_contact', value)
                }
            },
            {
                'label': 'Referral Contact Account Owner',
                'editable': true,
                'value': details.referral_contact_account_owner,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('referral_contact_account_owner', value)
                }
            },
            {
                'label': 'Affiliate Referral Name',
                'editable': true,
                'value': details.affiliate_referral_name,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('affiliate_referral_name', value)
                }
            },
            {
                'label': 'Employee Referral Name',
                'editable': true,
                'value': details.employee_referral_name,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('employee_referral_name', value)
                }
            },
            {
                'label': 'Gender',
                'editable': true,
                'value': details.gender,
                'editingRender': {
                    'elementType': 'dropdown',
                    'items': ['Male', 'Female'],
                    'save': (value) => updatePatientData('gender', value)
                }
            },
            {
                'label': 'Menstrual Cycle',
                'editable': details.gender === 'Female' || details.gender === 'female',
                'value': (details.gender === 'Female' || details.gender === 'female') ? details.menstrual_cycle : 'N/A',
                'editingRender': {
                    'elementType': 'dropdown',
                    'items': ["--None--", "Luteal", "Menopausal", "Birth Control", "Unknown"],
                    'save': (value) => updatePatientData('menstrual_cycle', value)
                }
            },
            {
                'label': 'Weight',
                'editable': true,
                'value': details.weight,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('weight', value)
                }
            },
            {
                'label': 'Height',
                'editable': true,
                'value': details.height,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('height', value)
                }
            },
            {
                'label': 'Request To Review',
                'editable': true,
                'value': JSON.parse((details.request_to_review || 'false').toLowerCase()),
                'editingRender': {
                    'elementType': 'checkbox',
                    'save': (value) => updatePatientData('request_to_review', JSON.stringify(value))
                }
            },
            {
                'label': 'Patient Review Notes',
                'editable': true,
                'value': details.patient_review_notes,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('patient_review_notes', value)
                }
            },
            {
                'label': 'Most Recent PA Phone Call',
                'editable': true,
                'value': details.most_recent_phone_call,
                'editingRender': {
                    'elementType': 'date',
                    'save': (value) => updatePatientData('most_recent_phone_call', value)
                }
            },
            {
                'label': 'Most Recent KF Call',
                'editable': true,
                'value': details.most_recent_kf_call,
                'editingRender': {
                    'elementType': 'date',
                    'save': (value) => updatePatientData('most_recent_kf_call', value)
                }
            },
            {
                'label': 'Most Recent AR Call',
                'editable': true,
                'value': details.most_recent_ar_call,
                'editingRender': {
                    'elementType': 'date',
                    'save': (value) => updatePatientData('most_recent_ar_call', value)
                }
            },
            {
                'label': 'Partner',
                'editable': true,
                'value': 'XXX',
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('', value)
                }
            },
            {
                'label': 'Holiday Card',
                'editable': true,
                'value': details.holiday_card,
                'editingRender': {
                    'elementType': 'dropdown',
                    'items': ['--None--', 'VIP Patient Card', 'Expired Patient Card'],
                    'save': (value) => updatePatientData('holiday_card', value)
                }
            },
            {
                'label': 'Account Source',
                'editable': true,
                'value': details.account_source || "",
                'editingRender': {
                    'elementType': 'dropdown',
                    'items': [
                        "--None--",
                        "AdWords",
                        "Affiliate - FIO",
                        "Affiliate - OTF Manny",
                        "Affiliate - PIT Fitness",
                        "Affiliate Referral",
                        "Affiliate - Sweat 440",
                        "Affiliate - TBT",
                        "Amli",
                        "Aqua-Jupiter",
                        "Auberge",
                        "b12/bLean Tokens",
                        "Barry's Bootcamp",
                        "Beauty Pass",
                        "BHRC",
                        "Bing Ads",
                        "Bing Natural Search",
                        "Boca Beach Club Pop Up",
                        "Boca Magazine",
                        "Boca New Club Member Text",
                        "Boca Resort Direct Mail - 2021",
                        "Boca Resort Marketing",
                        "Body Project Movement",
                        "CAMP",
                        "Carillon",
                        "Carillon Member",
                        "Carillon Retreat",
                        "City Fitness",
                        "Club Pilates",
                        "Core LT Team",
                        "Direct Mail",
                        "Dr. Gallo",
                        "Dr. Hermann",
                        "Dr. Matez List",
                        "Dr. Referral",
                        "Dr. Yan Trokel",
                        "Email",
                        "Employee Referral",
                        "Erin & Ginger Miami",
                        "Event",
                        "Facebook",
                        "Gio",
                        "Google Natural Search",
                        "Grand Wailea",
                        "Hyde",
                        "Instagram",
                        "Jupiter Direct Mail 2021",
                        "LinkedIn",
                        "Live Chat",
                        "Local Search Google+",
                        "LT Members",
                        "LT Tampa",
                        "Miami Direct Mail - 2021",
                        "Miami Modern Luxury Mag",
                        "Midtown 29",
                        "Midtown 4",
                        "Midtown 5 Building",
                        "Mizner Country Club",
                        "Newspaper",
                        "One Thousand Museum",
                        "OSW",
                        "Other",
                        "Radio - ED Commercial",
                        "RealSelf.com",
                        "Referral of current patient",
                        "Steve Kane",
                        "Tampa $199 Promo Card",
                        "TBT Mag",
                        "Walk-In",
                        "Waze",
                        "Website",
                        "West Delray Direct Mail - 2021",
                        "Williams Island",
                        "Williams Island Direct Mail",
                        "Word of mouth",
                        "Yahoo! Natural Search",
                        "Yard 8",
                        "Yelp",
                        "Yelp Miami"
                    ],
                    'save': (value) => updatePatientData('account_source', value)
                }
            },
            {
                'label': 'Preferred Communication Method',
                'editable': true,
                'value': details.preferred_communication_method,
                'editingRender': {
                    'elementType': 'multi-select',
                    'delimiter': ";",
                    'items': ['DO NOT CONTACT', 'PHONE CALL', 'EMAIL', 'TEXT', 'Only'],
                    'save': (value) => updatePatientData('preferred_communication_method', value)
                }
            },
            {
                'label': 'Phone',
                'editable': true,
                'value': details.phone,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('phone', value)
                }
            },
            {
                'label': 'Mobile',
                'editable': true,
                'value': details.mobile,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('mobile', value)
                }
            },
            {
                'label': 'Fax',
                'editable': true,
                'value': details.fax,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('fax', value)
                }
            },
            {
                'label': 'SMS Opt Out',
                'editable': true,
                'value': JSON.parse((details.sms_opt_out || 'false').toLowerCase()),
                'editingRender': {
                    'elementType': 'checkbox',
                    'save': (value) => updatePatientData('sms_opt_out', JSON.stringify(value))
                }
            },
            {
                'label': 'Email Address',
                'editable': true,
                'value': details.email,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('email', value)
                }
            },
            {
                'label': 'Date of Birth',
                'editable': true,
                'value': details.date_of_birth,
                'editingRender': {
                    'elementType': 'date',
                    'save': (value) => updatePatientData('date_of_birth', value)
                }
            },
            {
                'label': 'Patient Age',
                'editable': false,
                'value': calculateAge(details.date_of_birth),
                'editingRender': {
                    'elementType': 'text',
                }
            },
            {
                'label': 'Driver License',
                'editable': true,
                'value': details.driver_license,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('driver_license', value)
                }
            },
            {
                'label': 'SSN',
                'editable': true,
                'value': details.ssn,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('ssn', value)
                }
            },
        ];
        const ptData = [
            {
                'label': 'Dr Bloom Note',
                'editable': true,
                'value': details.dr_bloom_note,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('dr_bloom_note', value)
                }
            },
            {
                'label': 'Patient Advocate Notes',
                'editable': true,
                'value': details.general_notes,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('general_notes', value)
                },
            },
            {
                'label': 'Practitioner Name',
                'editable': true,
                'value': details.practitioner_name,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('practitioner_name', value)
                },
            },
            {
                'label': 'Practitioner Phone',
                'editable': true,
                'value': details.practitioner_phone,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('practitioner_phone', value)
                },
            },
            {
                'label': 'Practitioner Fax',
                'editable': true,
                'value': details.practitioner_fax,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('practitioner_fax', value)
                },
            },
            {
                'label': 'Pardot Comments',
                'editable': true,
                'value': details.pardotcomments,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('pardotcomments', value)
                },
            },

        ];
        const businessData = [
            {
                'label': 'First Opportunity',
                'editable': false,
                'value': details.first_opportunity,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('first_opportunity', value)
                },
            },
            {
                'label': 'Total Won Opportunities',
                'editable': false,
                'value': details.total_won_opportunities,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('total_won_opportunities', value)
                },
            },
            {
                'label': 'Closed Won Blood Opp',
                'editable': false,
                'value': details.closed_won_blood_opp,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('closed_won_blood_opp', value)
                },
            },
            {
                'label': 'Closed Won HRT Opps',
                'editable': false,
                'value': details.closed_won_protocol_opp,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('closed_won_protocol_opp', value)
                },
            },
            {
                'label': 'Closed Won IV Infusion Opp',
                'editable': false,
                'value': details.closed_won_iv_infusion_opp,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('closed_won_iv_infusion_opp', value)
                },
            },
            {
                'label': 'Closed Won ED Opportunities',
                'editable': false,
                'value': details.closed_won_ed_opportunities,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('closed_won_ed_opportunities', value)
                },
            },
            {
                'label': 'Most Recent Opportunity',
                'editable': false,
                'value': details.most_recent_opportunity,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('most_recent_opportunity', value)
                },
            },
            {
                'label': 'Total Spend',
                'editable': false,
                'value': details.total_spend,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('total_spend', value)
                },
            },
            {
                'label': 'Most Recent IV Opp Date',
                'editable': false,
                'value': details.most_recent_iv_opp_date_pc,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('most_recent_iv_opp_date_pc', value)
                },
            },
            {
                'label': 'Most Recent Full Protocol Opportunity',
                'editable': false,
                'value': details.most_recent_full_protocol_pc,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('most_recent_full_protocol_pc', value)
                },
            },
            {
                'label': 'Most Recent HRT Opp Date',
                'editable': false,
                'value': details.most_recent_hrt_opportunity_pc,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('most_recent_hrt_opportunity_pc', value)
                },
            },
            {
                'label': 'Most Recent OTC Opportunity',
                'editable': false,
                'value': details.most_recent_otc_opportunity,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('most_recent_otc_opportunity', value)
                },
            },
            {
                'label': 'Most Recent ED Opportunity',
                'editable': false,
                'value': details.most_recent_ed_opportunity,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('most_recent_ed_opportunity', value)
                },
            },

        ];
        const systemData = [
            {
                'label': 'Created By',
                'editable': false,
                'value': `${details.createdbyid}, ${details.createddate}`,
                'editingRender': {'elementType': 'text'}
            },
            {
                'label': 'Account Name',
                'editable': true,
                'value': details.name,
                'editingRender': {
                    'elementType': 'text',
                    'save': (value) => updatePatientData('name', value)
                }
            },
            {
                'label': 'Last Modified By',
                'editable': false,
                'value': `${details.lastmodifiedbyid}, ${details.lastmodifieddate}`,
                'editingRender': {'elementType': 'text'}
            },
            {
                'label': 'Account Record Type',
                'editable': false,
                'value': details.record_type_id,
                'editingRender': {'elementType': 'text'}
            },

        ];

        setPtNotes(ptData);
        setBusinessInfo(businessData);
        setSystemInfo(systemData);
        setPatientDetails(profileData);
    };

    React.useEffect(() => {
        if (currentPatient) {
            patchPatientDetails();
        }
    }, [currentPatient]);

    return (
        <>
            <Row className="profile">
                <Col sm={12}>
                    <h2 className="section-title">Patient Profile</h2>
                </Col>
                <Col sm={12}>
                    <Row className="section-content">
                        <Col className="section-column" sm={12}>
                            {
                                patientDetails.map((detail, i) => {
                                    return (
                                        <SettingInput
                                            key={i}
                                            label={detail.label}
                                            value={detail.value}
                                            editable={detail.editable}
                                            editingRender={detail.editingRender}
                                        />
                                    )
                                })}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="notes-physician section">
                <Col sm={12}>
                    <h2 className="section-title">PT Notes for physician</h2>
                </Col>
                <Col sm={12}>
                    <Row className="section-content">
                        <Col className="section-column" sm={12}>
                            {
                                ptNotes.map((detail, i) => {
                                    return (
                                        <SettingInput
                                            key={i}
                                            label={detail.label}
                                            value={detail.value}
                                            editable={detail.editable}
                                            editingRender={detail.editingRender}
                                        />
                                    )
                                })}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="business-info section">
                <Col sm={12}>
                    <h2 className="section-title">Patient Business Info</h2>
                </Col>
                <Col sm={12}>
                    <Row className="section-content">
                        <Col className="section-column" sm={12}>
                            {
                                businessInfo.map((detail, i) => {
                                    return (
                                        <SettingInput
                                            key={i}
                                            label={detail.label}
                                            value={detail.value}
                                            editable={detail.editable}
                                            editingRender={detail.editingRender}
                                        />
                                    )
                                })}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="system-info section">
                <Col sm={12}>
                    <h2 className="section-title">System Information</h2>
                </Col>
                <Col sm={12}>
                    <Row className="section-content">
                        <Col className="section-column" sm={12}>
                            {
                                systemInfo.map((detail, i) => {
                                    return (
                                        <SettingInput
                                            key={i}
                                            label={detail.label}
                                            value={detail.value}
                                            editable={detail.editable}
                                            editingRender={detail.editingRender}
                                        />
                                    )
                                })}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
};

export default DetailsPage;