import { Button, Modal } from 'react-bootstrap';

const ConfirmationPopup = (props) => {

    const { show, title, body, onConfirm, onClose } = props;


    const handleConfirm = () => {
        onClose();
        onConfirm();
    };

    const handleCancel = () => {
        onClose();
    };

    return (
        <>
            <Modal show={show} onHide={handleCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{body}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleConfirm}>
                        Confirm
                    </Button>
                    <Button variant="secondary" onClick={handleCancel}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ConfirmationPopup;
