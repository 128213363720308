import React from "react";
import AgeGroup from "./elements/age-group";
import ActivenessGroup from "./elements/activeness-group";
import LapsedTimeGroup from "./elements/lapsed-time-group";
import PatientsGroup from "./elements/patient-group";

const GroupSettingsPage = () => {

    return (
        <>
            <AgeGroup />
            <ActivenessGroup />
            <LapsedTimeGroup />
            <PatientsGroup />
        </>
    )
}

export default GroupSettingsPage;