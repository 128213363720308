import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Biostation from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import store from './stores'
import {Provider} from "react-redux";
import {App} from "antd";

ReactDOM.render(
    <App>
        <BrowserRouter>
            <React.StrictMode>
                <Provider store={store}>
                    <Biostation />
                </Provider>
            </React.StrictMode>
        </BrowserRouter>
    </App>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
