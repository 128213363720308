// Auth
export const LOGIN_URL = '/auth/login';
export const AUTH_CHECK_URL = '/auth/ping';

export const UPLOAD_IMAGE_URL = '/utils/upload-image';

export const LOCATION_SAVE_URL = '/location/save';
export const LOCATION_LIST_URL = '/location/list';
export const LOCATION_DELETE_URL = '/location/deleteOne';
export const LOCATION_SHORT_LIST = '/location/shortList';

export const PATIENTS_LIST_URL = '/patient/list';
export const PATIENTS_INFO_LIST_URL = '/patient/infolist';

export const PATIENTS_CHECK_EXIST_URL = '/patient/checkExist';

export const MARKETING_LIST_URL = '/newsAndUpdate/list';
export const MARKETING_CREATE_URL = '/newsAndUpdate/creatematerial';

export const NEWS_POSTS_URL = '/marketing/news-post/';
export const POST_EXPOSURE_FILTER_URL = '/marketing/news-post/exposure-filter';

export const NOTIFICATION_SEND_PATIENT_URL = '/pushNotification/sendToPatients';
export const NOTIFICATION_SEND_ALL_URL = '/pushNotification/sendToAll';

// Rewards
const REWARD_PATH = '/reward';
export const REWARD_PROGRAM_PATH = REWARD_PATH + '/program';
export const REWARD_BONUS_PATH = REWARD_PATH + '/bonus';
export const REWARD_ADJUST_PATH = REWARD_PATH + '/adjust';
export const REWARD_REDEEM_PATH = REWARD_PATH + '/redeem';
export const REWARD_DONOR_GROUP_PATH = REWARD_PATH + '/donorgroup';
export const REWARD_CENTER_STATUS_PATH = REWARD_PATH + '/center-status';
const REWARD_SETTING = REWARD_PATH + '/setting';
export const REWARD_SETTING_PRESET = REWARD_SETTING + '/monthly';
export const REWARD_SETTING_EMAIL = REWARD_SETTING + '/email';
export const REWARD_SETTING_FEEDBACK = REWARD_SETTING + '/feedback';
export const REWARD_SETTING_REFERRAL = REWARD_SETTING + '/referral';
export const REWARD_SETTING_REDEEM = REWARD_SETTING + '/redeem';
export const REWARD_SETTING_REDEEM_ACTIVE = REWARD_SETTING + '/redeem-active';
export const REWARD_SETTING_QUOTA = REWARD_SETTING + '/point-quota';
export const REWARD_SETTING_EXPIRATION = REWARD_SETTING + '/expiration';
export const REWARD_SETTING_BONUS = REWARD_SETTING + '/member-bonus';
export const REWARD_SETTING_SETTINGS = REWARD_SETTING + '/settings';
export const REWARD_VIEW_PATH = '/reward/view'; //for testing only

export const FETCH_REWARD_REDEEM = '/redeem-orders/lists';
export const FETCH_REWARD_REDEEM_EGC = '/redeem-orders/egiftcard-list';
export const UPDATE_REWARD_REDEEM_STATUS = '/redeem-orders/update-status';
export const FETCH_REWARD_PRODUCT = '/redeem-items/lists';
export const CREATE_REWARD_PRODUCT = '/redeem-items/create';
export const UPDATE_REWARD_PRODUCT = '/redeem-items/update';
export const GET_REWARD_PRODUCT_BY_ID = '/redeem-items/detail';
export const GET_REWARD_PRODUCTS_TRE = '/redeem-items/tremendous';
export const GET_REWARD_PRODUCT_TRE_ONE = '/redeem-items/tremendous-one';
export const UPDATE_REWARD_PRODUCT_STATUS = '/redeem-items/un-or-freeze';
export const FETCH_REWARD_FAST_CASH = '/redeem-fast-cash/list';
export const CREATE_REWARD_FAST_CASH = '/redeem-fast-cash/create';
export const UPDATE_REWARD_FAST_CASH = '/redeem-fast-cash/update';
export const GET_REWARD_FAST_CASH_BY_ID = '/redeem-fast-cash/detail';
export const UPDATE_REWARD_FAST_CASH_STATUS = '/redeem-fast-cash/changing-freeze';
export const GET_REDEEM_SETTING = '/redeem/setting';
export const UPDATE_REDEEM_SETTING = '/redeem/setting/update-can-redeem';

export const FETCH_ALL_LOCATION_URL = '/locations'; //

// System content

// News and Events
export const FETCH_NEWS_EVENTS_URL = '/settings/news-events';
export const UPDATE_NEWS_EVENT_BY_ID_URL = '/settings/news-event/'; // /:_id
export const CREATE_NEWS_EVENT_URL = '/settings/news-event';
export const REMOVE_ACTIVE_ON_NEWS_EVENT_URL = '/settings/news-event/remove-active/'; // /:_id (delete)

// Acheivement Rule
export const FETCH_ACHIEVEMENT_RULE_URL = '/settings/achievementS';
export const CREATE_UPDATE_ACHIEVEMENT_RULE_URL = '/settings/achievement'; // PK is Country + State

// Group Setting
export const FETCH_AGE_GROUP_URL = '/settings/age-groups';
export const CREATE_AGE_GROUP_URL = '/settings/age-group';
export const UPDATE_AGE_GROUP_URL = '/settings/age-group/'; // :_id
export const UPDATE_AGE_GROUP_STATUS_URL = '/settings/age-group/status/'; // :_id

export const FETCH_ACTIVENESS_GROUP_URL = '/settings/activeness-groups';
export const CREATE_ACTIVENESS_GROUP_URL = '/settings/activeness-group';
export const UPDATE_ACTIVENESS_GROUP_URL = '/settings/activeness-group/'; // :_id
export const UPDATE_ACTIVENESS_GROUP_STATUS_URL = '/settings/activeness-group/status/'; // :_id

export const FETCH_DONORS_GROUP_URL = '/settings/donor-groups';
export const CREATE_DONORS_GROUP_URL = '/settings/donor-group';
export const UPDATE_DONORS_GROUP_URL = '/settings/donor-group/'; // :_id
export const UPDATE_DONORS_GROUP_STATUS_URL = '/settings/donor-group/status/'; // :_id
export const DONOR_RESEND_FEEDBACK_NOTIFICATION = '/notification/donation_feedback/resend';

export const FETCH_LAPSED_TIME_GROUP_URL = '/settings/lapsed-time-groups';
export const CREATE_LAPSED_TIME_GROUP_URL = '/settings/lapsed-time-group';
export const UPDATE_LAPSED_TIME_GROUP_URL = '/settings/lapsed-time-group/'; // :_id
export const UPDATE_LAPSED_TIME_GROUP_STATUS_URL = '/settings/lapsed-time-group/status/'; // :_id

// Points Setting
export const FETCH_POINTS_SETTING_URL = '/settings/point';
export const UPDATE_POINTS_SETTING_URL = '/settings/point';

// App Health History Link Setting
export const FETCH_APP_HEALTH_LINK_URL = '/settings/app-health-link';
export const UPDATE_APP_HEALTH_LINK_URL = '/settings/app-health-link';

// Feedback Options
export const FETCH_FEEDBACK_OPTIONS_URL = '/feedbackOptions/getAll';
export const CREATE_FEEDBACK_OPTION_URL = '/feedbackOptions/create';
export const UPDATE_FEEDBACK_OPTION_URL = '/feedbackOptions/update';
export const UPDATE_FEEDBACK_OPTION_ACTIVE_URL = '/feedbackOptions/activate';
export const UPDATE_FEEDBACK_OPTION_DEACTIVE_URL = '/feedbackOptions/deactivate';

export const CHECK_EXIST_DONORS_WITH_IDS = '/donors/check-exist-donors';

export const FETCH_REDEEM_LINK_URL = '/settings/redeem_active/get';
export const UPDATE_REDEEM_LINK_URL = '/settings/redeem_active/save';

export const GET_REWARD_CONFIG_PAGE_DATA = '/rewards/configuration-and-promotions';
export const UPDATE_REWARD_SETTINGS = '/rewards/configuration';
export const PROMOTIONS_URLs = '/rewards/promotions';

export const LAB_SEARCH_PATIENT = '/change-health/find-patient';
export const LAB_SEARCH_PATIENT_CH = '/change-health/find-patient-change-health';
export const LAB_DECLARE_PATIENT_CH = '/change-health/declare-patient-change-health';
export const LAB_PARAMS = '/change-health/declare-lab-params';
export const CREATE_LAB_ORDER = '/change-health/create-lab-order';
export const GET_ALL_LAB_ORDER = '/change-health/get-lab-order';

export const LAB_TEST_ANSWER = '/labtest/answer';
export const LAB_TEST_ADD = '/labtest/add';
export const LAB_TEST_QUESTION = '/labtest/question';

export const PATIENT_DETAIL_URL = '/patient/:id';
export const SYMPTOMS_AND_GOALS_URL = '/patient/:id/symptomsAndGoals';

export const NEW_TREATMENT_PLAN = '/patient/new-treatment-plan';
export const SAVE_DRAFT_PLAN = '/patient/save-draft-plan';
export const DELETE_DRAFT_PLAN = '/patient/delete-draft-plan/';
export const TREATMENT_PROPOSAL_CALIBRATE_URL = '/product/treatment-proposal-calibrate';
export const APPROVE_TREATMENT_PLAN = '/patient/approve-treatment-plan';
export const UPDATE_TREATMENT_PLAN = '/patient/update-treatment-plan';
export const PLACE_ORDER = '/patient/place-order';
export const QUERY_PATIENT_ORDER = '/patient/{id}/orders';
export const QUERY_PATIENT_APPOINTMENT = '/patient/{id}/appointments';
export const PATIENT_UPLOAD_LAB_REPORT = '/patient/{id}/upload-lab-report';
export const PATIENT_CHECK_PRESIGN_URL = '/patient/presignUrl/:type/:name';

export const PATIENT_SEARCH_URL = '/patient/search/:option/:query';
export const PATIENT_AUTO_COMPLETE_URL = '/patient/auto-complete/:option/:query';
export const PATIENT_SEARCH_ALL = '/patient/search/all';
export const PATIENT_RAW_UPDATE = '/patient/:patientId/detail';
export const PATIENT_MEDICAL_NOTES = '/patient/:patientId/medical-notes';
export const PATIENT_CREATE_URL = '/patient/create';

export const PROSPECT_PATIENT_DETAIL_URL = '/prospect-patient/:id';
export const PROSPECT_PATIENT_SEARCH_URL = '/prospect-patient/search';
export const PROSPECT_PATIENT_COMPARE_URL = '/prospect-patient/compare';

export const FILES_URL = '/patient/:id/files';
export const UPLOAD_FILE_URL = '/patient/:id/files/upload';
export const BIOIDS_FILES_URL = '/patient/:id/files/bio-ids';
export const LABREPORTS_FILES_URL = '/patient/:id/files/labreports';
export const DELETE_FILES = '/patient/delete/files';

export const SEARCH_ORDERS_URL = '/order/search';
export const ORDERS_OPTIONS_URL = '/order/search/options';
export const UPDATE_TRACKING_NUMBER_URL = '/order/update-tracking';
export const UPLOAD_TRACKING_NUMBER_URL = '/order/upload-tracking';

export const STAFF_LIST_URL = 'staff/list';
export const STAFF_SEARCH_URL = '/staff/search/:option/:query';
export const STAFF_AUTO_COMPLETE_URL = '/staff/auto-complete/:option/:query';
export const STAFF_CREATE_URL = '/staff/create';
export const STAFF_DELETE_URL = '/staff/delete/:id';
export const STAFF_UPDATE_URL = '/staff/update/:id';
export const STAFF_DETAIL_URL = '/staff/:id';
export const GET_STAFF_SCHEDULE_URL = '/staff/:staffId/schedules';
export const ADD_STAFF_SCHEDULE_URL = '/staff/add-schedules';

export const APPOINTMENT_URL = '/appointment';
export const APPOINTMENT_SCHEDULE_URL = '/appointment/schedule';
export const APPOINTMENT_AVAILABLE_SERVICES_URL = '/provider-service';
export const CREATE_APPOINTMENT = '/appointment/book';
export const CREATE_BLOCKED_TIME = '/appointment/book-blocked-time';
export const RESCHEDULE_APPOINTMENT = '/appointment/reschedule';
export const CANCEL_APPOINTMENT = '/appointment/cancel';
export const COMPLETE_APPOINTMENT = '/appointment/complete';
export const NO_SHOW_APPOINTMENT = '/appointment/no-show';

export const LIST_ROLE_URL = '/authorization-management/roles';
export const LIST_RIGHT_URL = '/authorization-management/rights';
export const MANAGE_ROLE_URL = '/authorization-management/role';
export const MANAGE_RIGHT_URL = '/authorization-management/right';
