export default {
  "FemaleMenopause": [
    "Estradiol",
    "Estrone",
    "Pregnenolone",
    "Prolactin",
    "Progesterone",
    "Total Testosterone",
    "Free Testosterone",
    "Bioavailable Testosterone",
    "Sex Hormone Binding Globulin",
    "FSH",
    "LH",
    "Cortisol",
    "IGF-1",
    "DHEA-S",
    "Vitamin D",
    "Free T3",
    "TSH",
    "Total Cholesterol",
    "Triglycerides",
    "HDL Cholesterol",
    "LDL Cholesterol Calc",
    "Lp(a)",
    "CRP-hs",
    "Homocysteine",
    "HGB A1C",
    "Glucose, Serum",
    "Vitamin B12",
    "BUN (Kidney)",
    "GFR",
    "Insulin",
    "Uric Acid",
    "AST (SGOT)",
    "ALT (SGPT)",
    "WBC",
    "Hemoglobin",
    "Hematocrit",
    "Ferritin"
  ],
  "FemaleLuteal": [
    "Estradiol",
    "Estrone",
    "Pregnenolone",
    "Prolactin",
    "Progesterone",
    "Total Testosterone",
    "Free Testosterone",
    "Bioavailable Testosterone",
    "Sex Hormone Binding Globulin",
    "FSH",
    "LH",
    "Cortisol",
    "IGF-1",
    "DHEA-S",
    "Vitamin D",
    "Free T3",
    "TSH",
    "Total Cholesterol",
    "Triglycerides",
    "HDL Cholesterol",
    "LDL Cholesterol Calc",
    "Lp(a)",
    "CRP-hs",
    "Homocysteine",
    "HGB A1C",
    "Glucose, Serum",
    "Vitamin B12",
    "BUN (Kidney)",
    "GFR",
    "Insulin",
    "Uric Acid",
    "AST (SGOT)",
    "ALT (SGPT)",
    "WBC",
    "Hemoglobin",
    "Hematocrit",
    "Ferritin"
  ],
  "Male": [
    "Total Testosterone",
    "Free Testosterone",
    "Bioavailable Testosterone",
    "Sex Hormone Binding Globulin",
    "Pregnenolone",
    "Prolactin",
    "Progesterone",
    "FSH",
    "LH",
    "Cortisol",
    "Estradiol",
    "Estrone",
    "IGF-1",
    "DHEA-S",
    "Vitamin D",
    "PSA",
    "Free T3",
    "TSH",
    "Total Cholesterol",
    "Triglycerides",
    "HDL Cholesterol",
    "LDL Cholesterol Calc",
    "Lp(a)",
    "CRP-hs",
    "Homocysteine",
    "HGB A1C",
    "Glucose, Serum",
    "Vitamin B12",
    "BUN (Kidney)",
    "GFR",
    "Insulin",
    "Uric Acid",
    "AST (SGOT)",
    "ALT (SGPT)",
    "WBC",
    "Hemoglobin",
    "Hematocrit",
    "Ferritin"
  ]
}
