import React, {useEffect, useState} from "react";
import {message,Button, Checkbox, Col, Collapse, Modal, Row, Typography} from "antd";
import {Paper} from "@mui/material";
import DataTable from "../../../components/data-table/data-table.component";
import {useDispatch, useSelector} from "react-redux";
import {placeOrder, updateMedicineCancellation,checkPresignUrl} from "../service";
import {setCurrentTreatmentPlan} from "../../../stores/patient-treatment-plan.store";
import {Document, Page, pdfjs} from "react-pdf";
import { AccessRight } from "../../../components/guarded-route/guarded-routes";
import { ApplicationRight } from "../../../share/RightList";


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const BioIdBriefPage = () => {
    const dispatch = useDispatch();

    const {current: currentTreatmentPlan} = useSelector(state => state.patientTreatmentPlan);
    const currentPatient = useSelector(state => state.currentPatient);
    const currentLabResult = useSelector(state => state.currentLabResult);

    const [abnormalBiomarkers, setAbnormalBiomarkers] = useState([]);
    const [treatmentPlan, setTreatmentPlan] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tableSetting, setTableSetting] = useState(null);
    const [editMode, setEditMode] = useState(null);
    const [patientGoals, setPatientGoals] = useState([]);
    const [changingPlan, setChangingPlan] = useState({});
    const [creatingOrders, setCreatingOrders] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const calcPriceReducer = (previousValue, currentValue) => previousValue + (currentValue.cancelled ? 0 : (isNaN(currentValue.price) ? 0 : Number(currentValue.price)));

    const init = (plans, isEditing) => {
        const basePlan = plans.filter(p => p.level === 'Base');
        const correctionalPlan = plans.filter(p => p.level === 'Correctional');
        const electivePlan = plans.filter(p => p.level === 'Elective');
        const otherPlan = plans.filter(p => p.level === 'Other');

        const basePlanPriceRow = {
            level: 'priceRow',
            price: basePlan.reduce(calcPriceReducer, 0),
        }

        const correctionalPlanPriceRow = {
            level: 'priceRow',
            price: correctionalPlan.reduce(calcPriceReducer, 0),
        }

        const electivePlanPriceRow = {
            level: 'priceRow',
            price: electivePlan.reduce(calcPriceReducer, 0),
        }

        const otherPlanPriceRow = {
            level: 'priceRow',
            price: otherPlan.reduce(calcPriceReducer, 0),
        }

        const baseRowSpan = basePlan.length;
        const correctionalRowSpan = correctionalPlan.length;
        const electiveRowSpan = electivePlan.length;
        const otherRowSpan = otherPlan.length;

        const planMapFunc = (p, i) => {
            if (i === 0) {
                return {...p};
            } else {
                return {
                    ...p,
                    level: '',
                };
            }
        }

        const basePlanTableData = basePlan.map(planMapFunc);
        const correctionalPlanTableData = correctionalPlan.map(planMapFunc);
        const electivePlanTableData = electivePlan.map(planMapFunc);
        const otherPlanTableData = otherPlan.map(planMapFunc);

        const rowSpan = {
            '': 0,
            'priceRow': 0,
            'Base': baseRowSpan + (isEditing ? 0 : 1),
            'Correctional': correctionalRowSpan + (isEditing ? 0 : 1),
            'Elective': electiveRowSpan + (isEditing ? 0 : 1),
            'Other': otherRowSpan + (isEditing ? 0 : 1),
        }

        const finalTableData = [];
        if (basePlanTableData.length) {
            finalTableData.push(...basePlanTableData);
            if (!isEditing) {
                finalTableData.push(basePlanPriceRow);
            }
        }
        if (correctionalPlanTableData.length) {
            finalTableData.push(...correctionalPlanTableData);
            if (!isEditing) {
                finalTableData.push(correctionalPlanPriceRow);
            }
        }
        if (electivePlanTableData.length) {
            finalTableData.push(...electivePlanTableData);
            if (!isEditing) {
                finalTableData.push(electivePlanPriceRow);
            }
        }
        if (otherPlanTableData.length) {
            finalTableData.push(...otherPlanTableData);
            if (!isEditing) {
                finalTableData.push(otherPlanPriceRow);
            }
        }

        setTableData([...finalTableData])

        setTableSetting({
            pagination: false,
            bordered: true,
            columns: [
                {
                    title: 'Therapy',
                    dataIndex: 'level',
                    onCell: (record) => ({
                        rowSpan: rowSpan[record.level] || 0
                    })
                },
                {
                    title: 'Medication / Product',
                    dataIndex: ['product', 'name'],
                    render: (text, record) => {
                        const plan = plans.find(t => t._id === record._id);
                        if (plan) {
                            if (!isEditing) {
                                return plan.cancelled ? <Typography.Text delete>{text}</Typography.Text> : text;
                            } else {
                                return (
                                    <Checkbox checked={!plan.cancelled} onChange={(event) => {
                                        plan.cancelled = !event.target.checked;

                                        if (plan._id in changingPlan) {
                                            delete changingPlan[plan._id];
                                        } else {
                                            changingPlan[plan._id] = plan.cancelled;
                                        }
                                        setChangingPlan({...changingPlan});
                                        setTableSetting(null);
                                        setTreatmentPlan(treatmentPlan);
                                        setTimeout(() => {
                                            init(treatmentPlan, isEditing);
                                        });
                                    }}>{plan.cancelled ?
                                        <Typography.Text delete>{text}</Typography.Text> : text}</Checkbox>
                                )
                            }
                        }
                    }
                },
                {
                    title: 'Quantity',
                    dataIndex: 'quantity',
                    render: (text, record) => {
                        const plan = plans.find(t => t._id === record._id);
                        if (plan && plan.cancelled) {
                            return <Typography.Text delete>{text}</Typography.Text>
                        }
                        return text;
                    }
                },
                {
                    title: 'Refill',
                    dataIndex: 'refill',
                    render: (text, record) => {
                        const plan = plans.find(t => t._id === record._id);
                        if (plan && plan.cancelled) {
                            return <Typography.Text delete>{text}</Typography.Text>
                        }
                        return text;
                    }
                },
                {
                    title: 'Dosage',
                    dataIndex: 'dosage',
                    render: (text, record) => {
                        const plan = plans.find(t => t._id === record._id);
                        if (plan && plan.cancelled) {
                            return <Typography.Text delete>{text}</Typography.Text>
                        }
                        return text;
                    }
                },
                {
                    title: 'Price',
                    dataIndex: 'price',
                    render: (text, record) => {
                        const plan = plans.find(t => t._id === record._id);
                        if (plan && plan.cancelled) {
                            return <Typography.Text delete>{`$${text ?? 'TBD'}`}</Typography.Text>
                        }
                        return <span
                            style={record.level === 'priceRow' ? {fontWeight: 'bold'} : { fontWeight : 'normal'}}>{`$${text ?? 'TBD'}`}</span>;
                    }


                },
            ]
        });
    };

    const submitTreatmentChange = async () => {
        setEditMode(false);
        setTableSetting(null);
        setTreatmentPlan(treatmentPlan);
        const newPlan = JSON.parse(JSON.stringify(currentTreatmentPlan));
        Object.entries(changingPlan).forEach(([id, value]) => {
            try {
                newPlan.forLabResult.find(m => m._id === id).cancelled = value;
            } catch (e) {
                newPlan.forSymptomsAndGoals.find(m => m._id === id).cancelled = value;
            }
        });

        await updateMedicineCancellation(newPlan);
        dispatch(setCurrentTreatmentPlan(newPlan))
    }

    const enableEdit = () => {
        setEditMode(true);
        setTableSetting(null);
        setChangingPlan({});
    }

    const placeOrders = async () => {
        setCreatingOrders(true);
        const location = currentPatient.lastVisitCenter;
        const signature = currentTreatmentPlan.approvalSignatureLink;
        const submittingMedicines = treatmentPlan.filter(p => !p.cancelled);

        const pdfLink = await placeOrder(currentPatient, signature, location, submittingMedicines, currentLabResult, currentTreatmentPlan._id);

        Modal.success({
            width: "700px",
            title: 'Result',
            content:
                <div className="d-flex align-items-center justify-content-between" style={{background: "#F9F9F9"}}>
                    <div style={{width: '330px', padding: "12px"}}>
                        <h6>Prescription Packing List:</h6>
                        <Document file={pdfLink.data.prescriptionLink} onLoadError={console.error}
                                  noData={"Prescription Packing List is not generated"} className="cursor-pointer"
                                  Click={() => pdfLink.data.prescriptionLink ? window.open(pdfLink.data.prescriptionLink, "_blank") : null}>
                            <Page pageNumber={1} width={300} scale={0.95} renderMode="canvas"/>
                        </Document>
                    </div>
                    <div style={{width: '330px', padding: "12px"}}>
                        <h6>Biostation Products Packing List:</h6>
                        <Document file={pdfLink.data.bProductLink} onLoadError={console.error}
                                  noData={"bProducts Packing List is not generated"} className="cursor-pointer"
                                  onClick={() => pdfLink.data.bProductLink ? window.open(pdfLink.data.bProductLink, "_blank") : null}>
                            <Page pageNumber={1} width={300} scale={0.95} renderMode="canvas"/>
                        </Document>
                    </div>
                    {/*<div style={{width: '330px', padding: "12px"}}>
                        <h6>BioID:</h6>
                        <Document file={pdfLink.data.bioIdLink} onLoadError={console.error}
                                  noData={"BioID is not generated"} className="cursor-pointer"
                                  onClick={() => pdfLink.data.bioIdLink ? window.open(pdfLink.data.bioIdLink, "_blank") : null} >
                            <Page pageNumber={1} width={300} scale={0.95} renderMode="canvas"/>
                        </Document>
                    </div>*/}
                </div>
        });
        setCreatingOrders(false)
    }

    useEffect(() => {
        if (currentTreatmentPlan) {
            setAbnormalBiomarkers([...currentLabResult.filter(r => r.isAbnormal)]);
            setPatientGoals([...currentPatient.symptomsAndGoals])

            const treatmentPlanReplica = JSON.parse(JSON.stringify([...currentTreatmentPlan.forLabResult, ...currentTreatmentPlan.forSymptomsAndGoals]));

            setTreatmentPlan(treatmentPlanReplica);
            init(treatmentPlanReplica);
        }
    }, [currentTreatmentPlan]);

    useEffect(() => {
        if (editMode !== null) {
            init(treatmentPlan, editMode);
        }
    }, [editMode]);


    const handleUrl = async (type,fileName) => {
        const urlData = await checkPresignUrl(type,fileName);

        if (urlData.data.status) {
            const newTab = window.open(urlData.data.url, '_blank');
            newTab.focus();
            return;
        }
        messageApi.open({
            type: "error",
            content: "Failed to open file, please try it again later.",
        });
    }
    return (
        <div>
            {contextHolder}
            <div className="d-flex justify-content-between align-content-center mb-4">
                <h4>BioID Brief</h4>
                <div>
                    <span className="me-3 fw-bold">Total Price: ${treatmentPlan.reduce(calcPriceReducer, 0)}</span>
                    {AccessRight(ApplicationRight.Orders_Generation) && <Button type="primary" className="me-3" loading={creatingOrders}
                            hidden={editMode} onClick={placeOrders} disabled={!currentTreatmentPlan}>
                        Generate Orders
                    </Button>}
                    <Button variant="outline-primary" className="me-3" hidden={editMode}
                            onClick={enableEdit} disabled={!currentTreatmentPlan}>
                        Update Treatment
                    </Button>
                    <Button type="primary" className="me-3" hidden={!editMode}
                            onClick={submitTreatmentChange}>OK</Button>
                </div>
            </div>
            <Row gutter={30}>
                <Col span={18}>
                    {tableSetting && tableData ?
                        <DataTable data={tableData} settings={tableSetting}/>
                        :
                        <div>There's no treatment plan for this patient</div>
                    }
                    {
                        currentTreatmentPlan ?
                            <Button onClick={() => handleUrl('bio-id',currentTreatmentPlan.bioIdFileName)} target="_blank" className="mt-2">Download BioID</Button>
                            :
                            <></>
                    }
                </Col>
                <Col span={6}>
                    <Paper rounded style={{borderRadius: 20, paddingTop: 20, paddingBottom: 20, marginBottom: 10}}>
                        <div className="mb-2 ps-2 pe-2">
                            <Typography.Title level={5}>Lab Results (Out Of Range)</Typography.Title>
                        </div>
                        <div className="mb-2">
                            <Collapse>
                                {
                                    abnormalBiomarkers.map((result, i) => (
                                        <Collapse.Panel header={`${result.biomarker} (${result.labData})`} key={i}>
                                            <p>{result.symptom}</p>
                                        </Collapse.Panel>
                                    ))
                                }
                            </Collapse>
                        </div>
                        <div className="mb-2 ps-2 pe-2">
                            <Typography.Title level={5}>Symptoms / Goals</Typography.Title>
                        </div>
                        <div className="mb-2 ps-3 pe-3">
                            {
                                patientGoals.map(g => <p>{g}</p>)
                            }
                        </div>
                    </Paper>
                </Col>
            </Row>
        </div>
    )
}
export default BioIdBriefPage;