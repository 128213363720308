import React from 'react'
import {Col, Row, Card, Table} from "react-bootstrap";
import SettingInput from "../../../components/setting/SettingInput";
import DataTable from "../../../components/data-table/data-table.component";
import moment from 'moment';
import './patient.css';

function EngagementPage() {
    const [opportunityInfo, setOpportunityInfo] = React.useState([]);
    const [smsInfo, setSmsInfo] = React.useState([]);
    const [smsPhoneInfo, setSmsPhoneInfo] = React.useState([]);
    const [labResults, setLabResults] = React.useState([]);
    const tableSettings = {
        appendable: false,
        removable: false,
        bordered : false,
        size: 'middle',
        pagination : {
            'showLessItems' :true,
            'pageSize' : 5,
        },
        columns: [
            {
                title: 'Date',
                dataIndex: 'date',
            },
            {
                title: 'Opportunities',
                dataIndex: 'opportunities',
            },
            {
                title: 'From',
                dataIndex: 'from',
            },
            {
                title: 'Category',
                dataIndex: 'category',
            },
            {
                title: 'Status',
                dataIndex: 'status',
            },
        ]
    }
    const smsTableSettings = {
        appendable: false,
        removable: false,
        bordered : false,
        size: 'middle',
        pagination : {
            'showLessItems' :true,
            'pageSize' : 5,
        },
        columns: [
            {
                title: 'Type',
                dataIndex: 'type',
            },
            {
                title: 'Date',
                dataIndex: 'date',
            },
        ]
    }
    const fetchLabInfodetails = () => {
        
        const opportunityData = [
            {
                _id:'1',
                date: '9/9/2022 01:48PM',
                opportunities : 'David Weely - Medical Weight Loss',
                from : 'DanyS',
                category : 'HCG Diet',
                status : 'Completed',
            },
            {
                _id:'2',
                date: '9/8/2022 01:48PM',
                opportunities : 'David Weely - Medical Aesthetics',
                from : 'FrontDesk',
                category : 'Botox/Xeomin',
                status : 'Completed',
            },
            {
                _id:'3',
                date: '8/9/2022 01:48PM',
                opportunities : 'David Weely - Blood Request',
                from : 'Asiem',
                category : '',
                status : 'Completed',
            },
            {
                _id:'4',
                date: '7/9/2022 01:48PM',
                opportunities : 'David Weely - Medical Aesthetics',
                from : 'DanyS',
                category : 'BioBank',
                status : 'Completed',
            },
            {
                _id:'5',
                date: '6/30/2022 01:48PM',
                opportunities : 'David Weely - Supplements/OTC',
                from : 'FrontDesk',
                category : '',
                status : 'Completed',
            },
            {
                _id:'6',
                date: '5/9/2022 01:48PM',
                opportunities : 'David Weely - Medical Weight Loss',
                from : 'FrontDesk',
                category : 'HCG Diet',
                status : 'Completed',
            }
        ];
        const smsData = [
            {
                _id:'1',
                date: '9/9/2022 01:48PM',
                opportunities : 'David Weely - Medical Weight Loss',
                from : 'DanyS',
                category : 'HCG Diet',
                status : 'Completed',
            },
            {
                _id:'2',
                date: '9/8/2022 01:48PM',
                opportunities : 'David Weely - Medical Aesthetics',
                from : 'FrontDesk',
                category : 'Botox/Xeomin',
                status : 'Completed',
            },
            {
                _id:'3',
                date: '8/9/2022 01:48PM',
                opportunities : 'David Weely - Blood Request',
                from : 'Asiem',
                category : '',
                status : 'Completed',
            },
            {
                _id:'4',
                date: '7/9/2022 01:48PM',
                opportunities : 'David Weely - Medical Aesthetics',
                from : 'DanyS',
                category : 'BioBank',
                status : 'Completed',
            },
            {
                _id:'5',
                date: '6/30/2022 01:48PM',
                opportunities : 'David Weely - Supplements/OTC',
                from : 'FrontDesk',
                category : '',
                status : 'Completed',
            },
            {
                _id:'6',
                date: '5/9/2022 01:48PM',
                opportunities : 'David Weely - Medical Weight Loss',
                from : 'FrontDesk',
                category : 'HCG Diet',
                status : 'Completed',
            }
        ];
        const smsPhoneData =[
            {
                _id:'1',
                type: 'Phone Call',
                date : '9/9/2022 01:48PM',
            },
            {
                _id:'2',
                type: 'Email',
                date : '9/9/2022 01:48PM',
            },
            {
                _id:'3',
                type: 'Phone Call',
                date : '9/9/2022 01:48PM',
            },
            {
                _id:'4',
                type: 'Phone Call',
                date : '9/9/2022 01:48PM',
            },
            {
                _id:'5',
                type: 'Email',
                date : '9/9/2022 01:48PM',
            },
            {
                _id:'6',
                type: 'Phone Call',
                date : '9/9/2022 01:48PM',
            },
        ];
        setSmsPhoneInfo(smsPhoneData);
        setOpportunityInfo(opportunityData);
        setSmsInfo(smsData);
    };

    React.useEffect(() => {
        fetchLabInfodetails();
    }, []);

    return (
        <>
            <Row className="profile">
                <Col sm={12}>
                    <h2 className="section-title">Opportunities</h2>
                </Col>
                <Col sm={12}>
                    <Row className="section-content">
                         <DataTable data={opportunityInfo} settings={tableSettings}/>
                    </Row>
                </Col>
            </Row>
            <Row className="section">
                <Col sm={12}>
                    <h2 className="section-title">SMS Conversation</h2>
                </Col>
                <Col sm={12}>
                    <Row className="section-content">
                         <DataTable data={smsInfo} settings={tableSettings}/>
                    </Row>
                </Col>
            </Row>
            <Row className="section">
                <Col sm={12}>
                    <Row className="section-content">
                         <DataTable data={smsPhoneInfo} settings={smsTableSettings}/>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default EngagementPage