import {
  calendarEssentialDataInitializer,
  getAppointment,
  getEventClass,
} from "./service";
import moment from "moment";
import "moment-timezone";
import { BsCalendar } from "react-icons/bs";
import { Avatar, Button, Dropdown, Select } from "antd";
import AppointmentCalendarComponent from "./appointment-calendar.component";
import NewAppointmentModal from "./new-appointment.modal";
import { useEffect, useState } from "react";
import { AppstoreAddOutlined } from "@ant-design/icons";
import AddBlockedTimeModal from "./add-blocked-time.modal";
import { useSearchParams } from "react-router-dom";
import { AccessRight } from "../../components/guarded-route/guarded-routes";
import { ApplicationRight } from "../../share/RightList";

const AppointmentCalendarByProviderPage = () => {
  const timezone = "America/New_York";
  moment.tz.setDefault(timezone);

  const [searchParam] = useSearchParams();

  const [locationOptions, setLocationOptions] = useState([]);
  const [events, setEvents] = useState(null);
  const [providingServices, setProvidingServices] = useState([]);
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [showNewAppointmentModal, setShowNewAppointmentModal] = useState(false);
  const [showAddBlockedTimeModal, setShowAddBlockedTimeModal] = useState(false);
  let item = [
    {
      key: "1",
      isHas: AccessRight(ApplicationRight.Appointment_Editing),
      label: (
        <a href={`/staff/schedule?staffId=${selectedProvider}`}>Add Shift</a>
      ),
    },
    {
      key: "2",
      isHas: AccessRight(ApplicationRight.Staff_Blocked_Time_Creating),
      label: (
        <a onClick={() => setShowAddBlockedTimeModal(true)}>New Blocked Time</a>
      ),
    },
    {
      key: "3",
      isHas: AccessRight(ApplicationRight.Patient_Creating),
      label: (
        <a onClick={() => setShowNewAppointmentModal(true)}>New Appointment</a>
      ),
    },
  ];
  const items = item.filter(it => it.isHas)

  const generateTitle = (serviceName, patient, staff, location) => {
    return `${serviceName} - ${patient.firstName} ${patient.lastName} [${location.name}]`;
  };

  const checkIfStaffIsSameAfterReschedule = (oldAppointment, newAppointment) =>
    oldAppointment.staff.staffId === newAppointment.staff.staffId;

  useEffect(() => {
    if (selectedProvider) {
      (async () => {
        const appointmentsOfTheProvider = await getAppointment([
          { provider: selectedProvider },
        ]);
        const allData = [];

        if (appointmentsOfTheProvider.data?.data.appointments) {
          appointmentsOfTheProvider.data.data.appointments.forEach(
            (appointment) => {
              allData.push({
                end: moment(appointment.end).toDate(),
                start: moment(appointment.start).toDate(),
                title: generateTitle(
                  appointment.service.serviceName,
                  appointment.patient,
                  appointment.staff,
                  appointment.location,
                ),
                className: getEventClass(appointment),
                type: "appointment",
                appointment,
              });
            },
          );
        }
        if (appointmentsOfTheProvider.data?.data.blockedTimes) {
          appointmentsOfTheProvider.data.data.blockedTimes.forEach((bt) => {
            allData.push({
              end: moment(bt.end).toDate(),
              start: moment(bt.start).toDate(),
              title: (
                <>
                  <p>Blocked Time For:</p>
                  <p>{bt.description}</p>
                </>
              ),
              type: "blocked-time",
              className: "blocked-event",
            });
          });
        }
        setEvents(allData);
      })();
    }
  }, [selectedProvider]);

  useEffect(() => {
    const staffId = searchParam.get("staffId");
    if (providers && providers.length && staffId) {
      const preSelectedProvider = providers.find((p) => p.staffId === staffId);
      if (preSelectedProvider) {
        setSelectedProvider(preSelectedProvider.staffId);
      }
    }
  }, [providers]);

  useEffect(() => {
    (async () =>
      calendarEssentialDataInitializer(
        setLocationOptions,
        setProviders,
        setProvidingServices,
      ))();
  }, []);

  return (
    <div className="appointment-management">
      <div className="d-flex justify-content-between m-3">
        <div className="d-flex align-items-center">
          <span
            style={{
              padding: 6,
              color: "white",
              background: "#5284C2",
              borderRadius: 4,
            }}
          >
            <BsCalendar
              style={{ fontSize: 18, position: "relative", top: -2 }}
            />
          </span>
          <span
            style={{
              fontSize: 18,
              marginLeft: 8,
              marginRight: 16,
              fontWeight: 700,
            }}
          >
            Appointment By Provider
          </span>
          <Select
            placeholder="Select A Provider"
            size="large"
            style={{ minWidth: 255 }}
            defaultActiveFirstOption={false}
            filterOption={false}
            value={selectedProvider}
            onChange={setSelectedProvider}
          >
            {(providers || []).map((s, i) => (
              <Select.Option
                key={i}
                value={s.staffId}
                label={`${s.firstName} ${s.lastName}`}
              >
                <div className="d-flex align-items-center">
                  <Avatar
                    size="30"
                    src={s.coverImage}
                    style={{ marginRight: 10 }}
                  />
                  <div>{`${s.firstName} ${s.lastName}`}</div>
                </div>
              </Select.Option>
            ))}
          </Select>
        </div>
        <div>
          {selectedProvider && (
            <Dropdown menu={{ items }} arrow className="dropdown">
              <Button
                type="primary"
                size="large"
                shape="round"
                icon={<AppstoreAddOutlined />}
              >
                New
              </Button>
            </Dropdown>
          )}
        </div>
      </div>
      {events && (
        <AppointmentCalendarComponent
          appointmentEvents={events}
          providers={providers}
          generateEventTitle={generateTitle}
          timezone={timezone}
          locationOptions={locationOptions}
          providingServices={providingServices}
          checkAfterReschedule={checkIfStaffIsSameAfterReschedule}
        />
      )}
      {showNewAppointmentModal && (
        <NewAppointmentModal
          show={showNewAppointmentModal}
          locations={locationOptions}
          defaultLocation={undefined}
          services={providingServices}
          onFinish={() => setShowNewAppointmentModal(false)}
        />
      )}
      {showAddBlockedTimeModal && (
        <AddBlockedTimeModal
          timezone={timezone}
          show={showAddBlockedTimeModal}
          staff={providers.find((p) => p.staffId === selectedProvider)}
          onFinish={(result) => {
            setShowAddBlockedTimeModal(false);
            if (result) {
              setEvents([...events, result]);
            }
          }}
        />
      )}
    </div>
  );
};

export default AppointmentCalendarByProviderPage;
