import { Outlet } from 'react-router-dom';
import { UserContext } from '../../App';
import { useContext } from 'react';
import PageNotAccess from '../../pages/not-access/PageNotAccess';
import store from '../../stores';

const GuardedRoutes = ({ accessingRight }) => {
  const user = useContext(UserContext);

  if (!accessingRight) {
    return <Outlet />;
  }

  return user.rightList.includes(accessingRight) ? <Outlet /> : <PageNotAccess />;
};

export const AccessRight = (accessingRight) => {
  const user =  store.getState().authenticatedUser;
  return user ? user.rightList.includes(accessingRight) : false;
};

export default GuardedRoutes;
