import React, { useState } from "react";
import { Button, Modal, Form, Input,Radio,message } from "antd";
import {updateTrackingNumber} from "../service";

const TrackingNumberFormModal = ({orderID,selectedCarrier,selectedTrackingNumber,fetchData}) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [carrier, setCarrier] = useState(selectedCarrier);
    const [loading,setLoading] = useState(false);
    const [trackingNumber, setTrackingNumber] = useState(selectedTrackingNumber);
    const [error, setError] = useState("");

    const onSubmit = async (values) => {
        setLoading(true)
        try{
          values.orderID = orderID;
          const updateTrackingNumberResult = await updateTrackingNumber(values);
          const status = updateTrackingNumberResult.data.status;
          const msg = updateTrackingNumberResult.data.msg;
          if (!status) {
            setError(msg);
          } else {
            messageApi.open({
              type: 'success',
              content: msg,
            });
            setOpen(false);
            setError("")
          }
          fetchData();
        }catch(err){
          setError(err.message);
        };
        setLoading(false);
    };
  
    const onChangeCarrier = ({ target: { value } }) => {
        setCarrier(value);
    };

    const onChangeTrackingNumber = ({target}) =>{
        setTrackingNumber(target.value);
    }

    return (
      <>
        {contextHolder}
        <Button
          style={{margin:'10px'}}
          type="primary"
          onClick={() => {
            setOpen(true);
          }}
        >
          {selectedTrackingNumber ? "Update Tracking Number" : "Enter Tracking Number"}
        </Button>
        <Modal
          open={open}
          title= {selectedTrackingNumber ? "Update Tracking Number" : "Enter Tracking Number"}
          okText="Submit"
          cancelText="Cancel"
          onCancel={() => {
            setOpen(false);
          }}
          footer={[
            <Button key="cancel" onClick={() => setOpen(false)}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    onSubmit(values);
                  });
              }}
            >
              Submit
            </Button>
          ]}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{
              modifier: "public",
              carrier: "fedex",
            }}
          >
            {error && (
              <>
                <span style={{ color: "red"}}>{error}</span>
              </>
            )}
            <Form.Item
              label="Carrier"
              name="carrier"
              rules={[
                {
                  required: true,
                  message: "Please select a carrier"
                }
              ]}              
            >
            <Radio.Group 
                defaultValue={carrier} 
                buttonStyle="solid"
                onChange={onChangeCarrier}
            >
                <Radio.Button value="fedex">Fedex</Radio.Button>
                <Radio.Button value="usps">USPS</Radio.Button>
                <Radio.Button value="ups">UPS</Radio.Button>
            </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Tracking Number"
              name="trackingNumber"
              initialValue={trackingNumber}
              rules={[
                {
                  required: true,
                  message: "Please enter Tracking Number"
                },
                { min: 10, message: 'Tracking Number must be minimum 10 characters.' }
              ]}
            >
              <Input value={trackingNumber} onChange={onChangeTrackingNumber}/>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
};

export default TrackingNumberFormModal