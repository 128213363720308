import {Calendar, momentLocalizer, Views} from "react-big-calendar";
import React, {forwardRef, useEffect, useState} from "react";
import moment from 'moment'
import 'moment-timezone'
import './appointment-calendar.css';
import {Button, message, Popover, Select, Switch, Typography} from "antd";
import {AiOutlineCaretLeft, AiOutlineCaretRight} from "react-icons/ai";
import {BiChevronDown} from "react-icons/bi";
import {cancelAppointment, completeAppointment, getEventClass, patientNotAppear} from "./service";
import DatePicker from "react-datepicker";
import ModifyAppointmentModal from "./modify-appointment.modal";
import dayjs from "dayjs";
import AppointmentDetail from "./appointment-detail.component";

const AppointmentCalendarComponent = ({
                                          appointmentEvents,
                                          timezone,
                                          locationOptions,
                                          providers,
                                          providingServices,
                                          generateEventTitle,
                                          checkAfterReschedule,
                                      }) => {
    moment.tz.setDefault(timezone)

    const localizer = momentLocalizer(moment);

    const [view, setView] = useState(Views.WEEK);
    const [selectedDate, setSelectedDate] = useState(moment(new Date()).startOf('day').toDate());
    const [events, setEvents] = useState(appointmentEvents);
    const [appointmentWindow, setAppointmentWindow] = useState({
        show: false, appointment: null
    })
    const [showCompleted, setShowCompleted] = useState(false);
    const [showCanceled, setShowCanceled] = useState(false);
    const [showRescheduled, setShowRescheduled] = useState(false);
    const [showNoShow, setShowNoShow] = useState(false);

    useEffect(() => setEvents(appointmentEvents), [appointmentEvents]);

    const weekHeader = ({date}) => <div onClick={() => {
        setSelectedDate(date);
        setView(Views.DAY);
    }}>
        <div style={{fontSize: 18, fontWeight: 700}}>
            {moment(date).isSame(selectedDate, 'date') ? '🎯' : ''}
            {date.getDate().toString().padStart(2, "0")}
        </div>
        <div style={{fontWeight: 500}}>{date.toLocaleString('en-US', {weekday: 'long'})}</div>
    </div>;

    const isWeekday = (date) => {
        const day = moment(date).day();
        return day !== 0 && day !== 6;
    };

    const CustomDatePickerInput = forwardRef(({value, onClick}, ref) => (
        <Button type="text" onClick={onClick} ref={ref}>
            {
                view === Views.DAY ?
                    moment.tz(value, "MM/DD/yyyy", timezone).startOf('day').format("MMMM Do, yyyy")
                    :
                    `${moment(value).startOf('week').format("MMMM DD, yyyy")} - ${moment(value).endOf('week').format("MMMM DD, yyyy")}`
            } <BiChevronDown style={{marginLeft: 5, position: 'relative', top: -2}}/>
        </Button>
    ));

    const changeDate = (direction) => {
        if (direction) {
            setSelectedDate(moment(selectedDate).add(direction, 'day').toDate());
        } else {
            setSelectedDate(moment(new Date()).startOf('day').toDate());
        }
    }

    const CustomToolbar = () => <>
        <div className="d-flex justify-content-between" style={{fontWeight: 600, marginBottom: 5, marginInline: 32}}>
            <div className="d-flex align-items-center">
                <div style={{width: '100%', marginRight: 10}}>
                    <span><AiOutlineCaretLeft style={{cursor: "pointer"}} onClick={() => changeDate(-1)}/></span>
                    <span style={{marginInline: 16}}><Typography.Link style={{color: 'black'}}
                                                                      onClick={() => changeDate(0)}>Today</Typography.Link></span>
                    <span><AiOutlineCaretRight style={{cursor: "pointer"}} onClick={() => changeDate(1)}/></span>
                </div>
                <DatePicker
                    selected={selectedDate}
                    disabledKeyboardNavigation
                    onChange={(date) => setSelectedDate(moment(date).toDate())}
                    customInput={<CustomDatePickerInput/>}
                    dayClassName={(date) => {
                        if (view === Views.DAY) {
                            return 'in-day-view'
                        }
                        return dayjs(selectedDate.toDateString()).isSame(date.toDateString(), 'week') ? "react-datepicker__day--same-week" : ""
                    }}
                />
            </div>
            <div className="d-flex align-items-center">
                <Switch checkedChildren={"𝍎"} checked={showCompleted}
                        onClick={() => setShowCompleted(!showCompleted)}/>
                <span style={{marginRight: 25, marginLeft: 5, cursor: "pointer"}}
                      onClick={() => setShowCompleted(!showCompleted)}>Show Completed</span>

                <Switch checkedChildren={<span className="fs-5">↬</span>} checked={showRescheduled}
                        onClick={() => setShowRescheduled(!showRescheduled)}/>
                <span style={{marginRight: 25, marginLeft: 5, cursor: "pointer"}}
                      onClick={() => setShowRescheduled(!showRescheduled)}>Show Rescheduled</span>

                <Switch checkedChildren="Ⓧ" checked={showCanceled}
                        onClick={() => setShowCanceled(!showCanceled)}/>
                <span style={{marginRight: 25, marginLeft: 5, cursor: "pointer"}}
                      onClick={() => setShowCanceled(!showCanceled)}>Show Canceled</span>

                <Switch checkedChildren={"Ø"} checked={showNoShow}
                        onClick={() => setShowNoShow(!showNoShow)}/>
                <span style={{cursor: "pointer", marginLeft: 5,}}
                      onClick={() => setShowNoShow(!showNoShow)}>Show No-show</span>
            </div>
            <div>
                <Select
                    value={view}
                    style={{width: 120}}
                    onChange={value => setView(value)}
                    options={[
                        {value: Views.WEEK, label: 'Week'},
                        {value: Views.DAY, label: 'Day'},
                    ]}
                    bordered={false}
                />
            </div>
        </div>
    </>

    const editClick = (event) => {
        setAppointmentWindow({
            show: true,
            appointment: {
                ...event.appointment,
                title: event.title
            }
        })
    }

    const cancelEvent = async (event) => {
        let cancelStatus;
        const hide = message.loading('Cancelling in progress..', 0, () => {
            if (cancelStatus === "success") {
                message.success('Successfully Canceled Appointment!');
            } else if (cancelStatus === "fail") {
                message.error('We ran into problems when canceling the appointment');
            }
        });

        try {
            await cancelAppointment(event.appointment.appointmentId);

            const oldEvent = events.filter(e => e.type === 'appointment').find(e => e.appointment.appointmentId === event.appointment.appointmentId);
            oldEvent.className = `${oldEvent.className} canceled-event`;
            oldEvent.appointment.status = 'Canceled';
            setEvents([...events]);
            cancelStatus = 'success';
        } catch (e) {
            cancelStatus = "fail";
        } finally {
            hide();
        }
    }

    const markPatientAbsent = async (event) => {
        let cancelStatus;
        const hide = message.loading('Putting Appointment in no-show...', 0, () => {
            if (cancelStatus === "success") {
                message.success('Successfully mark appointment in no show status!');
            } else if (cancelStatus === "fail") {
                message.error('Unable to mark appointment no-show!');
            }
        });

        try {
            await patientNotAppear(event.appointment.appointmentId);

            const oldEvent = events.filter(e => e.type === 'appointment').find(e => e.appointment.appointmentId === event.appointment.appointmentId);
            oldEvent.className = `${oldEvent.className} no-show-event`;
            oldEvent.appointment.status = 'PatientAbsents';
            setEvents([...events]);
            cancelStatus = 'success';
        } catch (e) {
            cancelStatus = "fail";
        } finally {
            hide();
        }
    }

    const completeEvent = async (event) => {
        let status;
        const hide = message.loading('Completing appointment in progress..', 0, () => {
            if (status === "success") {
                message.success('Successfully Completed Appointment!');
            } else if (status === "fail") {
                message.error('We ran into problems when completing the appointment');
            }
        });

        try {
            await completeAppointment(event.appointment.appointmentId);

            const oldEvent = events.filter(e => e.type === 'appointment').find(e => e.appointment.appointmentId === event.appointment.appointmentId);
            oldEvent.className = `${oldEvent.className} completed-event`;
            oldEvent.appointment.status = 'Completed';
            setEvents([...events]);
            status = 'success';
        } catch (e) {
            status = "fail";
        } finally {
            hide();
        }
    }

    const updateEvent = (result) => {
        if (result && result.type === 'reschedule') {
            const oldEvent = events.filter(e => e.type === 'appointment').find(e => e.appointment.appointmentId === appointmentWindow.appointment.appointmentId);
            oldEvent.className = `${oldEvent.className} rescheduled-event`;
            oldEvent.appointment.status = 'Rescheduled';
            if (checkAfterReschedule(oldEvent.appointment, result.data)) {
                const newEvent = {
                    end: moment(result.data.end).toDate(),
                    start: moment(result.data.start).toDate(),
                    title: generateEventTitle(result.data.service.serviceName, result.data.patient, result.data.staff, result.data.location),
                    className: getEventClass(result.data),
                    appointment: result.data,
                    type: 'appointment',
                };

                setEvents([...events, newEvent]);
            } else {
                setEvents([...events]);
            }
        } else if (result && result.type === 'cancel') {
            const oldEvent = events.filter(e => e.type === 'appointment').find(e => e.appointment.appointmentId === appointmentWindow.appointment.appointmentId);
            oldEvent.className = `${oldEvent.className} canceled-event`;
            oldEvent.appointment.status = 'Canceled';
            setEvents([...events]);
        }
        setAppointmentWindow({show: false})
    };

    const appointmentToNow = (start, end) => {
        const now = moment();
        if (now.isBetween(start, end)) {
            return "OnGoing"
        }
        if (now.isAfter(end)) {
            return "Passed";
        }
        if (now.isBefore(start)) {
            return "InComing"
        }
    }

    const eventPopOver = (eventWrapperProps) =>
        eventWrapperProps.event.type !== 'blocked-time' ?
            <Popover placement="right" title={null} content={
                <div style={{padding: 8, width: 320}}>
                    <AppointmentDetail appointment={eventWrapperProps.event.appointment} timezone={timezone}/>
                    <hr/>
                    <div style={{marginTop: 16}}>
                        {
                            eventWrapperProps.event.appointment.status === 'Booked' &&
                            <>
                                {
                                    ["OnGoing", "Passed"].includes(appointmentToNow(eventWrapperProps.event.start, eventWrapperProps.event.end)) &&
                                    <div className="text-center d-flex justify-content-around">
                                        <Button type="dashed" danger
                                                onClick={() => markPatientAbsent(eventWrapperProps.event)}>
                                            No Show
                                        </Button>
                                        <Button onClick={() => completeEvent(eventWrapperProps.event)}>
                                            Complete Appointment
                                        </Button>
                                    </div>
                                }
                                {
                                    appointmentToNow(eventWrapperProps.event.start, eventWrapperProps.event.end) === "InComing" &&
                                    <div className="text-center d-flex justify-content-around">
                                        <Button onClick={() => editClick(eventWrapperProps.event)}>Edit</Button>
                                        <Button danger onClick={() => cancelEvent(eventWrapperProps.event)}>
                                            Cancel Appointment</Button>
                                    </div>
                                }
                            </>
                        }
                        {
                            eventWrapperProps.event.appointment.status === 'Rescheduled' &&
                            <div className="text-center">
                                This appointment has been <Typography.Text type="warning">rescheduled</Typography.Text>.
                            </div>
                        }
                        {
                            eventWrapperProps.event.appointment.status === 'Canceled' &&
                            <div className="text-center">
                                This appointment has been <Typography.Text type="danger">canceled</Typography.Text>.
                            </div>
                        }
                        {
                            eventWrapperProps.event.appointment.status === 'Completed' &&
                            <div className="text-center">
                                This appointment has been completed.
                            </div>
                        }
                        {
                            eventWrapperProps.event.appointment.status === 'PatientAbsents' &&
                            <div className="text-center">
                                Patient doesn't show up for this appointment.
                            </div>
                        }
                    </div>
                </div>
            } trigger="click">
                {eventWrapperProps.children}
            </Popover> : <>{eventWrapperProps.children}</>

    return <>
        <Calendar
            date={selectedDate}
            localizer={localizer}
            defaultView={Views.WEEK}
            view={view}
            views={[Views.WEEK, Views.DAY]}
            components={{
                week: {
                    header: weekHeader,
                },
                toolbar: CustomToolbar,
                eventWrapper: eventPopOver
            }}
            timeslots={1}
            step={60}
            slotPropGetter={date => {
                // Disable times from 7:00 AM to 8:00 AM and from 5:00 PM to 6:00 PM
                const isDisabled = (moment(date).hour() < 8) || (moment(date).hour() > 16) || !isWeekday(date);
                if (isDisabled) {
                    return {
                        className: 'disabled-time'
                    }
                }
            }}
            dayLayoutAlgorithm={'no-overlap'}
            eventPropGetter={(event, start, end, isSelected) => ({
                className: event.className
            })}
            events={events
                .filter(e => {
                    if (e.type === 'appointment' && !showRescheduled) {
                        return e.appointment.status !== 'Rescheduled'
                    } else {
                        return true;
                    }
                }).filter(e => {
                    if (e.type === 'appointment' && !showCanceled) {
                        return e.appointment.status !== 'Canceled'
                    } else {
                        return true;
                    }
                }).filter(e => {
                    if (e.type === 'appointment' && !showNoShow) {
                        return e.appointment.status !== 'PatientAbsents'
                    } else {
                        return true;
                    }
                }).filter(e => {
                    if (e.type === 'appointment' && !showCompleted) {
                        return e.appointment.status !== 'Completed'
                    } else {
                        return true;
                    }
                })
            }
            scrollToTime={moment(new Date()).startOf('day').set('hour', 8).toDate()}
        />
        {
            appointmentWindow.appointment &&
            <ModifyAppointmentModal
                timezone={timezone}
                event={appointmentWindow.appointment}
                show={appointmentWindow.show}
                locations={locationOptions}
                services={providingServices}
                providers={providers}
                eventTitleGenerator={generateEventTitle}
                onFinish={updateEvent}
            />
        }
    </>
}

export default AppointmentCalendarComponent;