import {createSlice} from "@reduxjs/toolkit";

const currentLabResultSlice = createSlice({
    name: 'currentLabResult',
    initialState: null,
    reducers: {
        setCurrentLabResult: (state, action) => {
            return action.payload
        },
    }
})

export const { setCurrentLabResult } = currentLabResultSlice.actions;
export default currentLabResultSlice.reducer