import {
    Avatar,
    Button,
    Col, Input,
    message,
    Modal,
    Popconfirm,
    Result,
    Row,
    Select, Space,
    Spin,
    Typography
} from "antd";
import {FaHospital, FaHospitalUser, FaNotesMedical, FaUserNurse} from "react-icons/fa";
import {AiFillCalendar, AiFillClockCircle, AiFillMedicineBox} from "react-icons/ai";
import React, {useEffect, useState} from "react";
import {BsTrash} from "react-icons/bs";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import {Calendar, momentLocalizer} from "react-big-calendar";
import "moment-timezone";
import {cancelAppointment, generateTitle, getStaffSchedules, rescheduleAppointment} from "./service";
import {Link} from "react-router-dom";
import moment from "moment";
import DatePickerWithMoment from "../../components/date/DatePickerWithMoment";

const ModifyAppointmentModal = ({
                                    show,
                                    event,
                                    locations,
                                    services,
                                    providers,
                                    onFinish,
                                    timezone,
                                    eventTitleGenerator
                                }) => {
    moment.tz.setDefault(timezone);
    const localizer = momentLocalizer(moment);

    const [open, setOpen] = useState(show);
    const [selectedDate, setSelectedDate] = useState(moment(event.start).startOf('day').toDate());
    const [startEnd, setStartEnd] = useState([moment(event.start).toDate(), moment(event.end).toDate()]);
    const [status, setStatus] = useState("");
    const [service, setService] = useState(event.service);
    const [patient] = useState(event.patient);
    const [staff, setStaff] = useState(event.staff);
    const [title, setTitle] = useState(event.title);
    const [location, setLocation] = useState(event.location.stationId);
    const [note, setNote] = useState(event.note);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [showModifySuccess, setShowModifySuccess] = useState(false);
    const [showCancelSuccess, setShowCancelSuccess] = useState(false);
    const [availableServices] = useState(services);
    const [availableProviders] = useState(providers);
    const [events, setEvents] = useState([]);
    const [schedules, setSchedules] = useState([]);
    const [result, setResult] = useState(null);
    const [currentDaySchedule, setCurrentDaySchedule] = useState(null);

    const DnDCalendar = withDragAndDrop(Calendar)

    const calculateStatus = () => {
        const start = startEnd[0];
        const end = startEnd[1];
        const isTimeOverlap = (start1, end1, start2, end2) => {
            return (start1.getTime() < end2.getTime() && end1.getTime() > start2.getTime());
        }

        let status = '';
        if (moment(start).isBefore(currentDaySchedule.start) || moment(end).isAfter(currentDaySchedule.end)) {
            status = 'error';
        } else if (events && events.length) {
            if (!!events.find(e => isTimeOverlap(e.start, e.end, start, end) && e.type === 'blocked-time')) {
                status = 'error';
            } else if (!!events.find(e => isTimeOverlap(e.start, e.end, start, end))) {
                status = 'warning'
            }
        }
        return status
    }

    const disabledSaveButton = () => {
        return status === 'error'
            || (
                service.serviceID === event.service.serviceID
                && staff.staffId === event.staff.staffId
                && location === event.location.stationId
                && note === event.note
                && moment(selectedDate).isSame(event.start, 'date')
                && moment(startEnd[0]).isSame(event.start, 'hour')
                && moment(startEnd[0]).isSame(event.start, 'minute')
                && moment(startEnd[1]).isSame(event.end, 'hour')
                && moment(startEnd[1]).isSame(event.end, 'minute')
            )
            || !selectedDate
            || !startEnd[0]
            ;
    }

    const reschedule = async () => {
        const date = moment(selectedDate).startOf('day').toDate();
        try {
            setSubmitting(true);
            const rescheduledAppointment = await rescheduleAppointment(event.appointmentId, date, startEnd[0], startEnd[1], staff.staffId, location, service.serviceID, timezone);
            setShowModifySuccess(true);
            setResult({type: 'reschedule', data: rescheduledAppointment.data[0]});
        } catch (e) {
            message.error("We ran into problem rescheduling the appointment.");
        } finally {
            setSubmitting(false);
        }
    }

    const moveToPendingTimeslotBlock = (timeout) => setTimeout(() => {
        const timeslot = document.querySelector('.pending-event') || document.querySelector('.error-event');
        if (timeslot) timeslot.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
    }, timeout);

    const confirmCancel = async () => {
        let cancelStatus;
        const hide = message.loading('Cancelling in progress..', 0, () => {
            if (cancelStatus === "success") {
                message.success('Successfully Canceled Appointment!');
            } else if (cancelStatus === "fail") {
                message.error('We ran into problems when canceling the appointment');
            }
        });

        try {
            await cancelAppointment(event.appointmentId);
            cancelStatus = 'success';
            setShowCancelSuccess(true);
            setResult({type: 'cancel'})
        } catch (e) {
            cancelStatus = "fail";
        } finally {
            hide();
        }
    }

    useEffect(() => setOpen(show), [show])

    useEffect(() => {
        if (staff && service && location) {
            setTitle(eventTitleGenerator(service.serviceName, patient, staff, locations.find(l => l.value === location) || event.location));
            setLoading(true);
            (async () => {
                try {
                    const schedulesRequest = await getStaffSchedules({
                        staff: staff.staffId,
                        service: service.serviceID,
                        location,
                        timezone
                    });
                    const schedules = schedulesRequest.data.data;
                    setSchedules(schedules);

                    const selectedDateSchedule = schedules.find(s => moment(s.date).isSame(selectedDate, 'date'));
                    setCurrentDaySchedule(selectedDateSchedule);

                    if (!selectedDateSchedule) {
                        setStatus('error');
                        setEvents([]);
                    } else {
                        const displayingEvents = selectedDateSchedule.appointments
                            .filter(a => a.appointmentId !== event.appointmentId && a.status === 'Booked')
                            .map(a => ({
                                id: a.appointmentId,
                                title: eventTitleGenerator(a.serviceType, a.patient, staff, event.location),
                                start: moment(a.start).toDate(),
                                end: moment(a.end).toDate()
                            }))
                            .concat(selectedDateSchedule.blockedTimes.map(bt => ({
                                end: moment(bt.end).toDate(),
                                start: moment(bt.start).toDate(),
                                title: <><p>Blocked Time For: {bt.description}</p></>,
                                type: 'blocked-time',
                                className: 'blocked-event',
                            })));
                        setEvents(displayingEvents);
                    }
                } catch (e) {
                    setStatus('error');
                    setEvents([]);
                } finally {
                    setLoading(false);
                }
            })();
            moveToPendingTimeslotBlock(500);
        }
    }, [staff, service, location])

    useEffect(() => {
        if (selectedDate && schedules.length) {
            const selectedDateSchedule = schedules.find(s => moment(s.date).isSame(selectedDate, 'date'));
            setCurrentDaySchedule(selectedDateSchedule);
        } else {
            setCurrentDaySchedule(null);
        }
    }, [selectedDate])

    useEffect(() => {
        if (events.length && startEnd[0] && startEnd[1]) {
            setStatus(calculateStatus(startEnd[0], startEnd[1]));
        } else {
            setStatus("");
        }
    }, [events, startEnd])

    const header = <div className="ms-1 me-4">
        <p className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
                <div style={{background: "#5284C2", padding: 4, borderRadius: 4, marginRight: 8}}>
                    <AiFillCalendar style={{fontSize: 26, color: "white"}}/>
                </div>
                <div className="fw-bold fs-6">
                    <div style={event.title !== title ? {
                        textDecoration: 'line-through',
                        color: "gray",
                        fontStyle: 'italic',
                        fontSize: '12px'
                    } : {}}>
                        {event.title}
                    </div>
                    {
                        event.title !== title &&
                        <div>
                            {title}
                        </div>
                    }
                </div>
            </div>
            <div>
                <Button type="primary" className="me-1 mb-1" disabled={disabledSaveButton()}
                        loading={submitting} onClick={reschedule}>Save</Button>
                <Button onClick={() => onFinish(null)}>Close</Button>
            </div>
        </p>
        <div className="d-flex align-items-center">
            <BsTrash style={{marginRight: 10}}/>
            <Popconfirm
                title="Are you sure to cancel this appointment?"
                onConfirm={confirmCancel}
                okText="Yes"
                cancelText="No"
            >
                <Typography.Link style={{fontSize: 14}}>Cancel Appointment</Typography.Link>
            </Popconfirm>

        </div>
        <hr/>
    </div>;

    return (
        <Modal className="appointment-window"
               title={loading ?
                   <div className='d-flex justify-content-center'>
                       <Spin tip="Loading" size="large"/>
                   </div>
                   :
                   <>
                       {
                           (showModifySuccess || showCancelSuccess) ? <>Result</> : header
                       }
                   </>
               }
               open={open} width={900} destroyOnClose footer={null}
               onCancel={() => onFinish(result)}
        >
            {!loading &&
                <>
                    {
                        !(showModifySuccess || showCancelSuccess) &&
                        <Row>
                            <Col span={15}>
                                <Row className="d-flex align-items-center mb-4">
                                    <FaHospitalUser style={{fontSize: 20, width: 60}}/>
                                    <Typography.Title level={5} style={{marginBottom: 0}}>
                                        {patient.firstName} {patient.lastName}
                                        <Link to={`/patient/${patient.patientId}/`} style={{marginLeft: 20}}
                                              target="_blank">
                                            Patient #{patient.patientId}
                                        </Link>
                                    </Typography.Title>
                                </Row>

                                <Row className="d-flex align-items-center mb-4">
                                    <AiFillMedicineBox style={{fontSize: 22, width: 60}}/>
                                    <Select
                                        showSearch
                                        size="large"
                                        style={{minWidth: 180}}
                                        defaultValue={service.serviceID}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={Array.from(availableServices.reduce(
                                            (entryMap, e) => entryMap.set(e.type, [...entryMap.get(e.type) || [], {
                                                label: e.serviceName,
                                                value: e.serviceID,
                                                obj: e
                                            }]),
                                            new Map()
                                        )).map(([type, value]) => ({label: type, options: [...value]}))}
                                        onChange={(value, option) => {
                                            setService(option.obj);
                                        }}
                                    />
                                </Row>

                                <Row className="d-flex align-items-center mb-4">
                                    <FaUserNurse style={{fontSize: 24, width: 60}}/>
                                    <Select
                                        size="large"
                                        style={{minWidth: 220}}
                                        showSearch
                                        defaultValue={staff.staffId}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        onChange={(value, option) => {
                                            setStaff(option.obj);
                                            setSelectedDate(null);
                                            setStartEnd([null, null]);
                                            setEvents([]);
                                        }}
                                    >
                                        {availableProviders.map((p, i) => (
                                            <Select.Option key={i} value={p.staffId}
                                                           label={`${p.firstName} ${p.lastName}`}
                                                           obj={p}>
                                                <div className="d-flex align-items-center">
                                                    <Avatar size="30" src={p.coverImage} style={{marginRight: 10}}/>
                                                    {p.firstName} {p.lastName}
                                                </div>
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Row>

                                <Row className="d-flex align-items-center mb-4">
                                    <FaHospital style={{fontSize: 20, width: 60}}/>
                                    <Select
                                        size="large"
                                        value={location}
                                        style={{minWidth: 330}}
                                        onChange={value => {
                                            setLocation(value);
                                            setSelectedDate(null);
                                            setStartEnd([null, null]);
                                            setEvents([]);
                                        }}
                                        options={locations}
                                    />
                                </Row>

                                <Row className="d-flex align-items-center mb-3">
                                    <AiFillClockCircle style={{fontSize: 20, width: 60}}/>
                                    <Space.Compact>
                                        <DatePickerWithMoment
                                            value={selectedDate ? moment(selectedDate) : ""}
                                            format={"MMMM DD, YYYY"}
                                            disabledDate={(date) => !schedules.find(s => moment(s.date).isSame(date, 'date'))}
                                            onChange={(date) => {
                                                const newDate = moment(date.toDate()).startOf('day').toDate();
                                                setSelectedDate(newDate);

                                                if (startEnd[0]) {
                                                    const newStart = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), startEnd[0].getHours(), startEnd[0].getMinutes());
                                                    const newEnd = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), startEnd[1].getHours(), startEnd[1].getMinutes());
                                                    setStartEnd([newStart, newEnd]);
                                                }
                                                const selectedDateSchedule = schedules.find(s => moment(s.date).isSame(newDate, 'date'));
                                                const displayingEvents = selectedDateSchedule.appointments.filter(a => a.appointmentId !== event.appointmentId && a.status === 'Booked')
                                                    .map(a => ({
                                                        id: a.appointmentId,
                                                        title: generateTitle(a.serviceType, a.patient, staff),
                                                        start: moment(a.start).toDate(),
                                                        end: moment(a.end).toDate()
                                                    }))
                                                    .concat(selectedDateSchedule.blockedTimes.map(bt => ({
                                                        end: moment(bt.end).toDate(),
                                                        start: moment(bt.start).toDate(),
                                                        title: <><p>Blocked Time For: {bt.description}</p></>,
                                                        type: 'blocked-time',
                                                        className: 'blocked-event',
                                                    })));
                                                setEvents(displayingEvents);
                                            }}/>
                                        <DatePickerWithMoment.RangePicker
                                            picker="time"
                                            disabledTime={() => ({
                                                disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 18, 19, 20, 21, 22, 23],
                                                disabledMinutes: (selectedHour) => selectedHour === 17 ? [10, 15, 20, 30, 40, 45, 50] : []
                                            })}
                                            showSecond={false}
                                            hideDisabledOptions={true}
                                            minuteStep={service.duration % 10 === 5 ? 15 : 10}
                                            value={startEnd[0] ? [moment(startEnd[0]), moment(startEnd[1])] : ["", ""]}
                                            allowEmpty={[false, false]}
                                            allowClear={false}
                                            order={true}
                                            style={{width: 190}}
                                            format={'HH:mm'}
                                            onChange={(time, timeString) => {
                                                const newStart = moment(selectedDate).set('hour', timeString[0].split(":")[0]).set('minute', timeString[0].split(":")[1]).toDate();
                                                const newEnd = moment(selectedDate).set('hour', timeString[1].split(":")[0]).set('minute', timeString[1].split(":")[1]).toDate();
                                                setStartEnd([newStart, newEnd]);
                                                moveToPendingTimeslotBlock(500);
                                            }}
                                        />
                                    </Space.Compact>
                                </Row>
                                {
                                    status !== '' &&
                                    <div>
                                        {
                                            status === 'warning' &&
                                            <Typography.Text type="warning">
                                                The chosen time slot conflicts with an existing appointment for this
                                                provider.
                                            </Typography.Text>
                                        }
                                        {
                                            status === 'error' &&
                                            <Typography.Text type="danger">
                                                This time slot is unavailable for booking. It may be due to either the
                                                start or end time falling outside the scheduled hours or falling inside
                                                of a blocked time, or because the combination of service type, provider,
                                                location, and date is not currently available.
                                            </Typography.Text>
                                        }
                                    </div>
                                }
                                <Row className="d-flex align-items-center">
                                    <div><FaNotesMedical style={{fontSize: 20, width: 60}}/></div>
                                    <div><Input.TextArea style={{width: 320}} row={2} value={note}
                                                         onChange={(e) => setNote(e.target.value)}/></div>
                                </Row>
                            </Col>
                            <Col span={9} style={{height: 350, overflowY: 'auto'}}>
                                {
                                    !!currentDaySchedule &&
                                    <DnDCalendar
                                        defaultDate={selectedDate}
                                        localizer={localizer}
                                        defaultView={'day'}
                                        views={['day']}
                                        components={{
                                            toolbar: () => <></>,
                                        }}
                                        slotPropGetter={date => {
                                            // Disable times from 7:00 AM to 8:00 AM and from 5:00 PM to 6:00 PM
                                            const isDisabled = moment(date).hour() < moment(currentDaySchedule.start).get('hour') || moment(date).hour() >= moment(currentDaySchedule.end).get('hour')
                                            if (isDisabled) {
                                                return {
                                                    className: 'disabled-time'
                                                }
                                            }
                                        }}
                                        min={moment(selectedDate).set("hour", 8)}
                                        max={moment(selectedDate).set("hour", 18)}
                                        timeslots={4}
                                        step={15}
                                        eventPropGetter={(event, start, end, isSelected) => {
                                            let colorClass;
                                            if (event.type === 'SELECTED_SLOT') {
                                                if (status === 'error') {
                                                    colorClass = 'error-event draggable-event'
                                                } else {
                                                    colorClass = 'pending-event draggable-event';
                                                }
                                            } else if (event.type === 'blocked-time') {
                                                colorClass = 'blocked-event non-interaction-event';
                                            } else {
                                                colorClass = 'steelblue-event non-interaction-event';
                                            }
                                            return {
                                                className: `${colorClass}`
                                            }
                                        }}
                                        events={[...events,
                                            {
                                                id: events.length,
                                                type: 'SELECTED_SLOT',
                                                title: status === 'error' ? 'You can not book this slot' : title,
                                                start: startEnd[0],
                                                end: startEnd[1],
                                                isDraggable: true,
                                            }
                                        ]}
                                        resizable={false}
                                        draggableAccessor="isDraggable"
                                        onEventDrop={({event, start, end, allDay}) => {
                                            setStartEnd([start, end]);
                                        }}
                                    />
                                }
                            </Col>
                        </Row>
                    }
                    {
                        showModifySuccess &&
                        <Result
                            status="success"
                            title="Successfully Modified Appointment!"
                            extra={[
                                <>
                                    <h5>Appointment detail after modification:</h5>
                                    <p>
                                        <strong>Patient: </strong>
                                        {patient.firstName} {patient.lastName}
                                    </p>
                                    <p>
                                        <strong>Service: </strong>
                                        {service.serviceName}
                                    </p>
                                    <p>
                                        <strong>Provider: </strong>
                                        {staff.firstName} {staff.lastName}
                                    </p>
                                    <p>
                                        <strong>Location: </strong>
                                        {locations.find(l => location === l.value).label}
                                    </p>
                                    <p>
                                        <strong>Date: </strong>
                                        {moment(selectedDate).format("MMMM Do, YYYY")}
                                    </p>
                                    <p>
                                        <strong>Time: </strong>
                                        {moment(startEnd[0]).format("hh:mm a")} - {moment(startEnd[1]).format("hh:mm a")}
                                    </p>
                                </>
                            ]}
                        />
                    }
                    {
                        showCancelSuccess &&
                        <Result
                            status="success"
                            title="Successfully Canceled Appointment!"
                        />
                    }
                </>
            }
        </Modal>
    )

}
export default ModifyAppointmentModal;