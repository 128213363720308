
const StatesList = [
    {
      key: "AL",
      name: "Alabama"
    },
    {
      key: "AK",
      name: "Alaska"
    },
    {
      key: "AZ",
      name: "Arizona"
    },
    {
      key: "AR",
      name: "Arkansas"
    },
    {
      key: "CA",
      name: "California"
    },
    {
      key: "CO",
      name: "Colorado"
    },{
      key: "CT",
      name: "Connecticut"
    },{
      key: "DE",
      name: "Delaware"
    },
    {
      key: "FL",
      name: "Florida"
    },{
      key: "GA",
      name: "Georgia"
    }, {
      key: "HI",
      name: "Hawaii"
    }, {
      key: "ID",
      name: "Idaho"
    },{
      key: "IL",
      name: "Illinois"
    },{
      key: "IN",
      name: "Indiana",
    },{
      key: "IA",
      name: "Iowa"
    }, {
      key: "KS",
      name: "Kansas"
    },{
      key: "KY",
      name: "Kentucky"
    },{
      key: "LA",
      name: "Louisiana"
    },{
      key: "ME",
      name: "Maine"
    },{
      key: "MD",
      name: "Maryland"
    },{
      key: "MA",
      name: "Massachusetts"
    },{
      key: "MI",
      name: "Michigan"
    },{
      key: "MN",
      name: "Minnesota"
    },{
      key: "MS",
      name: "Mississippi"
    },{
      key: "MO",
      name: "Missouri"
    },{
      key: "MT",
      name: "Montana"
    },{
      key: "NE",
      name: "Nebraska"
    },{
      key: "NV",
      name: "Nevada"
    },{
      key: "NH",
      name: "New Hampshire"
    },{
      key: "NJ",
      name: "New Jersey"
    },{
      key: "NM",
      name: "New Mexico"
    },{
      key: "NY",
      name: "New York"
    },{
      key: "NC",
      name: "North Carolina"
    },{
      key: "ND",
      name: "North Dakota"
    },{
      key: "OH",
      name: "Ohio"
    },{
      key: "OK",
      name: "Oklahoma"
    },{
      key: "OR",
      name: "Oregon"
    },{
      key: "PA",
      name: "Pennsylvania"
    },{
      key: "RI",
      name: "Rhode Island"
    },{
      key: "SC",
      name: "South Carolina"
    },{
      key: "SD",
      name: "South Dakota"
    },{
      key: "TN",
      name: "Tennessee"
    },{
      key: "TX",
      name: "Texas"
    },{
      key: "UT",
      name: "Utah"
    },{
      key: "VT",
      name: "Vermont"
    },{
      key: "VA",
      name: "Virginia"
    },{
      key: "WA",
      name: "Washington"
    },{
      key: "WV",
      name: "West Virginia"
    },{
      key: "WI",
      name: "Wisconsin"
    },{
      key: "WY",
      name: "Wyoming"
    }
];

export const getStateNameByKey = (key) => {
  const state = StatesList.find((st) => st.key === key)
  if(state) {
    return state.name
  }
  
  return "Undefined"
}

export const parseStatesFromPlasmaCenters = (plasmaCenters) => {
  if(!plasmaCenters) {
    return []
  }
  const states = []
  for(let i = 0; i < plasmaCenters.length; i++) {
    if(plasmaCenters[i].address) {
      let state = plasmaCenters[i].address.state
      let exist = states.find((v) => v === state)
      if(!exist) {
          states.push(state)
      }
    }
  }

  return states
}

export const parsePlasmaCentersFromImportantMessages = (imgs) => {
  if(!imgs) {
    return []
  }
  const pls = []
  for(let i = 0; i < imgs.length; i++) {
    let pl = imgs[i].locationObjectID
    if(pl) {
      let exist = pls.find((v) => v._id === pl._id)
      if(!exist) {
          pls.push(pl)
      }
    }
  }

  return pls
}

export const parsePlasmaCentersFromNewsAndEvents = (nes) => {
  if(!nes) {
    return []
  }
  const pls = []
  for(let i = 0; i < nes.length; i++) {
    let pl = nes[i].location
    if(pl) {
      let exist = pls.find((v) => v.locationID === pl.locationID)
      if(!exist) {
          pls.push(pl)
      }
    }
  }

  return pls
}

export const parseCitiesFromPlasmaCenters = (plasmaCenters, state) => {
  let citiesPlasmaCenterFilter = []
  if(state !== "") {
      citiesPlasmaCenterFilter = plasmaCenters.filter((pl) => pl.address && (pl.address.state === state))
  } else {
      citiesPlasmaCenterFilter = plasmaCenters
  }

  if(!citiesPlasmaCenterFilter) {
    return []
  }
  
  const cities = []
  for(let i = 0; i < citiesPlasmaCenterFilter.length; i++) {
      let city = citiesPlasmaCenterFilter[i].address.city
      let exist = cities.find((v) => v === city)
      if(!exist) {
          cities.push(city)
      }
  }
  return cities
}

export const filterPlasmaCentersByStateAndCity = (plasmaCenters, state) => {
  if(!plasmaCenters) {
    return []
  }
  
  let plasma_centers = plasmaCenters
  if(state !== "") {
      plasma_centers = plasma_centers.filter((pl) => pl.address && (pl.address.state === state))
  }

  return plasma_centers
}

export default StatesList;
