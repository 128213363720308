import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import AnnouncementList from './AnnouncementList'
import NewAnnouncement from "./NewAnnouncement";
import Page from '../../../components/page/Page';
import { Tab, Tabs } from 'react-bootstrap';

class AnnouncementPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            type: 0
        };
    }

    render() {
        return (
            <Page title={'Announcement Management'} isCustom={false}>
                <div style={{ width: "100%", height: "100%" }}>
                    <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3" defaultActiveKey={this.state.type}>
                        <Tab eventKey={0} title="Send Announcement" onClick={() => {
                            this.setState({
                                type: 0
                            })
                        }}>
                            <NewAnnouncement />
                        </Tab>
                        <Tab eventKey={1} title="Announcement History" onClick={() => {
                            this.setState({
                                type: 1
                            })
                        }}>
                            <AnnouncementList
                                switch={false}
                                type={this.state.type}
                                history={this.props.history} />
                        </Tab>
                    </Tabs>
                </div>
            </Page>
        )
    }
};

export default AnnouncementPage;
