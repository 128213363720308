import React from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import DataTable from "../../../components/data-table/data-table.component";
import { Button, message, Space, Typography, Upload } from "antd";
import "./patient.css";
import {
  DeleteOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  deleteFiles,
  getBioIdFiles,
  getFiles,
  getLabreportsFiles,
  checkPresignUrl,
} from "../service";
import moment from "moment";
import ConfirmModal from "../../../components/modal/ConfirmModal";
import { UPLOAD_FILE_URL } from "../../../api/URLs";
import bioApi from "../../../api/bioApi";

const { Link } = Typography;

function FilePage() {
  const currentPatient = useSelector((state) => state.currentPatient);
  const [fileLoading, setFileLoading] = React.useState(true);
  const [fileData, setFileData] = React.useState([]);
  const [fileSetting, setFileSetting] = React.useState(null);
  const [bioDataLoading, setBioDataLoading] = React.useState(true);
  const [bioIdData, setPreviousBioIdData] = React.useState([]);
  const [bioIdTableSetting, setPreviousBioIdTableSetting] =
    React.useState(null);
  const [showLoading, setShowLoading] = React.useState(true);
  const [labData, setLabData] = React.useState([]);
  const [labDataSetting, setLabDataTableSetting] = React.useState(null);
  const [modalTitle, setModalTitle] = React.useState("Delete File");
  const [modalMessage, setModalMessage] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [deleteID, setDeleteID] = React.useState(null);
  const [messageApi, contextHolder] = message.useMessage();

  const fetchFiles = async () => {
    const response = await getFiles(currentPatient);
    if (response.status == 200) {
      setFileData(response.data);
    }
    setFileSetting({
      columns: [
        {
          title: "Title",
          dataIndex: "name",
          width: "400px",
        },
        {
          title: "Date",
          dataIndex: "creationDate",
          width: "350px",
          render: (text) =>
            moment(text).tz("UTC").format("MMM DD, YYYY - hh:mma "),
        },
      ],
      customActions: [
        {
          element: (record, data) => (
            <Space wrap>
              <Button
                onClick={() => handleUrl('file',record.name)}
                icon={<DownloadOutlined />}
              >
                Download
              </Button>
              <Button
                danger
                icon={<DeleteOutlined />}
                onClick={() => {
                  setModalMessage(
                    `Are you sure you want to remove file : ${record.name}'s Lab Report ?`,
                  );
                  setShowModal(true);
                  setDeleteID(record._id);
                }}
              >
                Delete
              </Button>
            </Space>
          ),
        },
      ],
      pagination: {
        showLessItems: true,
        pageSize: 6,
      },
      bordered: false,
      operationColumnTitle: "Action",
      tableClassName: "white-header-table mb-0-table",
    });
  };

  const fetchLabreportData = async () => {
    const response = await getLabreportsFiles(currentPatient);
    if (response.status == 200) {
      setLabData(response.data);
    }
    setLabDataTableSetting({
      columns: [
        {
          title: "Title",
          dataIndex: "name",
          width: "400px",
          render: (text) => text + "'s Lab Report",
        },
        {
          title: "Date",
          dataIndex: "creationDate",
          width: "350px",
          render: (text) =>
            moment(text).tz("UTC").format("MMM DD, YYYY - hh:mma "),
        },
      ],
      customActions: [
        {
          element: (record, data) => (
            <Space wrap>
              <Button
                onClick={() => handleUrl('lab-report',record.fileName)}
                icon={<DownloadOutlined />}
              >
                Download
              </Button>
            </Space>
          ),
        },
      ],
      pagination: {
        showLessItems: true,
        pageSize: 6,
      },
      bordered: false,
      operationColumnTitle: "Action",
      tableClassName: "white-header-table mb-0-table",
    });
  };

  const uploadProps = {
    name: "pdf",
    maxCount: 1,
    action:
      bioApi.defaults.baseURL +
      UPLOAD_FILE_URL.replace(":id", currentPatient.patientId),
    async onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} uploaded successfully`);
        await fetchData();
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} upload failed.`);
      }
    },
    withCredentials: true,
  };

  const fetchBioIdFiles = async () => {
    const response = await getBioIdFiles(currentPatient);
    if (response.status == 200) {
      setPreviousBioIdData(response.data);
    }
    setPreviousBioIdTableSetting({
      columns: [
        {
          title: "Title",
          dataIndex: "name",
          width: "400px",
          render: (text) => text + "'s bioID",
        },
        {
          title: "Date",
          dataIndex: "creationDate",
          width: "350px",
          render: (text) =>
            moment(text).tz("UTC").format("MMM DD, YYYY - hh:mma "),
        },
      ],
      customActions: [
        {
          element: (record, data) => (
            <Button
              onClick={() => handleUrl('bio-id',record.fileName)}
              icon={<DownloadOutlined />}
            >
              {" "}
              Download{" "}
            </Button>
          ),
        },
      ],
      bordered: false,
      operationColumnTitle: "Action",
      tableClassName: "white-header-table mb-0-table",
      pagination: {
        showLessItems: true,
        pageSize: 6,
      },
    });
  };

  const fetchData = async () => {
    setShowLoading(true);
    setBioDataLoading(true);
    setFileLoading(true);
    await fetchLabreportData();
    await fetchBioIdFiles();
    await fetchFiles();
    setBioDataLoading(false);
    setShowLoading(false);
    setFileLoading(false);
  };
  React.useEffect(() => {
    fetchData();
  }, []);

  const onConfirmDelete = async () => {
    await deleteFiles({ _id: deleteID });
    await fetchData();
    await fetchFiles();
    setShowModal(false);
  };

  const handleDownload = (url) => {
    const downloadWindow = window.open(url, "_blank");
    downloadWindow.focus();
  };


  const handleUrl = async (type,fileName) => {
    const urlData = await checkPresignUrl(type,fileName);

    if (urlData.data.status) {
      const newTab = window.open(urlData.data.url, '_blank');
      newTab.focus();
      return;
    }
    messageApi.open({
      type: "error",
      content: "Failed to open Lab Report, please try it again later.",
    });
  }

  return (
    <>
      {contextHolder} 
      {fileSetting && (
        <Row className="profile mt-4">
          <Col sm={8}>
            <h2 className="section-title">File</h2>
          </Col>
          <Col sm={4} className="create-row">
            <Upload {...uploadProps}>
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Col>
          <Col sm={12}>
            <Row className="section-content">
              <Col sm={12}>
                <div style={{ padding: "12px" }}>
                  <DataTable
                    loading={fileLoading}
                    data={fileData}
                    settings={fileSetting}
                  ></DataTable>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {labDataSetting && (
        <Row className="profile mt-4">
          <Col sm={6}>
            <h2 className="section-title">Lab Report</h2>
          </Col>
          <Col sm={12}>
            <Row className="section-content">
              <Col sm={12}>
                <div style={{ padding: "12px" }}>
                  <DataTable
                    loading={showLoading}
                    data={labData}
                    settings={labDataSetting}
                  ></DataTable>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {bioIdTableSetting && (
        <Row className="profile mt-4">
          <Col sm={6}>
            <h2 className="section-title">BioID</h2>
          </Col>
          <Col sm={12}>
            <Row className="section-content">
              <Col sm={12}>
                <div style={{ padding: "12px" }}>
                  <DataTable
                    data={bioIdData}
                    loading={bioDataLoading}
                    settings={bioIdTableSetting}
                  ></DataTable>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <ConfirmModal
        modalOption={{
          option: {
            title: modalTitle,
            message: modalMessage,
            buttonConfirmText: "Yes",
            onConfirm: onConfirmDelete,
            onCancel: () => {
              setShowModal(false);
            },
          },
          loading: showLoading,
          show: showModal,
        }}
      />
    </>
  );
}

export default FilePage;
