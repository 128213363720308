import {useDispatch, useSelector} from "react-redux";
import {
    Avatar,
    Button,
    Card,
    Checkbox,
    Col,
    Empty,
    Form,
    Input,
    InputNumber,
    List, Modal,
    notification,
    Row,
    Typography
} from "antd";
import {calculateAge, createMedicalNote, getMedicalNotes, updateSymptomsAndGoals,getSymptomsAndGoals} from "../service";
import React, {useEffect, useState} from "react";
import {SmileOutlined} from "@ant-design/icons";
import {updateCurrentPatient} from "../../../stores/current-patient.store";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from "moment";
import MedicalNoteDisplay from "./components/medical-note.display";
import Table from 'react-bootstrap/Table';

const MedicalNotesPage = () => {
    const timezone = 'America/New_York';
    moment.tz.setDefault(timezone);

    const dispatch = useDispatch();
    const currentPatient = useSelector(state => state.currentPatient);
    const currentLabResult = useSelector(state => state.currentLabResult);
    const [gender, setGender] = useState("");
    const [form] = Form.useForm();
    const [expanded, setExpanded] = useState("1");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [checkingNote, setCheckingNote] = useState(null);
    const [availableGoals, setAvailableGoals] = React.useState([]);
    const [medicalNotes, setMedicalNotes] = useState([]);

    const notesPrefill = `History, labs and recommendations reviewed with patient. Risks and benefits of HRT as well as therapy options and expectations were discussed with patient. Discussed staying up-to-date with applicable screening tests including breast, prostate, cervical, colorectal, and lung cancers as recommended by the U.S. Preventive Services Task Force (USPSTF).`;
    const onFinish = async (values) => {
        try {
            const newSymptomsAndGoals = values.health_concern;
            const oldSymptomsAndGoals = currentPatient.symptomsAndGoals || [];
            if (!arraysAreTheSame(newSymptomsAndGoals, oldSymptomsAndGoals)) {
                await updateSymptomsAndGoals(currentPatient.patientId, newSymptomsAndGoals);
                dispatch(updateCurrentPatient({key: 'symptomsAndGoals', value: newSymptomsAndGoals}));
            }
            await createMedicalNote(currentPatient.patientId, values);
            form.resetFields();
            notification.success({message: "successfully created medical note", duration: 0});
            setMedicalNotes([
                {
                    createdBy: {},
                    creationDate: new Date(),
                    patientId: currentPatient.patientId,
                    content: values
                },
                ...medicalNotes
            ])
            setExpanded("2");
        } catch (e) {
            notification.error({message: "unable to create medical note!", duration: 0});
        }
    };

    const fetchSymptomsAndGoals = async () => {
        const goals = await getSymptomsAndGoals(currentPatient.patientId);
        if (goals.data) {
          const data = goals.data.data.map((sag) => ({
            label: sag.name,
          }))
          setAvailableGoals(data);
        }
    }

    const expandChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const arraysAreTheSame = (arr1, arr2) => {
        return arr1.length === arr2.length && arr1.every(item => arr2.includes(item));
    }

    useEffect(() => {
        if (currentPatient) {
            const theGender =
                currentPatient.gender.charAt(0).toUpperCase()
                + currentPatient.gender.slice(1);
            setGender(theGender);

            (async () => {
                const response = await getMedicalNotes(currentPatient.patientId);
                setMedicalNotes(response.data);
            })()

            fetchSymptomsAndGoals();
        }
    }, [currentPatient]);

    return (
        <div>
            <Accordion expanded={expanded === '1'} onChange={expandChange('1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                >
                    <Typography.Title level={5}>Medical Intake Form</Typography.Title>
                </AccordionSummary>
                <AccordionDetails>
                    <Form
                        form={form}
                        name="basic"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 19}}
                        style={{maxWidth: "100%"}}
                        initialValues={{
                            age: calculateAge(currentPatient.dob),
                            notes: notesPrefill,
                            lab_review: currentLabResult ? currentLabResult.filter(r => r.isAbnormal) : [],
                            health_concern: currentPatient ? currentPatient.symptomsAndGoals : []
                        }}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Card title="Chief Complaint(s):" type="inner">
                            <Card.Grid style={{width: "50%"}} hoverable={false}>
                                <Form.Item
                                    label="HPI"
                                >
                                    <Form.Item
                                        name={['age']}
                                        noStyle
                                    >
                                        <InputNumber min={1} max={150} style={{"marginRight": "4px", width: "60px"}}/>
                                    </Form.Item>
                                    years old {gender}
                                    <br/>
                                    <Form.Item
                                        name={['complains']}
                                        noStyle
                                    >
                                        <Input.TextArea rows={6} placeholder="Complains"/>
                                    </Form.Item>
                                </Form.Item>
                            </Card.Grid>
                            <Card.Grid style={{width: "50%"}} hoverable={false}>
                                <Form.Item
                                    labelCol={{span: 12}}
                                    label="Labs being reviewed were drawn on"
                                    name={['lab_collection_date']}

                                >
                                    <Input/>
                                </Form.Item>

                                <Form.Item
                                    labelCol={{span: 12}}
                                    label="Labs were completed"
                                >
                                    {
                                        gender === "Male" ?
                                            <>
                                                <Form.Item
                                                    noStyle
                                                    name={['day_number']}
                                                >
                                                    <InputNumber style={{width: 60, marginRight: 4}}/>
                                                </Form.Item>
                                                days post injection
                                            </>
                                            :
                                            <>
                                                on Day
                                                <Form.Item
                                                    name={['day_number']}
                                                    noStyle
                                                >
                                                    <InputNumber style={{width: 60, marginInline: 4}}/>
                                                </Form.Item>
                                                of cycle
                                            </>
                                    }
                                </Form.Item>
                            </Card.Grid>
                        </Card>
                        <Card title="General Health" type="inner" style={{marginTop: 8}}>
                            <Row gutter={8}>
                                <Col span={8}>
                                    <Card title="Sleep" bordered={false}>
                                        <Form.Item
                                            name={['sleep']}
                                            noStyle
                                        >
                                            <Input.TextArea rows={3} placeholder="Sleep"/>
                                        </Form.Item>
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card title="Energy" bordered={false}>
                                        <Form.Item
                                            name={['energy']}
                                            noStyle
                                        >
                                            <Input.TextArea rows={3} placeholder="Energy"/>
                                        </Form.Item>
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card title="Stress" bordered={false}>
                                        <Form.Item
                                            name={['stress']}
                                            noStyle

                                        >
                                            <Input.TextArea rows={3} placeholder="Stress"/>
                                        </Form.Item>
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card title="Libido" bordered={false}>
                                        <Form.Item
                                            name={['libido']}
                                            noStyle
                                        >
                                            <Input.TextArea rows={3} placeholder="Libido"/>
                                        </Form.Item>
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card title="Exercise" bordered={false}>
                                        <Form.Item
                                            name={['exercise']}
                                            noStyle
                                        >
                                            <Input.TextArea rows={3} placeholder="Exercise"/>
                                        </Form.Item>
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card title="Diet" bordered={false}>
                                        <Form.Item
                                            name={['diet']}
                                            noStyle
                                        >
                                            <Input.TextArea rows={3} placeholder="Diet"/>
                                        </Form.Item>
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card title="Gut health" bordered={false}>
                                        <Form.Item
                                            name={['gut_health']}
                                            noStyle
                                        >
                                            <Input.TextArea rows={3} placeholder="Gut health"/>
                                        </Form.Item>
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card title="Cognitive function" bordered={false}>
                                        <Form.Item
                                            name={['cognitive_function']}
                                            noStyle
                                        >
                                            <Input.TextArea rows={3} placeholder="Cognitive function"/>
                                        </Form.Item>
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card title="Weight management" bordered={false}>
                                        <Form.Item
                                            name={['weight_management']}
                                            noStyle
                                        >
                                            <Input.TextArea rows={3} placeholder="Weight management"/>
                                        </Form.Item>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                        <Card title={`Sexual Health (${gender})`} style={{marginTop: 8}} type="inner">
                            {
                                gender === "Male" ?
                                    <>
                                        <Form.Item
                                            label={"Hx of TRT/Anabolic steroid use"}
                                            name={['steroid_use']}
                                        >
                                            <Input.TextArea rows={2}/>
                                        </Form.Item>
                                        <Form.Item
                                            label={"ED"}
                                            name={['ed']}
                                        >
                                            <Input.TextArea rows={2}/>
                                        </Form.Item>
                                        <Form.Item
                                            label={"STI"}
                                            name={['sti']}
                                        >
                                            <Input.TextArea rows={2}/>
                                        </Form.Item>
                                        <Row>
                                            <Col span={12}>
                                                <Form.Item
                                                    labelCol={{span: 12}}
                                                    label={"Last Colonoscopy"}
                                                    name={['last_colonoscopy']}
                                                >
                                                    <Input/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    labelCol={{span: 12}}
                                                    label={"Last Prostate Exam"}
                                                    name={['last_prostate_exam']}
                                                >
                                                    <Input/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </>
                                    :
                                    <>
                                        <Form.Item
                                            label={"GP"}
                                            name={['gp']}
                                        >
                                            <Input.TextArea rows={2}/>
                                        </Form.Item>
                                        <Form.Item
                                            label={"Menstrual Cycle"}
                                            name={['menstrual_cycle']}
                                        >
                                            <Input.TextArea rows={2}/>
                                        </Form.Item>
                                        <Form.Item
                                            label={"LMP"}
                                            name={['lmp']}
                                        >
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item
                                            label={"PMS"}
                                            name={['pms']}
                                        >
                                            <Input.TextArea rows={2}/>
                                        </Form.Item>
                                        <Form.Item
                                            label={"Birth Control"}
                                            name={['birth_control']}
                                        >
                                            <Input.TextArea rows={2}/>
                                        </Form.Item>
                                        <Form.Item
                                            label={"HX of Fibroids"}
                                            name={['hx_of_fibroids']}
                                        >
                                            <Input.TextArea rows={2}/>
                                        </Form.Item>
                                        <Form.Item
                                            label={"HX of Endometriosis"}
                                            name={['hx_of_endometriosis']}
                                        >
                                            <Input.TextArea rows={2}/>
                                        </Form.Item>
                                        <Form.Item
                                            label={"STIs"}
                                            name={['sti']}
                                        >
                                            <Input.TextArea rows={2}/>
                                        </Form.Item>
                                        <Form.Item
                                            label={"Hormonal imbalance symptoms"}
                                            name={"hormonal_imbalance_symptoms"}
                                        >
                                            <Input.TextArea rows={2}/>
                                        </Form.Item>
                                        <Form.Item
                                            label={"Last Pap Smear"}
                                            name={"last_pap_smear"}
                                        >
                                            <Input.TextArea rows={2}/>
                                        </Form.Item>
                                        <Form.Item
                                            label={"Last Mammogram"}
                                            name={"last_mammogram"}
                                        >
                                            <Input.TextArea rows={2}/>
                                        </Form.Item>
                                        <Form.Item
                                            label={"Last Bone Density"}
                                            name={"last_bone_density"}
                                        >
                                            <Input.TextArea rows={2}/>
                                        </Form.Item>
                                        <Form.Item
                                            label={"Last Colonoscopy"}
                                            name={"last_colonoscopy"}
                                        >
                                            <Input.TextArea rows={2}/>
                                        </Form.Item>
                                    </>
                            }
                        </Card>
                        <Card title={"Allergies"} type="inner" style={{marginTop: "8px"}}>
                            <Row gutter={8}>
                                <Col span={8}>
                                    <Card title="Medications" bordered={false}>
                                        <Form.Item
                                            noStyle
                                            name={"medication_allergy"}
                                        >
                                            <Input.TextArea rows={3} placeholder="Medication Allergy"/>
                                        </Form.Item>
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card title="Food" bordered={false}>
                                        <Form.Item
                                            noStyle
                                            name={"food_allergy"}
                                        >
                                            <Input.TextArea rows={3} placeholder="Food Allergy"/>
                                        </Form.Item>
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card title="Environmental" bordered={false}>
                                        <Form.Item
                                            noStyle
                                            name={"environmental_allergy"}
                                        >
                                            <Input.TextArea rows={3} placeholder="Environmental Allergy"/>
                                        </Form.Item>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                        <Card type="inner" style={{marginTop: "8px"}} title={"Outside Products"}>
                            <Row gutter={8}>
                                <Col span={12}>
                                    <Card title="Outside Medications" bordered={false}>
                                        <Form.Item
                                            noStyle
                                            name={"outside_medications"}
                                        >
                                            <Input.TextArea rows={3} placeholder="Outside Medications"/>
                                        </Form.Item>
                                    </Card>
                                </Col>
                                <Col span={12}>
                                    <Card title="Outside Supplements" bordered={false}>
                                        <Form.Item
                                            noStyle
                                            name={"outside_supplements"}
                                        >
                                            <Input.TextArea rows={3} placeholder="Outside Supplements"/>
                                        </Form.Item>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                        <Card type="inner" style={{marginTop: "8px"}} title={"Medical History"}>
                            <Form.Item
                                label={"Family history"}
                                name={"family_history"}
                            >
                                <Input.TextArea rows={2}/>
                            </Form.Item>
                            <Form.Item
                                label={"Past Medical History"}
                                name={"past_medical_history"}
                            >
                                <Input.TextArea rows={2}/>
                            </Form.Item>
                            <Form.Item
                                label={"Past Surgeries/Hospitalizations"}
                                name={"past_surgeries"}
                            >
                                <Input.TextArea rows={2}/>
                            </Form.Item>
                        </Card>
                        <Card title={"Social History"} type="inner" style={{marginTop: "8px"}}>
                            <Row gutter={8}>
                                <Col span={8}>
                                    <Card title="ETOH" bordered={false}>
                                        <Form.Item
                                            noStyle
                                            name={"etoh"}
                                        >
                                            <Input.TextArea rows={3} placeholder="ETOH"/>
                                        </Form.Item>
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card title="Tobacco" bordered={false}>
                                        <Form.Item
                                            noStyle
                                            name={"tobacco"}
                                        >
                                            <Input.TextArea rows={3} placeholder="Tobacco"/>
                                        </Form.Item>
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card title="RD" bordered={false}>
                                        <Form.Item
                                            noStyle
                                            name={"rd"}
                                        >
                                            <Input.TextArea rows={3} placeholder="RD"/>
                                        </Form.Item>
                                    </Card>
                                </Col>
                                <Col span={6}>
                                    <Card title="Occupation" bordered={false}>
                                        <Form.Item
                                            noStyle
                                            name={"occupation"}
                                        >
                                            <Input.TextArea rows={3} placeholder="Occupation"/>
                                        </Form.Item>
                                    </Card>
                                </Col>
                                <Col span={6}>
                                    <Card title="Marital Status" bordered={false}>
                                        <Form.Item
                                            noStyle
                                            name={"marital_status"}
                                        >
                                            <Input.TextArea rows={3} placeholder="Marital Status"/>
                                        </Form.Item>
                                    </Card>
                                </Col>
                                <Col span={6}>
                                    <Card title="Children" bordered={false}>
                                        <Form.Item
                                            noStyle
                                            name={"children"}
                                        >
                                            <Input.TextArea rows={3} placeholder="Children"/>
                                        </Form.Item>
                                    </Card>
                                </Col>
                                <Col span={6}>
                                    <Card title="Heritage" bordered={false}>
                                        <Form.Item
                                            noStyle
                                            name={"heritage"}
                                        >
                                            <Input.TextArea rows={3} placeholder="Heritage"/>
                                        </Form.Item>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                        <Card title={"ROS"} type="inner" style={{marginTop: "8px"}}>
                            <Row gutter={6}>
                                <Col span={6}>
                                    <Form.Item
                                        label={"HEENT"}
                                        name={"heent"}
                                    >
                                        <Input.TextArea rows={2}/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label={"CV"}
                                        name={"cv"}
                                    >
                                        <Input.TextArea rows={2}/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label={"Resp"}
                                        name={"resp"}
                                    >
                                        <Input.TextArea rows={2}/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label={"GI"}
                                        name={"gi"}
                                    >
                                        <Input.TextArea rows={2}/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label={"MSK"}
                                        name={"msk"}
                                    >
                                        <Input.TextArea rows={2}/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label={"Neuro"}
                                        name={"neuro"}
                                    >
                                        <Input.TextArea rows={2}/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label={"Derm"}
                                        name={"derm"}
                                    >
                                        <Input.TextArea rows={2}/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label={"Endo"}
                                        name={"endo"}
                                    >
                                        <Input.TextArea rows={2}/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label={"Hemat"}
                                        name={"hemat"}
                                    >
                                        <Input.TextArea rows={2}/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label={"Psych"}
                                        name={"psych"}
                                    >
                                        <Input.TextArea rows={2}/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label={"GU"}
                                        name={"gu"}
                                    >
                                        <Input.TextArea rows={2}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <Card title={"Physical"} type="inner" style={{marginTop: 8}}>
                            <Form.Item
                                noStyle
                                name={"physical"}
                            >
                                <Input.TextArea rows={2}/>
                            </Form.Item>
                        </Card>
                        <Card title={"AREAS OF HEALTH CONCERN"} type="inner" style={{marginTop: 8}}>
                            <Form.Item
                                noStyle
                                name={"health_concern"}
                            >
                                <Checkbox.Group>
                                    <Row>
                                        {
                                            availableGoals && availableGoals.map((sag, i) =>
                                                <Col key={i} span={8}>
                                                    <Checkbox value={sag.label}
                                                              style={{lineHeight: '32px'}}>
                                                        {sag.label}
                                                    </Checkbox>
                                                </Col>
                                            )
                                        }
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Card>
                        <Card title={"LAB REVIEW"} type="inner" style={{marginTop: 8}}>
                            {
                                (!currentLabResult || !currentLabResult.length) ?
                                    <Empty description={"No lab result was found"}/>
                                    :
                                    <>
                                        {
                                            !currentLabResult.filter(r => r.isAbnormal).length ?
                                                <Empty
                                                    image={<SmileOutlined/>}
                                                    description={"No out of range biomarker!"}
                                                />
                                                :
                                                <Table bordered>
                                                    <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Biomarker</th>
                                                        <th>Lab Data</th>
                                                        <th>Optimal Range</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        [...currentLabResult.filter(r => r.isAbnormal)
                                                            .reduce(
                                                                (entryMap, e) => entryMap.set(e.type, [...entryMap.get(e.type) || [], e]),
                                                                new Map()
                                                            )].map(([type, value], i) =>
                                                            value.map((r, ii) =>
                                                                <tr key={ii}>
                                                                    {
                                                                        ii === 0 &&
                                                                        <td rowSpan={value.length} style={{
                                                                            textAlign: "center",
                                                                            verticalAlign: "middle",
                                                                            fontWeight: "bold"
                                                                        }}>{type}</td>
                                                                    }
                                                                    <td>{r.biomarker}</td>
                                                                    <td>{r.labData}</td>
                                                                    <td>{r.optimalRange}</td>
                                                                </tr>
                                                            )
                                                        )
                                                    }
                                                    </tbody>
                                                </Table>
                                        }
                                    </>
                            }
                            <Form.Item name={"lab_review"} noStyle>
                                <Input type="hidden"></Input>
                            </Form.Item>
                        </Card>
                        <Card title={"RECOMMENDATIONS"} type="inner" style={{marginTop: 8}}>
                            <Form.Item
                                noStyle
                                name={"recommendations"}
                            >
                                <Input.TextArea rows={2}/>
                            </Form.Item>
                        </Card>
                        <Card title={"OTHER"} type="inner" style={{marginTop: 8}}>
                            <Form.Item
                                noStyle
                                name={"other"}
                            >
                                <Input.TextArea rows={2}/>
                            </Form.Item>
                        </Card>
                        <Card title={"Specialty Testing"} type="inner" style={{marginTop: 8}}>
                            <Form.Item
                                noStyle
                                name={"specialty_testing"}
                            >
                                <Input.TextArea rows={2}/>
                            </Form.Item>
                        </Card>
                        <Card title={"Follow up"} type="inner" style={{marginTop: 8}}>
                            <Form.Item
                                noStyle
                                name={"follow_up"}
                            >
                                <Input.TextArea rows={2}/>
                            </Form.Item>
                        </Card>
                        <Card title={"Notes"} type="inner" style={{marginTop: 8}}>
                            <Form.Item
                                noStyle
                                name={"notes"}
                            >
                                <Input.TextArea rows={4}/>
                            </Form.Item>
                        </Card>
                        <Form.Item wrapperCol={{offset: 11, span: 16}} style={{marginTop: 8}}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === '2'} onChange={expandChange('2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                >
                    <Typography.Title level={5}>Previous Medical Notes</Typography.Title>
                </AccordionSummary>
                <AccordionDetails>
                    <List
                        itemLayout="horizontal"
                        dataSource={medicalNotes}
                        renderItem={(item) => (
                            <List.Item actions={[<Button key="check-note" type="primary" onClick={() => {
                                setCheckingNote(item);
                                setIsModalOpen(true)
                            }}>check</Button>]}>
                                <List.Item.Meta
                                    avatar={<Avatar src={item.createdBy?.coverImage || ""} size={48}/>}
                                    title={
                                        <span>Note created at {moment(item.creationDate).format("MMM Do, yyyy")}</span>}
                                    description={
                                        <span>By {item.createdBy?.firstName || ""} {item.createdBy?.lastName || ""}</span>}
                                />
                            </List.Item>
                        )}
                    />
                </AccordionDetails>
            </Accordion>
            {
                isModalOpen &&
                <Modal open={isModalOpen}
                       onCancel={() => setIsModalOpen(false)}
                       footer={null}
                       title={<>Note created at {moment(checkingNote.creationDate).format("MMM Do, yyyy")}<hr /></>}
                       width={"80vw"}
                >
                    <MedicalNoteDisplay medicalNote={checkingNote.content} gender={gender}/>
                </Modal>
            }

        </div>
    );
}


export default MedicalNotesPage