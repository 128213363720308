import React from 'react';
import { Button, CloseButton, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import helpIcon from '../../../assets/images/yellow_help.png';

import BoxLoader from '../../../components/loading/BoxLoader';

import '../../../styles/station.css';

function getCenterName(centerArray, centerid) {
    for (var i = 0; i < centerArray.length; i++) {
        var item = centerArray[i];
        if (item.locationID === centerid) {
            return item.name;
        }
    }
    return '';
}

class NotificationModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            published: {},
            unpublish: {},
            sendType: "plasma_message",
            loading: false,
            achecked: { '0': true },
            bchecked: { '0': true },
        };
    }

    handlebChange = (index) => {
        var checked = this.state.bchecked;

        if (checked[index + '']) {
            delete checked[index + '']
        } else {
            checked[index + ''] = true;
        }

        if (Object.keys(checked).length === 0) {
            checked = { "0": true };
        } else {
            delete checked['0'];
        }

        this.setState({
            bchecked: checked
        });
    };

    handleaChange = (index) => {
        var checked = this.state.achecked;

        if (checked[index + '']) {
            delete checked[index + '']
        } else {
            checked[index + ''] = true;
        }

        if (index === 0) {
            checked = { "0": true };
        } else {
            if (Object.keys(checked).length === 0 || this.props.centers.length === Object.keys(checked).length) {
                checked = { "0": true };
            } else {
                delete checked['0'];
            }
        }

        this.setState({
            achecked: checked
        });
    };

    render() {
        return (
            <Modal
                centered
                show={this.props.showReviewModal}
                onShow={() => {
                    this.setState({
                        sendType: this.props.sendType,
                        achecked: this.props.centersChecked,
                        formData: this.props.formData
                    })
                }}
                onHide={this.props.handleReviewModalClose}
                id="existing-user"
            >
                <Modal.Header padding={0} style={{ padding: 10, display: 'flex', justifyContent: 'space-between' }}>
                    <h4 className="modal-title text-center">Notification review</h4>
                    <CloseButton onClick={this.props.handleReviewModalClose} />
                </Modal.Header>
                <Modal.Body padding={0} style={{ padding: 10, paddingTop: 0 }}>
                    <BoxLoader show={this.state.loading} />
                    <div>
                        <div style={{ margin: "0px 20px 10px" }}>
                            <div style={{ display: "flex", padding: "8px 0px" }}>
                                <span style={{ fontWeight: "bold", width: "25%" }}>Title&#8758; &nbsp;</span>
                                <span>{this.props.title ? this.props.title : 'No title'}</span>
                            </div>
                            <div style={{ display: "flex", padding: "8px 0px" }}>
                                <span style={{ fontWeight: "bold", width: "25%" }}>Message&#8758; &nbsp;</span>
                                <span>{this.props.message ? this.props.message : 'No message'}</span>
                            </div>
                            {
                                this.state.sendType === "all" ? (
                                    <div>
                                        <div style={{ display: "flex", padding: "8px 0px" }}>
                                            <span style={{ fontWeight: "bold", marginTop: "auto", marginBottom: "auto" }}>Send to all app users</span>
                                        </div>
                                    </div>
                                ) : (
                                    this.state.sendType === "plasma_message" ? (
                                        <div>
                                            <div style={{ display: "flex", padding: "8px 0px" }}>
                                                <span style={{ fontWeight: "bold", marginTop: "auto", marginBottom: "auto" }}>Select Center(s)&#8758; &nbsp;</span>
                                            </div>
                                            <div style={{ display: "flex", padding: "8px 0px" }}>
                                                <div className="col-md-9" style={{ width: "100%", maxHeight: 100, overflowY: "scroll", paddingLeft: 0, paddingRight: 0 }}>
                                                    {
                                                        this.props.selectRegion.selectAll === true ? (
                                                            this.props.centers.map((item, index) => {
                                                                return <div className={"vselected"} key={'fatimes' + index}>
                                                                    {item.name}
                                                                </div>
                                                            })
                                                        ) : (
                                                            this.props.selectRegion.ids.map((item, index) => {
                                                                return <div className={"vselected"} key={'fatimes' + index}>
                                                                    {getCenterName(this.props.centers, item)}
                                                                </div>
                                                            })
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div style={{ display: "flex", padding: "8px 0px" }}>
                                                <span style={{ fontWeight: "bold" }}>Selected Patient(s)&#8758; &nbsp;</span>
                                            </div>
                                            <div style={{ display: "flex", padding: "0px 0px" }}>
                                                {
                                                    this.state.formData.donors && this.state.formData.donors.length > 0 ? (
                                                        <div style={{ flex: 1 }}>
                                                            <div style={{ borderBottom: "1px solid #eee", fontSize: 13, textAlign: "center", lineHeight: "28px", height: 28 }}><span>Selected Patient</span></div>
                                                            <div style={{ borderBottom: "1px solid #eee", display: "flex", lineHeight: "28px", height: 28 }}>
                                                                <div style={{ fontSize: 10, width: "40%", textAlign: "center" }}><span>Patient ID</span></div>
                                                                <div style={{ fontSize: 10, width: "60%", textAlign: "center" }}><span>Name</span></div>
                                                            </div>
                                                            <div style={{ maxHeight: 84, overflowY: "scroll" }}>
                                                                {
                                                                    this.state.formData.donors && this.state.formData.donors.map((item, index) => {
                                                                        return <div key={'donors' + index} style={{ borderBottom: "1px solid #eee", display: "flex", lineHeight: "21px", height: 21 }}>
                                                                            <div style={{ fontSize: 10, width: "40%", textAlign: "center" }}><span>{item.patientId}</span></div>
                                                                            <div style={{ fontSize: 10, width: "60%", textAlign: "center" }}><span>{item.firstName + ' ' + item.lastName}</span></div>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    ) : null
                                                }
                                            </div>
                                        </div>
                                    )
                                )
                            }
                            <div style={{ display: "flex", padding: "8px 0px" }}>
                                <div style={{ marginTop: 3, marginRight: 5 }}><img alt="" src={helpIcon} style={{ width: 18, height: 18 }} /></div>
                                <div style={{ color: "#adadad", marginTop: 3, fontSize: 12 }}><b sytle={{ color: "#000" }}>Note&#8758; &nbsp;</b>Please double confirm all information and once submit, the information is no allowed to change or recall.</div>
                            </div>
                        </div>
                        <div style={{ marginBottom: 10, marginTop: 10, textAlign: "center" }}>
                            {
                                this.props.disableClose ? (
                                    <Button
                                        className="btn btn-blue btn-md"
                                        style={{ padding: "5px 30px" }}
                                        onClick={this.props.handleReviewModalClose}>
                                        Close
                                    </Button>
                                ) : (
                                    <Button
                                        className="btn btn-blue btn-md"
                                        style={{ padding: "5px 30px" }}
                                        onClick={() => {
                                            if (this.state.sendType === "plasma_message") {
                                                this.props.handleReviewModalSubmit(this.state.sendType, this.state.achecked);
                                            } else {
                                                this.props.handleReviewModalSubmit(this.state.sendType, this.state.formData);
                                            }
                                        }}>
                                        Confirm to Send
                                    </Button>
                                )
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
};

export default NotificationModal;
