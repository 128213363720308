import React, {forwardRef, useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import "moment-timezone";
import {
    Alert,
    Avatar,
    Button,
    Col,
    Descriptions,
    Dropdown,
    message,
    notification,
    Row,
    Select,
    Space,
    Spin,
    Typography
} from "antd";
import {BiChevronDown} from "react-icons/bi";
import {Checkbox} from "@mui/material";
import {getLocation, getStaffSchedules} from "../appointments/service";
import {DownOutlined} from "@ant-design/icons";
import {searchStaff} from "./staff-search.service";
import {submitSchedule} from "./staff.service";
import {Link, useSearchParams} from "react-router-dom";
import ScheduleModal from "./components/schedule.model";
import moment from "moment";
import dayjs from "dayjs";
import DatePickerWithMoment from "../../components/date/DatePickerWithMoment";

const StaffSchedulePage = () => {
    const timezone = 'America/New_York';
    moment.tz.setDefault(timezone);
    const weekDate = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [newSchedules, setNewSchedules] = useState([]);
    const [existingSchedules, setExistingSchedules] = useState([]);
    const [locations, setLocations] = useState([]);
    const [timeRange, setTimeRange] = useState([{'value' : '9to17' , 'label' : "9am - 5pm"}]);
    const [staffs, setStaffs] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [staffId, setStaffId] = useState("");
    const [hasError,setHasError] = useState(false);
    const [appointmentAdjustModal, setAppointmentAdjustModal] = useState({
        open: false, appointments: [], enableButton: false, date: null, disableSelect: false
    });
    const [appointmentConfirmAdjustModal, setAppointmentConfirmAdjustModal] = useState({
        open: false, appointments: [],enableButton: true
    });
    const [messageApi, contextHolder] = message.useMessage();
    const [searchParam] = useSearchParams();

    const appointmentConfirmAdjustTableSetting = {
        appendable: false,
        removable: false,
        bordered: false,
        size: 'middle',
        pagination: false,
        columns: [
            {
                title: 'Date',
                dataIndex: 'start',
                render: (text, record, index) => moment(text).format("MM/DD/YYYY")
            },
            {
                title: 'Start',
                dataIndex: 'start',
                render: (text, record, index) => moment(text).format("MM/DD/YYYY | hh:mm")
            },
            {
                title: 'End',
                dataIndex: 'end',
                render: (text, record, index) => moment(text).format("MM/DD/YYYY | hh:mm")
            },
            {
                title: 'Service',
                dataIndex: 'serviceType',
            },
            {
                title: 'Patient',
                dataIndex: ['patient', 'fullName'],
                render: (text, record, index) => <Link to={`/patient/${record.patientId}`}>{text}</Link>
            },
            {
                title: 'Status',
                dataIndex: 'status',
            },
            {
                title: 'Resolution',
                dataIndex: 'resolution',
            },
        ]
    }

    const appointmentAdjustTableSetting = {
        appendable: false,
        removable: false,
        bordered: false,
        size: 'middle',
        pagination: false,
        columns: [
            {
                title: 'Date',
                dataIndex: 'start',
                render: (text, record, index) => moment(record.start).format("MM/DD/YYYY")
            },
            {
                title: 'Start - End',
                dataIndex: 'start',
                render: (text, record, index) => (
                    <DatePickerWithMoment.RangePicker
                        picker="time"
                        disabledTime={disableTimeRange}
                        value={[(record.start ? moment(record.start)  : '' ), (record.end ? moment(record.end) : '')]} 
                        format="h:mm a" 
                        size="large"
                        minuteStep={30}
                        required
                        onChange={(event)=> {
                                const appointments = appointmentAdjustModal.appointments;
                                const index = appointments.findIndex((element) => element.appointmentId === record.appointmentId);
                                record.start = moment(event[0]).toDate();
                                record.end = moment(event[1]).toDate();
                                appointments[index] = {...record}
                                setAppointmentAdjustModal({...appointmentAdjustModal});
                        }}
                        disabled={record.resolution !== "reschedule"}
                    />
                ),
            },
            {
                title: 'Service',
                dataIndex: 'serviceType',
            },
            {
                title: 'Patient',
                dataIndex: ['patient', 'fullName'],
                render: (text, record, index) => <Link to={`/patient/${record.patientId}`}>{text}</Link>
            },
            {
                title: 'Status',
                dataIndex: 'status',
            },
        ],
        customActions: [
            {
                element: (record, data) => {
                    return <Select style={{width: 240}} value={record.resolution}
                                   placeholder="Select a resolution"
                                   onChange={(value) => {
                                        appointmentAdjustModal.enableButton = !data.find(d => !d.resolution);
                                        const appointments = appointmentAdjustModal.appointments;
                                        const index = appointments.findIndex((element) => element.appointmentId === record.appointmentId);
                                        record.resolution = value;
                                        appointments[index] = {...record}
                                        appointmentAdjustModal.enableButton = !data.find(d => !d.resolution);
                                        setAppointmentAdjustModal({...appointmentAdjustModal});
                                   }}
                                   disabled={appointmentAdjustModal.disableSelect}
                    >
                        <Select.Option value={"cancel"}>
                            <strong>Cancel</strong>
                        </Select.Option>
                        <Select.Option value={"reschedule"}>
                            <strong>Reschedule</strong> (You can choose to move the appointment to a new location and/or time while keeping the same staff. If you remove the staff's shift on this day, it will be set to <i>Cancel</i> automatically.)
                        </Select.Option>
                    </Select>
                }
            }
        ]
    }

    const CustomDatePickerInput = forwardRef(({value, onClick}, ref) => (
        <Button type="text" onClick={onClick} ref={ref} style={{fontSize: 16}}>
            {
                `at the week of: ${moment(value).startOf('week').format("MMMM DD, yyyy")} - ${moment(value).endOf('week').format("MMMM DD, yyyy")}`
            }
            <BiChevronDown style={{marginLeft: 5, position: 'relative', top: -2}}/>
        </Button>
    ));

    const isInputBad = () => {
        const atLeastCheckOne = !!newSchedules.find(s => s.checked);
        if (atLeastCheckOne) {
            return !!newSchedules.find(s => s.checked && !s.location);
        }

        return false;
    }

    const createSchedule = async () => {
        if (!hasError) {
            setSubmitting(true);
            try {
                    const payload = newSchedules.map(ns => {
                        return {
                            date: ns.date.toDate(),
                            location: ns.location,
                            start: ns.start,
                            end: ns.end,
                            operation: ns.checked ? 'upsert' : 'remove',
                            appointments: ns.bookedAppointments
                        }
                    });
                    await submitSchedule(staffId, payload, timezone);
                    const week = `${newSchedules[0].date.format("MMM DD, yyyy")} - ${newSchedules[6].date.format("MMM DD, yyyy")}`;
                    const staff = staffs.find(s => s.staffId === staffId);
                    notification.success({
                        message: `New schedule has been set!`,
                        description: <Descriptions layout="vertical" bordered>
                            <Descriptions.Item label="Staff">{`${staff.firstName} ${staff.lastName}`}</Descriptions.Item>
                            <Descriptions.Item label="Week">{week}</Descriptions.Item>
                        </Descriptions>,
                        duration: 0
                    })
            } catch (e) {

            } finally {
                setSubmitting(false);
            }
        }
    }

    const checkAdjustedAppointments = async () => {
        const affectedAppointments = [];
        newSchedules.forEach(schedule => {
            const start = moment(schedule.start, "YYYY-MM-DD HH:mm:ss");
            const end = moment(schedule.end, "YYYY-MM-DD HH:mm:ss");
            const appointments = schedule.bookedAppointments;
            if (appointments.length !== 0) {
                appointments.forEach(appointment => {
                    const appointmentStart = moment(appointment.start, "YYYY-MM-DD HH:mm:ss")
                    const appointmentEnd = moment(appointment.end, "YYYY-MM-DD HH:mm:ss")
                    if (appointment.resolution === "cancel" || appointment.resolution === "reschedule") {
                        if (appointmentStart.isAfter(start) || appointmentEnd.isBefore(end) ) {
                            // add it to data if appointment is being affected 
                            affectedAppointments.push(appointment);
                        }
                    }
                });
            }
        });

        checkError();
        if (!hasError) {
            if (affectedAppointments.length === 0) {
                await createSchedule();
            } else {
                setAppointmentConfirmAdjustModal({
                    open: true,
                    appointments: JSON.parse(JSON.stringify(affectedAppointments)),
                    title: 'Please kindly confirm that following appointments will be modified based on selected resolution',
                    enableButton : true,
                });
            }
        } else {
            error();
        }
    }

    const checkError = () => {
        let error = false;
        newSchedules.forEach((schedule) => {
            if (schedule.error) {
                error = true;
            }
        });
        setHasError(error);
    }
    const validateAppointmentTime = (scheduleStart, scheduleEnd, appointments) => {
        let invalid = false;
        if (appointments.length !== 0) {
            appointments.forEach(appointment => {
                const appointmentStart = moment(appointment.start)
                const appointmentEnd = moment(appointment.end)
                if (appointment.resolution === "reschedule") {
                    if (moment(appointmentStart).isBefore(scheduleStart) || moment(scheduleEnd).isBefore(appointmentEnd) ) {
                        // add it to data if appointment is being affected 
                        invalid = true;
                    }
                }
            });
        }
        return invalid;
    }

    const disableTimeRange  = () => {
        // Disable hours after 7 PM and before 8 AM
        return {
            disabledHours: () => [...Array(8).keys()].concat([...Array(24).keys()].slice(20))
        };
    }

    const validateScheduleTime = (event,index) => {
        const start = event[0].toDate();
        const end   = event[1].toDate();
        const error = validateAppointmentTime(start,end,newSchedules[index].bookedAppointments);
        setHasError(error);
        newSchedules[index].start = moment(start);
        newSchedules[index].end = moment(end);
        newSchedules[index].error = error;
        const newSchedulesArray = [...newSchedules];
        setNewSchedules(newSchedulesArray);
    }

    const validateAllAppointments = () => {
        newSchedules.forEach((schedule,index) => {
            if (schedule.start && schedule.end) {
                const start = moment(schedule.start)
                const end   = moment(schedule.end)
                const appointments = schedule.bookedAppointments;
                newSchedules[index].error = validateAppointmentTime(start, end, appointments);
                const newSchedulesArray = [...newSchedules];
                setNewSchedules(newSchedulesArray);
            }
        });
    }

    const openModal = ({bookedAppointments: appointments, date, checked, location}) => {
        const locationName = locations.find(l => l.value = location).label;
        setAppointmentAdjustModal({
            open: true,
            enableButton: !appointments.find(d => !d.resolution) || !checked,
            disableSelect: !checked,
            appointments: JSON.parse(JSON.stringify(appointments)),
            title: `Resolution For booked appointments on [${date.format('MMM Do, YYYY')}] at [${locationName}]`,
            date
        });
    }

    const error = () => {
        messageApi.open({
          type: 'error',
          content: 'Please solve the error before submitting.',
        });
    };

    useEffect(() => {
        if (staffId) {
            (async () => {
                const staffSchedule = await getStaffSchedules({staff: staffId, timezone});
                setExistingSchedules(staffSchedule.data.data);
            })();
        }
    }, [staffId]);

    useEffect(() => {
        const startOfWeek = moment(selectedDate).startOf('week');
        const endOfWeek = moment(selectedDate).endOf('week');

        const theWeek = [];

        for (let date = startOfWeek; date <= endOfWeek; date = date.clone().add(1, 'd')) {
            const existingBookedAppointments = existingSchedules
                .find(es => date.isSame(es.date, 'date'))?.appointments
                .filter(a => a.status === 'Booked') || [];

            const message = () => {
                if (date.isBefore(moment(), 'date')) {
                    return 'Past Date'
                }
                if (!!existingBookedAppointments.length) {
                    return `${existingBookedAppointments.length} Booked Appointment(s). Click here to provide resolution to enable editing.`
                }
                return ''
            }

            const location = existingSchedules.find(es => date.isSame(es.date, 'date'))?.location;
            const start = existingSchedules.find(es => date.isSame(es.date, 'date'))?.start;
            const end = existingSchedules.find(es => date.isSame(es.date, 'date'))?.end;

            const isRemovedSchedule = location === null || location === '';
            theWeek.push({
                date: date,
                location,
                start,
                end,
                checked: !(date.day() === 0 || date.day() === 6) && date.isSameOrAfter(moment(), 'date') && !isRemovedSchedule,
                disabled: date.isBefore(moment(), 'date') || !!existingBookedAppointments.length,
                bookedAppointments: existingBookedAppointments,
                message: message()
            });
        }

        setNewSchedules([...theWeek]);
    }, [selectedDate, existingSchedules]);

    useEffect(() => {
        (async () => {
            const locationOptions = [];
            const locationInformation = await getLocation();

            if (locationInformation.data.data) {
                locationInformation.data.data.forEach(location => {
                    locationOptions.push({
                        value: location.stationId,
                        label: `${location.name}, ${location.address.state}`
                    })
                });
            }
            setLocations(locationOptions);
        })();
        (async () => {
            searchStaff('role', 'provider').then(response => {
                if (response.status === 200) {
                    setStaffs(response.data);
                    const passingStaff = searchParam.get('staffId');
                    setStaffId(passingStaff);
                } else {
                    alert(response.message);
                }
            })
        })()
    }, []);

    return (
        <>
        {contextHolder}
        <div className="appointment-management" style={{paddingInline: 16, paddingTop: 80, paddingBottom: 20}}>
            <Typography.Title level={2}>Staff Schedule</Typography.Title>
            {
                !(staffs && staffs.length) &&
                <Spin tip="Loading...">
                    <Alert
                        message="Loading"
                        description="Loading Essential Data"
                        type="info"
                    />
                </Spin>
            }
            {
                !!(staffs && staffs.length) &&
                <>
                    <Row className="align-items-center justify-content-between">
                        <Col>
                            <Row className="align-items-center">
                                <Col>
                                    <Select
                                        placeholder="Select A Staff"
                                        size="large"
                                        style={{minWidth: 255}}
                                        defaultActiveFirstOption={false}
                                        filterOption={false}
                                        onChange={setStaffId}
                                        optionLabelProp="label"
                                        value={staffId}
                                    >
                                        {
                                            (staffs || []).map((s) => (
                                                <Select.Option value={s.staffId} label={`${s.firstName} ${s.lastName}`}>
                                                    <div className="d-flex align-items-center">
                                                        <Avatar size="30" src={s.coverImage} style={{marginRight: 10}}/>
                                                        <div>{`${s.firstName} ${s.lastName}`}</div>
                                                    </div>
                                                </Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Col>
                                <Col>
                                    {staffId &&
                                        <DatePicker
                                            selected={selectedDate}
                                            minDate={moment().toDate()}
                                            disabledKeyboardNavigation
                                            onChange={(date) => setSelectedDate(moment(date).toDate())}
                                            customInput={<CustomDatePickerInput/>}
                                            dayClassName={(date) => dayjs(selectedDate.toDateString()).isSame(date.toDateString(), 'week') ? "react-datepicker__day--same-week" : ""}
                                        />
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {
                        !!staffId &&
                        <>
                            <div className="mt-3">
                                <Row className="mb-2">
                                    <Col span={5}>
                                    </Col>
                                    <Col span={5}>
                                        <Dropdown
                                            menu={{
                                                items: timeRange.map((l, i) => ({key: l.value, label: l.label,})),
                                                onClick: ({key}) => {
                                                    const numbers = key.split("to").map(numStr => parseInt(numStr));
                                                    newSchedules.forEach(s => {
                                                        if (!s.disabled && s.checked) {
                                                            const start = s.date.clone().set({ hour: numbers[0], minute: 0, second: 0 });
                                                            const end = s.date.clone().set({ hour: numbers[1], minute: 0, second: 0 });   
                                                            s.start = start;
                                                            s.end = end;
                                                        }
                                                    });
                                                    setNewSchedules([...newSchedules]);
                                                }
                                            }}>
                                            <a onClick={e => e.preventDefault()}>
                                                <Space>
                                                    Set all selected dates' time to
                                                    <DownOutlined style={{position: "relative", top: -2}}/>
                                                </Space>
                                            </a>
                                        </Dropdown>
                                    </Col>
                                    <Col span={4} style={{marginLeft:"15px"}}>
                                        <Dropdown
                                            menu={{
                                                items: locations.map((l, i) => ({key: l.value, label: l.label,})),
                                                onClick: ({key}) => {
                                                    newSchedules.forEach(s => (!s.disabled && s.checked) ? s.location = key : null);
                                                    setNewSchedules([...newSchedules]);
                                                }
                                            }}>
                                            <a onClick={e => e.preventDefault()}>
                                                <Space>
                                                    Set all selected dates' location to
                                                    <DownOutlined style={{position: "relative", top: -2}}/>
                                                </Space>
                                            </a>
                                        </Dropdown>
                                    </Col>
                                </Row>
                                {
                                    newSchedules.map((schedule, i) => (
                                        <Typography.Paragraph>
                                            <Row>
                                                <Col className="d-flex align-items-center" span={3}>
                                                    <Checkbox
                                                        checked={schedule.checked}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {fontSize: 28},
                                                        }}
                                                        onChange={(event, checked) => {
                                                            schedule.checked = checked;
                                                            if (!checked && schedule.message) {
                                                                schedule.message = 'All Booked Appointments will be canceled.'
                                                                schedule.bookedAppointments.forEach(a => a.resolution = 'cancel');
                                                            }
                                                            setNewSchedules([...newSchedules]);
                                                        }}
                                                        disabled={schedule.disabled}
                                                    />
                                                    <Typography.Title level={4} disabled={schedule.disabled}
                                                                      delete={schedule.disabled}
                                                                      style={{marginBottom: '0'}}>{weekDate[i]}</Typography.Title>
                                                </Col>
                                                <Col className="d-flex align-items-center" span={2}>
                                                    <Typography.Text type={"secondary"}
                                                                     delete={schedule.disabled}
                                                                     disabled={schedule.disabled}>
                                                        {schedule.date.format('YYYY-MM-DD')}
                                                    </Typography.Text>
                                                </Col>
                                                <Col className="d-flex align-items-center position-relative" span={4}>
                                                    {schedule.error && <span style={{color:"red", position:"absolute", top: "-15px"}}>Time conflict with existing appointments</span>}
                                                    <DatePickerWithMoment.RangePicker
                                                        picker="time"
                                                        disabledTime={disableTimeRange}
                                                        onChange={(event) => validateScheduleTime(event,i)} 
                                                        value={[(schedule.start ? moment(schedule.start)  : '' ), (schedule.end ? moment(schedule.end) : '')]} 
                                                        format="h:mm a" 
                                                        size="large"
                                                        disabled={!schedule.checked || schedule.disabled}
                                                        status={schedule.error ? "error" : ''}
                                                        minuteStep={30}
                                                    />
                                                </Col>
                                                <Col className="d-flex align-items-center" style={{marginLeft:"15px"}}> 
                                                    <Select
                                                        placeholder="Please select location"
                                                        size="large"
                                                        value={schedule.location}
                                                        style={{width: 350}}
                                                        onChange={value => {
                                                            schedule.location = value;
                                                            setNewSchedules([...newSchedules]);
                                                        }}
                                                        options={locations}
                                                        disabled={!schedule.checked || schedule.disabled}
                                                    />
                                                </Col>
                                                
                                                {
                                                    schedule.message ?
                                                        <Col className="d-flex align-items-center ms-2" span={6}>
                                                            {schedule.message === 'Past Date' ?
                                                                <Typography.Text type={"danger"}>
                                                                    {schedule.message}
                                                                </Typography.Text>
                                                                :
                                                                <Typography.Link type={"danger"}
                                                                                 onClick={() => openModal(schedule)}>
                                                                    {schedule.message}
                                                                </Typography.Link>
                                                            }
                                                        </Col>
                                                        : <></>
                                                }

                                            </Row>
                                        </Typography.Paragraph>
                                    ))
                                }
                            </div>
                            <Row className="justify-content-end align-items-center">
                                <Button type="primary" size="large" shape="round"
                                        onClick={checkAdjustedAppointments}
                                        loading={submitting}
                                        disabled={isInputBad()}>
                                    Submit
                                </Button>
                            </Row>
                        </>
                    }
                </>
            }
            <ScheduleModal 
                appointmentModal={appointmentAdjustModal}
                setModal = {setAppointmentAdjustModal}
                onFinish={() => {
                                const correspondingSchedule = newSchedules.find(ns => ns.date.isSame(appointmentAdjustModal.date, 'date'));
                                correspondingSchedule.bookedAppointments = appointmentAdjustModal.appointments;
                                correspondingSchedule.message = 'All booked appointments are accommodated. Click to review.'
                                correspondingSchedule.disabled = false;
                                setNewSchedules([...newSchedules]);
                                setAppointmentAdjustModal({open: false});
                                validateAllAppointments();
                }}
                tableSetting={appointmentAdjustTableSetting}
            />

                <ScheduleModal 
                    appointmentModal={appointmentConfirmAdjustModal}
                    setModal = {setAppointmentConfirmAdjustModal}
                    onFinish={async () => {
                                    await createSchedule();
                                    setAppointmentConfirmAdjustModal({open: false});
                    }}
                    tableSetting={appointmentConfirmAdjustTableSetting}
                />
        </div>
        </>
    )
}

export default StaffSchedulePage;