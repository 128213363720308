import Layout from "antd/lib/layout/layout";
import React from "react";
import moment from "moment";
import AppleIcon from "@mui/icons-material/Apple";
import { Table, Modal, Button, Form, Alert, Spinner, Col, Row } from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import bioApi from "../api/bioApi";
import Dropdown from 'react-dropdown';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';

const AppVersionConfigPage = () => {
  const [entry, setentry] = React.useState(null);
  const [entryerror, setentryerror] = React.useState(null);
  const [entryloading, setentryloading] = React.useState(false);
  const [newentry, setnewentry] = React.useState({});

  const [iosversions, setiosversions] = React.useState([]);
  const [iosversionspage, setiosversionspage] = React.useState(0);
  const [iosversionsloading, setiosversionsloading] = React.useState(false);
  const [iosversionserror, setiosversionserror] = React.useState(false);

  const [androidversions, setandroidversions] = React.useState([]);
  const [androidversionspage, setandroidversionspage] = React.useState(0);
  const [androidversionsloading, setandroidversionsloading] =
    React.useState(false);
  const [androidversionserror, setandroidversionserror] = React.useState(false);

  const onChangeEntry = (k, v) => {
    setentryerror(null);
    setnewentry({ ...newentry, [k]: v.target.value });
  };

  React.useEffect(() => {
    console.log(newentry);
  }, [newentry]);

  React.useEffect(() => {
    fetchios();
    fetchandroid();
  }, []);

  const createRelease = async () => {
    try {
      setentryloading(true);
      if (
        !newentry.date ||
        !newentry.time ||
        !newentry.version ||
        !newentry.desc
      ) {
        setentryerror(`Please fill out all fields before proceeding`);
        setentryloading(false);
        return;
      }
      const results = await bioApi
        .post("/app-version/create", {
          ts: new Date(`${newentry.date} ${newentry.time}`),
          desc: newentry.desc,
          version: newentry.version,
          os: entry.toLowerCase(),
        })
        .then((res) => res.data);

      if (results.status !== 200) {
        setentryerror(results.message);
      } else {
        if (entry === "android") {
          fetchandroid();
        } else {
          fetchios();
        }
        setentry(null);
        setnewentry({});
      }
    } catch (err) {
      console.log(err);
    }
    setentryloading(false);
  };

  const fetchios = async () => {
    setiosversionsloading(true);
    try {
      const results = await bioApi
        .post("/app-version/get", {
          page: iosversionspage,
          os: `ios`,
        })
        .then((res) => res.data);
      if (results.status === 200) {
        setiosversions(results.versions);
      } else {
        setiosversionserror(results.message);
      }
    } catch (err) {
      setiosversionserror(err.message);
    }
    setiosversionsloading(false);
  };

  const fetchandroid = async () => {
    setandroidversionsloading(true);
    try {
      const results = await bioApi
        .post("/app-version/get", {
          page: iosversionspage,
          os: `android`,
        })
        .then((res) => res.data);
      if (results.status === 200) {
        setandroidversions(results.versions);
      } else {
        setandroidversionserror(results.message);
      }
    } catch (err) {
      setandroidversionserror(err.message);
    }
    setandroidversionsloading(false);
  };

  return (
    <Layout>
      <div style={{ padding: 18, marginTop: 80 }}>
        {/* <div className="border-seperator"/> */}
        <div className="d-flex flex-row justify-content-between align-items-center" style={{marginBottom: 12}}>
          <div
            className="d-flex flex-row align-items-center"
          >
            <AppleIcon />
            <div className="section-title" style={{ marginLeft: 12 }}>
              iOS Release
            </div>
          </div>
          <div className="cta-1-btn" onClick={() => setentry(`iOS`)}>
            ADD
            <AddIcon
              sx={{ width: `0.9rem`, height: `0.9rem`, fontSize: `0.9rem` }}
            />
          </div>
        </div>
        {iosversions && iosversions.length > 0 && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Version</th>
                <th>Release Date</th>
                <th>Description</th>
                <th style={{ whiteSpace: "nowrap", overflow: "hidden" }}>Released By</th>
              </tr>
            </thead>
            <tbody>
              {iosversions.map((item, index) => (
                <tr key={`ios-release-${index}`}>
                  <td style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
                    {item.version}
                  </td>
                  <td style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
                    {new moment(item.releaseTS).format("LLL")}
                  </td>
                  <td>{item.description}</td>
                  <td style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
                    {item.createdByName}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {iosversionsloading && (
          <Alert
            variant="primary"
            className="d-flex align-items-center justify-content-center"
          >
            <Spinner animation="border" variant="primary" size="md" />
            <div style={{ marginLeft: 12 }}>Fetching iOS Version Releases</div>
          </Alert>
        )}
        {iosversionserror && (
          <Alert
            variant="danger"
            className="d-flex align-items-center justify-content-center"
          >
            <div style={{ marginLeft: 12 }}>{iosversionserror}</div>
          </Alert>
        )}

        <div className="d-flex flex-row justify-content-between align-items-center" style={{marginTop: 48, marginBottom: 12}}>
          <div
            className="d-flex flex-row align-items-center"
          >
            <AppleIcon />
            <div className="section-title" style={{ marginLeft: 12 }}>
              Android Release
            </div>
          </div>
          <div className="cta-1-btn" onClick={() => setentry(`android`)}>
            ADD
            <AddIcon
              sx={{ width: `0.9rem`, height: `0.9rem`, fontSize: `0.9rem` }}
            />
          </div>
        </div>
        {androidversions && androidversions.length > 0 && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Version</th>
                <th>Release Date</th>
                <th>Description</th>
                <th style={{ whiteSpace: "nowrap", overflow: "hidden" }}>Released By</th>
              </tr>
            </thead>
            <tbody>
              {androidversions.map((item, index) => (
                <tr key={`android-release-${index}`}>
                  <td style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
                    {item.version}
                  </td>
                  <td style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
                    {new moment(item.releaseTS).format("LLL")}
                  </td>
                  <td>{item.description}</td>
                  <td style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
                    {item.createdByName}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>

      {androidversionsloading && (
        <Alert
          variant="primary"
          className="d-flex align-items-center justify-content-center"
        >
          <Spinner animation="border" variant="primary" size="md" />
          <div style={{ marginLeft: 12 }}>
            Fetching android Version Releases
          </div>
        </Alert>
      )}
      {androidversionserror && (
        <Alert
          variant="danger"
          className="d-flex align-items-center justify-content-center"
        >
          <div style={{ marginLeft: 12 }}>{androidversionserror}</div>
        </Alert>
      )}

      {entry && (
        <Modal backdrop="static" show onHide={() => setentry(null)}>
          <Modal.Header closeButton>
            <Modal.Title>{`Add ${entry} Entry`}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>
                  App Version <span className="required-star">*</span>
                </Form.Label>
                <Form.Control
                  disabled={entryloading}
                  placeholder="e.g. 1.0.1"
                  type="text"
                  value={newentry[`version`]}
                  onChange={(v) => onChangeEntry(`version`, v)}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  Release Date <span className="required-star">*</span>
                </Form.Label>
                <Form.Control
                  disabled={entryloading}
                  placeholder="e.g. 1.0.1"
                  type="date"
                  value={newentry[`date`]}
                  onChange={(v) => onChangeEntry(`date`, v)}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  Release Time <span className="required-star">*</span>
                </Form.Label>
                <Form.Control
                  disabled={entryloading}
                  placeholder="e.g. 08:30 P.M."
                  type="time"
                  value={newentry[`time`]}
                  onChange={(v) => onChangeEntry(`time`, v)}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  Description <span className="required-star">*</span>
                </Form.Label>
                <Form.Control
                  disabled={entryloading}
                  rows="3"
                  placeholder="e.g. implemented new location finder feature & phone verification ..."
                  as="textarea"
                  line
                  value={newentry[`desc`]}
                  onChange={(v) => onChangeEntry(`desc`, v)}
                />
              </Form.Group>
            </Form>

            {entryerror && (
              <Alert variant="danger" style={{ marginTop: 12 }}>
                {entryerror}
              </Alert>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" onClick={createRelease}>
              {entryloading ? (
                <Spinner variant="light" animation="border" size="sm" />
              ) : (
                `Add New Entry`
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Layout>
  );
};

export default AppVersionConfigPage;
