import {useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import DataTable from '../../components/data-table/data-table.component';
import './patient-search.css';
import {Input} from 'antd';
import {searchPatients} from './patient-search.service';
import { AccessRight } from '../../components/guarded-route/guarded-routes';
import { ApplicationRight } from '../../share/RightList';


function PatientSearchPage() {
    const [searchProspectPatientResult,setProspectPatientResult] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [loading,setLoading] = useState(false);

    const patienttableSettings = {
        appendable: false,
        removable: false,
        bordered: false,
        size: 'middle',
        pagination: {
            'showLessItems': true,
            'pageSize': 20,
        },
        columns: [
            {
                title: 'Patient ID',
                dataIndex: 'patientId'
            },
            {
                title: 'Phone Number',
                dataIndex: 'phoneNumber'
            },
            {
                title: 'Email Address',
                dataIndex: 'email'
            },
            {
                title: 'Service Location',
                dataIndex: 'serviceCenter',
                filterable: true
            },
        ],
        customActions: [
            {
                element: (record, data) => <Link to={`/patient/${record.patientId }`}>{record.fullName}</Link>,
            }
        ],
        operationColumnTitle: 'Name',
        actionColumnIndex: 1
    }
    const prospectPatienttableSettings = {
        appendable: false,
        removable: false,
        bordered: false,
        size: 'middle',
        pagination: {
            'showLessItems': true,
            'pageSize': 20,
        },
        columns: [
            {
                title: 'APPUSER ID',
                dataIndex: 'appuserId'
            },
            {
                title: 'Phone Number',
                dataIndex: 'phoneNumber'
            },
            {
                title: 'Email Address',
                dataIndex: 'email'
            },
        ],
        customActions: [
            {
                element: (record, data) => <Link to={`/prospect-patient/${record.appuserId }`}>{record.fullName}</Link>,
            }
        ],
        operationColumnTitle: 'Name',
        actionColumnIndex: 1
    }
    

    let patientSearchDebounceTimeout;
    let searchCriteria;
    const debouncedSearch = (newValue) => {
        if (newValue && newValue.length > 3) {
            if (patientSearchDebounceTimeout) {
                clearTimeout(patientSearchDebounceTimeout);
                patientSearchDebounceTimeout = null;
            }
            searchCriteria = newValue;

            patientSearchDebounceTimeout = setTimeout(() => immediateSearch(newValue, true), 300);
        }
    };

    const immediateSearch = async (newValue, fromDebounce) => {
        setLoading(true);
        if (newValue) {
            const result = await searchPatients('general-matching', newValue,false,true);
            if (fromDebounce) {
                if (searchCriteria === newValue) {
                    setProspectPatientResult(result.data.prospectPatients || [])
                    setSearchResult(result.data.patients);
                }
            } else {
                setProspectPatientResult(result.data.prospectPatients || [])
                setSearchResult(result.data.patients);
            }
        } else {
            setProspectPatientResult([])
            setSearchResult([]);
        }
        setLoading(false);
    }

    return (
        <div style={{ marginTop: '60px', background: '#F0F2F5 0% 0% no-repeat padding-box', padding: 32 }}>
            <div className='d-flex justify-content-between'>
                <h2>Patients Management</h2>
                {AccessRight(ApplicationRight.Patient_Creating) && <Link to='/patient/create'>
                    <Button>Create New Patient</Button>
                </Link>}
            </div>
            <br />
            <div className='d-flex'>
                <Input.Search placeholder="Search by Patient ID, Name, Phone Number and Email or App User ID"
                              size="large" allowClear style={{ width: "50%" }}
                              onChange={(e) => debouncedSearch(e.target.value)}
                              onSearch={(value) => immediateSearch(value, false)}
                              />
            </div>
            {
                searchResult &&
                <Row className='section'>
                    <Col sm={12}>
                        <h3>Patients</h3>
                    </Col>
                    <Col sm={12}>
                        <Row className='section-content'>
                            <DataTable loading={loading} data={searchResult} settings={patienttableSettings} />
                        </Row>
                    </Col>
                </Row>
            }
            {
                searchProspectPatientResult &&
                <Row className='section'>
                    <Col sm={12}>
                        <h3>Prospect Patients</h3>
                    </Col>
                    <Col sm={12}>
                        <Row className='section-content'>
                            <DataTable loading={loading} data={searchProspectPatientResult} settings={prospectPatienttableSettings} />
                        </Row>
                    </Col>
                </Row>
            }
        </div>
    )
}

export default PatientSearchPage;
