import { Modal ,Button } from "antd";
import React,{useState} from "react";
import DataTable from "../../../components/data-table/data-table.component";

const ScheduleModal = ({appointmentModal,onFinish,tableSetting,setModal}) => {
    return (
        <Modal open={appointmentModal.open}
                footer={
                    <Button disabled={!appointmentModal.enableButton}
                            onClick={() => onFinish()}
                    >Confirm</Button>
                }
                className="appointment-resolve"
                title={appointmentModal.title}
                width={1200}
                onCancel={() => setModal({open: false})}
                destroyOnClose
        >
            <DataTable data={appointmentModal.appointments} settings={tableSetting}/>
        </Modal>
    );
}
export default ScheduleModal;