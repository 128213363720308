import React from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import SettingInput from "../../../components/setting/SettingInput";
import "./patient.css";
import { Button, Checkbox, message, Modal, Result, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCurrentPatient,
  updatePatientDetailsInStore,
} from "../../../stores/current-patient.store";
import { updatePatientRawData, updateSymptomsAndGoals,checkPresignUrl, getSymptomsAndGoals } from "../service";
import { UploadOutlined } from "@ant-design/icons";
import bioApi from "../../../api/bioApi";
import { PATIENT_UPLOAD_LAB_REPORT } from "../../../api/URLs";
import Countdown from "react-countdown";

function LabInfoPage() {
  const dispatch = useDispatch();
  const currentPatient = useSelector((state) => state.currentPatient);
  const currentLabResult = useSelector((state) => state.currentLabResult);
  const [messageApi, contextHolder] = message.useMessage();

  const [labInfo, setLabInfo] = React.useState([]);
  const [labResults, setLabResults] = React.useState([]);
  const [editMode, setEditMode] = React.useState(false);
  const [symptomsAndGoals, setSymptomsAndGoals] = React.useState([]);
  const [availableGoals, setAvailableGoals] = React.useState([]);
  
  const updatePatientData = async (field, value) => {
    await updatePatientRawData(currentPatient.patientId, field, value);
    await dispatch(updatePatientDetailsInStore({ key: field, value }));
  };

  const fetchLabInfoDetails = () => {
    const details = currentPatient.details;
    const labInfoData = [
      {
        label: "HRT Due to Refill",
        editable: true,
        value: details.hrt_due_to_refill,
        editingRender: {
          elementType: "date",
          save: (value) => updatePatientData("hrt_due_to_refill", value),
        },
      },
      {
        label: "Refill Notes",
        editable: true,
        value: details.refill_notes,
        editingRender: {
          elementType: "text",
          save: (value) => updatePatientData("refill_notes", value),
        },
      },
      {
        label: "Full Blood Collected",
        editable: true,
        value: details.blood_received,
        editingRender: {
          elementType: "date",
          save: (value) => updatePatientData("blood_received", value),
        },
      },
      {
        label: "Follow Up Blood Collected",
        editable: true,
        value: details.follow_up_blood_received_date,
        editingRender: {
          elementType: "date",
          save: (value) =>
            updatePatientData("follow_up_blood_received_date", value),
        },
      },
      {
        label: "Specialty Test Received",
        editable: true,
        value: details.specialty_test_recieved,
        editingRender: {
          elementType: "date",
          save: (value) => updatePatientData("specialty_test_recieved", value),
        },
      },
      {
        label: "Covid Collected Date",
        editable: true,
        value: details.covid_collected_date,
        editingRender: {
          elementType: "date",
          save: (value) => updatePatientData("covid_collected_date", value),
        },
      },
      {
        label: "IV Bloodwork Rcv’d Date",
        editable: true,
        value: details.iv_bloodwork,
        editingRender: {
          elementType: "date",
          save: (value) => updatePatientData("iv_bloodwork", value),
        },
      },
      {
        label: "Allergy Test Date",
        editable: true,
        value: details.allergy_test_date,
        editingRender: {
          elementType: "date",
          save: (value) => updatePatientData("allergy_test_date", value),
        },
      },
      {
        label: "Full Thyroid Collected",
        editable: true,
        value: details.full_thyroid_received,
        editingRender: {
          elementType: "date",
          save: (value) => updatePatientData("full_thyroid_received", value),
        },
      },
      {
        label: "Basic Thyroid Collected",
        editable: true,
        value: details.basic_thyroid_received,
        editingRender: {
          elementType: "date",
          save: (value) => updatePatientData("basic_thyroid_received", value),
        },
      },
      {
        label: "Most Recent Saliva Date",
        editable: true,
        value: details.most_recent_saliva_date,
        editingRender: {
          elementType: "date",
          save: (value) => updatePatientData("most_recent_saliva_date", value),
        },
      },
      {
        label: "ZRT Test Date",
        editable: true,
        value: details.zrt_test_date,
        editingRender: {
          elementType: "date",
          save: (value) => updatePatientData("zrt_test_date", value),
        },
      },
      {
        label: "Schedule next blood draw for",
        editable: true,
        value: details.schedule_next_blood_draw_for,
        editingRender: {
          elementType: "date",
          save: (value) =>
            updatePatientData("schedule_next_blood_draw_for", value),
        },
      },
      {
        label: "Lab Follow Up Info",
        editable: true,
        value: details.lab_follow_up_info,
        editingRender: {
          elementType: "text",
          save: (value) => updatePatientData("lab_follow_up_info", value),
        },
      },
      {
        label: "Labs Scheduled",
        editable: true,
        value: JSON.parse((details.labs_scheduled || "false").toLowerCase()),
        editingRender: {
          elementType: "checkbox",
          save: (value) =>
            updatePatientData("labs_scheduled", JSON.stringify(value)),
        },
      },
      {
        label: "Most Recent Blood Opp",
        editable: true,
        value: details.most_recent_blood_opp,
        editingRender: {
          elementType: "date",
          save: (value) => updatePatientData("most_recent_blood_opp", value),
        },
      },
      {
        label: "Most Recent Annual Review",
        editable: true,
        value: details.most_recent_annual_exam,
        editingRender: {
          elementType: "date",
          save: (value) => updatePatientData("most_recent_annual_exam", value),
        },
      },
      {
        label: "Consult Note Sent",
        editable: true,
        value: details.consult_note,
        editingRender: {
          elementType: "date",
          save: (value) => updatePatientData("consult_note", value),
        },
      },
    ];
    setLabInfo(labInfoData);
  };

  const toggleOrSave = async () => {
    if (editMode) {
      await updateSymptomsAndGoals(currentPatient.patientId, symptomsAndGoals);
      dispatch(
        updateCurrentPatient({
          key: "symptomsAndGoals",
          value: symptomsAndGoals,
        }),
      );
    }
    setEditMode(!editMode);
  };

  const fetchSymptomsAndGoals = async () => {
    const goals = await getSymptomsAndGoals(currentPatient.patientId);
    if (goals.data) {
      const data = goals.data.data.map((sag) => ({
        label: sag.name,
      }))
      setAvailableGoals(data);
    }
  }

  const uploadProps = {
    name: "pdf",
    maxCount: 1,
    action:
      bioApi.defaults.baseURL +
      PATIENT_UPLOAD_LAB_REPORT.replace("{id}", currentPatient.patientId),
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        Modal.success({
          content: (
            <Result
              status="success"
              title="Successfully Uploaded Lab Report!"
              subTitle={
                <span>
                  Pulling new data in{" "}
                  <Countdown
                    date={Date.now() + 3000}
                    renderer={({ hours, minutes, seconds, completed }) => (
                      <span>{seconds}</span>
                    )}
                  />{" "}
                  seconds
                </span>
              }
            />
          ),
          closable: false,
          okButtonProps: {
            style: { display: "none" },
          },
        });
        setTimeout(() => window.location.reload(), 4000);
      } else if (info.file.status === "error") {
        info.file.error.message = info.file.response.msg.msg;
        messageApi.open({
          type: "error",
          content: `${info.file.name} file upload failed. ${info.file.response.msg.msg}`,
        });
      }
    },
    withCredentials: true,
  };

  React.useEffect(() => {
    if (currentPatient) {
      fetchLabInfoDetails();
      setSymptomsAndGoals(currentPatient.symptomsAndGoals);
      setLabResults([...currentLabResult]);
      fetchSymptomsAndGoals();
    }
  }, [currentPatient]);

  const handleUrl = async (type,fileName) => {
    const urlData = await checkPresignUrl(type,fileName);

    if (urlData.data.status) {
      const newTab = window.open(urlData.data.url, '_blank');
      newTab.focus();
      return;
    }
    messageApi.open({
      type: "error",
      content: "Failed to open Lab Report, please try it again later.",
    });
  }

  return (
    <>
      {contextHolder}
      <Row className="profile">
        <Col sm={12} className="d-flex justify-content-between">
          <h2 className="section-title">Lab Info</h2>
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>
              Click to Upload Lab Report
            </Button>
          </Upload>
        </Col>
        <Col sm={12}>
          <Row className="section-content">
            <Col className="section-column" sm={12}>
              {labInfo.map((detail, i) => {
                return (
                  <SettingInput
                    key={i}
                    label={detail.label}
                    value={detail.value}
                    editable={detail.editable}
                    editingRender={detail.editingRender}
                  />
                );
              })}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="section">
        <Col sm={12}>
          <div className="section-link">
            <h2 className="section-title">Lab results (Out of Range)</h2>
            {currentPatient.currentLabReportPDF ? (
              currentPatient.currentLabReportPDF.startsWith("http") ? (
                <a
                  className="view-result-link"
                  onClick={ () => handleUrl('lab-report',currentPatient.currentLabReportPDFFileName)}
                  target="_blank"
                  download
                >
                  View All Results
                </a>
              ) : (
                <a
                  className="view-result-link"
                  onClick={() => {
                    const base64Data = currentPatient.currentLabReportPDF;
                    const src = "data:application/pdf;base64," + base64Data;
                    const win = window.open();
                    win.document.write(
                      '<iframe src="' +
                        src +
                        '" style="width:100%; height:100vh;"></iframe>',
                    );
                  }}
                >
                  View All Results
                </a>
              )
            ) : (
              <></>
            )}
          </div>
        </Col>
        <Col sm={12}>
          <Row gutter={30}>
            <Col span={19} className="p-0">
              <Card className="mb-3">
                <Card.Body className="p-0">
                  <Table
                    responsive
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                    bordered
                    size="md"
                    className="patient-lab-result-table"
                  >
                    <tbody>
                      <tr>
                        <th>Biomarker</th>
                        <th>Lab Result</th>
                        <th>Optimal Range</th>
                        <th>Tips</th>
                      </tr>
                      {labResults
                        .filter((r) => r.isAbnormal)
                        .map((result) => (
                          <tr>
                            <td>{result.biomarker}</td>
                            <td>{result.labData}</td>
                            <td>{result.optimalRange}</td>
                            <td>{result.description}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="section">
        <Col sm={12}>
          <Row gutter={30}>
            <Col span={19} className="p-0">
              <Card style={{ borderRadius: 20 }} className="mb-5">
                <Card.Body>
                  <div className="d-flex justify-content-between ps-3 pe-3 mb-2">
                    <Card.Title>Symptoms / Goals</Card.Title>
                    <Card.Link onClick={toggleOrSave}>
                      {editMode ? "Save" : "Edit"}
                    </Card.Link>
                  </div>
                  <div>
                    <ul style={{ columns: 2, listStyle: "none" }}>
                      {availableGoals && availableGoals.map((ug) => (
                        <li>
                          <Checkbox
                            checked={symptomsAndGoals?.includes(ug.label)}
                            disabled={!editMode}
                            key={ug.label}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSymptomsAndGoals([
                                  ...symptomsAndGoals,
                                  ug.label,
                                ]);
                              } else {
                                setSymptomsAndGoals([
                                  ...symptomsAndGoals.filter(
                                    (s) => s !== ug.label,
                                  ),
                                ]);
                              }
                            }}
                          >
                            {ug.label}
                          </Checkbox>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default LabInfoPage;
