import React from 'react';
import { Modal } from 'react-bootstrap';

import successIcon from "../../assets/images/success_icon.png";
import warningIcon from "../../assets/images/warning_icon.jpg";
import failIcon from "../../assets/images/fail_icon.jpg";

class AlertModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show: false, 
            option: {
                message: '', 
                autoDismiss: false,
                onAlertDissimiss: null
            }
        }
        this.timer = null
    }
    
    closeModal = () => {
        this.setState({
            show: false
        })
        if(this.state.option.onAlertDissimiss) {
            setTimeout(() => {
                this.state.option.onAlertDissimiss()
            }, 500)
        }
    }

    show(option) {
        this.setState({
            show: true, 
            option
        })
    }

    showHttpError(er) {
        if(!er.response.data) {
            return
        }

        this.setState({
            show: true, 
            option: {
                message: er.response.data.message, 
                autoDismiss: true, 
                type: 'error'
            }
        })
    }

    hide() {
        this.closeModal()
    }

    render() {
        const { message, autoDismiss, type } = this.state.option;
        const label = message instanceof Object ? "" : message

        if(this.state.show && autoDismiss) {
            this.timer = setTimeout(this.closeModal, 2000)
        } else if (this.timer) {
            clearTimeout(this.timer)
        }

        return (
                <Modal 
                    show={this.state.show}
                    backdrop={autoDismiss ? true : "static"}
                    keyboard={autoDismiss}
                    backdropClassName="message-modal-backdrop"
                    onHide={this.closeModal.bind(this)}
                    dialogClassName="customSucsess-modal">
                    <Modal.Body className={"modal-body modal-body-sm" }>
                        {type ? 
                            <div className="text-center">
                                <img 
                                    style={{ maxWidth: "45px" }} 
                                    alt="" 
                                    src={
                                        type === 'success' ? successIcon : type === 'warning' ?
                                        warningIcon : failIcon
                                    } 
                                />
                                <br />
                                <h5 style={{ marginBottom: "0" }}>{label}</h5>
                            </div> : 
                            <h5>{label}</h5>
                        }
                    </Modal.Body>
                </Modal>
        );
    }
}

export default AlertModal