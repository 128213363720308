import React from "react";
import { Col, Row, Form, Table, Button, ButtonGroup } from "react-bootstrap";
import Layout from "../components/layouts";
import bioApi from "../api/bioApi";
import { LOCATION_DELETE_URL, LOCATION_LIST_URL } from "../api/URLs";
import BoxLoader from "../components/loading/BoxLoader";
import Pager from "../components/Pager/PagerControl";
import { useNavigate } from "react-router-dom";

const LocationPage = () => {
  const navigate = useNavigate();

  const [locations, setlocations] = React.useState([]);
  const [loading, setloading] = React.useState(false);
  const [pagerSetting, setpagerSetting] = React.useState({
    totalCount: 0,
    pageSize: 10,
    pageIndex: 0,
    firstText: 'First',
    prevText: '«',
    nextText: '»',
    lastText: 'Last',
    recordTextFormat: "{0}/{1}",
    goWidth: 50,
    showLinkNum: 3,
    callBack: index => {
      handleGetLocations(index, pagerSetting.pageSize);
    }
  });

  const handleGetLocations = async (pageIndex, pageSize) => {
    setloading(true);
    try {
      bioApi.post(LOCATION_LIST_URL, {
        limit: pageSize,
        page: pageIndex + 1,
      }).then(response => {
        if (response.data.status !== 200) {
          alert(response.data.message);
        } else {
          var pagerdata = {
            ...pagerSetting,
            pageIndex: pageIndex,
            totalCount: response.data.totalCount,
          };
          setpagerSetting(pagerdata);
          setlocations(response.data.data);
        }
        setloading(false);
      });
    } catch (error) {
      setloading(false);
      alert(error);
    }
  };

  const handleDeleteLocation = async locationID => {
    var result = window.confirm('Are you sure delete ' + locationID);
    if (!result) {
      return;
    }
    setloading(true);
    try {
      bioApi.post(LOCATION_DELETE_URL, {
        stationId: locationID,
      }).then(response => {
        if (response.data.status !== 200) {
          alert(response.data.message);
        } else {
          handleGetLocations(0, pagerSetting.pageSize);
        }
        setloading(false);
      });
    } catch (error) {
      setloading(false);
      alert(error);
    }
  };

  React.useEffect(() => {
    handleGetLocations(pagerSetting.pageIndex, pagerSetting.pageSize);
  }, []);

  const tableHeader = () => {
    return (
      <tr>
        <th>No.</th>
        <th>State</th>
        <th>City</th>
        <th>Plasma Name</th>
        <th>Phone Number</th>
        <th>Action</th>
      </tr>
    );
  }
  const tableData = (item, index) => {
    return (
      <tr>
        <td>{index + 1}</td>
        <td>{item.address.state}</td>
        <td>{item.address.city}</td>
        <td>{item.name}</td>
        <td>{'+' + item.phonenumber.countryCode + ' ' + item.phonenumber.number}</td>
        <td style={{ display: 'flex' }}>
          <div style={{ marginLeft: 5, marginRight: 5 }} onClick={() => {
            navigate('/location/create', {
              state: item
            })             
          }}>
            <img src={require('../assets/images/edit.png')} style={{ width: 21, height: 21 }} alt="edit" className="icon-edit" />
          </div>
          <div style={{ marginLeft: 5, marginRight: 5 }} onClick={() => {
            navigate('/location/review', {
              state: item
            })             
          }}>
            <img src={require('../assets/images/review.png')} style={{ width: 21, height: 21 }} alt="review" className="icon-edit" />
          </div>
          <div style={{ marginLeft: 5, marginRight: 5 }} onClick={() => {
            handleDeleteLocation(item.stationId);
          }}>
            <img src={require('../assets/images/trash.png')} style={{ width: 21, height: 21 }} alt="trash" className="icon-edit" />
          </div>
        </td>
      </tr>
    );
  };

  return (
    <Layout>
      <BoxLoader show={loading} loadingStyle={2} />

      <Row style={{ margin: 10 }}>
        <Col>
          <Row style={{ marginBottom: 10 }}>
            <Col><Form.Label column="lg">Location Management</Form.Label></Col>
            <Col style={{ display: 'flex', height: 'auto' }}>
              <Col style={{ flex: 1 }}></Col>
              <Button href="/location/create" variant="primary" style={{ margin: 'auto' }}>Create</Button>
            </Col>
          </Row>
          <Table responsive="sm">
            <thead>
              {tableHeader()}
            </thead>
            <tbody>
              {locations && locations.map(tableData)}
            </tbody>
          </Table>
            <Col style={{ display: 'flex' }}>
              <Col style={{ flex: 1 }}></Col>
              <Pager {...pagerSetting} />
            </Col>
        </Col>
      </Row>
    </Layout>
  )
};

export default LocationPage;