import {createSlice} from "@reduxjs/toolkit";

const currentPatientSlice = createSlice({
    name: 'currentPatient',
    initialState: null,
    reducers: {
        setCurrentPatient: (state, action) => {
            return action.payload;
        },
        updateCurrentPatient: (state, action) => {
            return {
                ...state,
                [action.payload.key]: action.payload.value
            };
        },
        updatePatientDetailsInStore: (state, action) => {
            return {
                ...state,
                details: {
                    ...state.details,
                    [action.payload.key]: action.payload.value
                }
            }
        },
    }
})

export const { setCurrentPatient, updateCurrentPatient, updatePatientDetailsInStore } = currentPatientSlice.actions;
export default currentPatientSlice.reducer