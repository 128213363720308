import React, {useState} from 'react'
import {Col, Row} from "react-bootstrap";
import {Button, Modal, Typography} from 'antd';
import DataTable from "../../../components/data-table/data-table.component";
import './patient.css';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {getAppointments} from "../service";
import moment from "moment";
import AppointmentDetail from "../../appointments/appointment-detail.component";

function AppointmentPage() {
    const currentPatient = useSelector(state => state.currentPatient);

    const [appointmentList, setAppointmentList] = useState([]);

    const [appointmentModal, setAppointmentModal] = useState({show: false, appointment: {}});

    const tableSettings = {
        appendable: false,
        removable: false,
        bordered : false,
        size: 'middle',
        pagination : {
            'showLessItems' :true,
            'pageSize' : 6,
        },
        columns: [
            {
                title: 'Date/ Time',
                dataIndex: 'start',
                render: (text) => moment(text).format("MM/DD/YYYY | hh:mm")
            },
            {
                title: 'Service',
                dataIndex: 'serviceType',
            },
            {
                title: 'Appointment Status',
                dataIndex: 'status',
            },
            {
                title: 'Location',
                dataIndex: ['location', 'name'],
            },
            {
                title: 'Staff',
                render: (text, record) => `${record.staff.firstName} ${record.staff.lastName}`
            },
        ],
        customActions: [
            {
                text: 'View',
                whenPerform: (record) => {
                    const appointment = {
                        start: record.start,
                        end: record.end,
                        service: {serviceName: record.serviceType},
                        patient: {
                            patientId: currentPatient.patientId,
                            firstName: currentPatient.firstName,
                            lastName: currentPatient.lastName
                        },
                        location: record.location,
                        staff: record.staff,
                        note: record.note,
                        status: record.status
                    }
                    setAppointmentModal({
                        show: true,
                        appointment
                    });
                }
            }
        ]
    }

    React.useEffect(() => {
        (async () => {
            const appointmentData = await getAppointments(currentPatient);
            setAppointmentList(appointmentData.data.sort((a,b) => Date.parse(b.appointmentDate) - Date.parse(a.appointmentDate)));
        })();
    }, []);

    return (
        <>
            <Row className="profile">
                <Col sm={6}>
                    <h2 className="section-title">Appointment</h2>
                </Col>
                <Col sm={6} className="create-row">
                    <Link to={`/patient/${currentPatient.patientId}/new-appointment`}>
                        <Button type="primary">
                            New Appointment
                        </Button>
                    </Link>
                </Col>
                <Col sm={12}>
                    <Row className="section-content">
                         <DataTable data={appointmentList} settings={tableSettings}/>
                    </Row>
                </Col>
            </Row>
            <Modal open={appointmentModal.show} onCancel={() => setAppointmentModal({show: false, appointment: {}})} footer={null}>
                <div>
                    <AppointmentDetail appointment={appointmentModal.appointment} timezone={'America/New_York'}/>
                    {
                        appointmentModal.appointment.status !== 'Booked' && <hr />
                    }
                    {
                        appointmentModal.appointment.status === 'Rescheduled' &&
                        <div className="text-center">
                            This appointment has been <Typography.Text type="warning">rescheduled</Typography.Text>
                        </div>
                    }
                    {
                        appointmentModal.appointment.status === 'Canceled' &&
                        <div className="text-center">
                            This appointment has been <Typography.Text type="danger">canceled</Typography.Text>
                        </div>
                    }
                    {
                        appointmentModal.appointment.status === 'Completed' &&
                        <div className="text-center">
                            This appointment has been <Typography.Text type="success">completed</Typography.Text>
                        </div>
                    }
                    {
                        appointmentModal.appointment.status === 'PatientAbsents' &&
                        <div className="text-center">
                            Patient didn't show up for this appointment
                        </div>
                    }
                </div>
            </Modal>
        </>
    )
}

export default AppointmentPage