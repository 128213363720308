import {createSlice} from "@reduxjs/toolkit";

const newsPostsSlice = createSlice({
    name: 'news-posts',
    initialState: {
        initialized: false,
        error: false,
        data: [],
        locationData: [],
    },
    reducers: {
        setNewsPostLoadError: (state, action) => {
            return {
                initialized: true,
                error: true,
                data: []
            };
        },
        setNewsPostStoreData: (state, action) => {
            return {
                initialized: true,
                error: false,
                data: [...action.payload.data],
                locationData: [...action.payload.locationData]
            };
        },
        addPost: (state, action) => {
            return {
                ...state,
                data: [action.payload, ...state.data]
            };
        },
        updatePost: (state, action) => {
            return {
                ...state,
                data: [
                    action.payload,
                    ...state.data.filter(post => post.newsId !== action.payload.newsId)
                ]
            };
        },
    }
})

export const { setNewsPostLoadError, addPost, updatePost, setNewsPostStoreData } = newsPostsSlice.actions;
export default newsPostsSlice.reducer