import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Avatar, Col, Row, Spin, Statistic, Tabs} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentPatient} from "../../stores/current-patient.store";
import {getPatientInfoById} from "./service";
import {
    setCurrentTreatmentPlan,
    setHistoryTreatmentPlan,
    setPendingTreatmentPlan
} from "../../stores/patient-treatment-plan.store";
import {setCurrentLabResult} from "../../stores/current-lab-result.store";
import {setTreatmentCheatsheet} from "../../stores/treatment-cheatsheet.store";


const PatientPage = () => {
    const [userSummary, setUserSummary] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const {patientId} = useParams();
    const currentPatient = useSelector(state => state.currentPatient);

    const tabs = [
        {
            text: 'Details',
            route: 'details',
            disable: false
        },
        {
            text: 'Medical Notes',
            route: 'medical-notes',
            disable: false
        },
        {
            text: 'Lab Info',
            route: 'lab-info',
            disable: false
        },
        {
            text: 'Treatment',
            route: 'treatment',
            disable: false
        },
        {
            text: 'BioID Brief',
            route: 'bioid-brief',
            disable: false
        },
        // {
        //     text: 'Orders',
        //     route: 'orders',
        //     disable: false
        // },
        // {
        //     text: 'Engagement',
        //     route: 'engagement',
        //     disable: false
        // },
        {
            text: 'Appointment',
            route: 'appointment',
            disable: false
        },
        // {
        //     text: 'Payment',
        //     route: 'payment',
        //     disable: false
        // },
        {
            text: 'Files',
            route: 'files',
            disable: false
        }
    ];

    if (!location.pathname.split("/")[3]) {
        navigate('details');
    }

    useEffect(() => {
        (async () => {
            if (!currentPatient || ( !currentPatient.status && currentPatient.patientId !== patientId)) {
                const patientDataRequest = await getPatientInfoById(patientId);
                    dispatch(setCurrentPatient({
                        ...patientDataRequest.data.patient,
                        currentLabReportPDF: patientDataRequest.data.currentLabReportPDF,
                        currentLabReportPDFFileName : patientDataRequest.data.currentLabReportPDFFileName
                    }))
                    dispatch(setCurrentTreatmentPlan(patientDataRequest.data.currentTreatment || {forLabResult: [], forSymptomsAndGoals: []}));
                    dispatch(setHistoryTreatmentPlan(patientDataRequest.data.treatments.filter(t => t.approved || t.isDraft) || []));
                    dispatch(setPendingTreatmentPlan(patientDataRequest.data.treatments.filter(t => !t.approved && !t.isDraft) || []));
                    dispatch(setCurrentLabResult(patientDataRequest.data.currentLabResult || []));
                    dispatch(setTreatmentCheatsheet(patientDataRequest.data.treatmentCheatsheet));
            } else {
                setLoading(false);
                setUserSummary([
                    {
                        key: 'Type',
                        value: currentPatient.details.account_type
                    },
                    {
                        key: 'Phone',
                        value: currentPatient.details.phone
                    },
                    {
                        key: 'Physician',
                        value: currentPatient.details.primary_care_physician
                    },
                    {
                        key: 'Primary Service Location',
                        value: currentPatient.details.primary_service_location
                    },
                    {
                        key: 'Account Owner',
                        value: currentPatient.details.ownerid
                    },
                    // {
                    //     key: 'Most Recent Service Date',
                    //     value: '3/23/2022'
                    // },
                    // {
                    //     key: 'Membership',
                    //     value: currentPatient.rewardStatus
                    // }
                ]);

            }
        })()
    }, [currentPatient]);

    return (
        <>
            {
                !loading ?
                    (location.pathname.split("/")[3] === 'new-appointment' ?
                            <Outlet/> :
                            <div style={{
                                marginTop: "60px",
                                background: '#F0F2F5 0% 0% no-repeat padding-box',
                                padding: 32
                            }}>
                                <h2>Patients</h2>
                                <Row align="middle" style={{
                                    height: 127,
                                    background: "white",
                                    padding: "0 12px",
                                    border: "1px solid white",
                                    borderRadius: 10
                                }} gutter={12}>
                                    <Col span={2} style={{textAlign: "center"}}>
                                        <Avatar size={87} src={currentPatient.image && currentPatient.image}/>
                                    </Col>
                                    <Col span={22} style={{textAlign: "center"}}>
                                        <Row>
                                            <h4>{currentPatient.firstName}&nbsp;{currentPatient.lastName}</h4>
                                        </Row>
                                        <Row align="middle" justify="space-between">
                                            {
                                                userSummary.map((summary, i) =>
                                                    <Statistic
                                                        title={<div style={{textAlign: 'left'}}>{summary.key}</div>}
                                                        value={summary.value}
                                                        formatter={value => value || ""}
                                                        valueStyle={{fontSize: 14, textAlign: "left"}}
                                                        key={i}
                                                    />
                                                )
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                                {
                                    (!currentPatient.status && currentPatient.status !== 'prospect-patient') &&
                                        <Tabs activeKey={location.pathname.split("/")[3] || "details"}
                                      onTabClick={async (key) => {
                                          navigate(`${key}`);
                                      }}
                                      style={{marginTop: "12px", marginBottom: "20px"}}
                                >
                                    {
                                        tabs.map((tab) => {
                                            return (
                                                <Tabs.TabPane key={tab.route} tab={tab.text} disabled={tab.disable}>
                                                    <div style={{margin: "12px 0"}}>
                                                        <Outlet/>
                                                    </div>
                                                </Tabs.TabPane>
                                            )
                                        })
                                    }
                                </Tabs>
                                }
                            </div>
                    )
                    :
                    <div className="page-loader">
                        <Spin size="large"/>
                    </div>
            }
        </>
    )
}

export default PatientPage;