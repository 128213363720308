import bioApi from "../../api/bioApi";
import {
    ORDERS_OPTIONS_URL,
    SEARCH_ORDERS_URL,
    UPDATE_TRACKING_NUMBER_URL
} from "../../api/URLs";

export const getShippingOptions = (searchType = "orderID",searchContent = "") => {
    const url = ORDERS_OPTIONS_URL;
    return bioApi.post(url,{
        'type' : searchType,
        'content' : searchContent,
        'tracking' : false
    });
}

export const getTrackingOptions = (searchType = "orderID",searchContent = "") => {
    const url = ORDERS_OPTIONS_URL;
    return bioApi.post(url,{
        'type' : searchType,
        'content' : searchContent,
        'tracking' : true
    });
}

export const searchOrders = (searchType = "orderID",searchContent = "",sortType = "orderID",rangeDate = null) => {
    const url = SEARCH_ORDERS_URL;
    return bioApi.post(url,{
        'type' : searchType,
        'content' : searchContent,
        'sortType' : sortType,
        'rangeDate' : rangeDate,
        'tracking' : false
    });
}

export const searchTracking = (searchType = "orderID",searchContent = "",sortType = "orderID",rangeDate = null) => {
    const url = SEARCH_ORDERS_URL;
    return bioApi.post(url,{
        'type' : searchType,
        'content' : searchContent,
        'sortType' : sortType,
        'rangeDate' : rangeDate,
        'tracking' : true
    });
}

export const updateTrackingNumber = (data = null) => {
    const url = UPDATE_TRACKING_NUMBER_URL;
    return bioApi.post(url,data);
}