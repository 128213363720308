import {createSlice} from "@reduxjs/toolkit";

const currentStaffSlice = createSlice({
    name: 'currentStaff',
    initialState: null,
    reducers: {
        setCurrentStaff: (state, action) => {
            return action.payload;
        },
        updateCurrentStaff: async (state, action) => {
            return {
                ...state,
                [action.payload.key]: action.payload.value
            };
        },
    }
})

export const { setCurrentStaff, updateCurrentStaff } = currentStaffSlice.actions;
export default currentStaffSlice.reducer;