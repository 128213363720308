import React from 'react'
import Loader from './Loader'

const BoxLoader = ({ show, loadingStyle }) => {
    if (!show) {
        return null
    }
    return <div style={{
        position: loadingStyle === 2 ? 'fixed' : 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        overflow: 'auto',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        zIndex: 1050,
        borderRadius: 5,
    }}>
        <Loader color="#C6322C" height={36} width={36} visible={true} style={{
            margin: 'auto',
            maxHeight: '100%'
        }} />
    </div>
}

export default BoxLoader;