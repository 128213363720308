import React, {useContext, useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import DataTable from "../../../../components/data-table/data-table.component";
import {Button, Checkbox, Col, Modal, Row, Upload} from "antd";
import {Tab, Tabs} from "@mui/material";
import SignaturePad from "react-signature-pad-wrapper";
import {fileToBase64} from "../../../../share/StringHelper";
import bioApi from "../../../../api/bioApi";
import {UPLOAD_IMAGE_URL} from "../../../../api/URLs";
import {UploadFile} from "@mui/icons-material";
import {UserContext} from "../../../../App";

const ESignForm = ({approvingTreatment, goBackButton, onSigning, afterSigning}) => {
    const user = useContext(UserContext);

    const [tableData, setTableData] = useState([]);
    const [tableSetting, setTableSetting] = useState(null);
    const [signatureLink, setSignatureLink] = useState('');
    const [signatureBase64, setSignatureBase64] = useState('');
    const [activeTab, setActiveTab] = useState(0);
    const [signAll, setSignAll] = useState(null);
    const [signaturePadRef, setSignaturePadRef] = useState();
    const [loading, setLoading] = useState(false);
    const [role, setRole] = useState(user.role);

    const signatureUploadProps = {
        action: async (file) => {
            const base64 = await fileToBase64(file);

            const response = await bioApi.post(UPLOAD_IMAGE_URL, {
                folderType: 'doctor-signature',
                image: base64.split(',')[1]
            });
            if (response.data.result) {
                signaturePadRef.fromDataURL(base64);
                setSignatureBase64(base64);
                setActiveTab(0);
                setSignatureLink(response.data.data);
                return response.data.data;
            }
            return '';
        },
        listType: "picture-card",
        maxCount: 1,
        fileList: signatureLink ? [{
            uid: '-1',
            name: 'signature',
            status: 'done',
            url: signatureLink,
        }] : [],
        onPreview: () => Modal.info({
            title: 'Signature Preview',
            content: (
                <img src={signatureLink} alt='signature' style={{maxWidth: 280, maxHeight: 280}}/>
            )
        }),
        onChange: (upload) => {
            if (upload.file.status === 'removed') {
                setSignatureLink('');
                setSignatureBase64('');
            }
        }
    };

    const submitSignature = async () => {
        const response = await bioApi.post(UPLOAD_IMAGE_URL, {
            folderType: 'doctor-signature',
            image: signatureBase64.split(',')[1]
        });
        if (response.data.result) {
            setSignatureBase64(signaturePadRef.toDataURL());
            setSignatureLink(response.data.data);
        }
    }

    const onStrokeEnd = () => {
        setSignatureBase64(signaturePadRef.toDataURL())
    }

    const init = () => {
        const plans = [...approvingTreatment.forLabResult, ...approvingTreatment.forSymptomsAndGoals];
        const approvedMedicineMap = new Map();

        const basePlan = plans.filter(p => p.level === 'Base');
        const correctionalPlan = plans.filter(p => p.level === 'Correctional');
        const electivePlan = plans.filter(p => p.level === 'Elective');
        const otherPlan = plans.filter(p => p.level === 'Other');

        const baseRowSpan = basePlan.length;
        const correctionalRowSpan = correctionalPlan.length;
        const electiveRowSpan = electivePlan.length;
        const otherRowSpan = otherPlan.length;

        const planMapFunc = (p, i) => {
            const signatureLink = approvedMedicineMap.get(p._id);
            if (i === 0) {
                return {...p, signed: !!signatureLink, signature: signatureLink};
            } else {
                return {
                    level: '',
                    _id: p._id,
                    product: p.product,
                    dosage: p.dosage,
                    refill: p.refill,
                    signed: !!signatureLink,
                    signature: signatureLink
                };
            }
        }
        const basePlanTableData = basePlan.map(planMapFunc);
        const correctionalPlanTableData = correctionalPlan.map(planMapFunc);
        const electivePlanTableData = electivePlan.map(planMapFunc);
        const otherPlanTableData = otherPlan.map(planMapFunc);

        const rowSpan = {
            '': 0,
            'Base': baseRowSpan,
            'Correctional': correctionalRowSpan,
            'Elective': electiveRowSpan,
            'Other': otherRowSpan,
        }

        setTableData([...basePlanTableData, ...correctionalPlanTableData, ...electivePlanTableData, ...otherPlanTableData]);

        if (role === 'provider') {
            setTableSetting({
                pagination: false,
                bordered: true,
                operationColumnTitle:
                    <div className="d-flex justify-content-between align-items-center" style={{width: 150}}>
                        <span>E-Sign</span>
                        <span>
                        <Checkbox disabled={!signatureLink} onChange={(event) => {
                            setSignAll(event.target.checked)
                        }}>Select All</Checkbox>
                    </span>
                    </div>,
                operationColumnClass: 'action-column',
                customActions: [
                    {
                        element: (record, data) => <>
                            <Checkbox checked={record.signed} disabled={true}
                                      onChange={(event) => {
                                          record.signed = event.target.checked;
                                          if (!event.target.checked) {
                                              record.signature = '';
                                          } else {
                                              record.signature = signatureLink;
                                          }
                                          setTableData([...data]);
                                      }}/>
                            <img className="ms-1" src={record.signature} hidden={!record.signed || !record.signature}
                                 height={30}
                                 alt="signature"/>
                        </>
                    }
                ],
                columns: [
                    {
                        title: 'Therapy',
                        dataIndex: 'level',
                        onCell: (record) => ({
                            rowSpan: rowSpan[record.level]
                        })
                    },
                    {
                        title: 'Medication / Product',
                        dataIndex: ['product', 'name']
                    },
                    {
                        title: 'Dosage',
                        dataIndex: 'dosage'
                    },
                    {
                        title: 'Refill',
                        dataIndex: 'refill',
                    }
                ]
            });
        } else {
            setTableSetting({
                pagination: false,
                bordered: true,
                columns: [
                    {
                        title: 'Therapy',
                        dataIndex: 'level',
                        onCell: (record) => ({
                            rowSpan: rowSpan[record.level]
                        })
                    },
                    {
                        title: 'Medication / Product',
                        dataIndex: ['product', 'name']
                    },
                    {
                        title: 'Dosage',
                        dataIndex: 'dosage'
                    },
                    {
                        title: 'Refill',
                        dataIndex: 'refill',
                    }
                ]
            });
        }
    };

    const signTreatment = async () => {
        try {
            setLoading(true);
            await onSigning(signatureLink);
            if (afterSigning) afterSigning();
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        init();
    }, [role]);

    useEffect(() => {
        if (signAll !== null) {
            if (signAll) {
                tableData.forEach(d => {
                    d.signed = true;
                    d.signature = signatureLink;
                });
            } else {
                tableData.forEach(d => {
                    d.signed = false;
                    d.signature = '';
                });
            }
            setTableData([...tableData]);
        }
    }, [signAll]);

    useEffect(() => {
        if (signaturePadRef) {
            signaturePadRef.instance.addEventListener('endStroke', onStrokeEnd);
            if (activeTab === 0 && signatureBase64) {
                signaturePadRef.fromDataURL(signatureBase64);
            }
        }
    }, [signaturePadRef]);

    useEffect(() => {
        if (signatureLink) {
            setTableSetting(null);
            setTableData([]);
            setTimeout(() => {
                init();
            });
        }
    }, [signatureLink])

    return (
        <div>
            <div className="d-flex justify-content-between mb-4">
                <h4>E-Sign</h4>
                <div>
                    {
                        goBackButton &&
                        <Button className="me-2" onClick={goBackButton}>Go back and edit</Button>
                    }
                    <Button type="primary" loading={loading} onClick={signTreatment} disabled={!role}>
                        {
                            role === 'provider' ?
                                'Confirm, Sign and Generate Documents'
                                :
                                'Confirm'
                        }
                    </Button>
                </div>
            </div>
            <Row gutter={60}>
                <Col span={role === "physician" ? 17 : 24}>
                    {tableSetting && <DataTable data={tableData} settings={tableSetting}/>}
                </Col>
                {
                    role === "physician" &&
                    <Col span={7}>
                        <Card style={{borderRadius: 20}}>
                            <Card.Body className="p-4">
                                <div className="mb-5">
                                    <Card.Title>My Signature</Card.Title>
                                </div>
                                <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
                                    <Tab label="Draw"/>
                                    <Tab label="Upload"/>
                                </Tabs>
                                <div
                                    role="tabpanel"
                                    hidden={activeTab !== 0}
                                >
                                    {activeTab === 0 && (
                                        <div className="mt-2">
                                            <SignaturePad ref={node => {
                                                if (node) {
                                                    setSignaturePadRef(node);
                                                }
                                            }} options={{backgroundColor: '#EDEDED'}}/>
                                            <div className="d-flex justify-content-around">
                                                <Button onClick={() => {
                                                    signaturePadRef.clear();
                                                    setSignatureBase64('');
                                                }}>Clear</Button>
                                                <Button type="primary" disabled={!signatureBase64}
                                                        onClick={submitSignature}>
                                                    Use This Signature</Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div
                                    role="tabpanel"
                                    hidden={activeTab !== 1}
                                >
                                    {activeTab === 1 && (
                                        <div className="mt-4 d-flex justify-content-center">
                                            <Upload {...signatureUploadProps} className="text-center">
                                                {
                                                    !signatureLink && (
                                                        <div>
                                                            <UploadFile/>
                                                            <div style={{marginTop: 8}}>Upload</div>
                                                        </div>
                                                    )
                                                }
                                            </Upload>
                                        </div>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                }
            </Row>
        </div>

    )
};
export default ESignForm