import React from 'react';
import { Link } from 'react-router-dom';

import NotificationList from './NotificationList'
import NewNotification from "./NewNotification";

import Page from '../../../components/page/Page';
import { ButtonGroup, Tab, Tabs, ToggleButton } from 'react-bootstrap';

class NotificationPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            type: 0
        };
    }

    render() {
        return (
            <Page title={'Notification Management'} isCustom={false}>
                <div style={{ width: "100%", height: "100%" }}>
                    <ButtonGroup className='mb-3'>
                        <ToggleButton
                            size="sm" type="radio" name="radio"
                            variant={this.state.type === 0 ? 'primary' : 'outline-dark'}
                            onClick={() => this.setState({ type: 0 })}
                        >
                            Send Notification
                        </ToggleButton>
                        <ToggleButton
                            size="sm" type="radio" name="radio"
                            variant={this.state.type === 1 ? 'primary' : 'outline-dark'}
                            onClick={() => this.setState({ type: 1 })}
                        >
                            Notification History
                        </ToggleButton>
                    </ButtonGroup>
                    {this.state.type === 0 && (
                        <NewNotification />
                    )}
                    {this.state.type === 1 && (
                        <NotificationList switch={false} type={this.state.type} history={this.props.history} />
                    )}
                </div>
            </Page>
        )
    }
};

export default NotificationPage;
