import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { setAuthenticatedUser } from "./authenticated-user.store";

export const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  matcher: isAnyOf(setAuthenticatedUser),
  effect: (action, listenerApi) => {
    localStorage.setItem(
      "user",
      JSON.stringify(listenerApi.getState().authenticatedUser),
    );
  },
});
