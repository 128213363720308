import bioApi from "../../api/bioApi";
import {
    APPOINTMENT_AVAILABLE_SERVICES_URL,
    APPOINTMENT_SCHEDULE_URL,
    APPROVE_TREATMENT_PLAN,
    BIOIDS_FILES_URL,
    CREATE_APPOINTMENT,
    CREATE_BLOCKED_TIME,
    DELETE_DRAFT_PLAN,
    DELETE_FILES,
    FILES_URL,
    LABREPORTS_FILES_URL,
    NEW_TREATMENT_PLAN,
    PATIENT_DETAIL_URL,
    PATIENT_MEDICAL_NOTES,
    PATIENT_RAW_UPDATE,
    PLACE_ORDER,
    QUERY_PATIENT_APPOINTMENT,
    QUERY_PATIENT_ORDER,
    SAVE_DRAFT_PLAN,
    TREATMENT_PROPOSAL_CALIBRATE_URL,
    SYMPTOMS_AND_GOALS_URL,
    UPDATE_TREATMENT_PLAN,
    PROSPECT_PATIENT_DETAIL_URL,
    PATIENT_CHECK_PRESIGN_URL
} from "../../api/URLs";

export const getPatientInfoById = (patientId) => {
    const url = PATIENT_DETAIL_URL.replace(":id", patientId);
    return bioApi.get(url);
}
export const getAppuserInfoById = (appuserId) => {
    const url = PROSPECT_PATIENT_DETAIL_URL.replace(":id", appuserId);
    return bioApi.get(url);
}
export const submitNewTreatmentPlan = async (treatmentPlan, patient, labResult, signatureLink, isApproved, existingPlanOrDraftId) => {
    return bioApi.post(NEW_TREATMENT_PLAN, {treatmentPlan, patient, labResult, signatureLink, isApproved, existingPlanOrDraftId});
}

export const submitDraftTreatment = async (treatmentPlan, patient, labResult, existingPlanOrDraftId) => {
    return bioApi.post(SAVE_DRAFT_PLAN, {treatmentPlan, patient, labResult, existingPlanOrDraftId});
}

export const deleteDraftTreatment = async (draftId) => {
    return bioApi.delete(DELETE_DRAFT_PLAN + draftId);
}

export const populateMedicationInfo = (medications, gender) => {
    return bioApi.post(TREATMENT_PROPOSAL_CALIBRATE_URL, {medications, gender})
}

export const approveTreatmentPlan = (patient, treatmentPlan, signatureLink) => {
    return bioApi.post(APPROVE_TREATMENT_PLAN, {treatmentPlanId: treatmentPlan._id, patientUUID: patient._id, signatureLink});
}

export const updateSymptomsAndGoals = (patientId, symptomsAndGoals) => {
    const url = SYMPTOMS_AND_GOALS_URL.replace(':id', patientId);

    return bioApi.post(url, {symptomsAndGoals});
}

export const getSymptomsAndGoals = (patientId) => {
    const url = SYMPTOMS_AND_GOALS_URL.replace(':id', patientId);

    return bioApi.get(url);
}

export const updateMedicineCancellation = (updatedPlan) => {
    return bioApi.post(UPDATE_TREATMENT_PLAN, {updatedPlan});
}

export const placeOrder = (patient, signature, location, medication, labResult, treatmentId) => {
    return bioApi.post(PLACE_ORDER, {patient, signature, location, medication, labResult, treatmentId});
}

export const getOrders = (patient) => {
    const url = QUERY_PATIENT_ORDER.replace('{id}', patient.patientId);
    return bioApi.get(url);
}

export const getAppointments = (patient) => {
    const url = QUERY_PATIENT_APPOINTMENT.replace('{id}', patient.patientId);
    return bioApi.get(url);
}
export const getAppointmentAvailableServices = () => {
    return bioApi.get(APPOINTMENT_AVAILABLE_SERVICES_URL);
}

export const getBioIdFiles = (patient) => {
    const url = BIOIDS_FILES_URL.replace(':id', patient.patientId);
    return bioApi.get(url);
}
export const getFiles = (patient) => {
    const url = FILES_URL.replace(':id', patient.patientId);
    return bioApi.get(url);
}
export const getLabreportsFiles = (patient) => {
    const url = LABREPORTS_FILES_URL.replace(':id', patient.patientId);
    return bioApi.get(url);
}
export const getStaffSchedulesByService = (service, timezone) => {
    const url = APPOINTMENT_SCHEDULE_URL + '?service=' + service + '&timezone=' + timezone;
    return bioApi.get(url);
}
export const deleteFiles = (payload) => {
    return bioApi.post(DELETE_FILES, payload);
}
export const scheduleAppointment = (payload) => {
    return bioApi.post(CREATE_APPOINTMENT, payload);
}

export const scheduleBlockedTime = (payload) => {
    return bioApi.post(CREATE_BLOCKED_TIME, payload);
}

export const updatePatientRawData = (patientId, field, value) => {
    return bioApi.post(PATIENT_RAW_UPDATE.replace(":patientId", patientId), {
        field, value
    });
}

export const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const currentDate = new Date();

    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Check if the birthday hasn't occurred yet this year
    const hasBirthdayPassed = (
        currentDate.getMonth() > birthDate.getMonth() ||
        (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() >= birthDate.getDate())
    );

    // Subtract 1 from age if the birthday hasn't occurred yet
    if (!hasBirthdayPassed) {
        age--;
    }

    return age;
}

export const createMedicalNote = (patientId, data) => {
    return bioApi.post(PATIENT_MEDICAL_NOTES.replace(":patientId", patientId), {
        data
    });
}

export const getMedicalNotes = (patientId) => {
    return bioApi.get(PATIENT_MEDICAL_NOTES.replace(":patientId", patientId));
}

export const checkPresignUrl = (type,fileName) => {
    return bioApi.get(PATIENT_CHECK_PRESIGN_URL.replace(":type",type).replace(":name", fileName));
}