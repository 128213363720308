import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import bioApi from '../../api/bioApi';
import { LIST_ROLE_URL, UPLOAD_IMAGE_URL } from '../../api/URLs';
import BoxLoader from '../../components/loading/BoxLoader';
import { fileToBase64 } from '../../share/StringHelper';
import { listAllLocations } from '../patient/patient-search.service';
import { getAppointmentAvailableServices } from '../patient/service';
import './staff-create.css';
import { createStaff } from './staff.service';

function StaffCreatePage() {
  const navigate = useNavigate();

  const [staffData, setStaffData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    title: '',
    role: '',
    position: '',
    dob: null,
    password: '',
    introduction: '',
    description: [''],
    countryCode: 1,
    phoneNumber: '',
    gender: '',
    languages: [],
    affiliations: [''],
    accreditations: [''],
    locations: [''],
    services: [''],
    coverImage: '',
    dea: '',
    me: '',
  });

  const [loading, setloading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [allLocations, setAllLocations] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const coverImageRef = useRef(null);

  useEffect(() => {
    fetchAllLocations();
    fetchAllServices();
    fetchAllRoles();
  }, []);

  const fetchAllLocations = () => {
    listAllLocations().then((response) => {
      if (response.data.status !== 200) {
        alert(response.data.message);
      } else {
        setAllLocations(response.data.data);
      }
    });
  };

  const fetchAllServices = () => {
    getAppointmentAvailableServices().then((response) => {
      if (response.data.status !== 200) {
        alert(response.data.message);
      } else {
        setAllServices(response.data.data);
      }
    });
  };

  const fetchAllRoles = () => {
    (async () => {
      const rolesResponse = await bioApi.get(LIST_ROLE_URL);
      setAllRoles(rolesResponse.data);
    })();
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const staff = { ...staffData };
    staff.dob = date;
    setStaffData(staff);
  };

  const addLocation = () => {
    const staff = { ...staffData };
    staff.locations = [...staff.locations, ''];
    setStaffData(staff);
  };

  const removeLocation = () => {
    const staff = { ...staffData };
    let locations = [...staff.locations];
    locations.pop();
    staff.description = locations;
    setStaffData(staff);
  };

  const addService = () => {
    const staff = { ...staffData };
    staff.services = [...staff.services, ''];
    setStaffData(staff);
  };

  const removeService = () => {
    const staff = { ...staffData };
    let services = [...staff.services];
    services.pop();
    staff.description = services;
    setStaffData(staff);
  };

  const addDescription = () => {
    const staff = { ...staffData };
    staff.description = [...staff.description, ''];
    setStaffData(staff);
  };

  const removeDescription = () => {
    const staff = { ...staffData };
    let description = [...staff.description];
    description.pop();
    staff.description = description;
    setStaffData(staff);
  };

  const addAffiliation = () => {
    const staff = { ...staffData };
    staff.affiliations = [...staff.affiliations, ''];
    setStaffData(staff);
  };

  const removeAffiliation = () => {
    const staff = { ...staffData };
    let affiliations = [...staff.affiliations];
    affiliations.pop();
    staff.affiliations = affiliations;
    setStaffData(staff);
  };

  const addAccreditation = () => {
    const staff = { ...staffData };
    staff.accreditations = [...staff.accreditations, ''];
    setStaffData(staff);
  };

  const removeAccreditation = () => {
    const staff = { ...staffData };
    let accreditations = [...staff.accreditations];
    accreditations.pop();
    staff.accreditations = accreditations;
    setStaffData(staff);
  };

  const handleCoverImage = () => {
    coverImageRef.current.click();
  };

  const handleAddCoverImage = async (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    setloading(true);
    const file = await fileToBase64(e.target.files[0]);
    try {
      bioApi
        .post(UPLOAD_IMAGE_URL, {
          folderType: 'staff_upload',
          image: file.split(',')[1],
        })
        .then((response) => {
          if (response.data.result) {
            var staff = { ...staffData };
            staff.coverImage = response.data.data;
            setStaffData(staff);
          } else {
            alert(response.data.message);
          }
          setloading(false);
        });
    } catch (error) {
      setloading(false);
      alert(error);
    }
  };

  const handleSubmit = async () => {
    let staff = { ...staffData };
    if (staff.password !== confirmPassword) {
      return;
    }
    if (staff.description) {
      staff.description = staff.description.filter((item) => item.trim() !== '');
    }
    if (staff.locations) {
      staff.locations = staff.locations.filter((item) => item.trim() !== '');
    }
    if (staff.affiliations) {
      staff.affiliations = staff.affiliations.filter((item) => item.trim() !== '');
    }
    if (staff.accreditations) {
      staff.accreditations = staff.accreditations.filter((item) => item.trim() !== '');
    }
    if (staff.services) {
      staff.services = staff.services.filter((item) => item.trim() !== '');
    }

    setloading(true);
    try {
      createStaff(staff)
        .then((response) => {
          setloading(false);
          if (response.data?.status !== 200) {
            alert(response.data.message);
          } else {
            navigate(`/staff/search`);
          }
        })
        .catch((err) => {
          if (err.response?.data) {
            alert(err.response.data.message);
          } else {
            alert(err);
            console.log('Error creating staff', err);
          }
          setloading(false);
        });
    } catch (e) {
      alert(e);
      setloading(false);
    }
  };

  return (
    <div
      style={{
        marginTop: '60px',
        background: '#F0F2F5 0% 0% no-repeat padding-box',
        padding: 32,
      }}
    >
      <BoxLoader show={loading} loadingStyle={2} />
      <h2>Create New Staff</h2>
      <br />

      <Form>
        <Form.Group as={Row} className="mb-3" controlId="formEmail">
          <Form.Label column sm={2}>
            Email Address
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="email"
              placeholder="Enter email"
              onChange={(e) => {
                const staff = { ...staffData };
                staff.email = e.target.value;
                setStaffData(staff);
              }}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formPassword">
          <Form.Label column sm={2}>
            Password
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="password"
              placeholder="Enter password"
              onChange={(e) => {
                const staff = { ...staffData };
                staff.password = e.target.value;
                setStaffData(staff);
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="confirmPassword">
          <Form.Label column sm={2}>
            Confirm Password
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="password"
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
              isInvalid={confirmPassword && staffData.password !== confirmPassword}
            />
            <Form.Control.Feedback type="invalid">Passwords do not match.</Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formFirstName">
          <Form.Label column sm={2}>
            First Name
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              placeholder="Enter first name"
              onChange={(e) => {
                const staff = { ...staffData };
                staff.firstName = e.target.value;
                setStaffData(staff);
              }}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formLastName">
          <Form.Label column sm={2}>
            Last Name
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              placeholder="Enter last name"
              onChange={(e) => {
                const staff = { ...staffData };
                staff.lastName = e.target.value;
                setStaffData(staff);
              }}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formTitle">
          <Form.Label column sm={2}>
            Title
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              placeholder="Enter title"
              onChange={(e) => {
                const staff = { ...staffData };
                staff.title = e.target.value;
                setStaffData(staff);
              }}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formGender">
          <Form.Label column sm={2}>
            Gender
          </Form.Label>
          <Col sm={10}>
            <Form.Select
              defaultValue="Choose..."
              onChange={(e) => {
                const staff = { ...staffData };
                staff.gender = e.target.value;
                setStaffData(staff);
              }}
            >
              <option disabled>Choose...</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </Form.Select>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formLanguages">
          <Form.Label column sm={2}>
            Languages
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              placeholder="Enter languages, separated by comma or space"
              onChange={(e) => {
                const staff = { ...staffData };
                staff.languages = e.target.value.split(/[, ]+/);
                setStaffData(staff);
              }}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formPhone">
          <Form.Label column sm={2}>
            Phone
          </Form.Label>
          <Col sm={2}>
            <Form.Control
              type="number"
              min={1}
              max={999}
              placeholder="Enter country code"
              onChange={(e) => {
                const staff = { ...staffData };
                staff.countryCode = parseInt(e.target.value);
                setStaffData(staff);
              }}
            />
          </Col>
          <Col sm={8}>
            <Form.Control
              type="tel"
              placeholder="Enter phone number"
              onChange={(e) => {
                const staff = { ...staffData };
                staff.phoneNumber = e.target.value;
                setStaffData(staff);
              }}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formRole">
          <Form.Label column sm={2}>
            Role
          </Form.Label>
          <Col sm={10}>
            <Form.Select
              onChange={(e) => {
                const staff = { ...staffData };
                staff.role = e.target.value;
                setStaffData(staff);
              }}
            >
              <option value={''}>Choose...</option>
              {allRoles.map((role, i) => (
                <option selected={role._id === staffData.role} value={role._id}>
                  {role.name}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formPosition">
          <Form.Label column sm={2}>
            Position
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              placeholder="Enter position"
              onChange={(e) => {
                const staff = { ...staffData };
                staff.position = e.target.value;
                setStaffData(staff);
              }}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formDob">
          <Form.Label column sm={2}>
            Date of Birth
          </Form.Label>
          <Col sm={10}>
            <ReactDatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="MM/dd/yyyy"
              className="form-control"
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formDEA">
          <Form.Label column sm={2}>
            DEA #
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="Enter DEA Number"
              onChange={(e) => {
                const staff = { ...staffData };
                staff.dea = e.target.value;
                setStaffData(staff);
              }}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formME">
          <Form.Label column sm={2}>
            ME #
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="Enter ME Number"
              onChange={(e) => {
                const staff = { ...staffData };
                staff.me = e.target.value;
                setStaffData(staff);
              }}
            />
          </Col>
        </Form.Group>

        {staffData.locations.map((value, index) => (
          <Form.Group key={index} as={Row} className="mb-3" controlId={`formLocation_${index}`}>
            <Form.Label column sm={2}>
              Location {index + 1}
            </Form.Label>
            <Col sm={10}>
              <Form.Select
                value={value}
                onChange={(e) => {
                  const staff = { ...staffData };
                  staff.locations[index] = e.target.value;
                  setStaffData(staff);
                }}
              >
                <option value="">Choose...</option>
                {allLocations.map((location) => (
                  <option value={location.stationId}>{location.name}</option>
                ))}
              </Form.Select>
            </Col>
          </Form.Group>
        ))}
        <div className="d-grid gap-2 d-flex" style={{ justifyContent: 'end' }}>
          <Button variant="secondary" onClick={addLocation} className="description-button">
            Add Location
          </Button>
          <Button
            variant="secondary"
            onClick={removeLocation}
            className="description-button"
            disabled={!staffData.locations || staffData.locations.length === 0}
          >
            Remove Last Location
          </Button>
        </div>

        {staffData.services.map((value, index) => (
          <Form.Group key={index} as={Row} className="mb-3" controlId={`formService_${index}`}>
            <Form.Label column sm={2}>
              Service {index + 1}
            </Form.Label>
            <Col sm={10}>
              <Form.Select
                value={value}
                onChange={(e) => {
                  const staff = { ...staffData };
                  staff.services[index] = e.target.value;
                  setStaffData(staff);
                }}
              >
                <option value="">Choose...</option>
                {allServices.map((service) => (
                  <option value={service.serviceID}>{service.serviceName}</option>
                ))}
              </Form.Select>
            </Col>
          </Form.Group>
        ))}
        <div className="d-grid gap-2 d-flex" style={{ justifyContent: 'end' }}>
          <Button variant="secondary" onClick={addService} className="description-button">
            Add Service
          </Button>
          <Button
            variant="secondary"
            onClick={removeService}
            className="description-button"
            disabled={!staffData.services || staffData.services.length === 0}
          >
            Remove Last Service
          </Button>
        </div>

        {staffData.description.map((value, index) => (
          <Form.Group key={index} as={Row} className="mb-3" controlId={`formDescription_${index}`}>
            <Form.Label column sm={2}>
              Description {index + 1}
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder="Enter description"
                value={value}
                onChange={(e) => {
                  const staff = { ...staffData };
                  staff.description[index] = e.target.value;
                  setStaffData(staff);
                }}
              />
            </Col>
          </Form.Group>
        ))}
        <div className="d-grid gap-2 d-flex" style={{ justifyContent: 'end' }}>
          <Button variant="secondary" onClick={addDescription} className="description-button">
            Add Description
          </Button>
          <Button
            variant="secondary"
            onClick={removeDescription}
            className="description-button"
            disabled={!staffData.description || staffData.description.length === 0}
          >
            Remove Last Description
          </Button>
        </div>

        {staffData.affiliations.map((value, index) => (
          <Form.Group key={index} as={Row} className="mb-3" controlId={`formAffiliation_${index}`}>
            <Form.Label column sm={2}>
              Affiliation {index + 1}
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder="Enter Affiliation"
                value={value}
                onChange={(e) => {
                  const staff = { ...staffData };
                  staff.affiliations[index] = e.target.value;
                  setStaffData(staff);
                }}
              />
            </Col>
          </Form.Group>
        ))}
        <div className="d-grid gap-2 d-flex" style={{ justifyContent: 'end' }}>
          <Button variant="secondary" onClick={addAffiliation} className="description-button">
            Add Affiliation
          </Button>
          <Button
            variant="secondary"
            onClick={removeAffiliation}
            className="description-button"
            disabled={!staffData.affiliations || staffData.affiliations.length === 0}
          >
            Remove Last Affiliation
          </Button>
        </div>

        {staffData.accreditations.map((value, index) => (
          <Form.Group key={index} as={Row} className="mb-3" controlId={`formAccreditation_${index}`}>
            <Form.Label column sm={2}>
              Accreditation & Certification {index + 1}
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder="Enter Accreditation & Certification"
                value={value}
                onChange={(e) => {
                  const staff = { ...staffData };
                  staff.accreditations[index] = e.target.value;
                  setStaffData(staff);
                }}
              />
            </Col>
          </Form.Group>
        ))}
        <div className="d-grid gap-2 d-flex" style={{ justifyContent: 'end' }}>
          <Button variant="secondary" onClick={addAccreditation} className="description-button">
            Add Accreditation or Certification
          </Button>
          <Button
            variant="secondary"
            onClick={removeAccreditation}
            className="description-button"
            disabled={!staffData.accreditations || staffData.accreditations.length === 0}
          >
            Remove Last Accreditation or Certification
          </Button>
        </div>

        <Form.Group as={Row} className="mb-3" controlId="formIntroduction">
          <Form.Label column sm={2}>
            Introduction
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="textarea"
              placeholder="Enter introduction"
              onChange={(e) => {
                const staff = { ...staffData };
                staff.introduction = e.target.value;
                setStaffData(staff);
              }}
            />
          </Col>
        </Form.Group>

        <Form.Group className="mb-2" controlId="formCoverImage">
          <Row className="mb-2">
            <Col style={{ display: 'flex' }}>
              <Form.Label column="sm" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                Cover Image
              </Form.Label>
            </Col>
            <Col style={{ display: 'flex' }}>
              <Col style={{ flex: 1 }}></Col>
              <Button variant="outline-primary" style={{ marginLeft: 10 }} onClick={handleCoverImage}>
                Upload
              </Button>
              <input
                style={{
                  display: 'none',
                }}
                ref={coverImageRef}
                type="file"
                accept="image/*"
                onChange={handleAddCoverImage}
              />
            </Col>
          </Row>
          <Col style={{ display: 'flex' }}>
            {staffData.coverImage && staffData.coverImage !== '' ? (
              <Card style={{ margin: 'auto', width: '30%', marginBottom: 10 }}>
                <Card.Img variant="top" src={staffData.coverImage} />
                <Card.Body>
                  <Col style={{ display: 'flex' }}>
                    <Button
                      variant="outline-danger"
                      style={{ margin: 'auto' }}
                      onClick={() => {
                        var staff = { ...staffData };
                        staff.coverImage = '';
                        setStaffData(staff);
                      }}
                    >
                      Delete
                    </Button>
                  </Col>
                </Card.Body>
              </Card>
            ) : null}
          </Col>
        </Form.Group>

        <Row className="mt-4 mb-2">
          <Col style={{ display: 'flex' }}>
            <Button variant="primary" style={{ margin: 'auto' }} onClick={handleSubmit}>
              Submit
            </Button>
            <Button variant="secondary" style={{ margin: 'auto' }} onClick={() => navigate(`/staff/search`)}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default StaffCreatePage;
