import {createSlice} from "@reduxjs/toolkit";

const treatmentCheatsheetSlice = createSlice({
    name: 'treatmentCheatsheet',
    initialState: null,
    reducers: {
        setTreatmentCheatsheet: (state, action) => {
            return action.payload
        },
    }
})

export const { setTreatmentCheatsheet } = treatmentCheatsheetSlice.actions;
export default treatmentCheatsheetSlice.reducer