import {createSlice} from "@reduxjs/toolkit";

const currentAppuserSlice = createSlice({
    name: 'currentAppuser',
    initialState: null,
    reducers: {
        setCurrentAppuser: (state, action) => {
            return action.payload;
        },
    }
})

export const { setCurrentAppuser  } = currentAppuserSlice.actions;
export default currentAppuserSlice.reducer