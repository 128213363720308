import React from 'react';
import { FiArrowLeftCircle } from "react-icons/fi";
import { Link } from "react-router-dom";

import Layout from '../layouts';

class Page extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            windowMinHeight: '100vh'
        }
    }

    render() {
        const { title, description, children, URL, gobackCallback } = this.props;

        return (
            <Layout>
                <div className="content-wrapper" style={{ minHeight: this.state.windowMinHeight, backgroundColor: '#eee', fontSize: 14 }}>
                    <section className="content-header" style={{padding: 10}}>
                        <div style={{ display: "flex", alignItems: "center", height: 30 }}>
                            {URL ?
                                <Link to={URL}>
                                    <FiArrowLeftCircle className="back-icon" />
                                </Link>
                                :
                                null
                            }
                            {
                                gobackCallback ? <FiArrowLeftCircle onClick={() => { gobackCallback() }} className="back-icon" /> : null
                            }
                            <h3 className="title-page"
                                style={{
                                    fontSize: "16px",
                                    marginTop: "0",
                                    marginBottom: "5px",
                                    color: "#333",
                                    fontFamily: "Roboto"
                                }}>
                                {title}
                            </h3>
                            {
                                description && description.length > 0 ? (
                                    <div style={{ flex: 1, display: "flex" }}>
                                        <div style={{ flex: 1 }}></div>
                                        <div>{description}</div>
                                    </div>
                                ) : null
                            }
                        </div>
                    </section>
                    <section className="content container-fluid">
                        {children}
                    </section>
                </div>
            </Layout>
        );
    }
}

export default Page;
