import bioApi from "../../api/bioApi";
import {
    APPOINTMENT_URL,
    CANCEL_APPOINTMENT,
    COMPLETE_APPOINTMENT,
    GET_STAFF_SCHEDULE_URL,
    LOCATION_LIST_URL,
    NO_SHOW_APPOINTMENT,
    RESCHEDULE_APPOINTMENT,
} from "../../api/URLs";
import {getAppointmentAvailableServices} from "../patient/service";
import {searchStaff} from "../staff/staff-search.service";

export const getAppointment = (params) => {
    const paramsObject = new URLSearchParams();

    params.forEach((dataObject) => {
        Object.keys(dataObject).forEach((key) => {
            paramsObject.append(key, dataObject[key]);
        });
    });
    const url = APPOINTMENT_URL + '?' + paramsObject.toString() ;
    return bioApi.get(url);
}

export const getLocation = () => {
    const url = LOCATION_LIST_URL;
    return bioApi.post(url);
}

export const generateTitle = (serviceName, patient, staff) => {
    return `${serviceName} - ${patient.firstName} ${patient.lastName} (${staff.firstName} ${staff.lastName})`;
}

export const getStaffSchedules = ({staff, location, service, timezone, date}) => {
    const paramsObject = new URLSearchParams();

    Object.entries({location, service, timezone, date}).forEach(([key, value]) => {
        if (value) {
            paramsObject.append(key, value);
        }
    });

    let url = GET_STAFF_SCHEDULE_URL.replace(':staffId', staff);
    url = url + '?' + paramsObject.toString();
    return bioApi.get(url);
}

export const rescheduleAppointment = (appointmentId, date, start, end, staffId, locationId, serviceId, timezone) => {
    const url = RESCHEDULE_APPOINTMENT;
    const payload = {
        appointmentId, date, start, end, staffId, locationId, serviceId, timezone
    };
    return bioApi.post(url, payload);
}

export const cancelAppointment = (appointmentId) => {
    const url = CANCEL_APPOINTMENT;
    const payload = {
        appointmentId
    };
    return bioApi.post(url, payload);
}

export const patientNotAppear = (appointmentId) => {
    const url = NO_SHOW_APPOINTMENT;
    const payload = {
        appointmentId
    };
    return bioApi.post(url, payload);
}

export const completeAppointment = (appointmentId) => {
    const url = COMPLETE_APPOINTMENT;
    const payload = {
        appointmentId
    };
    return bioApi.post(url, payload);
}

export const getEventClass = (event) => {
    const serviceType = event.service.type;
    let className = '';
    switch (serviceType) {
        case 'IV Lounge/Blood':
            className = 'green-event';
            break;
        case 'HRT Consults':
            className = 'blue-event';
            break;
        case 'Medical Aesthetics':
            className = 'purple-event';
            break;
        case 'Erectile Dysfunction':
            className = 'orange-event';
            break;
        case 'HCG Diet':
            className = 'yellow-event';
            break;
        default:
            className = '';
    }
    if (event.status === 'Canceled') {
        className = className + ' canceled-event'
    } else if (event.status === 'Rescheduled') {
        className = className + ' rescheduled-event'
    } else if (event.status === 'PatientAbsents') {
        className = className + ' no-show-event'
    } else if (event.status === 'Completed') {
        className = className + ' completed-event'
    }

    return className;
}

export const calendarEssentialDataInitializer = async (setLocationOptions, setProviders, setProvidingServices) => {
    const locationOptions = [];
    const locationInformation = await getLocation();
    const services = await getAppointmentAvailableServices();
    const providers = await searchStaff('role', 'provider');

    if (locationInformation.data.data) {
        locationInformation.data.data.forEach(location => {
            locationOptions.push({
                value : location.stationId,
                label : `${location.name}, ${location.address.state}`,
                name: location.name
            })
        });
    }
    setLocationOptions(locationOptions);
    setProviders(providers.data);
    setProvidingServices(services.data.data);
}