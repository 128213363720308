import React, { useEffect, useState } from "react";
import "./appointment-calendar.css";
import { Button, Select } from "antd";
import { BsCalendar } from "react-icons/bs";
import {
  calendarEssentialDataInitializer,
  generateTitle,
  getAppointment,
  getEventClass,
} from "./service";
import NewAppointmentModal from "./new-appointment.modal";
import AppointmentCalendarComponent from "./appointment-calendar.component";
import moment from "moment";
import "moment-timezone";
import { AccessRight } from "../../components/guarded-route/guarded-routes";
import { ApplicationRight } from "../../share/RightList";

const AppointmentCalendarByLocationPage = () => {
  const timezone = "America/New_York";
  moment.tz.setDefault(timezone);

  const [selectedLocation, setSelectedLocation] = useState(
    "biostation-west-delray-beach-0",
  );
  const [locationOptions, setLocationOptions] = useState([]);
  const [events, setEvents] = useState(null);
  const [providingServices, setProvidingServices] = useState([]);
  const [providers, setProviders] = useState([]);
  const [showNewAppointmentWindow, setShowNewAppointmentWindow] =
    useState(false);

  const checkIfLocationIsSameAfterReschedule = (
    oldAppointment,
    newAppointment,
  ) => oldAppointment.location.stationId === newAppointment.location.stationId;

  useEffect(() => {
    if (selectedLocation) {
      (async () => {
        const appointmentsInTheLocation = await getAppointment([
          { location: selectedLocation },
        ]);
        const allData = [];

        if (appointmentsInTheLocation.data?.data.appointments) {
          appointmentsInTheLocation.data.data.appointments.forEach(
            (appointment) => {
              allData.push({
                end: moment(appointment.end).toDate(),
                start: moment(appointment.start).toDate(),
                title: generateTitle(
                  appointment.service.serviceName,
                  appointment.patient,
                  appointment.staff,
                ),
                className: getEventClass(appointment),
                type: "appointment",
                appointment,
              });
            },
          );
        }
        setEvents(allData);
      })();
    }
  }, [selectedLocation]);

  useEffect(() => {
    (async () =>
      calendarEssentialDataInitializer(
        setLocationOptions,
        setProviders,
        setProvidingServices,
      ))();
  }, []);

  return (
    <div className="appointment-management">
      <div className="d-flex justify-content-between m-3">
        <div className="d-flex align-items-center">
          <span
            style={{
              padding: 6,
              color: "white",
              background: "#5284C2",
              borderRadius: 4,
            }}
          >
            <BsCalendar
              style={{ fontSize: 18, position: "relative", top: -2 }}
            />
          </span>
          <span
            style={{
              fontSize: 18,
              marginLeft: 8,
              marginRight: 16,
              fontWeight: 700,
            }}
          >
            Appointment By Location
          </span>
          <Select
            value={selectedLocation}
            style={{ width: 350 }}
            onChange={(value) => setSelectedLocation(value)}
            options={locationOptions}
          />
        </div>
        <div>
          {AccessRight(ApplicationRight.Patient_Creating) && <Button
            type="primary"
            onClick={() => setShowNewAppointmentWindow(true)}
          >
            + New Appointment
          </Button>}
        </div>
      </div>
      {events && (
        <AppointmentCalendarComponent
          appointmentEvents={events}
          providers={providers}
          timezone={timezone}
          locationOptions={locationOptions}
          providingServices={providingServices}
          generateEventTitle={generateTitle}
          checkAfterReschedule={checkIfLocationIsSameAfterReschedule}
        />
      )}
      {showNewAppointmentWindow && (
        <NewAppointmentModal
          show={showNewAppointmentWindow}
          locations={locationOptions}
          defaultLocation={selectedLocation}
          services={providingServices}
          onFinish={() => setShowNewAppointmentWindow(false)}
        />
      )}
    </div>
  );
};

export default AppointmentCalendarByLocationPage;
