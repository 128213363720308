import React from "react";
import {Avatar, Typography} from "antd";
import {Link} from "react-router-dom";
import {CiAlarmOn, CiLocationOn, CiMedicalMask, CiStickyNote} from "react-icons/ci";
import moment from "moment/moment";

const AppointmentDetail = ({appointment, timezone}) => {
    moment.tz.setDefault(timezone);

    return (
        <div>
            <h5>{appointment.service.serviceName}</h5>
            <hr/>
            <div>
                <div>
                    <CiMedicalMask size={20} style={{marginRight: 5, top: -1, position: "relative"}}/>
                    <Typography.Text underline>
                        <Link to={`/patient/${appointment.patient.patientId}/appointment`}
                              style={{color: "black"}}>
                            {`${appointment.patient.firstName} ${appointment.patient.lastName}`}
                        </Link>
                    </Typography.Text>
                </div>
                <div>
                    <CiAlarmOn size={20} style={{marginRight: 5, top: -1, position: "relative"}}/>
                    <Typography.Text type="secondary">
                        {moment(appointment.start).format("MMMM Do, YYYY | HH:mma") + ` - ` + moment(appointment.end).format("HH:mma")}
                    </Typography.Text>
                </div>
                <div>
                    <CiLocationOn size={20} style={{marginRight: 5, top: -1, position: "relative"}}/>
                    <Typography.Text type="secondary">
                        {appointment.location.name}
                    </Typography.Text>
                </div>
            </div>
            <hr/>
            <div className="d-flex align-items-center">
                <Avatar shape={"square"} src={appointment.staff.coverImage}
                        style={{marginRight: 8}}/>
                <Typography.Text strong underline>
                    <Link to={`/appointment-management/by-provider?staffId=${appointment.staff.staffId}`}
                          style={{color: "black"}}>
                        {`${appointment.staff.firstName} ${appointment.staff.lastName}`}</Link>
                </Typography.Text>
            </div>
            {
                appointment.note &&
                <>
                    <hr/>
                    <div>
                        <CiStickyNote size={20} style={{marginRight: 5, top: -1, position: "relative"}}/>
                        <Typography.Text>
                            {appointment.note}
                        </Typography.Text>
                    </div>
                </>
            }
        </div>
    )
}

export default AppointmentDetail;