import React from 'react';
import { CloseButton, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import BoxLoader from '../loading/BoxLoader'

const ConfirmModal = (props) => {

    const { modalOption } = props;
    const { 
        title,
        message,
        buttonConfirmText,
        onConfirm,
        onCancel
    } = modalOption.option

    const closeModal = () => {
        if(onCancel) onCancel()
    }

    return (
        <Modal show={modalOption.show} onHide={closeModal}>
            <BoxLoader show={modalOption.loading} />
            <Modal.Header padding={0} style={{ padding: 10, display: 'flex', justifyContent: 'space-between' }}>
                <h3 className="modal-title text-center">{title}</h3>
                <CloseButton onClick={closeModal} />
            </Modal.Header>
            <Modal.Body>
                <h5 className="text-center"> {message} </h5>
                <br/>
                <br/>
                <div className={"modal-footer-btn"}>
                    <button
                        onClick={onConfirm}
                        className="btn btn-blue btn-md btn-block">
                        {buttonConfirmText ? buttonConfirmText : 'Yes, Confirm'}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ConfirmModal;

export class CSLConfirmModal extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: false,
            loading: false,
            option: {
                title: '',
                message: '',
                buttonConfirmText: '',
                onConfirm: null,
                onCancel: null
            }
        }
    }

    closeModal() {
        this.setState({
            show: false
        })
        if(this.state.option.onCancel) {
            this.state.option.onCancel()
        }
    }

    openModal(option) {
        this.setState({
            show: true,
            option
        })
    }

    showLoading(show) {
        this.setState({
            loading: show
        })
    }

    render() {
        const { 
            title,
            message,
            buttonConfirmText,
            onConfirm
        } = this.state.option

        return (
            <Modal show={this.state.show} onHide={this.closeModal.bind(this)}>
                <BoxLoader show={this.state.loading} />
                <Modal.Header padding={0} style={{ padding: 10, display: 'flex', justifyContent: 'space-between' }}>
                    <h3 className="modal-title text-center">{title}</h3>
                    <CloseButton onClick={this.closeModal.bind(this)} />
                </Modal.Header>
                <Modal.Body>
                    <h5 className="text-center"> {message} </h5>
                    <br/>
                    <br/>
                    <div className={"modal-footer-btn"}>
                        <button
                            onClick={onConfirm}
                            className="btn btn-blue btn-md btn-block">
                            {buttonConfirmText ? buttonConfirmText : 'Yes, Confirm'}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}