import React from 'react';
import { CSLConfirmModal } from '../modal/ConfirmModal';
import AlertModal from '../modal/AlertModal';

export const WithBio = (WrappedComponent) => {
    class MyComponent extends React.Component {

        constructor(props) {
            super(props)

            this.confirmModalRef = null
            this.alertModalRef = null
        }

        showConfirm(option) {
            this.confirmModalRef.openModal(option)
        }

        hideConfirm() {
            this.confirmModalRef.closeModal()
        }

        showConfirmLoading(show) {
            this.confirmModalRef.showLoading(show)
        }

        hideAlert() {
            this.alertModalRef.hide()
        }

        showAlert(title, onAlertDissimiss) {
            this.alertModalRef.show({
                message: title, 
                autoDismiss: true,
                onAlertDissimiss
            })
        }

        showAlertSuccess(title, onAlertDissimiss) {
            this.alertModalRef.show({
                message: title, 
                type: 'success',
                autoDismiss: true,
                onAlertDissimiss
            })
        }

        showAlertError(title, onAlertDissimiss) {
            this.alertModalRef.show({
                message: title, 
                type: 'error',
                autoDismiss: true,
                onAlertDissimiss
            })
        }

        showAlertWarning(title, onAlertDissimiss) {
            this.alertModalRef.show({
                message: title, 
                type: 'warning',
                autoDismiss: true,
                onAlertDissimiss
            })
        }

        showHttpError(err) {
            this.alertModalRef.showHttpError(err)
        }

        render() {
            const { forwardedRef } = this.props
            return <>
                <CSLConfirmModal ref={ref => {
                    this.confirmModalRef = ref
                }} />
                <AlertModal ref={ref=> {
                    this.alertModalRef = ref
                }}/>
                <WrappedComponent
                    ref={forwardedRef}
                    showConfirm={this.showConfirm.bind(this)} 
                    showConfirmLoading={this.showConfirmLoading.bind(this)}
                    hideConfirm={this.hideConfirm.bind(this)}
                    hideAlert={this.hideAlert.bind(this)}
                    showAlert={this.showAlert.bind(this)}
                    showAlertSuccess={this.showAlertSuccess.bind(this)}
                    showAlertError={this.showAlertError.bind(this)}
                    showHttpError={this.showHttpError.bind(this)}
                    showAlertWarning={this.showAlertWarning.bind(this)}
                    {...this.props} 
                />
            </>
        }
    };

    return React.forwardRef((props, ref) => {
        return <MyComponent {...props} forwardedRef={ref} />;
    })
  }