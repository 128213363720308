import React from 'react'
import { Button } from 'react-bootstrap';
import calendarIcon from '../../assets/images/rili.png';

const DateInput = React.forwardRef(({value, width, error, onClick}, ref) => {
    return <div className="input-date" style={{width: width}}>
        <Button variant="outline-primary" style={{ width: '100%', height: 38, marginBottom: 0, display: 'flex', justifyContent: 'space-between', textAlign: 'left'}} ref={ref} onClick={onClick} className={"form-control clickable input-sm" + (error ? ' input-error' : '')}>
            {value && value !== '' ? <label>{value}</label> : <label>&nbsp;&nbsp;</label>}
            <div style={{flex: 1}}></div>
            <img alt="" src={calendarIcon} style={{margin: 'auto'}} />
        </Button>
    </div>
})

export default DateInput