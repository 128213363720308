import axios from "axios";
import { LOGIN_URL } from "./URLs";
import { setAuthenticatedUser } from "../stores/authenticated-user.store";
import store from "../stores";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_REV,
  withCredentials: true,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const response = error.response;
    if (
      response &&
      response.config.url !== LOGIN_URL &&
      response.status === 401
    ) {
      store.dispatch(setAuthenticatedUser(null));
      window.location = "/login";
    }
    return Promise.reject(error);
  },
);

export default instance;
