import React from 'react';
import { CloseButton, InputGroup, FormControl, Modal, Button, Form } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { BsSearch } from 'react-icons/bs'

import bioApi from '../../api/bioApi';
import BoxLoader from '../loading/BoxLoader';
import { LOCATION_SHORT_LIST } from '../../api/URLs';

import '../../styles/station.css';

function checkSelectedAll(state) {
    var selectRegion = state.selectRegion;
    var plasmaData = state.plasmaData;
    var checked = state.checked;
    var stateChecked = state.stateChecked;
    var currentCityChecked = state.currentCity.checked;
    var searchParam = state.searchParam;
    if (!selectRegion) {
        return false;
    }
    if (selectRegion.selectAll === true || !selectRegion.ids) {
        return false;
    }
    if (Object.keys(checked).length === 0) {
        return false;
    }
    for (var i = 0; i < plasmaData.length; i++) {
        var item = plasmaData[i];
        if (item.status === 'inactive') {
            continue;
        }
        if (selectRegion.ids.indexOf(item.stationId) > -1) {
            continue;
        }
        if (stateChecked !== 'State') {
            if (stateChecked !== item.address.state) {
                continue;
            }
        }
        if (currentCityChecked !== 'City') {
            if (currentCityChecked !== item.address.city) {
                continue;
            }
        }
        if (searchParam !== '') {
            var lowname = item.name.toLowerCase();
            var searchName = searchParam.toLowerCase();
            if (lowname.indexOf(searchName) < 0) {
                continue;
            }
        }
        if (checked[i + '']) {
            continue;
        } else {
            return false;
        }
    }
    return true;
}

function getCenterName(centerArray, centerid) {
    for (var i = 0; i < centerArray.length; i++) {
        var item = centerArray[i];
        if (item.stationId === centerid) {
            return item.name;
        }
    }
    return '';
}

class RegionLevelModal extends React.Component {
    constructor(props) {
        super(props);

        this.handleShow = this.handleShow.bind(this);
        this.handleGetInformation = this.handleGetInformation.bind(this);

        this.state = {
            searchValue: '',
            searchParam: '',
            currentCity: {},
            stateDict: {},
            stateChecked: 'State',
            loading: false,
            checked: {},
            plasmaData: [],
            regionLevelArray: ['Region', "SubRegion", "Center"]
        };
    }

    handleShow() {
        this.setState({
            loading: true
        })
        var pathstring = LOCATION_SHORT_LIST;
        bioApi.post(pathstring).then(response => {
            if (response.data.status !== 200) {
                this.setState({
                    loading: false
                })
                alert(response.data.message);
            } else {
                this.handleGetInformation(response.data.data);
            }
        }).catch((err) => {
            this.setState({ loading: false })
            alert('Network error');
        })
    }

    handleGetInformation(plasmaData) {
        var stateDict = { 'State': { checked: 'City', City: 'City' } };
        var locationids = [];
        for (var i = 0; i < plasmaData.length; i++) {
            var item = plasmaData[i];
            locationids.push(item.stationId);
            var currentState = stateDict[item.address.state];
            if (currentState === undefined) {
                stateDict[item.address.state] = { checked: 'City', City: 'City' };
                stateDict[item.address.state][item.address.city] = item.address.city;
            } else {
                stateDict[item.address.state][item.address.city] = item.address.city;
            }
            stateDict['State'][item.address.city] = item.address.city;
        }
        this.setState({
            stateChecked: 'State',
            loading: false,
            checked: {},
            plasmaData: plasmaData,
            currentCity: stateDict['State'],
            stateDict: stateDict,
            selectRegion: this.props.selectRegion ? {...this.props.selectRegion} : {selectAll: false, ids: []}
        });
    }

    render() {
        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onShow={this.handleShow}
                show={this.props.show}
                onHide={this.props.onClose}
            >
                <Modal.Header padding={0} style={{ padding: 10, display: 'flex', justifyContent: 'space-between' }}>
                    <h4 className="modal-title text-center">Select Station</h4>
                    <CloseButton onClick={this.props.onClose} />
                </Modal.Header>
                <Modal.Body padding={0} style={{ padding: 20, paddingTop: 0 }}>
                    <BoxLoader show={this.state.loading} />
                    <table className="table table-target-audience" style={{ marginBottom: 0, fontSize: 14 }}>
                        <tbody>
                            <tr style={{ borderBottom: "4px solid #eee" }}>
                                <td style={{ width: "30%" }}>
                                    <span>Selected&#8758; &nbsp;</span>
                                </td>
                                <td>
                                    <div className="col-md-9" style={{ width: "100%", overflowY: "scroll", maxHeight: 72 }}>
                                        {
                                            this.state.selectRegion && this.state.selectRegion.ids.length > 0 ? (
                                                <div style={{ marginBottom: '1rem' }}>
                                                    {
                                                        this.state.selectRegion.ids.map((item, index) => {
                                                            return <div className={"vselected"} key={'fatimes' + index}>
                                                                <span>{getCenterName(this.state.plasmaData, item)}</span>
                                                                <FaTimes className={"fa-times"} onClick={() => {
                                                                    var selectRegion = { ...this.state.selectRegion };
                                                                    var formData = selectRegion.ids;
                                                                    formData.splice(index, 1);
                                                                    if (formData.length === 0) {
                                                                        selectRegion.selectAll = false;
                                                                        selectRegion.ids = [];
                                                                    } else {
                                                                        selectRegion.selectAll = false;
                                                                        selectRegion.ids = formData;
                                                                    }
                                                                    this.setState({
                                                                        selectRegion: selectRegion
                                                                    })
                                                                }} />
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            ) : (
                                                this.state.selectRegion && this.state.selectRegion.selectAll === true ? (
                                                    <div style={{ marginBottom: '1rem' }}>
                                                        {
                                                            this.state.plasmaData.map((item, index) => {
                                                                return <div className={"vselected"} key={'fatimes' + index}>
                                                                    {item.name}
                                                                    <FaTimes className={"fa-times"} onClick={() => {
                                                                        var selectedIds = [];
                                                                        for (var i = 0; i < this.state.plasmaData.length; i++) {
                                                                            selectedIds.push(this.state.plasmaData[i].stationId);
                                                                        }
                                                                        selectedIds.splice(index, 1);
                                                                        var selectRegion = {...this.state.selectRegion}
                                                                        if (selectedIds.length === 0) {
                                                                            selectRegion.selectAll = false;
                                                                            selectRegion.ids = [];
                                                                        } else {
                                                                            selectRegion.selectAll = false;
                                                                            selectRegion.ids = selectedIds;
                                                                        }
                                                                        this.setState({
                                                                            selectRegion: selectRegion
                                                                        })
                                                                    }} />
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                ) : null
                                            )
                                        }
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Station List&#8758; &nbsp;</td>
                                <td style={{ display: "flex" }}>
                                    <span style={{ marginTop: 'auto', marginBottom: 'auto' }}>USA</span>
                                    <select style={{ marginLeft: 5, marginTop: 'auto', marginBottom: 'auto', height: 28, flex: 1 }} onChange={(event) => {
                                        var keyvalue = event.target.value;
                                        this.setState({
                                            currentCity: {
                                                ...this.state.stateDict[keyvalue],
                                                checked: 'City'
                                            },
                                            stateChecked: keyvalue,
                                        })
                                    }}>
                                        {
                                            this.state.stateDict && Object.keys(this.state.stateDict).map((sitem, index) => {
                                                return <option key={'state' + index} value={sitem} selected={sitem === this.state.stateChecked}>{sitem}</option>
                                            })
                                        }
                                    </select>
                                    <select style={{ marginLeft: 5, marginTop: 'auto', marginBottom: 'auto', height: 28, flex: 1 }} onChange={(event) => {
                                        this.setState({
                                            currentCity: { ...this.state.currentCity, checked: event.target.value }
                                        })
                                    }}>
                                        {
                                            this.state.currentCity && Object.keys(this.state.currentCity).map((citem, index) => {
                                                if (citem === 'checked') {
                                                    return null
                                                }
                                                return <option key={'city' + index} value={citem} selected={citem === this.state.currentCity.checked}>{citem}</option>
                                            })
                                        }
                                    </select>
                                    <InputGroup style={{ marginLeft: 5, marginTop: 'auto', marginBottom: 'auto', flex: 1 }}>
                                        <FormControl
                                            size="sm"
                                            placeholder="Please input center name"
                                            aria-label="Please input center name"
                                            aria-describedby="basic-addon2"
                                            value={this.state.searchValue}
                                            onChange={(event) => {
                                                this.setState({
                                                    searchValue: event.target.value
                                                })
                                            }}
                                        />
                                        <Button size="sm" variant="outline-secondary" id="button-addon2" onClick={() => {
                                            this.setState({
                                                searchParam: this.state.searchValue,
                                            })
                                        }}>
                                            <BsSearch />
                                        </Button>
                                    </InputGroup>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <div style={{ display: "flex", lineHeight: "24px", fontWeight: "bold", borderBottom: "1px solid #eee" }}>
                                        <div style={{ width: "16%" }}>
                                            <Form.Check
                                                checked={
                                                    checkSelectedAll(this.state)
                                                }
                                                label={'No.'}
                                                onClick={() => {
                                                    if (checkSelectedAll(this.state)) {
                                                        this.setState({
                                                            checked: {}
                                                        })
                                                    } else {
                                                        var checkeddict = { ...this.state.checked };
                                                        for (var i = 0; i < this.state.plasmaData.length; i++) {
                                                            var item = this.state.plasmaData[i];
                                                            if (item.status === 'inactive') {
                                                                continue;
                                                            }
                                                            if (this.state.selectRegion.ids.indexOf(item.stationId) > -1) {
                                                                continue;
                                                            }
                                                            if (this.state.stateChecked !== 'State') {
                                                                if (this.state.stateChecked !== item.address.state) {
                                                                    continue;
                                                                }
                                                            }
                                                            if (this.state.currentCity.checked !== 'City') {
                                                                if (this.state.currentCity.checked !== item.address.city) {
                                                                    continue;
                                                                }
                                                            }
                                                            if (this.state.searchParam !== '') {
                                                                var lowname = item.name.toLowerCase();
                                                                var searchName = this.state.searchParam.toLowerCase();
                                                                if (lowname.indexOf(searchName) < 0) {
                                                                    continue;
                                                                }
                                                            }
                                                            if (!checkeddict[i + '']) {
                                                                checkeddict[i + ''] = true;
                                                            }
                                                        }

                                                        this.setState({
                                                            checked: checkeddict
                                                        })
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div style={{ width: "28%" }}>State</div>
                                        <div style={{ width: "28%" }}>City</div>
                                        <div style={{ width: "28%" }}>Center</div>
                                    </div>
                                    <div style={{ marginBottom: 0, height: 120, overflow: "scroll" }}>
                                        {
                                            this.state.plasmaData.length > 0 && this.state.plasmaData.map((item, index) => {
                                                if (item.status === 'inactive') {
                                                    return null;
                                                }
                                                if (this.state.selectRegion && this.state.selectRegion.selectAll === true) {
                                                    return null;
                                                }
                                                if (this.state.selectRegion && this.state.selectRegion.ids.indexOf(item.stationId) > -1) {
                                                    return null;
                                                }
                                                if (this.state.stateChecked !== 'State') {
                                                    if (this.state.stateChecked !== item.address.state) {
                                                        return null;
                                                    }
                                                }
                                                if (this.state.currentCity.checked !== 'City') {
                                                    if (this.state.currentCity.checked !== item.address.city) {
                                                        return null;
                                                    }
                                                }
                                                if (this.state.searchParam !== '') {
                                                    var lowname = item.name.toLowerCase();
                                                    var searchName = this.state.searchParam.toLowerCase();
                                                    if (lowname.indexOf(searchName) < 0) {
                                                        return null;
                                                    }
                                                }
                                                return <div style={{ display: "flex", lineHeight: "24px" }} key={index + 'region'}>
                                                    <div style={{ width: "16%" }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={this.state.checked[index + ''] || false}
                                                            onChange={() => { }}
                                                            onClick={() => {
                                                                var checkeddict = this.state.checked;
                                                                if (checkeddict[index + '']) {
                                                                    delete checkeddict[index + ''];
                                                                } else {
                                                                    checkeddict[index + ''] = true;
                                                                }
                                                                this.setState({
                                                                    checked: checkeddict
                                                                })
                                                            }} />
                                                        <span style={{ marginLeft: "5px" }}>{index + 1}</span>
                                                    </div>
                                                    <div style={{ width: "28%", overflowX: "hidden" }}>{item.address.state}</div>
                                                    <div style={{ width: "28%", overflowX: "hidden" }}>{item.address.city}</div>
                                                    <div style={{ width: "28%", overflowX: "hidden" }}>{item.name}</div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <div style={{ display: 'flex' }}>
                                        <Button
                                            variant='outline-primary'
                                            className="btn btn-border-ouline btn-md"
                                            style={{ paddingTop: "2px", paddingBottom: "2px", width: "auto", margin: 'auto' }}
                                            onClick={() => {
                                                if (Object.keys(this.state.checked).length > 0) {
                                                    var selectRegion = this.state.selectRegion;
                                                    var formData = selectRegion.ids;
                                                    for (var index in this.state.checked) {
                                                        var item = this.state.plasmaData[index];
                                                        if (formData.indexOf(item.stationId) < 0) {
                                                            formData.push(item.stationId);
                                                        }
                                                    }
                                                    if (formData.length === 0 || formData.length === this.state.plasmaData.length) {
                                                        selectRegion.selectAll = true;
                                                        selectRegion.ids = [];
                                                    } else {
                                                        selectRegion.selectAll = false;
                                                        selectRegion.ids = formData;
                                                    }

                                                    this.setState({
                                                        selectRegion: selectRegion,
                                                        checked: {}
                                                    })
                                                } else {
                                                    alert('Please select ' + this.state.regionLevelArray[this.props.regionLevel])
                                                }
                                            }}>
                                            Add selected
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <div style={{ display: 'flex' }}>
                                        <Button
                                            variant='primary'
                                            className="btn btn-border-ouline btn-md"
                                            style={{ paddingTop: "2px", paddingBottom: "2px", width: "auto", margin: 'auto' }}
                                            onClick={() => {
                                                var selectRegion = this.state.selectRegion;
                                                this.props.onConfirm(selectRegion, this.state.plasmaData);
                                            }}>
                                            Confirm
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>
        )
    }
};

export default RegionLevelModal