import bioApi from "../../api/bioApi";
import {
    ADD_STAFF_SCHEDULE_URL,
    STAFF_CREATE_URL,
    STAFF_DELETE_URL,
    STAFF_DETAIL_URL,
    STAFF_UPDATE_URL
} from "../../api/URLs";

export const createStaff = (staff) => {
    return bioApi.post(STAFF_CREATE_URL, staff);
}

export const getStaffByid = (staffId) => {
    const url = STAFF_DETAIL_URL.replace(":id", staffId);
    return bioApi.get(url);
}

export const deleteStaff = (staffId) => {
    const url = STAFF_DELETE_URL.replace(":id", staffId);
    return bioApi.delete(url);
}

export const updateStaff = (staffId, staff) => {
    const url = STAFF_UPDATE_URL.replace(":id", staffId);
    return bioApi.put(url, staff);
}

export const submitSchedule = (staffId, schedules, timezone) => {
    return bioApi.post(ADD_STAFF_SCHEDULE_URL, {staffId, schedules, timezone});
}