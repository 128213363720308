import React, { useState } from "react";
import "./AuthenPage.css";
import { Link, useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { message, Spin } from "antd";
import bioApi from "../../api/bioApi";
import { LOGIN_URL } from "../../api/URLs";
import { useDispatch } from "react-redux";
import { setAuthenticatedUser } from "../../stores/authenticated-user.store";

const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [loginData, setLoginData] = useState({
    login: "",
    password: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleHide = () => {
    setIsShow(!isShow);
  };

  const validation = () => {
    if (!loginData.login) {
      return true;
    }
    return !loginData.password;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    if (validation()) {
      return false;
    }
    setLoading(true);
    const loginPayload = {
      login: loginData.login,
      password: loginData.password,
    };
    await bioApi
      .post(LOGIN_URL, loginPayload)
      .then((res) => {
        setLoading(false);

        if (res.status === 200) {
          dispatch(setAuthenticatedUser(res.data));
          navigate("/");
        } else {
          message.error(res.message);
        }
      })
      .catch((err) => {
        message.error(err.message);
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        borderTop: `6px solid #3f86c7`,
        backgroundColor: "white",
        borderRadius: "4px",
        overflow: "hidden",
        boxShadow: "0px 3px 10px #ddd",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "8rem",
        marginBottom: "8rem",
      }}
      className="auth_body"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: "32px",
          marginTop: "32px",
        }}
      >
        <Link
          to="/login"
          style={{
            fontWeight: `700`,
            fontSize: "24px",
            marginBottom: "0",
            color: "black",
          }}
        >
          LOGIN
        </Link>
      </div>
      <form
        onSubmit={handleSubmit}
        style={{
          width: "100%",
          height: "100%",
          padding: "32px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
          }}
        >
          <>
            <label
              style={{
                marginBottom: "8px",
                fontWeight: "700",
                fontSize: "14px",
                marginTop: "18px",
              }}
            >
              Username / Staff ID / Email
            </label>
            <input
              value={loginData.login}
              onChange={(e) => {
                setLoginData({
                  ...loginData,
                  login: e.target.value,
                });
              }}
              className={`form-control ${
                isSubmit && !loginData.login ? "is-invalid" : ""
              }`}
              style={{
                height: "50px",
                marginBottom: "5px",
              }}
            />
            <div className="invalid-feedback">
              {isSubmit && !loginData.login ? "The field is require!" : ""}
            </div>
          </>
          <div>
            <label
              style={{
                marginBottom: "8px",
                fontWeight: "700",
                fontSize: "14px",
                marginTop: "18px",
              }}
            >
              Password
            </label>
            <div style={{ position: "relative" }}>
              <input
                type={isShow ? "text" : "password"}
                className={`form-control ${
                  isSubmit && !loginData.password ? "is-invalid" : ""
                }`}
                style={{
                  height: "50px",
                }}
                value={loginData.password}
                onChange={(e) => {
                  setLoginData({
                    ...loginData,
                    password: e.target.value,
                  });
                }}
              />
              <div className="invalid-feedback">
                {isSubmit && !loginData.password ? "The field is require!" : ""}
              </div>
              {isSubmit && !loginData.password ? (
                ""
              ) : (
                <div
                  onClick={() => handleHide()}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    margin: "15px 15px",
                    cursor: "pointer",
                  }}
                >
                  {isShow ? (
                    <AiFillEye size={24} />
                  ) : (
                    <AiFillEyeInvisible size={24} />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <button
          className="button_auth button_body"
          style={{ marginTop: "32px" }}
          onSubmit={() => handleSubmit()}
          disabled={loading}
        >
          <div
            style={{
              fontWeight: "800",
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              color: "white",
            }}
          >
            {loading && <Spin style={{ marginRight: 10 }} />}
            LOGIN
          </div>
        </button>
      </form>
    </div>
  );
};

export default LoginPage;
