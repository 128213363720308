import React, { useState } from 'react';
import { Button, Modal, Timeline } from 'antd';
import moment from 'moment';

const HistoryOrderModal = ({ selectedCarrier, orderHistory}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
  return (
    <>
      <Button onClick={showModal}>
        Track Order
      </Button>
        <Modal 
            title="Tracking Information" 
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
            footer={[
                <Button type='primary' onClick={handleOk}>
                  OK
                </Button>
            ]}
        >
        <Timeline>
        {
            orderHistory.map((order,i) => (  
                <Timeline.Item key={i} color={i === 0 ? 'blue' : 'gray'} >
                    <p>{moment(order.date).format('MMMM Do YYYY, h:mm:ss a')}</p>
                    <p>{order.derivedStatus}</p>
                    {
                      selectedCarrier == 'usps' ?
                      <>
                         <p>{order.location}</p>
                      </> :
                      <>
                        <p>{order.eventDescription}</p>
                        <p>{order.scanLocation.streetLines[0] != '' ? order.scanLocation.streetLines[0] + ',' : ''} {order.scanLocation.city} {order.scanLocation.countryName} {order.scanLocation.postalCode}</p>
                      </>
                    }
                </Timeline.Item> 
            ))
        }   
        </Timeline>
      </Modal>
    </>
  );
};

export default HistoryOrderModal;