import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { message,Button, Form, Input, Select,DatePicker,Spin,AutoComplete,Modal } from 'antd';
import { listAllLocations,createPatient } from '../patient/patient-search.service';
import './patient-create.css';
import StatesList from '../../share/StatesList'
import { getPropsectPatientOptions,compareProspectPatient } from './patient-search.service';
import { ExclamationCircleFilled } from '@ant-design/icons';

const { confirm } = Modal;
const { Option } = Select;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    span: 16,
  },
};
  
const PatientCreatePage = () => {
    const [form] = Form.useForm();
    const [loading,setLoading] = React.useState(false);
    const [locations,setLocations] = React.useState(null);
    const [values,setValues] = React.useState(null);
    const [messageApi, contextHolder] = message.useMessage();
    const [searchOptions,setSearchOptions] = React.useState([]);
    const onFinish = async (values) => {
        setLoading(true);
        setValues(values);
        await compareData(values);
    };

    const showConfirm = () => {
        confirm({
          title: 'Do you want to proceed?',
          icon: <ExclamationCircleFilled />,
          content: 'Provided Patient Data is not match with Prospect Patient Data, proceed would override data',
          onOk() {
            submitNewPatient(values);
          },
          onCancel() {
              setLoading(false);
          },
        });
    };

    const handleSearchContent = (value) => {
        showOptions(value);
    };

    const compareData = async (values) => {
        if (values.prospectID) {
            const patientDataValid = await compareProspectPatient(values);
            if (patientDataValid.data.notfound){
                messageApi.open({
                    type: 'error',
                    content: 'Prospect Patient not found, please remove it before proceed'
                });
                setLoading(false);
            } else if (!patientDataValid.data.valid && !patientDataValid.data.notfound) {
                showConfirm();
            } else {
                submitNewPatient(values);
            }
        } else {
            submitNewPatient(values);
        }
    }

    const showOptions = async (value) => {
        if (value != '') {
            const allOptions = await getPropsectPatientOptions(value)
            setSearchOptions(allOptions.data.data);
        } else {
            setSearchOptions([]);
        }
    }

    React.useEffect(() => {
        fetchLocations();
    }, [])

    const submitNewPatient = async (data) => {
        try {
            const response = await createPatient(data);
            if (response.data.status !== 200) {
                messageApi.open({
                    type: 'success',
                    content: 'Patient has been created!',
                });
                form.resetFields();
            } else {
                messageApi.open({
                    type: 'error',
                    content: 'Something is wrong, please try it again later.'
                });
            }
        } catch (err) {
            messageApi.open({
                type: 'error',
                content: 'Something is wrong, please try it again later. Please make sure email is not duplicate.'
            });
        }
        setLoading(false);
    }
    const fetchLocations = async () => {
        try {
            setLoading(true);
            const response = await listAllLocations();
            if (response.data.status !== 200) {
                alert(response.data.message);
            } else {
                setLocations(response.data.data);
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };
    
    return (
        <>
        {contextHolder}
        <div style={{ marginTop: '60px', background: '#F0F2F5 0% 0% no-repeat padding-box', padding: 32 }}>
        <h2>Create New Patient</h2>
        <br />
        <Spin spinning={loading}>
            <Form
            {...layout}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            style={{
                maxWidth: 600,
            }}
            className="form_"
            size="large"
            >
            <div className="form_content_">
            <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                {
                    required: true,
                },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                {
                    required: true,
                },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name="dob"
                label="Date of Birth"
                rules={[
                {
                    required: true,
                },
                ]}
            >
                <DatePicker />
            </Form.Item>
            <Form.Item
                name="gender"
                label="Gender"
                rules={[
                {
                    required: true,
                },
                ]}
            >
                <Select
                placeholder="Select a gender"
                >
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
                <Option value="other">Other</Option>
                </Select>
            </Form.Item>
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.gender !== currentValues.gender}
            >
                {({ getFieldValue }) =>
                getFieldValue('gender') === 'female' ? (
                    <Form.Item
                        name="cycle"
                        label="Cycle"
                        rules={[
                        {
                            required: true,
                        },
                        ]}
                    >
                        <Select
                        placeholder="Select cycle options"
                        >
                        <Option value="luteal">Luteal</Option>
                        <Option value="menopause">Menopause</Option>
                        <Option value="birth_control">Birth control</Option>
                        </Select>
                    </Form.Item>
                ) : null
                }
            </Form.Item>
            <Form.Item
                name="phone"
                label="Phone Number"
                rules={[
                {
                    required: true,
                    pattern: new RegExp(/^[0-9]+$/),
                    message: 'Zipcode must be digit.' 
                },{ min: 10,max:10, message: 'Phone Number must be 10 digit.' },
                ]}
            >
                <Input addonBefore="+1" />
            </Form.Item>
            <Form.Item
                name="email"
                label="Email"
                rules={[
                {
                    type: 'email',
                    message: 'Email is not a valid E-mail.',
                },
                {
                    required: true,
                },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name="address_line_1"
                label="Address line 1"
                rules={[
                {
                    required: true,
                },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name="address_line_2"
                label="Address line 2"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name="city"
                label="City"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name="state"
                label="State"
                rules={[
                {
                    required: true,
                },
                ]}
            >
                 <Select
                placeholder="Select a state"
                >
                {StatesList && StatesList.map((state,index) => (
                    <option key={index} value={state.key}>{state.name}</option>
                ))}
                </Select>
                
            </Form.Item>
            <Form.Item
                name="zipcode"
                label="Zipcode"
                rules={[
                {
                    required: true,
                    pattern: new RegExp(/^[0-9]+$/),
                    message: 'Zipcode must be digit.' 
                },{ min: 5,max:5, message: 'Zipcode must be 5 digit.' },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name="prospectID"
                label="Prospect patient ID"
            >
                <AutoComplete
                    onSearch={showOptions}
                    onChange={handleSearchContent}
                    notFoundContent="No app user found"
                    options={searchOptions}
                >
                <Input  size="large"
                        placeholder="Select Prospect patient (Optional)"
                        suffix={<SearchOutlined/>}
                        allowClear/>
                </AutoComplete>
            </Form.Item>
            <Form.Item
                name="location"
                label="Service Location"
            >
                <Select
                placeholder="Select a location (Optional)"
                >
                {locations && locations.map((location,index) => (
                    <Option key={index} value={location.stationId}>{location.name}</Option>
                ))}
                </Select>
            </Form.Item>
            <Form.Item
                name="leadSource"
                label="Lead Source"
            >
                <Input placeholder="Enter a lead Source (Optional)"/>
            </Form.Item>
            </div>
            <Form.Item {...tailLayout} className="button-container">
                <Button type="primary" htmlType="submit" disabled={loading}>
                Submit
                </Button>
            </Form.Item>
            </Form>
        </Spin>
        </div>
        </>
    );
};
export default PatientCreatePage;