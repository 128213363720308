import React from 'react';
import ChatbotPresetBox from './ChatbotPresetBox'
import '../../styles/marketing/marketing.css'
import '../../styles/marketing/promotion.css'
import '../../styles/layout_custom.css';
import Page from './Page';

class chatbotPresetPage extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Page title={'Chatbox Preset'} isCustom={false}>
                <div style={{ width: "100%", height: "100%" }}>
                    <div>
                        <ChatbotPresetBox category={"new-qualified"} />
                    </div>
                </div>
            </Page>
        )
    }
};

export default chatbotPresetPage;
