import {createSlice} from '@reduxjs/toolkit'

const patientTreatmentPlanSlice = createSlice({
    name: 'patientTreatmentPlan',
    initialState: {
        current: null,
        pending: [],
        past: []
    },
    reducers: {
        setHistoryTreatmentPlan: (state, action) => {
            return {
                ...state,
                past: [...action.payload]
            };
        },
        setCurrentTreatmentPlan: (state, action) => {
            if (!state.current) {
                return {
                    ...state,
                    current: action.payload,
                };
            } else {
                return {
                    current: action.payload,
                    past: [
                        state.current,
                        ...state.past,
                    ],
                    pending: [
                        ...state.pending.filter(p => p._id !== action.payload._id)
                    ]
                };
            }
        },
        setPendingTreatmentPlan: (state, action) => {
            return {
                ...state,
                pending: [...action.payload],
            };
        },
        addPendingTreatmentPlan: (state, action) => {
            return {
                ...state,
                pending: [action.payload, ...state.pending],
            };
        },
        addHistoryTreatmentPlan: (state, action) => {
            return {
                ...state,
                past: [action.payload, ...state.past],
            };
        },
        updateHistoryTreatmentPlan: (state, action) => {
            return {
                ...state,
                past: [action.payload, ...state.past.filter(p => p._id !== action.payload._id)],
            };
        },
        removeDraftPlan: (state, action) => {
            return {
                ...state,
                past: [...state.past.filter(p => p._id !== action.payload._id)],
            };
        }
    }
})

export const { setHistoryTreatmentPlan, setCurrentTreatmentPlan, setPendingTreatmentPlan, addPendingTreatmentPlan, addHistoryTreatmentPlan, updateHistoryTreatmentPlan, removeDraftPlan } = patientTreatmentPlanSlice.actions;
export default patientTreatmentPlanSlice.reducer