import React from "react";
import { Col, Row } from "react-bootstrap";
import DataTable from "../../../components/data-table/data-table.component";
import { AutoComplete, Button, Input, Select, Space } from "antd";
import "./main.css";
import moment from "moment";
import { getTrackingOptions, searchTracking } from "../service";
import { SearchOutlined } from "@ant-design/icons";
import TrackingNumberFormModal from "./TrackingNumberModal";
import HistoryOrderModal from "./HistoryOrderModal";
import { Link } from "react-router-dom";
import DatePickerWithMoment from "../../../components/date/DatePickerWithMoment";

const { Option } = Select;
const dateFormat = "MM/DD/YYYY";

function TrackingPage() {
  const [shipmentInfo, setShipmentInfo] = React.useState([]);
  const [searchType, setSearchType] = React.useState("Search by OrderID");
  const [type, setType] = React.useState("orderID");
  const [loading, setLoading] = React.useState(true);
  const [searchOptions, setSearchOptions] = React.useState([]);
  const [sortType, setSortType] = React.useState("date");
  const [searchContent, setSearchContent] = React.useState("");
  const [rangeDate, setRangeDate] = React.useState([
    moment(),
    moment().add(1, "days"),
  ]);

  const tableSettings = {
    appendable: false,
    removable: false,
    bordered: true,
    size: "middle",
    operationColumnTitle: "Action",
    pagination: {
      showLessItems: true,
      pageSize: 10,
    },
    columns: [
      {
        title: "Create Date",
        dataIndex: "creationTS",
        width: "125px",
      },
      {
        title: "Order ID",
        dataIndex: "orderID",
      },
      {
        title: "Ship From",
        dataIndex: "shipFrom",
      },
      {
        title: "Patient Name",
        dataIndex: "patientName",
        render: (record, data) => {
          if (data.patientID) {
            return (
              <Link to={"/patient/" + data.patientID + "/details"}>
                {" "}
                {data.patientName}
              </Link>
            );
          } else {
            return <span> {data.patientName}</span>;
          }
        },
        width: "150px",
      },
      {
        title: "Status",
        dataIndex: "status",
        width: "100px",
      },
      {
        title: "ETA Delivery Date",
        dataIndex: "deliveryDate",
        width: "125px",
        render: (record, data) => {
          if (record != null) {
            return moment(record).format("YYYY-MM-DD");
          } else {
            return <div className="text-center">-</div>;
          }
        },
      },
      {
        title: "Carrier",
        dataIndex: "carrier",
        render: (record, data) => {
          return record ? record.toUpperCase() : "";
        },
      },
      {
        title: "Tracking Number",
        dataIndex: "trackingNumber",
      },
      {
        title: "Destination",
        dataIndex: "destination",
      },
    ],
    customActions: [
      {
        element: (record, data) => (
          <div className="d-flex align-items-center">
            {record.carrier != "fedex" ? (
              <Button
                href="#"
                onClick={() =>
                  handleTrackClick(record.trackingNumber, record.carrier)
                }
              >
                Track Order
              </Button>
            ) : (
              <>
                <HistoryOrderModal
                  trackingNumber={record.trackingNumber}
                  selectedCarrier={record.carrier}
                  orderHistory={record.orderHistory}
                />
              </>
            )}
            <TrackingNumberFormModal
              orderID={record.orderID}
              selectedCarrier={record.carrier}
              selectedTrackingNumber={record.trackingNumber}
              fetchData={fetchTrackingData}
            />
          </div>
        ),
      },
    ],
  };

  const handleTrackClick = (trackingNumber, carrier) => {
    let trackingUrl = `https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=${trackingNumber}`;
    if (carrier == "ups") {
      trackingUrl = `https://www.ups.com/track?track=yes&trackNums=${trackingNumber}&loc=en_US&requester=ST/trackdetails`;
    }
    window.open(trackingUrl, "_blank");
  };

  const updateSearchType = (e) => {
    setSearchOptions([]);
    setSearchType("Search By " + e);
    setType(e);
  };

  const showSearchOptions = async (value) => {
    if (value != "") {
      const allOptions = await getTrackingOptions(type, value);
      setSearchOptions(allOptions.data);
    } else {
      setSearchOptions([]);
    }
  };

  const updateSortTypeAndSearch = async (value) => {
    setLoading(true);
    setSortType(value);
    const patientDataRequest = await searchTracking(
      type,
      searchContent,
      value,
      rangeDate,
    );
    if (patientDataRequest.data.data) {
      setShipmentInfo(patientDataRequest.data.data);
    } else {
      setShipmentInfo();
    }
    setLoading(false);
  };
  const updateRangeDate = async (value) => {
    setLoading(true);
    setRangeDate(value);
    const patientDataRequest = await searchTracking(
      type,
      searchContent,
      sortType,
      value,
    );
    if (patientDataRequest.data.data) {
      setShipmentInfo(patientDataRequest.data.data);
    } else {
      setShipmentInfo();
    }
    setLoading(false);
  };
  const searchTrackingData = async (value) => {
    setLoading(true);
    handleSearchContent(value);
    let patientDataRequest;
    patientDataRequest = await searchTracking(type, value, sortType, rangeDate);

    if (patientDataRequest.data.data) {
      setShipmentInfo(patientDataRequest.data.data);
    } else {
      setShipmentInfo([]);
    }
    setLoading(false);
  };
  const fetchTrackingData = async () => {
    try {
      setLoading(true);
      const patientDataRequest = await searchTracking(
        type,
        searchContent,
        sortType,
        rangeDate,
      );
      if (patientDataRequest.data.data) {
        setShipmentInfo(patientDataRequest.data.data);
      } else {
        setShipmentInfo([]);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const handleSearchContent = (value) => {
    setSearchContent(value);
    if (value == "") {
      fetchTrackingData();
    }
  };

  const handleEnterKeySearch = (e) => {
    if (e.key === "Enter") {
      handleSearchContent(e.target.value);
      fetchTrackingData();
    }
  };

  React.useEffect(() => {
    fetchTrackingData();
  }, [searchContent]);

  return (
    <>
      <Row>
        <Col sm={12} className="pb-4">
          <Row className="align-items-end">
            <Col sm={6}>
              <Input.Group size="large" compact>
                <Select
                  defaultValue={type}
                  onSelect={updateSearchType}
                  size="large"
                  style={{
                    width: 150,
                    borderRadius: "4px",
                  }}
                >
                  <Option value="orderID">OrderID</Option>
                  <Option value="patientName">Patient Name</Option>
                </Select>
                <Space direction="vertical">
                  <AutoComplete
                    onSearch={showSearchOptions}
                    onSelect={searchTrackingData}
                    onChange={handleSearchContent}
                    style={{
                      width: 450,
                    }}
                    notFoundContent="No order found with searched key"
                    options={searchOptions}
                    onKeyDown={(e) => handleEnterKeySearch(e)}
                  >
                    <Input
                      size="large"
                      placeholder={searchType}
                      suffix={
                        <SearchOutlined onClick={() => fetchTrackingData()} />
                      }
                      allowClear
                    />
                  </AutoComplete>
                </Space>
              </Input.Group>
            </Col>
            <Col sm={6}>
              <Row>
                <Col sm={6} className="sort-input">
                  <Input.Group compact>
                    <div
                      className="sort-label"
                      style={{ display: "inline-grid" }}
                    >
                      <label>Sort By</label>
                      <Select
                        defaultValue={sortType}
                        size="large"
                        onSelect={updateSortTypeAndSearch}
                        style={{
                          width: 150,
                        }}
                      >
                        <Option value="date">Date</Option>
                        <Option value="orderID">OrderID</Option>
                        <Option value="patientName">Patient Name</Option>
                        <Option value="shipFrom">Ship From</Option>
                        <Option value="status">Status</Option>
                        <Option value="destination">Destination</Option>
                      </Select>
                    </div>
                  </Input.Group>
                </Col>
                <Col sm={6} className="date-input">
                  <Input.Group size="large">
                    <div
                      className="date-label"
                      style={{ display: "inline-grid" }}
                    >
                      <label>Date</label>
                      <div className="date-input">
                        <DatePickerWithMoment.RangePicker
                          size={"large"}
                          defaultValue={rangeDate}
                          format={dateFormat}
                          placement="bottomLeft"
                          onChange={updateRangeDate}
                        />
                      </div>
                    </div>
                  </Input.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col sm={12}>
          <DataTable
            loading={loading}
            data={shipmentInfo}
            settings={tableSettings}
          />
        </Col>
      </Row>
    </>
  );
}

export default TrackingPage;
