import React from 'react'
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import trashIcon from '../../../assets/images/trash.png';
import BoxLoader from '../../../components/loading/BoxLoader';
import DateInput from '../../../components/date/DateInput';
import { DATE_FORMAT } from '../../../share/StringHelper';
import { WithBio } from '../../../components/mycomponent/WithBio';
import bioApi from '../../../api/bioApi';
import RegionLevelModal from '../../../components/modal/RegionLevelModal';
import { Button } from 'react-bootstrap';

import '../../../styles/station.css';

function getCenterName(centerArray, centerid) {
    for (var i = 0; i < centerArray.length; i++) {
        var item = centerArray[i];
        if (item.stationId === centerid) {
            return item.name;
        }
    }
    return '';
}

class NewAnnouncement extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.initData();
    }

    initData() {
        var formData = {
            loading: false,
            showRegionLevelModal: false,
            title: '',
            subTitle: '',
            staffDescription: "test test",
            validStart: moment().format(DATE_FORMAT),
            validEnd: moment().add('7', 'days').format(DATE_FORMAT),
            plasmaData: [],
            selectRegion: {
                selectAll: false,
                ids: []
            },
            details: [
                {
                    detail_type: "title",
                    text: ""
                }
            ]
        };

        return formData
    }

    handleTitleChange = (event) => {
        this.setState({
            title: event.target.value
        })
    }

    handleChange = (event) => {
        this.setState({
            subTitle: event.target.value
        })
    }

    handleDetailsChange = (index, event) => {
        var data = this.state.details[index];
        data.text = event.target.value;
        var details = this.state.details;
        details.splice(index, 1, data);
        this.setState({
            details: details
        })
    }

    handleSend(event) {
        if (!this.state.title || this.state.title === '') {
            alert('Please input title.');
            return;
        }
        if (!this.state.subTitle || this.state.subTitle === '') {
            alert('Please input sub-title.');
            return;
        }
        for (var i = 0; i < this.state.details.length; i++) {
            var item = this.state.details[i];
            if (!item.text || item.text === '') {
                alert('Please input detail.');
                return;
            }
        }
        if (!this.state.validStart || this.state.validStart === '') {
            alert('Please select valid start.');
            return;
        }
        if (!this.state.validEnd || this.state.validEnd === '') {
            alert('Please select valid end.');
            return;
        }
        this.setState({
            loading: true
        })
        try {
            var formData = { ...this.state };
            // var selectData = {
            //     "selectAll": this.state.selectRegion.selectAll,
            //     "plasmaIDs": this.state.selectRegion.ids,
            // };
            // formData.selectData = selectData;
            delete formData.loading;
            delete formData.showRegionLevelModal;
            delete formData.selectRegion;
            delete formData.plasmaData;

            var pathstring = '/announcement/save';
            bioApi.post(pathstring, formData).then(response => {
                this.setState({
                    loading: false
                })
                if (response.data.status === 200) {
                    this.setState({
                        ...this.initData()
                    })
                    alert('Successful!');
                } else {
                    alert(response.data.message);
                }
            }).catch((err) => {
                this.setState({ loading: false })
                alert('Network error');
            })
        } catch (err) {
            this.setState({ loading: false })
            alert('Network error');
        }
    }

    handleRegionLevelModalConfirm(selectRegion, plasmaData) {
        this.setState({
            showRegionLevelModal: false,
            selectRegion: selectRegion,
            plasmaData: plasmaData
        })
    }

    handleDetailType(type, index, event) {
        var details = this.state.details;
        var data = details[index];
        data.detail_type = type;
        details.splice(index, 1, data);
        this.setState({
            details: details
        });
    }

    handleDetailDelete(index, event) {
        var details = this.state.details;
        details.splice(index, 1);
        if (details.length === 0) {
            details.push({
                detail_type: "title",
                text: ""
            });
        }
        this.setState({
            details: details
        })
    }

    handleDetailAdd(event) {
        var details = this.state.details;
        details.push({
            detail_type: "title",
            text: ""
        });
        this.setState({
            details: details
        })
    }

    handleValidChange(type, event) {
        if (type === 0) {
            this.setState({
                validStart: event.target.value
            })
        } else {
            this.setState({
                validEnd: event.target.value
            })
        }
    }

    render() {
        return (
            <div>
                <div className="box" style={{ width: "100%", padding: 40, paddingTop: 20, paddingBottom: 20, display: "flex", backgroundColor: "white" }}>
                    <BoxLoader show={this.state.loading} />
                    <div className={"col-md-12 " + "container-form"}>
                        <div className={"box-form"}>
                            <div className={"title-qrcode"}>
                                <h3 className={'qrcode-h3'}>Bio Station</h3>
                                <span className={"text-des"}>Announcement</span>
                            </div>
                            <div className={"form-input"}>
                                <div style={{ display: "flex", marginTop: "10px" }}>
                                    <span style={{ width: "100px", marginTop: "auto", marginBottom: "auto" }}><span style={{ color: "red" }}>*</span>Title: </span>
                                    <input type="text" className={"form-control input-sm"} value={this.state.title} placeholder="Announcement title" onChange={this.handleTitleChange.bind(this)} />
                                </div>
                                <div style={{ display: "flex", marginTop: "10px" }}>
                                    <span style={{ width: "100px", marginTop: "auto", marginBottom: "auto" }}><span style={{ color: "red" }}>*</span>Subtitle: </span>
                                    <input type="text" className={"form-control input-sm"} value={this.state.subTitle} placeholder="Announcement sub-title" onChange={this.handleChange.bind(this)} />
                                </div>
                                <div style={{ display: "flex", marginTop: "10px" }}>
                                    <span style={{ width: "100px", marginTop: 10 }}><span style={{ color: "red" }}>*</span>Details: </span>
                                    <div className="form-control input-sm" style={{ padding: 0, border: "0px solid #ccc", height: "auto" }}>
                                        {
                                            this.state.details.length > 0 && this.state.details.map((item, index) => {
                                                return <div key={"announcement " + index} className="form-control input-sm" style={{ border: "0px solid #ccc", height: "auto" }}>
                                                    <div style={{ display: "flex" }}>
                                                        <div style={{ display: "flex" }}>
                                                            <div style={{ marginTop: "auto", marginBottom: "auto", marginRight: 10 }}>
                                                                <input type="radio" checked={item.detail_type === 'title'} onChange={this.handleDetailType.bind(this, 'title', index)} name={'selectdetail' + index} />
                                                            </div>
                                                            <span style={{ fontWeight: "normal", marginBottom: "auto", marginTop: "auto" }}>Title</span>
                                                        </div>
                                                        <div style={{ display: "flex" }}>
                                                            <div style={{ marginTop: "auto", marginBottom: "auto", marginLeft: 20, marginRight: 10 }}>
                                                                <input type="radio" checked={item.detail_type === 'bullet'} onChange={this.handleDetailType.bind(this, 'bullet', index)} name={'selectdetail' + index} />
                                                            </div>
                                                            <span style={{ fontWeight: "normal", marginBottom: "auto", marginTop: "auto" }}>Bullet Point</span>
                                                        </div>
                                                        <img alt="" style={{ width: 20, height: 20, margin: 'auto', marginLeft: 10 }} src={trashIcon} onClick={this.handleDetailDelete.bind(this, index)} className="input-addon-trash" />
                                                    </div>
                                                    <input
                                                        type="text"
                                                        style={{ marginTop: 10, }}
                                                        className={"form-control input-sm"}
                                                        value={item.text}
                                                        onChange={this.handleDetailsChange.bind(this, index)} />
                                                </div>
                                            })
                                        }
                                        <div style={{ display: "flex", marginTop: 10 }}>
                                            <Button variant='outline-primary' onClick={this.handleDetailAdd.bind(this)} style={{ margin: "auto", padding: "2px 10px" }} type="button" className="btn btn-border-ouline btn-new-city btn-md">Add Detail</Button>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", marginTop: 10 }}>
                                    <span style={{ width: "100px", margin: "auto" }}><span style={{ color: "red" }}>*</span>Validate&nbsp;</span>
                                    <div className="form-control input-sm" style={{ padding: 0, margin: "auto", border: "0px solid #ccc", height: "auto", flex: 1, display: "flex" }}>
                                        <div>
                                            <DatePicker
                                                selected={moment(this.state.validStart).toDate()}
                                                onChange={val => {
                                                    var fromDate = moment(val).format(DATE_FORMAT)
                                                    this.setState({
                                                        validStart: fromDate
                                                    })
                                                }}
                                                dateFormat="yyyy-MM-dd"
                                                customInput={<DateInput width={150} />}
                                            />
                                        </div>
                                        <span style={{ fontSize: 20, color: '#ddd', fontFamily: 'Source Sans Pro', margin: 'auto 5px' }}>&#8212;</span>
                                        <div>
                                            <DatePicker
                                                selected={moment(this.state.validEnd).toDate()}
                                                onChange={val => {
                                                    var fromDate = moment(val).format(DATE_FORMAT)
                                                    this.setState({
                                                        validEnd: fromDate
                                                    })
                                                }}
                                                dateFormat="yyyy-MM-dd"
                                                customInput={<DateInput width={150} />}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {(true) && (
                                    <div>
                                        {/* <div style={{ display: "flex", marginTop: 10 }}>
                                            <span style={{ marginTop: "auto", marginBottom: "auto" }}><span style={{ color: "red" }}>*</span>Select Center(s) to send∶ &nbsp;</span>
                                            <Button variant='outline-primary' type="button" className="btn btn-border-ouline btn-new-city btn-md" style={{ padding: "2px 10px", marginLeft: 20 }} onClick={() => { this.setState({ showRegionLevelModal: true }) }}>Select</Button>
                                        </div> */}
                                        {
                                            this.state.selectRegion && this.state.selectRegion.ids.length > 0 ? (
                                                <div className={"box-form mt-3"} style={{ display: "flex" }}>
                                                    <div className="col-md-9" style={{ width: "100%", overflowY: "scroll", maxHeight: 72 }}>
                                                        <div style={{ marginBottom: '1rem' }}>
                                                            {
                                                                this.state.selectRegion.ids.map((item, index) => {
                                                                    return <div className={"vselected"} key={'fatimes' + index}>
                                                                        {getCenterName(this.state.plasmaData, item)}
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                this.state.selectRegion && this.state.selectRegion.selectAll === true ? (
                                                    <div className={"box-form mt-3"} style={{ display: "flex" }}>
                                                        <div className="col-md-9" style={{ width: "100%", overflowY: "scroll", maxHeight: 72 }}>
                                                            <div style={{ marginBottom: '1rem' }}>
                                                                {
                                                                    this.state.plasmaData.map((item, index) => {
                                                                        return <div className={"vselected"} key={'fatimes' + index}>
                                                                            {item.name}
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null
                                            )
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={"box-form"}>
                            <div className={"form-input"}>
                                <div style={{ display: "flex", margin: 40 }}>
                                    <Button style={{ "margin": "auto", width: "30%", paddingTop: 5, paddingBottom: 5 }} className={"btn btn-md btn-blue"} onClick={this.handleSend.bind(this)}>Submit</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <RegionLevelModal
                    selectRegion={this.state.selectRegion}
                    show={this.state.showRegionLevelModal}
                    onClose={() => { this.setState({ showRegionLevelModal: false }) }}
                    onConfirm={this.handleRegionLevelModalConfirm.bind(this)}
                />
            </div>
        )
    }
}

export default WithBio(NewAnnouncement);
