import React, { useEffect, useRef, useState } from 'react';
import DataTable from '../../../../components/data-table/data-table.component';
import { Button, Divider, Dropdown, Empty, Input, message, Modal, Result, Select, Space, Typography } from 'antd';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { getMedicalNotes, populateMedicationInfo, submitDraftTreatment, submitNewTreatmentPlan } from '../../service';
import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  addHistoryTreatmentPlan,
  addPendingTreatmentPlan,
  removeDraftPlan,
  setCurrentTreatmentPlan,
  updateHistoryTreatmentPlan,
} from '../../../../stores/patient-treatment-plan.store';
import ESignForm from './e-sign.form';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment/moment';
import { DragIndicatorOutlined } from '@mui/icons-material';
import Draggable from 'react-draggable';
import MedicalNoteDisplay from './medical-note.display';
import biomarkerOrder from './biomarker-order.constant.js';

const parseDisplayLogic = (logic) => {
  const gt = logic['>'] || Number.MIN_SAFE_INTEGER;
  const gte = logic['>='] || Number.MIN_SAFE_INTEGER;
  const lt = logic['<'] || Number.MAX_SAFE_INTEGER;
  const lte = logic['<='] || Number.MAX_SAFE_INTEGER;

  const isMenopause = !!logic['menopause'];

  return (labData, menopause) =>
    labData > gt && labData >= gte && labData < lt && labData <= lte && menopause === isMenopause;
};

const mongoObjectIdGenerator = () => {
  const timestamp = (((new Date().getTime() + 315569260000) / 1000) | 0).toString(16);
  return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => ((Math.random() * 16) | 0).toString(16)).toLowerCase();
};

const TreatmentModal = ({ patient, labResult, treatmentCheatsheet, existingTreatment, title, visible, onClose }) => {
  const dispatch = useDispatch();

  const [labResultsTableData, setLabResultsTableData] = useState([]);
  const [symptomsAndGoalsTableData, setSymptomsAndGoalsTableData] = useState([]);
  const [biomarkerRowSpan, setBiomarkerRowSpan] = useState(null);
  const [newTreatment, setNewTreatment] = useState(null);
  const [previewTableData, setPreviewTableData] = useState([]);
  const [previewTableSetting, setPreviewTableSetting] = useState(null);
  const [isApproved] = useState(true);

  const [alwaysDisplayBiomarkers, setAlwaysDisplayBiomarkers] = useState([]);
  const [stage, setStage] = useState('editing');
  const [display, setDisplay] = useState(false);
  const [successMessage, setSuccessMessage] = useState({ title: '', subtitle: '' });
  const [medicalNotes, setMedicalNotes] = useState([]);
  const [showMedicalNote, setShowMedicalNote] = useState({ show: false, title: '', content: {} });

  const [dragDisabled, setDragDisabled] = useState(false);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);

  const menopause = !!(patient.menopause || patient.details.menstrual_cycle === 'Menopausal');
  const patientBioGender =
    patient.gender.toLowerCase() === 'male' ? 'Male' : menopause ? 'FemaleMenopause' : 'FemaleLuteal';

  const sortFunc = (a, b) => {
    const indexA = biomarkerOrder[patientBioGender].indexOf(a.biomarker);
    const indexB = biomarkerOrder[patientBioGender].indexOf(b.biomarker);
    if (indexA === -1 && indexB === -1) return 0;
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
    return indexA - indexB;
  };

  const originalLabResultDeepCopy = JSON.parse(JSON.stringify(labResult)).sort(sortFunc);
  const originalSymptomsAndGoalsDeepCopy = JSON.parse(JSON.stringify(patient.symptomsAndGoals));
  const existingTreatmentDeepCopy = JSON.parse(
    JSON.stringify(
      existingTreatment || {
        forLabResult: [],
        forSymptomsAndGoals: [],
      }
    )
  );

  const symptomGoalsTableSetting = {
    pagination: false,
    bordered: true,
    columns: [
      {
        title: 'Symptoms / Goals',
        dataIndex: 'symptomOrGoal',
      },
      {
        title: 'Medication / Product',
        dataIndex: ['treatment', 'product'],
        render: (text, record, index) => (
          <div style={{ position: 'relative' }}>
            <Select
              bordered={false}
              value={record.treatment?.product?.name}
              style={{ width: '100%' }}
              allowClear
              size="small"
              onClear={() => {
                if (record.for) {
                  symptomsAndGoalsTableData.splice(index, 1);
                } else {
                  record.treatment = { product: null, dosage: '', refill: '', quantity: '' };
                }
                setSymptomsAndGoalsTableData([...symptomsAndGoalsTableData]);
              }}
              onSelect={(value) => {
                const product = treatmentCheatsheet[record.symptomOrGoal || record.for].find(
                  (treatment) => treatment.product._id === value
                ).product;
                record.treatment.product = product;
                record.treatment.dosage = product.defaultDosage || '';
                setSymptomsAndGoalsTableData([...symptomsAndGoalsTableData]);
              }}
            >
              {(treatmentCheatsheet[record.symptomOrGoal || record.for] || []).map((treatment) => (
                <Select.Option value={treatment.product._id}>
                  {treatment.isDefault ? `🛎️ ${treatment.product.name}` : treatment.product.name}
                </Select.Option>
              ))}
            </Select>
            {record.symptomOrGoal && record.treatment.product && (
              <AiOutlinePlusCircle
                className="icon-btn"
                style={{
                  position: 'absolute',
                  left: '50%',
                  bottom: '-72%',
                  fontSize: '18px',
                  zIndex: 100,
                }}
                onClick={() => {
                  symptomsAndGoalsTableData.splice(index + 1, 0, {
                    for: record.symptomOrGoal,
                    treatment: {
                      product: null,
                      dosage: '',
                    },
                  });
                  setSymptomsAndGoalsTableData([...symptomsAndGoalsTableData]);
                }}
              />
            )}
          </div>
        ),
        className: 'action-column',
      },
      {
        title: 'Quantity',
        dataIndex: ['treatment', 'quantity'],
        render: (text, record) => (
          <TextField
            fullWidth
            size="small"
            variant="standard"
            value={record.treatment.quantity}
            onChange={(e) => {
              record.treatment.quantity = e.target.value;
              setSymptomsAndGoalsTableData([...symptomsAndGoalsTableData]);
            }}
          />
        ),
        className: 'action-column',
      },
      {
        title: 'Refill',
        dataIndex: ['treatment', 'refill'],
        render: (text, record) => (
          <TextField
            fullWidth
            size="small"
            variant="standard"
            value={record.treatment.refill}
            onChange={(e) => {
              record.treatment.refill = e.target.value;
              setSymptomsAndGoalsTableData([...symptomsAndGoalsTableData]);
            }}
          />
        ),
        className: 'action-column',
      },
      {
        title: 'Dosage',
        dataIndex: ['treatment', 'dosage'],
        render: (text, record) => (
          <TextField
            fullWidth
            size="small"
            variant="standard"
            value={record.treatment.dosage}
            onChange={(e) => {
              record.treatment.dosage = e.target.value;
              setSymptomsAndGoalsTableData([...symptomsAndGoalsTableData]);
            }}
          />
        ),
        className: 'action-column',
      },
    ],
  };

  const toPreview = async () => {
    setNewTreatment(await calculateProposal());
    setStage('preview');
  };

  const calculateProposal = async () => {
    const selectedLabResultTreatments = labResultsTableData
      .filter((t) => t.treatment?.product)
      .map((t) => ({
        for: t.biomarker || t.for,
        treatment: t.treatment,
      }));
    const selectedSymptomsGoalsTreatments = symptomsAndGoalsTableData
      .filter((t) => t.treatment?.product)
      .map((t) => ({
        for: t.symptomOrGoal || t.for,
        treatment: t.treatment,
      }));

    const proposalRequest = await populateMedicationInfo(
      [...selectedLabResultTreatments, ...selectedSymptomsGoalsTreatments],
      patient.gender
    );

    return {
      forLabResult: proposalRequest.data.filter((m) => selectedLabResultTreatments.find((s) => s.for === m.for)),
      forSymptomsAndGoals: proposalRequest.data.filter((m) =>
        selectedSymptomsGoalsTreatments.find((s) => s.for === m.for)
      ),
    };
  };

  const toSign = async () => {
    setStage('esign');
  };

  const createNewTreatment = async (signatureLink) => {
    try {
      const patientPayload = {
        _id: patient._id,
        currentLabReportId: patient.currentLabReportId,
        patientId: patient.patientId,
        gender: patient.gender,
        menopause: patient.details.menstrual_cycle,
      };
      const result = await submitNewTreatmentPlan(
        newTreatment,
        patientPayload,
        labResult,
        signatureLink,
        isApproved,
        existingTreatment ? existingTreatment._id : null
      );
      if (isApproved) {
        dispatch(setCurrentTreatmentPlan(result.data));
      } else {
        dispatch(addPendingTreatmentPlan(result.data));
      }
      if (existingTreatment) {
        dispatch(removeDraftPlan(result.data));
      }
      setStage('success');
      setSuccessMessage({
        title: 'Successfully Setup New Treatment Plan!',
        subtitle: isApproved ? '' : 'New Treatment Plan will be pending for approval.',
      });
    } catch (e) {
      message.error('Unable to create new treatment');
      throw e;
    }
  };

  const createDraft = async () => {
    try {
      const result = await submitDraftTreatment(
        await calculateProposal(),
        patient,
        labResult,
        existingTreatment ? existingTreatment._id : null
      );
      if (existingTreatment) {
        dispatch(updateHistoryTreatmentPlan(result.data));
      } else {
        dispatch(addHistoryTreatmentPlan(result.data));
      }
      setStage('success');
      setSuccessMessage({
        title: 'Successfully Saved as a draft!',
        subtitle: 'You can find it in the previous treatment plan table.',
      });
    } catch (e) {
      message.error('Unable to create draft');
      throw e;
    }
  };

  const onModalClose = async () => {
    if (
      stage === 'success' ||
      (labResultsTableData.filter((t) => !!t.treatment?.product).length === 0 &&
        symptomsAndGoalsTableData.filter((t) => !!t.treatment?.product).length === 0)
    ) {
      onClose();
      init();
      setStage('editing');
    } else {
      Modal.confirm({
        title: `Before you close, do you want to save this treatment as a draft?`,
        okText: 'Yes. Save as draft',
        cancelText: 'No. Close and discard',
        onCancel: () => {
          onClose();
          init();
          setStage('editing');
        },
        onOk: createDraft,
      });
    }
  };

  const init = () => {
    calculateTreatmentTable(originalLabResultDeepCopy);

    const goalsTableData = [];
    originalSymptomsAndGoalsDeepCopy.forEach((s) => {
      const existing = existingTreatmentDeepCopy.forSymptomsAndGoals.filter((st) => st.for === s);
      if (existing.length) {
        existing.forEach((et, i) => {
          if (i === 0) {
            goalsTableData.push({ symptomOrGoal: s, treatment: et });
          } else {
            goalsTableData.push({ symptomOrGoal: '', for: s, treatment: et });
          }
        });
      } else {
        const defaultTreatment = treatmentCheatsheet[s].find((tc) => tc.isDefault);
        if (!existingTreatment && defaultTreatment) {
          goalsTableData.push({
            symptomOrGoal: s,
            treatment: {
              product: defaultTreatment.product,
              dosage: defaultTreatment.product?.defaultDosage || '',
            },
          });
        } else {
          goalsTableData.push({
            symptomOrGoal: s,
            treatment: {
              product: null,
              dosage: '',
            },
          });
        }
      }
    });
    setSymptomsAndGoalsTableData([...goalsTableData]);
    setNewTreatment(null);
  };

  const calculateTreatmentTable = (labResult, updateRowSpanOnly) => {
    const labTableData = [];
    labResult.forEach((r) => {
      const existing = existingTreatmentDeepCopy.forLabResult.filter((st) => st.for === r.biomarker);
      if (existing.length) {
        existing.forEach((et, i) => {
          if (i === 0) {
            labTableData.push({ ...r, treatment: et });
          } else {
            labTableData.push({ ...r, biomarker: '', optimalRange: '', for: r.biomarker, treatment: et });
          }
        });
      } else {
        const defaultTreatment = r.biomarker ? treatmentCheatsheet[r.biomarker].find((tc) => tc.isDefault) : null;

        if (
          !existingTreatment &&
          r.isAbnormal &&
          defaultTreatment &&
          parseDisplayLogic(defaultTreatment.when || {})(r.labData, menopause)
        ) {
          const defaultProduct = defaultTreatment.product;
          labTableData.push({
            ...r,
            treatment: { product: defaultProduct, dosage: defaultProduct.defaultDosage },
          });
        } else {
          if (r.for && r.treatment.product) {
            labTableData.push({ ...r });
          } else {
            labTableData.push({ ...r, treatment: { product: null, dosage: '' } });
          }
        }
      }
    });

    const biomarkerGroupMapping = (ltd, i) => {
      if (i === 0) {
        return ltd;
      } else {
        return {
          ...ltd,
          type: '',
          _type: ltd.type || ltd._type,
        };
      }
    };

    const hormonesBiomarkers = labTableData
      .filter((ltd) => [ltd.type, ltd._type].includes('Hormones'))
      .map(biomarkerGroupMapping);
    const thyroidBiomarkers = labTableData
      .filter((ltd) => [ltd.type, ltd._type].includes('Thyroid Health'))
      .map(biomarkerGroupMapping);
    const cardiBiomarkers = labTableData
      .filter((ltd) => [ltd.type, ltd._type].includes('Cardiovascular & Inflammation'))
      .map(biomarkerGroupMapping);
    const generalWellnessBiomarkers = labTableData
      .filter((ltd) => [ltd.type, ltd._type].includes('General Wellness'))
      .map(biomarkerGroupMapping);

    const biomarkerRowSpan = {
      Hormones: hormonesBiomarkers.length,
      'Thyroid Health': thyroidBiomarkers.length,
      'Cardiovascular & Inflammation': cardiBiomarkers.length,
      'General Wellness': generalWellnessBiomarkers.length,
    };

    setBiomarkerRowSpan(biomarkerRowSpan);

    if (updateRowSpanOnly) {
      return;
    }
    setLabResultsTableData([
      ...hormonesBiomarkers,
      ...thyroidBiomarkers,
      ...cardiBiomarkers,
      ...generalWellnessBiomarkers,
    ]);
  };

  const treatmentPlanTableSetting = {
    pagination: false,
    bordered: true,
    columns: [
      {
        title: 'Type',
        dataIndex: 'type',
        width: 120,
        onCell: (record) => ({
          rowSpan: biomarkerRowSpan[record.type] || 0,
        }),
        render: (text) => (
          <Typography.Text underline strong>
            {text}
          </Typography.Text>
        ),
      },
      {
        title: 'BioMarker',
        dataIndex: 'biomarker',
      },
      {
        title: 'Lab Result',
        dataIndex: 'labData',
        render: (text, record) => {
          return !record.for && <span style={{ color: record.isAbnormal ? 'red' : 'black' }}>{text}</span>;
        },
      },
      {
        title: 'Optimal Range',
        dataIndex: 'optimalRange',
      },
      {
        title: 'Medication / Product',
        dataIndex: ['treatment', 'product'],
        width: 300,
        render: (text, record, index) => (
          <div style={{ position: 'relative' }}>
            <Select
              bordered={false}
              labelInValue
              value={{
                value: record.treatment?.product?._id,
                label: record.treatment?.product?.name,
              }}
              style={{ width: '100%' }}
              allowClear
              size="small"
              dropdownRender={(menu) => (
                <>
                  {menu}
                  {(record.isAbnormal || alwaysDisplayBiomarkers.includes(record.biomarker || record.for)) &&
                    !treatmentCheatsheet[record.biomarker || record.for].length && (
                      <>
                        <Divider
                          style={{
                            margin: '8px 0',
                          }}
                        />
                        <Input.Search
                          placeholder="Medicine Name"
                          allowClear
                          enterButton="+"
                          size="middle"
                          className="p-1"
                          onSearch={(value) => {
                            record.treatment.product = {
                              _id: mongoObjectIdGenerator(),
                              name: value,
                            };
                            setLabResultsTableData([...labResultsTableData]);
                          }}
                        />
                      </>
                    )}
                </>
              )}
              onClear={() => {
                if (record.for) {
                  labResultsTableData.splice(index, 1);
                } else {
                  record.treatment = { product: null, dosage: '', refill: '', quantity: '' };
                }
                setLabResultsTableData([...labResultsTableData]);
              }}
              onSelect={(value) => {
                const product = treatmentCheatsheet[record.biomarker || record.for].find(
                  (treatment) => treatment.product._id === value.value
                ).product;
                record.treatment.product = product;
                record.treatment.dosage = product.defaultDosage || '';
                setLabResultsTableData([...labResultsTableData]);
              }}
              notFoundContent={
                !record.isAbnormal && !alwaysDisplayBiomarkers.includes(record.biomarker || record.for) ? (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      <div className="d-flex flex-column justify-content-center">
                        <span>This biomarker is in optimal range, are you sure to add treatment?</span>
                        <Button
                          variant="link"
                          onClick={() =>
                            setAlwaysDisplayBiomarkers([...alwaysDisplayBiomarkers, record.biomarker || record.for])
                          }
                        >
                          Add treatment for this biomarker
                        </Button>
                      </div>
                    }
                  />
                ) : treatmentCheatsheet[record.biomarker || record.for].length ? (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      <div className="d-flex flex-column justify-content-center">
                        <span>
                          Although this biomarker is out-of-range, we recommend using treatments in other ranges. Do you
                          wish to show all available treatments for this biomarker?
                        </span>
                        <Button
                          variant="link"
                          onClick={() =>
                            setAlwaysDisplayBiomarkers([...alwaysDisplayBiomarkers, record.biomarker || record.for])
                          }
                        >
                          Show all available treatments
                        </Button>
                      </div>
                    }
                  />
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      <div className="d-flex flex-column justify-content-center">
                        <p>We don't have suggested medication for this biomarker for now.</p>
                        <b>You can manually input medicine name below</b>
                      </div>
                    }
                  />
                )
              }
            >
              {treatmentCheatsheet[record.biomarker || record.for].map(
                (treatment) =>
                  ((record.isAbnormal && parseDisplayLogic(treatment.when || {})(record.labData, menopause)) ||
                    alwaysDisplayBiomarkers.includes(record.biomarker || record.for) ||
                    !!record.for) && (
                    <Select.Option value={treatment.product._id}>
                      {treatment.isDefault ? `🛎️ ${treatment.product.name}` : treatment.product.name}
                    </Select.Option>
                  )
              )}
            </Select>
            {record.biomarker && record.treatment.product && (
              <AiOutlinePlusCircle
                className="icon-btn"
                style={{
                  position: 'absolute',
                  left: '50%',
                  bottom: '-80%',
                  fontSize: '18px',
                  zIndex: 100,
                }}
                onClick={() => {
                  labResultsTableData.splice(index + 1, 0, {
                    ...record,
                    biomarker: '',
                    optimalRange: '',
                    type: '',
                    _type: record._type || record.type,
                    for: record.biomarker,
                    treatment: {
                      product: null,
                      dosage: '',
                    },
                  });
                  setLabResultsTableData([...labResultsTableData]);
                }}
              />
            )}
          </div>
        ),
        className: 'action-column',
      },
      {
        title: 'Quantity',
        dataIndex: ['treatment', 'quantity'],
        width: 100,
        render: (text, record) => (
          <TextField
            fullWidth
            size="small"
            variant="standard"
            value={text}
            onChange={(e) => {
              record.treatment.quantity = e.target.value;
              setLabResultsTableData([...labResultsTableData]);
            }}
          />
        ),
        className: 'action-column',
      },
      {
        title: 'Refill',
        dataIndex: ['treatment', 'refill'],
        width: 150,
        render: (text, record) => (
          <TextField
            fullWidth
            size="small"
            variant="standard"
            value={text}
            onChange={(e) => {
              record.treatment.refill = e.target.value;
              setLabResultsTableData([...labResultsTableData]);
            }}
          />
        ),
        className: 'action-column',
      },
      {
        title: 'Dosage',
        width: 400,
        dataIndex: ['treatment', 'dosage'],
        render: (text, record) => (
          <TextField
            fullWidth
            size="small"
            variant="standard"
            value={text}
            onChange={(e) => {
              record.treatment.dosage = e.target.value;
              setLabResultsTableData([...labResultsTableData]);
            }}
          />
        ),
        className: 'action-column',
      },
    ],
  };

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  useEffect(() => init, [existingTreatment]);

  useEffect(() => {
    if (!newTreatment) {
      return;
    }
    const plans = [...newTreatment.forLabResult, ...newTreatment.forSymptomsAndGoals];
    const basePlan = plans.filter((p) => p.level === 'Base');
    const correctionalPlan = plans.filter((p) => p.level === 'Correctional');
    const electivePlan = plans.filter((p) => p.level === 'Elective');
    const otherPlan = plans.filter((p) => p.level === 'Other');

    const baseRowSpan = basePlan.length;
    const correctionalRowSpan = correctionalPlan.length;
    const electiveRowSpan = electivePlan.length;
    const otherRowSpan = otherPlan.length;

    const basePlanTableData = basePlan.map((p, i) => {
      if (i === 0) {
        return p;
      } else {
        return {
          ...p,
          level: '',
          _level: p.level,
        };
      }
    });
    const correctionalPlanTableData = correctionalPlan.map((p, i) => {
      if (i === 0) {
        return p;
      } else {
        return {
          ...p,
          level: '',
          _level: p.level,
        };
      }
    });
    const electivePlanTableData = electivePlan.map((p, i) => {
      if (i === 0) {
        return p;
      } else {
        return {
          ...p,
          level: '',
          _level: p.level,
        };
      }
    });
    const otherPlanTableData = otherPlan.map((p, i) => {
      if (i === 0) {
        return p;
      } else {
        return {
          ...p,
          level: '',
          _level: p.level,
        };
      }
    });

    const rowSpan = {
      '': 0,
      Base: baseRowSpan,
      Correctional: correctionalRowSpan,
      Elective: electiveRowSpan,
      Other: otherRowSpan,
    };

    setPreviewTableData([
      ...basePlanTableData,
      ...correctionalPlanTableData,
      ...electivePlanTableData,
      ...otherPlanTableData,
    ]);
    setPreviewTableSetting({
      pagination: false,
      bordered: true,
      customActions: [
        {
          element: (record) => (
            <Select
              bordered={false}
              value={record.level || record._level}
              style={{ width: '100%' }}
              size="small"
              onSelect={(value, option) => {
                const corresponding =
                  newTreatment.forLabResult.find((lr) => lr.for === record.for && lr.product === record.product) ||
                  newTreatment.forSymptomsAndGoals.find((lr) => lr.for === record.for && lr.product === record.product);
                if (corresponding) {
                  corresponding.level = option.value;
                  setNewTreatment({
                    forLabResult: [...newTreatment.forLabResult],
                    forSymptomsAndGoals: [...newTreatment.forSymptomsAndGoals],
                  });
                }
                setStage('');
                setTimeout(() => {
                  setStage('preview');
                });
              }}
            >
              <Select.Option value={'Base'}>Base</Select.Option>
              <Select.Option value={'Correctional'}>Correctional</Select.Option>
              <Select.Option value={'Elective'}>Elective</Select.Option>
              <Select.Option value={'Other'}>Other</Select.Option>
            </Select>
          ),
        },
      ],
      operationColumnTitle: 'Change Therapy Level',
      columns: [
        {
          title: 'Therapy',
          dataIndex: 'level',
          onCell: (record) => ({
            rowSpan: rowSpan[record.level],
          }),
        },
        {
          title: 'Medication / Product',
          dataIndex: ['product', 'name'],
        },
        {
          title: 'Quantity',
          dataIndex: 'quantity',
        },
        {
          title: 'Refill',
          dataIndex: 'refill',
        },
        {
          title: 'Dosage',
          dataIndex: 'dosage',
        },
      ],
    });
  }, [newTreatment]);

  useEffect(() => {
    setDisplay(visible);
    if (visible) {
      init();
    }
  }, [visible]);

  useEffect(() => {
    (async () => {
      const response = await getMedicalNotes(patient.patientId);
      setMedicalNotes(response.data);
    })();
  }, []);

  useEffect(() => {
    calculateTreatmentTable(labResultsTableData, true);
  }, [labResultsTableData.length]);

  return (
    <Modal
      centered
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: 40 }}>
          <div>{title}</div>
          <div>
            <Dropdown
              menu={{
                items: medicalNotes.slice(0, 10).map((mn, i) => ({
                  key: i,
                  label: <span>Note created at {moment(mn.creationDate).format('MMM Do, yyyy')}</span>,
                })),
                onClick: (e) => {
                  setShowMedicalNote({
                    show: true,
                    content: medicalNotes[e.key].content,
                    title: (
                      <span>Note created at {moment(medicalNotes[e.key].creationDate).format('MMM Do, yyyy')}</span>
                    ),
                  });
                },
              }}
              placement="bottomRight"
            >
              <Button>
                <Space>
                  Medical Notes (recent 10 only)
                  <DownOutlined style={{ position: 'relative', top: '-2px' }} />
                </Space>
              </Button>
            </Dropdown>
          </div>
        </div>
      }
      open={display}
      width={'95%'}
      footer={null}
      destroyOnClose={true}
      onCancel={onModalClose}
      maskClosable={false}
    >
      {stage === 'editing' && !!treatmentPlanTableSetting && (
        <div>
          <div className="d-flex justify-content-between mb-1">
            <h5 className="mb-0">For Lab Result</h5>
            <Button type="primary" onClick={toPreview}>
              Next
            </Button>
          </div>
          <DataTable data={labResultsTableData} settings={treatmentPlanTableSetting} />
          <h5 className="mt-3 mb-1">For Patient's Symptoms and Goals</h5>
          <DataTable data={symptomsAndGoalsTableData} settings={symptomGoalsTableSetting} />
        </div>
      )}
      {stage === 'preview' && !!previewTableSetting && (
        <div>
          <div className="d-flex justify-content-between mb-1">
            <h5>Preview</h5>
            <div>
              <Button className="me-2" onClick={() => setStage('editing')}>
                Go back and Edit
              </Button>
              <Button type="primary" onClick={toSign}>
                Proceed
              </Button>
            </div>
          </div>
          <DataTable data={previewTableData} settings={previewTableSetting} />
        </div>
      )}
      {stage === 'esign' && (
        <div>
          <ESignForm
            approvingTreatment={newTreatment}
            goBackButton={() => setStage('editing')}
            onSigning={createNewTreatment}
          ></ESignForm>
        </div>
      )}
      {stage === 'success' && (
        <Result
          status="success"
          title={successMessage.title}
          subTitle={successMessage.subtitle}
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => {
                onClose();
                init();
                setStage('editing');
              }}
            >
              Go Back To Treatment Review
            </Button>,
          ]}
        />
      )}
      <Modal
        open={showMedicalNote.show}
        title={
          <>
            <div
              style={{
                width: '100%',
                cursor: 'move',
                display: 'flex',
                alignItems: 'center',
              }}
              onMouseOver={() => {
                if (dragDisabled) {
                  setDragDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDragDisabled(true);
              }}
            >
              <DragIndicatorOutlined />
              {showMedicalNote.title}
              <Typography.Text type={'secondary'} style={{ marginLeft: 8 }}>
                Draggable
              </Typography.Text>
            </div>
            <hr />
          </>
        }
        onCancel={() => setShowMedicalNote({ show: false, title: '', content: {} })}
        modalRender={(modal) => (
          <Draggable disabled={dragDisabled} bounds={bounds} onStart={(event, uiData) => onStart(event, uiData)}>
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        mask={false}
        maskClosable={false}
        wrapClassName={'penetrable-click-modal'}
        width={'60vw'}
        style={{ top: 20 }}
        footer={null}
      >
        <div style={{ height: '300px', overflowY: 'scroll' }}>
          <MedicalNoteDisplay
            medicalNote={showMedicalNote.content}
            gender={patient.gender.charAt(0).toUpperCase() + patient.gender.slice(1)}
          ></MedicalNoteDisplay>
        </div>
      </Modal>
    </Modal>
  );
};
export default TreatmentModal;
