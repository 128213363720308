import React from "react";
import moment from "moment";

const NavigationTopper = () => {
  const [time, setTime] = React.useState(new moment());

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new moment());
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div
      style={{
        padding: 8,
        height: `60px`,
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        zIndex: 111,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={require("../../assets/images/biostation-logo.png")}
          style={{ height: 44 }}
        />
      </div>
      {time && (
        <div style={{ fontWeight: 500, marginLeft: 58 }}>
          {time.format("dddd, MMMM DD, YYYY - hh:mm A")}
        </div>
      )}
    </div>
  );
};

export default NavigationTopper;
