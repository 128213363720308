import {Checkbox, FormControl, Input, InputLabel, Link, NativeSelect} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {message, Typography} from "antd";
import {MdCancel, MdCheck, MdEdit} from "react-icons/md";
import './setting-input.css';
import DatePicker from "react-datepicker";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler/esm/OutsideClickHandler";
import {MultiSelect} from 'react-multi-select-component';


const {Text} = Typography;

const SettingInput = ({label, value, editable, editingRender}) => {
    const divStyle = {
        borderBottom: '1px solid rgba(210, 207, 207, 0.30)',
        padding: "8px 0",
        display: "inline-block",
        width: "100%"
    };

    const iconStyle = {
        cursor: 'pointer',
    };

    const confirm = () => {
        setIsEditing(false);
        if (currentValue === value) {
            return;
        }
        try {
            editingRender.save && editingRender.save(currentValue);
        } catch (e) {
            setCurrentValue(value);
            message.error('Unable to update ' + label);
        }
    };

    const cancel = () => {
        setIsEditing(false);
        setCurrentValue(value);
    };

    const renderKeyValueElement = () => {
        const inputType = editingRender.elementType;

        switch (inputType) {
            case "text":
                return [
                    <Input
                        name={label}
                        value={currentValue}
                        style={{width: "100%"}}
                        onChange={(e) => {
                            setCurrentValue(e.target.value);
                        }}
                        autoFocus
                    />,
                    <Text>{currentValue}</Text>
                ];
            case "dropdown":
                return [
                    <FormControl variant="standard" fullWidth size="small">
                        <InputLabel>{label}</InputLabel>
                        <NativeSelect
                            defaultValue={currentValue}
                            label={label}
                            onChange={(e) => {
                                setCurrentValue(e.target.value);
                            }}
                        >
                            {
                                editingRender.items && editingRender.items.map((item, i) =>
                                    <option key={i} value={item.text || item}>{item.text || item}</option>
                                )
                            }
                        </NativeSelect>
                    </FormControl>,
                    <Text>{ (currentValue && typeof currentValue.text) === 'string' ? currentValue.text : currentValue}</Text>
                ];
            case "checkbox":
                return [
                    <Checkbox
                        checked={currentValue}
                        style={{width: "100%"}}
                        onChange={(e) => {
                            setCurrentValue(e.target.checked);
                        }}
                    />,
                    <Checkbox
                        checked={currentValue}
                        style={{padding: 0}}
                        disabled={true}
                    />
                ];
            case "link":
                return [
                    <>
                    </>,
                    <Link href={editingRender.href}>{currentValue || editingRender.href}</Link>
                ];
            case "date":
                return [
                    <DatePicker selected={moment(currentValue || new Date()).toDate()}
                                onChange={(date) => setCurrentValue(moment(date).format('YYYY-MM-DD'))}
                    />,
                    <Text>{currentValue}</Text>
                ];
            case "multi-select":
                return [
                    <MultiSelect
                        hasSelectAll={false}
                        ClearSelectedIcon={null}
                        ClearIcon={null}
                        className={"setting-input-multiselect"}
                        options={editingRender.items.map(item => ({label: item, value: item}))}
                        onChange={(selections) => setCurrentValue(selections.filter(s => s.value).map(selected => selected.value).join(";"))}
                        value={currentValue ? currentValue.split(editingRender.delimiter).map(item => ({label: item, value: item})) : ""}
                        labelledBy={"multi-select"}/>
                    ,
                    <Text>{currentValue}</Text>
                ];
            default:
                return [<></>, <></>];
        }
    }

    const [isEditing, setIsEditing] = useState(false);
    const [currentValue, setCurrentValue] = useState(value);

    useEffect(() => {
        setCurrentValue(value);
    }, [value])

    return (
        <div style={divStyle}>
            <Row className="d-flex align-items-center">
                {
                    !!label &&
                    <Col sm={6}>
                        <Text type="secondary">{label}</Text>
                    </Col>
                }
                <Col sm={!!label ? 6 : 12} className="d-flex justify-content-between">
                    {
                        isEditing && editable ?
                            <OutsideClickHandler
                                onOutsideClick={confirm}
                                display="contents"
                            >
                                {renderKeyValueElement()[0]}
                                <Text type="secondary" style={{marginLeft: 5}}>
                                    {
                                        editable &&
                                        <>
                                            {
                                                isEditing ?
                                                    <>
                                                        <MdCheck className="icon-hover ok" style={iconStyle}
                                                                 onClick={confirm}></MdCheck>
                                                        <MdCancel className="icon-hover cancel" style={iconStyle}
                                                                  onClick={cancel}></MdCancel>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                        </>
                                    }
                                </Text>
                            </OutsideClickHandler>
                            : renderKeyValueElement()[1]
                    }
                    <Text type="secondary">
                        {
                            editable &&
                            <>
                                {
                                    isEditing ?
                                        <></>
                                        :
                                        <MdEdit style={{ cursor: 'pointer' }} onClick={() => setIsEditing(true)}></MdEdit>
                                }
                            </>
                        }
                    </Text>
                </Col>
            </Row>
        </div>
    )
}

export default SettingInput
