import React from "react";
import { Container } from "react-bootstrap";

const Layout = ({children, style}) => {
  return(
    <div className="layout" style={{...style, zIndex: 0}}>
      <div style={{width: '100%', height: 60}}></div>
      {children}
    </div>
  )
};

export default Layout;