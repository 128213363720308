import {createSlice} from "@reduxjs/toolkit";

const treatmentProposalSlice = createSlice({
    name: 'treatmentProposal',
    initialState: {
        proposal: null
    },
    reducers: {
        setTreatmentProposalFromExistingTreatment: (state, action) => {
            if (!state.proposal) {
                state.proposal = action.payload;
            }
        },
        proposeTreatment: (state, action) => {
            state.proposal = action.payload;
        }
    }
})

export const { setTreatmentProposalFromExistingTreatment, proposeTreatment } = treatmentProposalSlice.actions;
export default treatmentProposalSlice.reducer