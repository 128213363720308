import React from 'react';
import '../../styles/pager.css';

export const pagination = (array, page_size, page_number) => {
    return array.slice((page_number) * page_size, (page_number + 1) * page_size);
}

export const getIndex = (i, page_number, page_size) => {
    return page_size * (page_number) + i;
}

class PagerLink extends React.Component {
    constructor(props) {
        super(props)

        this.clickEvent = this.clickEvent.bind(this);
    }

    clickEvent() {
        if (this.props.className.indexOf('disabled') < 0 && this.props.className.indexOf('active') < 0) {
            this.props.callBack(this.props.index);
        }
    }

    render() {
        return (
            <li className={this.props.className} onClick={this.clickEvent}><span style={{padding: 5}}>{this.props.text}</span></li>
        );
    }
}

class Pager extends React.Component {
    constructor(props) {
        super(props)

        this.state = this.getInitialState();

        this.callBack = this.callBack.bind(this);
        this.goIndexChanged = this.goIndexChanged.bind(this);
        this.getTotalPages = this.getTotalPages.bind(this);
        this.goClicked = this.goClicked.bind(this);
    }

    getInitialState() {
        return {
            goIndex: ''
        };
    }

    callBack(index) {
        this.props.callBack(index);
    }

    getPageLink(p) {
        return <PagerLink key={p.Key} text={p.Text} index={p.Index} className={p.ClassName} callBack={this.callBack} />;
    }

    goIndexChanged(e) {
        var n = parseInt(e.target.value);
        var v = '';
        if (!isNaN(n) && n > 0) {
            v = Math.min(n, this.getTotalPages()) + '';
        }
        this.setState({ goIndex: v });
    }

    getTotalPages() {
        return Math.ceil(this.props.totalCount / this.props.pageSize);
    }

    goClicked() {
        var idx = ~~this.state.goIndex - 1;
        if (idx >= 0 && idx !== this.props.pageIndex) {
            this.callBack(idx);
            this.setState({ goIndex: '' });
        }
    }

    render() {
        var display = '';
        if (!this.props.alwaysShow || this.props.totalCount === 0) {
            display = this.props.totalCount <= this.props.pageSize ? 'none' : '';
        }
        var totalPages = this.getTotalPages();
        var arr = [];
        var prevDisplay = 0 === this.props.pageIndex ? 'disabled' : '';
        var lastDisplay = totalPages - 1 === this.props.pageIndex ? 'disabled' : '';
        arr.push(
            this.getPageLink({
                Key: "F",
                Text: this.props.firstText,
                Index: 0,
                ClassName: prevDisplay
            })
        );
        arr.push(
            this.getPageLink({
                Key: "P",
                Text: this.props.prevText,
                Index: Math.max(this.props.pageIndex - 1, 0),
                ClassName: prevDisplay
            })
        );
        if (this.props.showLinkNum > 0) {
            var startIndex = ~~(this.props.pageIndex / this.props.showLinkNum) * this.props.showLinkNum;
            var endIndex = Math.min(startIndex + this.props.showLinkNum, totalPages);
            for (var i = startIndex; i < endIndex; i++) {
                arr.push(
                    this.getPageLink({
                        Key: i,
                        Text: i + 1,
                        Index: i,
                        ClassName: i === this.props.pageIndex ? 'active' : ''
                    })
                );
            }
        }
        arr.push(
            this.getPageLink({
                Key: "N",
                Text: this.props.nextText,
                Index: Math.min(this.props.pageIndex + 1, totalPages - 1),
                ClassName: lastDisplay
            })
        );
        arr.push(
            this.getPageLink({
                Key: "L",
                Text: this.props.lastText,
                Index: totalPages - 1,
                ClassName: lastDisplay
            })
        );
        if (totalPages > this.props.showLinkNum && !this.props.hideSearch) {
            arr.push(
                <li key="G">
                    <div className="input-group" style={{ display: 'flex', float: 'left' }}>
                        <input type="text" className="form-control" maxLength={(totalPages + "").length} value={this.state.goIndex} onChange={this.goIndexChanged} style={{ width: this.props.goWidth, height: "30px", borderRadius: '0.375rem' }} />
                        <span className="input-group-btn" style={{ display: 'inline-block' }}>
                            <button className="btn btn-default cus-hover" style={{ height: "30px",padding: "0px 10px 0px 10px", margin: "0px 10px 0px 10px" }} onClick={this.goClicked} type="button">Go</button>
                        </span>
                    </div>
                </li>
            );
        }
        if (this.props.recordTextFormat.length > 0) {
            arr.push(
                <li key="T" style={{ marginLeft: 5 }}>
                    <span style={{ padding: 5 }}>{this.props.recordTextFormat.replace(/\{0\}/g, this.props.pageIndex + 1)
                        .replace(/\{1\}/g, totalPages).replace(/\{2\}/g, this.props.totalCount)}</span>
                </li>
            );
        }
        return (
            <ul className="pagination" style={{ margin: '0px 0px', display: display, cursor: "pointer" }}>
                {arr}
            </ul>
        );
    }
}

export default Pager;
