import React, {useEffect, useState} from "react";
import {Accordion, Card, FloatingLabel, Form} from "react-bootstrap";
import SelectLanguageComponent from "../../../../components/select-language/select-language.component";
import {languages} from "../../../legacy/contents/AddLanguageModal";
import "./license-agreement.css";
import {Button, Collapse, Modal, notification, Popconfirm, Spin} from "antd";
import {AddBox, ArrowDownward, ArrowUpward, CloseFullscreen, DeleteSweep, OpenInFull} from "@mui/icons-material";
import bioApi from "../../../../api/bioApi";


const LicenseAgreementPage = () => {
    const [loading, setLoading] = useState(true);
    const [fetchingData, setFetchingData] = useState(true);
    const [supportedLanguages, setSupportedLanguages] = useState([]);
    const [defaultLanguage, setDefaultLanguage] = useState('');
    const [headers, setHeaders] = useState([]);
    const [contents, setContents] = useState([]);

    const [originalHeaders, setOriginalHeaders] = useState([]);
    const [originalContents, setOriginalContents] = useState([]);

    const [expandContents, setExpandContents] = useState([]);
    const [allExpanded, setAllExpanded] = useState(false);

    const deleteHeader = (key) => {
        setHeaders([...headers.filter((_, index) => index !== key)]);
    }
    const deleteContent = (key) => {
        setContents([...contents.filter((_, index) => index !== key)]);
    }
    const deleteDescription = (contentKey, lang, descriptionKey) => {
        const el = contents[contentKey][lang].desc;
        contents[contentKey][lang].desc = [...el.filter((_, index) => index !== descriptionKey)]
        setContents([...contents]);
    }

    const addDescription = (contentKey, lang) => {
        const el = contents[contentKey][lang];
        el.desc = [...el.desc, '']
        setContents([...contents]);
    }
    const addContent = () => {
        setContents([...contents, Object.fromEntries(supportedLanguages.map(l => [l, {title: '', desc: ['']}]))])
    }
    const addHeader = () => {
        setHeaders([...headers, Object.fromEntries(supportedLanguages.map(l => [l, '']))])
    }

    const headerMove = (direction, key) => {
        const el = headers[key];

        if (direction === 'up') {
            headers[key] = headers[key - 1];
            headers[key - 1] = el;
        } else {
            headers[key] = headers[key + 1];
            headers[key + 1] = el;

        }

        setHeaders([...headers]);
    }
    const contentMove = (direction, key) => {
        const el = contents[key];
        if (direction === 'up') {
            contents[key] = contents[key - 1];
            contents[key - 1] = el;
        } else {
            contents[key] = contents[key + 1];
            contents[key + 1] = el;
        }

        setContents([...contents]);
    }
    const descriptionMove = (direction, contentKey, lang, descriptionKey) => {
        const el = contents[contentKey][lang].desc[descriptionKey];
        if (direction === 'up') {
            contents[contentKey][lang].desc[descriptionKey] = contents[contentKey][lang].desc[descriptionKey - 1];
            contents[contentKey][lang].desc[descriptionKey - 1] = el;
        } else {
            contents[contentKey][lang].desc[descriptionKey] = contents[contentKey][lang].desc[descriptionKey + 1];
            contents[contentKey][lang].desc[descriptionKey + 1] = el;
        }

        setContents([...contents]);
    }

    const handleInputChange = (e) => {
        const inputId = e.target.id;
        const info = inputId.split('-');
        const target = info[0];
        const lang = info[2];
        if (target === 'header') {
            const headerIndex = info[1];
            headers[headerIndex][lang] = e.target.value;
            setHeaders([...headers]);
        } else if (target === 'content') {
            const contentIndex = info[1];
            const specificTarget = info[3];
            if (specificTarget === 'title') {
                contents[contentIndex][lang].title = e.target.value;
            } else {
                const descIndex = info[4];
                contents[contentIndex][lang].desc[descIndex] = e.target.value;
            }
            setContents([...contents]);
        }
    }

    const markIfChanged = (target, config) => {
        let isChanged;
        if (target === 'header') {
            if (config === undefined) {
                isChanged = isHeadersChanged;
            } else if (config.lang) {
                isChanged = isThisHeaderOfThisLanguageChanged;
            } else {
                isChanged = isThisHeaderChanged;
            }
        } else {
            if (config === undefined) {
                isChanged = isContentsChanged;
            } else if (config.lang) {
                if (config.field === 'title') {
                    isChanged = isContentTitleChanged;
                } else if (config.field === 'desc') {
                    isChanged = isContentDescChanged
                } else {
                    isChanged = isThisContentOfThisLanguageChanged;
                }
            } else {
                isChanged = isThisContentChanged;
            }
        }

        return isChanged(config) ? ' (*)' : ''
    }

    const isHeadersChanged = () => JSON.stringify(headers) !== JSON.stringify(originalHeaders);

    const isThisHeaderChanged = (index) => !headers[index] || !originalHeaders[index] || JSON.stringify(headers[index]) !== JSON.stringify(originalHeaders[index]);

    const isThisHeaderOfThisLanguageChanged = ({index, lang}) => !headers[index] || !originalHeaders[index]
        || !headers[index][lang] || !originalHeaders[index][lang]
        || JSON.stringify(headers[index][lang]) !== JSON.stringify(originalHeaders[index][lang]);


    const isContentsChanged = () => JSON.stringify(contents) !== JSON.stringify(originalContents);

    const isThisContentChanged = (index) => !contents[index] || !originalContents[index] || JSON.stringify(contents[index]) !== JSON.stringify(originalContents[index]);

    const isThisContentOfThisLanguageChanged = ({index, lang}) => !contents[index] || !originalContents[index]
        || !contents[index][lang] || !originalContents[index][lang]
        || JSON.stringify(contents[index][lang]) !== JSON.stringify(originalContents[index][lang]);

    const isContentTitleChanged = ({contentIndex, lang}) => !contents[contentIndex] || !originalContents[contentIndex]
        || !contents[contentIndex][lang] || !originalContents[contentIndex][lang]
        || !contents[contentIndex][lang].title || !originalContents[contentIndex][lang].title
        || JSON.stringify(contents[contentIndex][lang].title) !== JSON.stringify(originalContents[contentIndex][lang].title);

    const isContentDescChanged = ({contentIndex, lang, descIndex}) => !contents[contentIndex] || !originalContents[contentIndex]
        || !contents[contentIndex][lang] || !originalContents[contentIndex][lang]
        || !contents[contentIndex][lang].desc || !originalContents[contentIndex][lang].desc
        || JSON.stringify(contents[contentIndex][lang].desc[descIndex]) !== JSON.stringify(originalContents[contentIndex][lang].desc[descIndex]);


    const onSupportLanguageChange = (mode, lang) => {
        if (mode === 'select') {
            setSupportedLanguages([...supportedLanguages, lang]);
            headers.forEach(header => header[lang] = '');
            contents.forEach(content => content[lang] = {title: '', desc: ['']});
        } else {
            setSupportedLanguages(([...supportedLanguages].filter(sl => sl !== lang)));
            headers.forEach(header => delete header[lang]);
            contents.forEach(content => delete content[lang]);
        }
        setHeaders([...headers]);
        setContents([...contents]);
    }

    const onSubmit = async () => {
        if (!sanityCheck()) {
            return;
        }

        setLoading(true);

        const numberedContents = [];
        contents.forEach((content, cIndex) => {
            const numberedContent = {
                index: cIndex + 1,
            }
            Object.entries(content).forEach(([lang, detail]) => {
                if (lang === 'index') {
                    return;
                }

                if (detail.desc.length === 1) {
                    numberedContent[lang] = {
                        title: detail.title,
                        desc: detail.desc[0]
                    };
                } else if (detail.desc.length > 1) {
                    const numberedDesc = detail.desc.map((d, dIndex) => ({index: dIndex + 1, text: d}));
                    numberedContent[lang] = {
                        title: detail.title,
                        desc: [...numberedDesc]
                    };
                } else {
                    throw new Error(`content ${cIndex} ${lang} without description!`)
                }
            });
            numberedContents.push(numberedContent);
        });

        const payload = {
            defaultLanguage,
            headers,
            supportLanguages: supportedLanguages,
            numberedContent: numberedContents
        };

        try {
            const savePath = "/settings/mobile_licence/save";
            const response = await bioApi.post(savePath, payload);
            if (response.data.status === 200) {
                setFetchingData(true);
                notification.success({
                    message: 'Success!',
                    description: 'License Agreement has been updated',
                    duration: 0,
                    placement: 'bottomRight'
                });
            } else {
                setLoading(false);
                notification.error({
                    message: 'Failed!',
                    description: 'Fail to update License Agreement',
                    duration: 0,
                    placement: 'bottomRight'
                });
            }
        } catch (e) {
            setLoading(false);
            notification.error({
                message: 'Failed!',
                description: 'Fail to update License Agreement',
                duration: 0,
                placement: 'bottomRight'
            });
        }
    }

    const sanityCheck = () => {
        const errors = [];
        if (!defaultLanguage) {
            errors.push('Default Language');
        }
        if (!supportedLanguages.length) {
            errors.push('Supported Languages');
        }
        headers.forEach((header, index) => Object.entries(header).forEach(([lang, msg]) => {
            if (!msg) {
                errors.push(`[Header No.${index + 1}] [${languages[lang]}] [message]`);
            }
        }))
        contents.forEach((content, cIndex) =>
            Object.entries(content).forEach(([lang, detail]) => {
                if (lang === 'index') {
                    return;
                }
                if (!detail.title) {
                    errors.push(`[Content No.${cIndex + 1}] [${languages[lang]}] [title]`);
                }
                detail.desc.forEach((d, dIndex) => {
                    if (!d) {
                        errors.push(`[Content No.${cIndex + 1}] [${languages[lang]}] [description No.${dIndex + 1}]`);
                    }
                })
            }))
        if (errors.length) {
            notification.error({
                message: 'Following required inputs are not set',
                description: (errors.map(e => <p>{e}</p>)),
                duration: 0,
                placement: 'bottomRight'
            });
        }

        return !errors.length;
    }

    useEffect( () => {
        (async () => {
            try {
                const retrieveLicensePath = "/settings/mobile_licence/get";
                const response = await bioApi.get(retrieveLicensePath);
                if (response.data.status === 200) {
                    const headerData = response.data?.data?.headers || [];
                    const contentData = response.data?.data?.numberedContent || [];

                    const convertedContents = [];
                    contentData.forEach(content => {
                        let newContent = {};

                        Object.entries(content).forEach(([lang, detail]) => {
                            const newDesc = [];

                            if (detail.desc) {
                                if (typeof detail.desc === 'string') {
                                    newDesc.push(detail.desc);
                                } else {
                                    newDesc.push(...detail.desc.map(d => d.text))
                                }
                            } else {
                                newDesc.push('');
                            }

                            newContent[lang] = {
                                title: detail.title,
                                desc: [...newDesc]
                            }
                        });
                        convertedContents.push(newContent);
                    });
                    setHeaders(JSON.parse(JSON.stringify(headerData)));
                    setContents(JSON.parse(JSON.stringify(convertedContents)));
                    setOriginalHeaders(JSON.parse(JSON.stringify(headerData)));
                    setOriginalContents(JSON.parse(JSON.stringify(convertedContents)));
                    setDefaultLanguage(response.data?.data?.defaultLanguage || 'en');
                    setSupportedLanguages([...(response.data?.data?.supportLanguages || [])]);
                }
            } finally {
                setLoading(false);
                setFetchingData(false);
            }
        })();

    }, [fetchingData]);


    return (
        <div className="license-agreement-page">
            <Modal
                title="Loading License Agreement Data"
                centered
                open={loading}
                maskClosable={false}
                closable={false}
                footer={null}
                keyboard={false}
            >
                <div style={{textAlign: 'center'}}>
                    <Spin size="large" />
                </div>
            </Modal>
            <div className="ms-5 me-5 mt-3">
                <SelectLanguageComponent supportedLanguage={supportedLanguages}
                                     defaultLanguage={defaultLanguage}
                                     onDefaultLanguageChange={setDefaultLanguage}
                                     onSupportLanguageChange={onSupportLanguageChange}/>
            </div>
            <div style={{height: 20}}/>
            <Accordion defaultActiveKey={["headers", "contents"]} alwaysOpen>
                <Accordion.Item eventKey="headers">
                    <Accordion.Header><h5 className="m-1">Headers {markIfChanged('header')}</h5></Accordion.Header>
                    <Accordion.Body>
                        {
                            <Collapse
                                className="mb-1"
                            >
                                {
                                    headers.map((header, index) =>
                                        <Collapse.Panel header={
                                            <>
                                                {`Number ${index + 1} ${markIfChanged('header', index)}`}
                                                <Button type="primary" className="move-up-button" disabled={index === 0}
                                                        icon={<ArrowUpward/>}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            headerMove('up', index)
                                                        }}/>
                                                <Button type="primary" className="move-down-button"
                                                        disabled={index === headers.length - 1} icon={<ArrowDownward/>}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            headerMove('down', index)
                                                        }}/>
                                            </>
                                        } key={`${index}`} extra={
                                            <div onClick={e => e.stopPropagation()}>
                                                <Popconfirm title={`Delete This Header?`} onConfirm={e => {
                                                    deleteHeader(index)
                                                }}>
                                                    <Button type="danger" className="delete-button"
                                                            disabled={headers.length === 1} icon={<DeleteSweep/>}
                                                            onClick={e => e.stopPropagation()}/>
                                                </Popconfirm>
                                            </div>
                                        }>
                                            {
                                                supportedLanguages.map(lang =>
                                                    <Card className='mb-2' style={{background: "ghostwhite"}}>
                                                        <Card.Body>
                                                            <Card.Subtitle
                                                                className="mb-2 text-muted">{languages[lang]} {markIfChanged('header', {
                                                                index,
                                                                lang
                                                            })}</Card.Subtitle>
                                                            <FloatingLabel controlId={`header-${index}-${lang}`}
                                                                           label="Message">
                                                                <Form.Control
                                                                    id={`header-${index}-${lang}`}
                                                                    as="textarea"
                                                                    placeholder="Header Message"
                                                                    style={{height: '115px', minHeight: "60px"}}
                                                                    value={header[lang]}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </FloatingLabel>
                                                        </Card.Body>
                                                    </Card>
                                                )
                                            }
                                        </Collapse.Panel>
                                    )
                                }
                            </Collapse>
                        }
                        <Button type="primary" icon={<AddBox/>} onClick={addHeader}>&nbsp;Add Another Header</Button>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="contents">
                    <Accordion.Header>
                        <h5 className="m-1 me-4">Contents {markIfChanged('content')}</h5>
                        {
                            allExpanded ?
                                <Button type="primary" onClick={e => {
                                    e.stopPropagation();
                                    setExpandContents([]);
                                    setAllExpanded(false)
                                }} icon={<CloseFullscreen/>}>&nbsp;Collapse All</Button> :
                                <Button type="primary" onClick={e => {
                                    e.stopPropagation();
                                    setExpandContents([...contents.map((_, index) => index)]);
                                    setAllExpanded(true)
                                }} icon={<OpenInFull/>}>&nbsp;Expand All</Button>
                        }
                    </Accordion.Header>
                    <Accordion.Body>


                        {
                            <Collapse
                                activeKey={expandContents}
                                className="mb-1"
                                onChange={(e) => setExpandContents([...e])}
                            >
                                {
                                    contents.map((content, index) =>
                                        <Collapse.Panel header={
                                            <>
                                                {`Number ${index + 1}`}
                                                {markIfChanged('content', index)}
                                                <Button type="primary" className="move-up-button" disabled={index === 0}
                                                        icon={<ArrowUpward/>}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            contentMove('up', index)
                                                        }}/>
                                                <Button type="primary" className="move-down-button"
                                                        disabled={index === contents.length - 1} icon={<ArrowDownward/>}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            contentMove('down', index)
                                                        }}/>
                                            </>
                                        } key={`${index}`} extra={
                                            <div onClick={e => e.stopPropagation()}>
                                                <Popconfirm title={`Delete This Redirect Page?`} onConfirm={e => {
                                                    deleteContent(index)
                                                }}>
                                                    <Button type="danger" className="delete-button"
                                                            disabled={contents.length === 1} icon={<DeleteSweep/>}
                                                            onClick={e => e.stopPropagation()}/>
                                                </Popconfirm>
                                            </div>
                                        }>
                                            {
                                                supportedLanguages.map(lang =>
                                                    <Card className='mb-2' style={{background: "ghostwhite"}}>
                                                        <Card.Body>
                                                            <Card.Subtitle
                                                                className="mb-2 text-muted">{languages[lang]} {markIfChanged('content', {
                                                                index,
                                                                lang
                                                            })}</Card.Subtitle>
                                                            <FloatingLabel
                                                                controlId={`content-${index}-${lang}-title`}
                                                                label={'Title' + markIfChanged('content', {
                                                                    contentIndex: index,
                                                                    lang,
                                                                    field: 'title'
                                                                })}>
                                                                <Form.Control
                                                                    id={`content-${index}-${lang}-title`}
                                                                    as="input"
                                                                    placeholder="Content Title"
                                                                    value={content[lang]?.title || ''}
                                                                    style={{marginBottom: "5px"}}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </FloatingLabel>
                                                            {
                                                                (content[lang]?.desc || ['']).map((desc, descIndex, array) =>
                                                                    <FloatingLabel
                                                                        controlId={`content-${index}-${lang}-desc-${descIndex}`}
                                                                        label={`Description ${descIndex + 1}` + markIfChanged('content', {
                                                                            contentIndex: index,
                                                                            lang,
                                                                            field: 'desc',
                                                                            descIndex
                                                                        })}>
                                                                        <Form.Control
                                                                            id={`content-${index}-${lang}-desc-${descIndex}`}
                                                                            as="textarea"
                                                                            placeholder="Content Description"
                                                                            style={{height: '115px', minHeight: "60px"}}
                                                                            value={desc}
                                                                            onChange={handleInputChange}
                                                                        />
                                                                        <Button type="primary" size="small"
                                                                                shape="circle"
                                                                                className="move-up-description-button"
                                                                                disabled={descIndex === 0}
                                                                                icon={<ArrowUpward/>}
                                                                                onClick={e => {
                                                                                    e.stopPropagation();
                                                                                    descriptionMove('up', index, lang, descIndex)
                                                                                }}/>
                                                                        <Button type="primary" size="small"
                                                                                shape="circle"
                                                                                className="move-down-description-button"
                                                                                disabled={descIndex === array.length - 1}
                                                                                icon={<ArrowDownward/>}
                                                                                onClick={e => {
                                                                                    e.stopPropagation();
                                                                                    descriptionMove('down', index, lang, descIndex)
                                                                                }}/>
                                                                        <Popconfirm title={`Delete This Description?`}
                                                                                    onConfirm={e => {
                                                                                        e.stopPropagation();
                                                                                        deleteDescription(index, lang, descIndex)
                                                                                    }}>
                                                                            <Button type="danger" size="small"
                                                                                    shape="circle"
                                                                                    className="delete-description-button"
                                                                                    disabled={array.length === 1}
                                                                                    icon={<DeleteSweep/>}
                                                                                    onClick={e => e.stopPropagation()}/>
                                                                        </Popconfirm>
                                                                    </FloatingLabel>)
                                                            }
                                                            <Button style={{marginTop: "4px"}} type="primary"
                                                                    size="small" shape="circle" icon={<AddBox/>}
                                                                    onClick={() => addDescription(index, lang)}
                                                            />
                                                        </Card.Body>
                                                    </Card>
                                                )
                                            }
                                        </Collapse.Panel>
                                    )
                                }
                            </Collapse>
                        }
                        <Button type="primary" icon={<AddBox/>} onClick={addContent}>&nbsp;Add Another Content</Button>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Button className="mt-2 float-end" type="primary" size="large" onClick={onSubmit} shape="round">Submit</Button>
            <div style={{height: 50}} />
        </div>
    );
}

export default LicenseAgreementPage;