import React, {useEffect, useState} from "react";
import {AiFillCalendar, AiFillMedicineBox} from "react-icons/ai";
import {Avatar, Button, Modal, Row, Select} from "antd";
import {FaHospital, FaHospitalUser} from "react-icons/fa";
import {Link} from "react-router-dom";
import {searchPatients} from "../patient/patient-search.service";

const NewAppointmentModal = ({show, locations, services, defaultLocation, onFinish}) => {
    const [open, setOpen] = useState(show);
    const [availableServices] = useState(services);
    const [serviceId, setServiceId] = useState(null);
    const [patientId, setPatientId] = useState(null);
    const [locationId, setLocationId] = useState(defaultLocation);
    const [enableRedirectButton, setEnableRedirectButton] = useState(false);
    const [patientSearchResult, setPatientSearchResult] = useState([]);

    useEffect(() => setOpen(show), [show])

    useEffect(() => setEnableRedirectButton(serviceId && patientId), [serviceId, patientId])

    const header =
        <div>
            <div className="me-4 d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    <div style={{background: "#5284C2", padding: 4, borderRadius: 4, marginRight: 8}}>
                        <AiFillCalendar style={{fontSize: 26, color: "white"}}/>
                    </div>
                    <div className="fw-bold fs-6">
                        New Appointment
                    </div>
                </div>
                <div>
                    <Button onClick={onFinish}>Close</Button>
                </div>
            </div>
            <hr/>
        </div>
    ;

    let patientSearchDebounceTimeout;
    let currentCriteria;

    const handlePatientSearch = (newValue) => {
        if (newValue && newValue.length > 3) {
            if (patientSearchDebounceTimeout) {
                clearTimeout(patientSearchDebounceTimeout);
                patientSearchDebounceTimeout = null;
            }
            currentCriteria = newValue;

            patientSearchDebounceTimeout = setTimeout(async () => {
                const result = await searchPatients('general-matching', newValue);
                if (currentCriteria === newValue) {
                    setPatientSearchResult(result.data.patients);
                }
            }, 300);
        } else {
            setPatientSearchResult([]);
        }
    };

    return (
        <Modal className="appointment-window"
               title={header} open={open} width={550} destroyOnClose footer={null}
               onCancel={onFinish}
        >
            <Row className="d-flex align-items-center mb-4">
                <FaHospitalUser style={{fontSize: 20, width: 60}}/>
                <Select
                    showSearch
                    placeholder="Add Patient"
                    size="large"
                    style={{minWidth: 420}}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onSearch={handlePatientSearch}
                    onChange={setPatientId}
                    optionLabelProp="label"
                >
                    {
                        (patientSearchResult || []).map((p) => (
                            <Select.Option value={p.patientId} label={p.fullName}>
                                <div className="d-flex align-items-center">
                                    <Avatar size="30" src={p.picture} style={{marginRight: 10}}/>
                                    <div>
                                        <div>{p.fullName}</div>
                                        <div>(Phone #{p.phoneNumber.replace(/\D+/g, '')
                                            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')})
                                        </div>
                                    </div>
                                </div>
                            </Select.Option>
                        ))
                    }
                </Select>
            </Row>

            <Row className="d-flex align-items-center mb-4">
                <AiFillMedicineBox style={{fontSize: 22, width: 60}}/>
                <Select
                    showSearch
                    placeholder="Select Service"
                    size="large"
                    style={{minWidth: 420}}
                    defaultValue={serviceId}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={Array.from(availableServices.reduce(
                        (entryMap, e) => entryMap.set(e.type, [...entryMap.get(e.type) || [], {
                            label: e.serviceName,
                            value: e.serviceID,
                        }]),
                        new Map()
                    )).map(([type, value]) => ({label: type, options: [...value]}))}
                    onChange={(value, option) => {
                        setServiceId(value);
                    }}
                />
            </Row>

            <Row className="d-flex align-items-center mb-4">
                <FaHospital style={{fontSize: 20, width: 60}}/>
                <Select
                    size="large"
                    value={locationId}
                    onChange={value => {
                        setLocationId(value)
                    }}
                    options={locations}
                    style={{minWidth: 420}}
                    placeholder="Add Location (Optional)"
                    allowClear
                />
            </Row>
            <Row>
                <Button disabled={!enableRedirectButton}>
                    <Link
                        to={`/patient/${patientId}/new-appointment?service=${serviceId}&location=${locationId || ''}`}>
                        Continue to schedule
                    </Link>
                </Button>
            </Row>
        </Modal>
    );
}

export default NewAppointmentModal;