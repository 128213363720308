import {Avatar, Col, Input, message, Modal, Result, Row, Select, Typography} from "antd";
import {AiOutlineLeft} from "react-icons/ai";
import {Link, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {Box, Checkbox, FormControlLabel} from "@mui/material";
import {
    amber,
    blue,
    blueGrey,
    brown,
    common,
    cyan,
    deepOrange,
    deepPurple,
    green,
    grey,
    indigo,
    lightBlue,
    lightGreen,
    lime,
    orange,
    pink,
    purple,
    red,
    teal,
    yellow
} from '@mui/material/colors';
import {useSelector} from "react-redux";
import styled, {css} from 'styled-components';
import {Calendar, momentLocalizer} from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'

import moment from "moment";
import "moment-timezone";

import "./new-appointment.css";
import "./new-appointment-rbc.css";
import "./new-appointment-datepicker.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

import {getAppointmentAvailableServices, getStaffSchedulesByService, scheduleAppointment} from "../service";
import DatePickerWithMoment from "../../../components/date/DatePickerWithMoment";

const NewAppointmentPage = () => {
    const timezone = 'America/New_York';
    moment.tz.setDefault(timezone);

    const colorSchema = [
        amber,
        blue,
        blueGrey,
        brown,
        cyan,
        deepOrange,
        deepPurple,
        green,
        grey,
        indigo,
        lightBlue,
        lightGreen,
        lime,
        orange,
        pink,
        purple,
        red,
        teal,
        yellow
    ];

    const StyledDatePicker = styled.div`
      ${props => colorSchema.map((color, i) => css`
        .day-color-${i} {
          background: ${color[700]} !important;
        }
      `)}
    `
    const DnDCalendar = withDragAndDrop(Calendar)

    const [staffs, setStaffs] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDateInTimezone, setSelectedDateInTimezone] = useState(null);
    const [selectedService, setSelectedService] = useState(null);
    const [availableServices, setAvailableService] = useState([]);
    const [availableDates, setAvailableDates] = useState(null);
    const [locationChecks, setLocationChecks] = useState([]);
    const [staffChecks, setStaffChecks] = useState([]);
    const [colorMap, setColorMap] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [locationParamUsed, setLocationParamUsed] = useState(false);

    const [appointmentWindow, setAppointmentWindow] = useState({
        open: false,
        provider: null,
        start: null,
        end: null,
        location: null,
        events: [],
        status: '',
        schedule: null,
        note: ""
    });
    const [suggestedTime, setSuggestedTime] = useState({
        suggestions: [],
        index: 0
    })

    const currentPatient = useSelector(state => state.currentPatient);
    const [searchParam] = useSearchParams();

    const localizer = momentLocalizer(moment);
    const CustomToolbar = ({label}) => {
        return (
            <div className="text-center">
                <strong>{moment(selectedDateInTimezone).format('dddd, MMMM Do, YYYY (zz)')}</strong>
            </div>
        )
    }

    const calculateAppointmentWindowStatus = (start, end, events, schedule) => {
        const isTimeOverlap = (start1, end1, start2, end2) => {
            return (start1.getTime() < end2.getTime() && end1.getTime() > start2.getTime());
        }

        const scheduleStart = new Date(schedule.start);

        const scheduleEnd = new Date(schedule.end);

        let status = 'success';
        if (moment(start).isBefore(scheduleStart) || moment(end).isAfter(scheduleEnd)) {
            status = 'error';
        } else if (events && events.length) {
            if (!!events.find(e => isTimeOverlap(e.start, e.end, start, end) && e.type === 'blocked-time')) {
                status = 'error';
            } else if (!!events.find(e => isTimeOverlap(e.start, e.end, start, end))) {
                status = 'warning'
            }
        }

        return status;
    }

    const findAvailableSlots = (appointments, {from, to}, duration, step) => {
        const startTime = moment(selectedDateInTimezone).set('hour', from).toDate();
        const endTime = moment(selectedDateInTimezone).set('hour', to).toDate();

        // Convert step and new appointment duration to milliseconds
        const stepMs = step * 60 * 1000;
        const durationMs = duration * 60 * 1000;

        // Sort appointments by start time
        const sortedAppointments = appointments.sort((a, b) => a.start - b.start);

        // Initialize variables
        let availableSlots = [];
        let prevEnd = startTime.getTime();

        // Loop through appointments to find available slots
        for (const appointment of sortedAppointments) {
            // Check for gap between previous appointment and current appointment
            const gapStart = new Date(prevEnd);
            const gapEnd = new Date(appointment.start);
            const gapMs = gapEnd - gapStart;
            if (gapMs >= durationMs) {
                // Calculate available time slots within the gap
                const gapStartMs = gapStart.getTime();
                const numSlots = Math.floor(gapMs / stepMs);
                for (let i = 0; i < numSlots; i++) {
                    const slotStartMs = gapStartMs + (i * stepMs);
                    const slotEndMs = slotStartMs + durationMs;
                    const slotStart = new Date(slotStartMs);
                    const slotEnd = new Date(slotEndMs);
                    if (slotEnd <= endTime) {
                        const slot = {start: slotStart, end: slotEnd};
                        // Check for overlap with existing appointments
                        const overlaps = sortedAppointments.some((appt) => (
                            appt.start < slotEnd && slotStart < appt.end
                        ));
                        if (!overlaps) {
                            availableSlots.push(slot);
                        }
                    }
                }
            }

            // Update previous end time
            prevEnd = appointment.end.getTime();
        }

        // Check for gap between last appointment and end of time range
        const lastAppointment = sortedAppointments[sortedAppointments.length - 1];
        const lastEnd = lastAppointment ? lastAppointment.end.getTime() : startTime.getTime();
        if (lastEnd < endTime.getTime()) {
            const gapStart = new Date(lastEnd);
            const gapEnd = endTime;
            const gapMs = gapEnd - gapStart;
            if (gapMs >= durationMs) {
                // Calculate available time slots within the gap
                const gapStartMs = gapStart.getTime();
                const numSlots = Math.floor(gapMs / stepMs);
                for (let i = 0; i < numSlots; i++) {
                    const slotStartMs = gapStartMs + (i * stepMs);
                    const slotEndMs = slotStartMs + durationMs;
                    const slotStart = new Date(slotStartMs);
                    const slotEnd = new Date(slotEndMs);
                    if (slotStart >= startTime) {
                        const slot = {start: slotStart, end: slotEnd};
                        // Check for overlap with existing appointments
                        const overlaps = sortedAppointments.some((appt) => (
                            appt.start < slotEnd && slotStart < appt.end
                        ));
                        if (!overlaps) {
                            availableSlots.push(slot);
                        }
                    }
                }
            }
        }

        return availableSlots;
    }

    const bookAppointment = () => {
        setSubmitting(true)
        setTimeout(async () => {
            try {
                const payload = {
                    start: appointmentWindow.start,
                    end: appointmentWindow.end,
                    staffId: appointmentWindow.provider.staffId,
                    patientId: currentPatient.patientId,
                    locationId: appointmentWindow.location.stationId,
                    serviceId: selectedService.serviceID,
                    timezone,
                    note: appointmentWindow.note,
                };
                await scheduleAppointment(payload);
                const existingAppointments = staffs.find(st => st.staffId === appointmentWindow.provider.staffId)
                    .schedules.find(s => moment(s.date).isSame(selectedDateInTimezone, 'date')).appointments;
                existingAppointments.push({
                    start: appointmentWindow.start,
                    end: appointmentWindow.end,
                    serviceType: selectedService.serviceName,
                    status: 'Booked'
                })
                setStaffs([...staffs]);
                setShowSuccess(true);
            } catch (e) {
                message.error('Unable to book the appointment!');
            } finally {
                setSubmitting(false);
            }
        }, 20);
    }

    const moveTimeslotBlock = (timeout) => setTimeout(() => {
        const timeslot = document.querySelector('.pending-event') || document.querySelector('.error-event');
        timeslot.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
    }, timeout);

    useEffect(() => {
        (async () => {
            const services = await getAppointmentAvailableServices();
            setAvailableService(services.data.data);
            const passedServiceId = searchParam.get('service');
            if (passedServiceId) {
                const correspondingService = services.data.data.find(as => as.serviceID === passedServiceId);
                setSelectedService(correspondingService);
            }
        })();
    }, [])

    useEffect(() =>
            selectedDate ? setSelectedDateInTimezone(moment.tz(selectedDate.toDateString(), "ddd MMM DD yyyy", timezone).startOf('day').toDate()) : setSelectedDateInTimezone(null)
        , [selectedDate])

    useEffect(() => {
        if (selectedService) {
            setSelectedDate(null);
            setAvailableDates(null);
            (async () => {
                const t = (await getStaffSchedulesByService(selectedService.serviceID, timezone)).data.data.sort((a, b) => a.name >= b.name ? 1 : -1);
                setStaffs(t);
                setStaffChecks(t.map(tt => ({name: tt.name, checked: true})));
                const locationIds = [];
                const distinctLocations = [];
                t.forEach(tt => tt.schedules.forEach(ttt => {
                    if (!locationIds.includes(ttt.location.stationId)) {
                        distinctLocations.push(ttt.location);
                        locationIds.push(ttt.location.stationId);
                    }
                }))
                setLocationChecks([...Array.from(distinctLocations).map(dl => {
                    if (!locationParamUsed) {
                        const passedLocationId = searchParam.get('location');
                        if (passedLocationId) {
                            return {
                                name: dl.name, checked: dl.stationId === passedLocationId
                            }
                        } else {
                            return {
                                name: dl.name, checked: true
                            }
                        }
                    } else {
                        return {
                            name: dl.name, checked: true
                        }
                    }
                })]);
                setLocationParamUsed(true);
                const newColorMap = {};
                Array.from(distinctLocations).forEach((location, i) => {
                    newColorMap[location.name] = {
                        dark: colorSchema[(i % 19)][800],
                        light: colorSchema[(i % 19)][600],
                        className: 'day-color-' + (i % 19)
                    };
                });
                setColorMap(newColorMap);

                const distinctDates = new Set(t.flatMap(tt => tt.schedules.map(ttt => moment(ttt.date).format('YYYY-MM-DDz'))));
                setAvailableDates([...Array.from(distinctDates).map(dd => new Date(dd))]);
            })();
        }
    }, [selectedService])

    return <div className="new-appointment-page">
        <Row style={{marginTop: '60px'}}>
            <Col span={5}
                 style={{
                     background: '#F0F2F5 0% 0% no-repeat padding-box',
                     padding: '16px',
                     overflowY: 'auto',
                     height: "calc(100vh - 60px)"
                 }}>
                <p style={{fontSize: 'large'}}>Appointment</p>
                <Link to={`/patient/${currentPatient.patientId}/appointment`}
                      style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                    <div className="d-inline-flex align-items-center">
                        <AiOutlineLeft/>
                        <span className="ms-1">Patient: {currentPatient.firstName} {currentPatient.lastName}</span>
                    </div>
                </Link>
                {!!availableServices.length &&
                    <>
                        <div className="mt-3">
                            <div>
                                <strong>Select Service</strong>
                            </div>
                            <div>
                                <Select
                                    showSearch
                                    style={{width: '100%'}}
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    value={selectedService?.serviceID}
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={Array.from(availableServices.reduce(
                                        (entryMap, e) => entryMap.set(e.type, [...entryMap.get(e.type) || [], {
                                            label: e.serviceName,
                                            value: e.serviceID,
                                            obj: e
                                        }]),
                                        new Map()
                                    )).map(([type, value]) => ({label: type, options: [...value]}))}
                                    onChange={(value, option) => {
                                        setSelectedService(option.obj)
                                    }}
                                />
                            </div>
                        </div>
                        {
                            selectedService && availableDates &&
                            <>
                                <div className="mt-3 overall-schedule">
                                    <div>
                                        <strong>Select Date</strong>
                                    </div>
                                    <DatePicker
                                        inline
                                        disabledKeyboardNavigation
                                        selected={selectedDateInTimezone}
                                        onChange={(date) => setSelectedDate(date)}
                                        includeDates={availableDates}
                                    />
                                </div>
                                <div className="mt-3">
                                    <div>
                                        <strong>Select Location</strong>
                                    </div>
                                    <div>
                                        <FormControlLabel
                                            label="Select All"
                                            control={
                                                <Checkbox
                                                    checked={locationChecks.every(l => l.checked)}
                                                    indeterminate={!locationChecks.every(l => !l.checked) && !locationChecks.every(l => l.checked)}
                                                    onChange={(event) => {
                                                        locationChecks.forEach(lc => lc.checked = event.target.checked);
                                                        setLocationChecks([...locationChecks]);
                                                    }}
                                                    sx={{
                                                        color: common.black,
                                                        '&.Mui-checked': {
                                                            color: common.black,
                                                        },
                                                    }}
                                                />
                                            }
                                        />
                                        <Box sx={{display: 'flex', flexDirection: 'column', ml: 3}}>
                                            {
                                                locationChecks.map((l, i) => <FormControlLabel
                                                    key={i}
                                                    control={
                                                        <Checkbox
                                                            checked={l.checked}
                                                            sx={{
                                                                color: colorMap[l.name].dark,
                                                                '&.Mui-checked': {
                                                                    color: colorMap[l.name].light,
                                                                },
                                                            }}
                                                            onChange={(event) => {
                                                                locationChecks.find(lc => lc.name === l.name).checked = event.target.checked;
                                                                setLocationChecks([...locationChecks]);
                                                            }}
                                                        />
                                                    }
                                                    label={l.name}
                                                />)
                                            }
                                        </Box>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div>
                                        <strong>Select Staff</strong>
                                    </div>

                                    <div>
                                        <FormControlLabel
                                            label="Select All"
                                            control={
                                                <Checkbox
                                                    checked={staffChecks.every(l => l.checked)}
                                                    indeterminate={!staffChecks.every(l => !l.checked) && !staffChecks.every(l => l.checked)}
                                                    onChange={(event) => {
                                                        staffChecks.forEach(lc => lc.checked = event.target.checked);
                                                        setStaffChecks([...staffChecks]);
                                                    }}
                                                    sx={{
                                                        color: common.black,
                                                        '&.Mui-checked': {
                                                            color: common.black,
                                                        },
                                                    }}
                                                />
                                            }
                                        />
                                        <Box sx={{display: 'flex', flexDirection: 'column', ml: 3}}>
                                            {
                                                staffChecks.map((s, i) =>
                                                    <div key={i}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={s.checked}
                                                                    sx={{
                                                                        color: common.black,
                                                                        '&.Mui-checked': {
                                                                            color: common.black,
                                                                        },
                                                                    }}
                                                                    onChange={(event) => {
                                                                        staffChecks.find(sc => sc.name === s.name).checked = event.target.checked;
                                                                        setStaffChecks([...staffChecks]);
                                                                    }}
                                                                />
                                                            }
                                                            label={s.name}
                                                        />
                                                    </div>
                                                )
                                            }
                                        </Box>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }
            </Col>
            <Col span={19} style={{overflowY: 'auto', height: "calc(100vh - 60px)"}}>
                <Row>
                    {selectedDateInTimezone !== null &&
                        staffs
                            .map((s, i) => {
                                const thisDaySchedule = s.schedules.find(s => moment(s.date).isSame(selectedDateInTimezone, 'date')) || {
                                    appointments: [],
                                    blockedTimes: [],
                                    location: {},
                                    isFallback: true
                                };
                                const events = thisDaySchedule.appointments.filter(a => a.status === 'Booked').map((a, i) => ({
                                    id: i,
                                    start: new Date(a.start),
                                    end: new Date(a.end),
                                    title: a.serviceType,
                                    type: 'appointment',
                                    isDraggable: false,
                                })).concat(thisDaySchedule.blockedTimes.map(bt => ({
                                    end: moment(bt.end).toDate(),
                                    start: moment(bt.start).toDate(),
                                    title: <><p>Blocked Time For: {bt.description}</p></>,
                                    type: 'blocked-time',
                                    className: 'blocked-event',
                                })));

                                const thisDayScheduleStartHour = moment(thisDaySchedule.start).get('hour');
                                const thisDayScheduleEndHour = moment(thisDaySchedule.end).get('hour');

                                return <Col span={6} key={i} style={{padding: 8}}>
                                    <div className="d-flex flex-column align-items-center individual-schedule">
                                        <Avatar size={150} src={s.coverImage}/>
                                        <h5 style={{margin: '12px 0'}}>{s.name}</h5>
                                        <StyledDatePicker>
                                            <DatePicker
                                                inline
                                                disabledKeyboardNavigation
                                                selected={selectedDateInTimezone}
                                                onChange={(date) => setSelectedDate(date)}
                                                includeDates={[...Array.from(new Set(s.schedules.map(ttt => moment(ttt.date).format('YYYY-MM-DDz')))).map(aa => new Date(aa))]}
                                                dayClassName={(date) => {
                                                    const dateInTimeZone = moment.tz(date.toDateString(), "ddd MMM DD yyyy", timezone).startOf('day').toDate();
                                                    const locationName = s.schedules.find(ss => moment(ss.date).isSame(dateInTimeZone, 'date'))?.location.name;
                                                    return locationName ? colorMap[locationName].className : '';
                                                }}
                                            />
                                        </StyledDatePicker>
                                        <div className="w-100 position-relative">
                                            {
                                                (
                                                    thisDaySchedule.isFallback
                                                    || !staffChecks.find(sc => sc.name === s.name).checked
                                                    || !locationChecks.find(sc => thisDaySchedule.location.name === sc.name)?.checked
                                                )
                                                &&
                                                <div className="musk-cover"></div>
                                            }
                                            <Calendar
                                                date={selectedDateInTimezone}
                                                localizer={localizer}
                                                defaultView={'day'}
                                                views={['day']}
                                                components={{
                                                    toolbar: CustomToolbar
                                                }}
                                                min={moment(selectedDateInTimezone).set("hour", 8)}
                                                max={moment(selectedDateInTimezone).set("hour", 18)}
                                                timeslots={60 / (selectedService.duration % 10 === 5 ? 15 : 10)}
                                                step={selectedService.duration % 10 === 5 ? 15 : 10}
                                                slotPropGetter={date => {
                                                    // Disable times from 8:00 AM to schedule start time and from schedule end time to 6:00 PM
                                                    const thisHour = moment(date).get('hour');
                                                    const isDisabled = (thisHour < thisDayScheduleStartHour) || (thisHour >= thisDayScheduleEndHour)
                                                    if (isDisabled) {
                                                        return {
                                                            className: 'disabled-time'
                                                        }
                                                    }
                                                }}
                                                selectable={true}
                                                onSelectSlot={(slotInfo) => {
                                                    const start = slotInfo.start;
                                                    let end = slotInfo.end;

                                                    if (Math.abs(slotInfo.end - slotInfo.start) < selectedService.duration * 60 * 1000) {
                                                        end = moment(slotInfo.start).add(selectedService.duration, 'minute').toDate();
                                                    }
                                                    const status = calculateAppointmentWindowStatus(start, end, events, thisDaySchedule);
                                                    const location = thisDaySchedule.location;

                                                    setAppointmentWindow({
                                                        open: true,
                                                        provider: s,
                                                        schedule: thisDaySchedule,
                                                        start,
                                                        end,
                                                        location,
                                                        events,
                                                        status
                                                    });
                                                    setSuggestedTime({
                                                        suggestions: findAvailableSlots(events, {
                                                            from: thisDayScheduleStartHour,
                                                            to: thisDayScheduleEndHour
                                                        }, selectedService?.duration, 5),
                                                        index: 0
                                                    });
                                                    moveTimeslotBlock(500);
                                                }}
                                                onSelecting={(slotInfo) => {
                                                    const slotStartHour = moment(slotInfo.start).get('hour');
                                                    const slotEndHour = moment(slotInfo.end).get('hour');
                                                    const slotEndMinute = moment(slotInfo.end).get('minute');
                                                    if (slotStartHour < 8 || slotStartHour > 16) {
                                                        return false;
                                                    }
                                                    if (slotEndHour > 17) {
                                                        return false;
                                                    } else if (slotEndHour === 17) {
                                                        return slotEndMinute === 0 && Math.abs(slotInfo.end - slotInfo.start) <= selectedService.duration * 60 * 1000;
                                                    } else {
                                                        return Math.abs(slotInfo.end - slotInfo.start) <= selectedService.duration * 60 * 1000;
                                                    }
                                                }}
                                                eventPropGetter={(event, start, end, isSelected) => ({
                                                    className: event.type === 'blocked-time' ? 'blocked-event' : 'steelblue-event'
                                                })}
                                                events={events}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            })
                    }
                </Row>
            </Col>
        </Row>
        {appointmentWindow.open &&
            <Modal className="appointment-window" style={{top: 20}}
                   title="Confirm Your Appointment" open={appointmentWindow.open} okText="Book This Appointment"
                   width={800} destroyOnClose
                   onCancel={() => {
                       setAppointmentWindow({open: false});
                       setShowSuccess(false);
                       setSubmitting(false);
                   }}
                   onOk={bookAppointment}
                   confirmLoading={submitting}
                   cancelButtonProps={{style: {display: showSuccess ? 'none' : 'initial'}}}
                   okButtonProps={{
                       disabled: appointmentWindow.status === 'error',
                       style: {display: showSuccess ? 'none' : 'initial'}
                   }}
            >
                {
                    !showSuccess &&
                    <Row>
                        <Col span={14} className="d-flex flex-column justify-content-center align-items-center">
                            <Avatar size={150} src={appointmentWindow.provider.coverImage}/>
                            <h5 style={{margin: '12px 0'}}>{appointmentWindow.provider?.name}</h5>
                            <p>{appointmentWindow.provider?.title}</p>
                            <div>
                                <p>
                                    <strong>Service: </strong>
                                    {selectedService?.serviceName}
                                </p>
                                <p>
                                    <strong>Patient: </strong>
                                    {currentPatient.firstName} {currentPatient.lastName}
                                </p>
                                <p>
                                    <strong>Location: </strong>
                                    {appointmentWindow.location?.name}
                                </p>
                                <p>
                                    <strong>Date: </strong>
                                    {moment(selectedDateInTimezone).format("MMMM Do, YYYY (zz)")}
                                </p>
                                <p>
                                    <strong style={{marginRight: 2}}>Time: </strong>
                                    <DatePickerWithMoment.RangePicker
                                        picker="time"
                                        disabledTime={() => ({
                                            disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 18, 19, 20, 21, 22, 23],
                                            disabledMinutes: (selectedHour) => selectedHour === 17 ? [10, 15, 20, 30, 40, 45, 50] : []
                                        })}
                                        disabled={[false, false]}
                                        showSecond={false}
                                        hideDisabledOptions={true}
                                        minuteStep={15}
                                        value={[moment(appointmentWindow.start), moment(appointmentWindow.end)]}
                                        allowEmpty={[false, false]}
                                        allowClear={false}
                                        order={false}
                                        format={'HH:mm'}
                                        style={{width: "calc(100% - 46px)"}}
                                        onChange={(date) => {
                                            const start = date[0].toDate();
                                            const end = date[1].toDate();
                                            setAppointmentWindow({
                                                ...appointmentWindow,
                                                start, end,
                                                status: calculateAppointmentWindowStatus(start, end, appointmentWindow.events, appointmentWindow.schedule)
                                            })
                                            moveTimeslotBlock(20);
                                        }}
                                        status={appointmentWindow.status}
                                    />
                                    <div className="text-center">
                                        <Typography.Link onClick={() => {
                                            const start = suggestedTime.suggestions[suggestedTime.index].start;
                                            const end = suggestedTime.suggestions[suggestedTime.index].end;
                                            setAppointmentWindow({
                                                ...appointmentWindow,
                                                start,
                                                end,
                                                status: calculateAppointmentWindowStatus(start, end, appointmentWindow.events, appointmentWindow.schedule)
                                            });
                                            const newIndex = ++suggestedTime.index % suggestedTime.suggestions.length;
                                            setSuggestedTime({
                                                ...suggestedTime,
                                                index: newIndex
                                            });
                                            moveTimeslotBlock(20);
                                        }} disabled={!suggestedTime.suggestions?.length}
                                        >{suggestedTime.suggestions?.length ? 'Pick A Time' : 'No Available Slot'}</Typography.Link>
                                    </div>
                                </p>
                                <div className="d-flex">
                                    <strong style={{marginRight: 5}}>Note:</strong>
                                    <Input.TextArea row={2} onBlur={(e) =>
                                        setAppointmentWindow({
                                            ...appointmentWindow,
                                            note: e.target.value
                                        })
                                    } />
                                </div>
                            </div>
                            {
                                appointmentWindow.status !== 'success' &&
                                <div>
                                    {
                                        appointmentWindow.status === 'warning' &&
                                        <Typography.Text type="warning">Selected time slot overlaps existing
                                            schedule</Typography.Text>
                                    }
                                    {
                                        appointmentWindow.status === 'error' &&
                                        <Typography.Text type="danger">Selected time slot is not available</Typography.Text>
                                    }
                                </div>
                            }
                        </Col>
                        <Col span={10} style={{height: 480, overflowY: 'auto'}}>
                            {
                                CustomToolbar({})
                            }
                            <DnDCalendar
                                defaultDate={selectedDateInTimezone}
                                localizer={localizer}
                                defaultView={'day'}
                                views={['day']}
                                components={{
                                    toolbar: () => <></>,
                                }}
                                slotPropGetter={date => {
                                    // Disable times from 7:00 AM to 8:00 AM and from 5:00 PM to 6:00 PM
                                    const thisHour = moment(date).get('hour');
                                    const isDisabled = (thisHour < moment(appointmentWindow.schedule.start).get('hour')) || (thisHour >= moment(appointmentWindow.schedule.end).get('hour'))
                                    if (isDisabled) {
                                        return {
                                            className: 'disabled-time'
                                        }
                                    }
                                }}
                                min={moment(selectedDateInTimezone).set("hour", 7)}
                                max={moment(selectedDateInTimezone).set("hour", 18)}
                                timeslots={60 / (selectedService.duration % 10 === 5 ? 15 : 10)}
                                step={selectedService.duration % 10 === 5 ? 15 : 10}
                                eventPropGetter={(event, start, end, isSelected) => {
                                    let colorClass;
                                    if (event.type === 'SELECTED_SLOT') {
                                        if (appointmentWindow.status === 'error') {
                                            colorClass = 'error-event draggable-event'
                                        } else {
                                            colorClass = 'pending-event draggable-event';
                                        }
                                    } else if (event.type === 'blocked-time') {
                                        colorClass = 'blocked-event non-interaction-event';
                                    } else {
                                        colorClass = 'steelblue-event non-interaction-event';
                                    }
                                    return {
                                        className: `${colorClass}`
                                    }
                                }}
                                events={[...appointmentWindow.events, {
                                    id: appointmentWindow.events.length,
                                    title: appointmentWindow.status === 'error' ? 'You can not book this slot' : 'You are about to book this slot',
                                    start: appointmentWindow.start,
                                    end: appointmentWindow.end,
                                    type: 'SELECTED_SLOT',
                                    isDraggable: true,
                                }]}
                                resizable={false}
                                draggableAccessor="isDraggable"
                                onEventDrop={({event, start, end, allDay}) => {
                                    const status = calculateAppointmentWindowStatus(start, end, appointmentWindow.events, appointmentWindow.schedule);
                                    setAppointmentWindow({...appointmentWindow, start, end, status});
                                }}
                            />
                        </Col>
                    </Row>
                }
                {
                    showSuccess &&
                    <Result
                        status="success"
                        title="Successfully Booked Appointment!"
                        extra={[
                            <>
                                <p>
                                    <strong>Service: </strong>
                                    {selectedService?.serviceName}
                                </p>
                                <p>
                                    <strong>Doctor: </strong>
                                    {appointmentWindow.provider.name}
                                </p>
                                <p>
                                    <strong>Location: </strong>
                                    {appointmentWindow.location?.name}
                                </p>
                                <p>
                                    <strong>Date: </strong>
                                    {moment(selectedDateInTimezone).format("MMMM Do, YYYY (zz)")}
                                </p>
                                <p>
                                    <strong>Time: </strong>
                                    {moment(appointmentWindow.start).format("hh:mm a")} - {moment(appointmentWindow.end).format("hh:mm a")}
                                </p>
                                <p>
                                    <strong>Note: </strong>
                                    {appointmentWindow.note}
                                </p>
                            </>
                            ,
                            <Link to={`/patient/${currentPatient.patientId}/appointment`}>
                                <div className="d-inline-flex align-items-center">
                                    Back to Appointment Page
                                </div>
                            </Link>
                        ]}
                    />
                }
            </Modal>
        }
    </div>
}

export default NewAppointmentPage;