import React from 'react';

import '../../styles/marketing/marketing.css'
import '../../styles/marketing/promotion.css'
import '../../styles/layout_custom.css';
import '../../styles/ChatboxPreset.css'
import bioApi from "../../api/bioApi";
import { addChatboxRef } from './ChatboxRefs';
import { message } from 'antd';

class Chatbox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            children: []
        };
    }

    handleCreate() {
        this.setState({
            showReviewModal: true
        })
    }

    handleChange(value, id) {
        this.setState({children: this.state.children.map(it => {
            if(it._id === id ) {
                return {
                    ...it,
                    text: {
                        ...it.text,
                        en: value
                    }
                }
            }
            return it;
        })})
    }
    handleChangeDescription(value, id) {
        this.setState({children: this.state.children.map(it => {
            if(it._id === id ) {
                return {
                    ...it,
                    description: {
                        ...it.description,
                        en: value
                    }
                }
            }
            return it;
        })})
    }
    // ES language
    handleChangeEs(value, id) {
        this.setState({children: this.state.children.map(it => {
            if(it._id === id ) {
                return {
                    ...it,
                    text: {
                        ...it.text,
                        es: value
                    }
                }
            }
            return it;
        })})
    }
    handleChangeDescriptionEs(value, id) {
        this.setState({children: this.state.children.map(it => {
            if(it._id === id ) {
                return {
                    ...it,
                    description: {
                        ...it.description,
                        es: value
                    }
                }
            }
            return it;
        })})
    }
    handleCheckedOption(value, id){
        this.setState({children: this.state.children.map(it => {
            if(it._id === id ) {
                return {
                    ...it,
                    isFeedback: value
                }
            }
            return it;
        })})
    }

    handleAdd(e) {
        const children = [...this.state.children, {
            isNew: true,
            text: {
                en: "",
                es: ""
            },
            description: {
                en: "",
                es: ""
            },
            isFeedback: false,
            parent: (this.props.parent ? this.props.parent._id  : undefined) ,
            _id: (this.props.parent ? this.props.parent._id  : '' ) + this.state.children.length + 1
        }];
        this.setState({children});
    }

    
    handleRemove(_id) {
        this.setState({
            children: this.state.children.filter(e => e._id !== _id)
        });
    }

    componentDidMount(){
        this.requestGetData();
    }

    getResult() {
        return this.props.parent;
    }

    requestGetData() {
        const {parent} = this.props;
        const params = {parentId: parent ? parent._id : null };
        setTimeout(() => {
            this.setState({
                loading: true
            });
            var pathstring = '/chat/preset-by-parent';
            try {
                bioApi.post(pathstring, params)
                .then((response) => {
                    response = response.data
                    if(response.status === 1) {
                        if(!parent && response.data.length === 0){
                            this.setState({ loading: false })
                            this.handleAdd()
                        }else{
                            this.setState({ loading: false, children: response.data })
                        }
                    } else {
                        // message.error(response.message)
                    }
                    this.setState({ loading: false })
                })
                .catch((err) => {
                    message.error(err.message)
                    this.setState({ loading: false })
                })
                // bioApi.post(pathstring, params).then(response => {
                //     if(!parent && response.data.data.length === 0){
                //         this.setState({ loading: false })
                //         this.handleAdd()
                //     }else{
                //         this.setState({ loading: false, children: response.data.data })
                //     }
                // }).catch((err) => {
                //     this.setState({ loading: false })
                // })
            } catch (err) {
                this.setState({ loading: false })
                message.error('Network error');
            }
        }, 0);
    }

    render() {

        const { parent, onChangeText, onChangeTextEs, onRemove, handleChangeDescription, handleChangeDescriptionEs, handleCheckedOption } = this.props;
        const { children } = this.state;
        return (
            <li>
                {parent ? (<div style={{display: "flex"}}>
                    <div>
                        <label for="English-title" className='label-cus'>English Title </label>
                        <input 
                            className={"form-control form-control-cus input-sm"} 
                            style={{ width: '25em', borderBottom:'none', fontWeight:'bold' }} 
                            type="text" 
                            placeholder="Title"
                            value={parent.text.en} onChange={e => {
                            onChangeText(e.target.value, parent._id)
                        }} />
                        <input 
                            className={"form-control form-control-cus input-sm"} 
                            style={{ width: '25em',borderTop:'none' }} 
                            type="text" 
                            placeholder="Description"
                            value={parent.description? parent.description.en: ''} onChange={e => {
                            handleChangeDescription(e.target.value, parent._id)
                        }} />
                    </div>
                    <div>
                        <label for="English-title" className='label-cus'>Spanish Title </label>
                        <input 
                            className={"form-control form-control-cus input-sm"} 
                            style={{ width: '25em', borderBottom:'none', fontWeight:'bold' }} 
                            type="text" 
                            placeholder="Título"
                            value={parent.text.es} onChange={e => {
                            onChangeTextEs(e.target.value, parent._id)
                        }} />
                        <input 
                            className={"form-control form-control-cus input-sm"} 
                            style={{ width: '25em',borderTop:'none' }} 
                            type="text" 
                            placeholder="Descripción"
                            value={parent.description? parent.description.es: ''} onChange={e => {
                            handleChangeDescriptionEs(e.target.value, parent._id)
                        }} />
                    </div>
                    <div style={{ padding: '4px', display: 'flex', height: '38px' }}>
                        {
                            !parent.isNew? <button type="button" className={!parent.isFeedback? 'btn btn-blue btn-sm' : 'btn btn-blue btn-sm disabled'} 
                            onClick={!parent.isFeedback? this.handleAdd.bind(this): null} style={{ padding: '1px 7px', marginRight: '2px' }}> + </button> : null
                        }
                        {
                            parent.parent ? (<button type="button" className="btn btn-blue btn-sm" onClick={ e => {
                                onRemove(parent._id)
                            }} style={{ padding: '1px 7px' }}> - </button>) : null
                        }
                        {
                            children.length > 0 ? null : <label className='label-warp' ><span style={{verticalAlign:'text-top', marginLeft: '2px', marginRight:'2px'}}>Enable Feedback</span><input type="checkbox" 
                                    value={parent.isFeedback} 
                                    checked={parent.isFeedback} 
                                    onChange={ e => {
                                        handleCheckedOption(e.target.checked, parent._id)
                                    }} 
                                    className="btn btn-blue btn-sm" 
                                    style={{ padding: '1px 7px', marginRight: '2px' }}/></label>
                        }
                        
                    </div></div>
                ): null
                }
                <ul>
                {
                    (parent && !parent.isNew || !parent) ? 
                        children.map((ch, index) => {
                            return (
                                <Chatbox 
                                    parent={ch}
                                    onChangeText={this.handleChange.bind(this)}
                                    onChangeTextEs={this.handleChangeEs.bind(this)}
                                    onRemove={this.handleRemove.bind(this)}
                                    handleChangeDescription={this.handleChangeDescription.bind(this)}
                                    handleChangeDescriptionEs={this.handleChangeDescriptionEs.bind(this)}
                                    handleCheckedOption={this.handleCheckedOption.bind(this)}
                                    key={ch._id}
                                    ref={ref => {
                                        addChatboxRef(ch._id, ref);
                                    }}
                                />
                            )
                        }) : null
                }
                </ul>
            </li>
        )
    }
};

export default Chatbox;
