import { createSlice } from "@reduxjs/toolkit";

const authenticatedUserSlice = createSlice({
  name: "authenticated-user",
  initialState: null,
  reducers: {
    setAuthenticatedUser: (state, action) => {
      return action.payload;
    },
  },
});

export const { setAuthenticatedUser } = authenticatedUserSlice.actions;
export default authenticatedUserSlice.reducer;
