import React from 'react';
import { connect } from 'react-redux';

import reviewIcon from '../../../assets/images/review.png';

import AnnouncementModal from './AnnouncementModal'
import { FormatDateToString, GetDefaultFromDate, GetDefaultToDate } from '../../../share/StringHelper';
import bioApi from '../../../api/bioApi';
import BoxLoader from '../../../components/loading/BoxLoader';
import Pager from '../../../components/Pager/PagerControl';
import { WithBio } from '../../../components/mycomponent/WithBio';

function getCenterName(centerArray, centerid) {
    for (var i = 0; i < centerArray.length; i++) {
        var item = centerArray[i];
        if (item.locationID === centerid) {
            return item.name;
        }
    }
    return '';
}

class NotificationList extends React.Component {

    constructor(props) {
        super(props)

        this.mapItem = this.mapItem.bind(this);
        this.requestGetData = this.requestGetData.bind(this);
        this.getPagerSetting = this.getPagerSetting.bind(this);
        this.handlePageIndexChange = this.handlePageIndexChange.bind(this);

        this.state = {
            ...this.getInitData(),
            promotionData: [],
            pagerSetting: this.getPagerSetting(),
            notificationData: this.getInitNotificationData()
        };

        this.requestGetData();
    }

    getInitNotificationData() {
        return {
            showReviewModal: false,
            sendType: 'all',
            title: '',
            message: '',
            selectRegion: [
                {
                    headQuarterData: {
                        plasmaData: {
                            hasAccess: true,
                            selectAll: false,
                            selectedIds: []
                        }
                    }
                }
            ],
            formData: {
                donors: [],
            }
        }
    }

    getInitData() {
        return {
            loading: false,
            searchType: "title",
            fromDate: GetDefaultFromDate(),
            toDate: GetDefaultToDate(),
            reviewSelectIndex: 0,
            showReviewModal: false,
            searchForm: {}
        }
    }

    getPlasmaIdsData(plasmaIds) {
        return [
            {
                headQuarterData: {
                    plasmaData: {
                        hasAccess: true,
                        selectAll: false,
                        selectedIds: plasmaIds
                    }
                }
            }
        ]
    }

    getPagerSetting() {
        return {
            totalCount: 0,
            pageSize: 10,
            pageIndex: 0,
            firstText: 'First',
            prevText: 'Prev',
            nextText: 'Next',
            lastText: 'Last',
            recordTextFormat: "{0}/{1}",
            goWidth: 50,
            showLinkNum: 5,
            callBack: this.handlePageIndexChange
        };
    }

    requestGetData() {
        setTimeout(() => {
            this.setState({
                loading: true
            });
            var pathstring = '/announcement/list';
            var formData = {
                // ...this.state.searchForm,
                "staffId": this.state.staffId,
                "limit": 10,
                "page": this.state.pagerSetting.pageIndex + 1
            };
            // if (this.state.fromDate !== '') {
            //     formData['fromDate'] = this.state.fromDate;
            // }
            // if (this.state.toDate !== '') {
            //     formData['toDate'] = this.state.toDate;
            // }
            try {
                bioApi.post(pathstring, formData).then(response => {
                    if (response.data.status === 200) {
                        this.setState({
                            loading: false,
                            promotionData: response.data.data,
                            pagerSetting: {
                                ...this.state.pagerSetting,
                                totalCount: response.data.totalCount
                            }
                        })
                    } else {
                        this.setState({ loading: false })
                        alert(response.data.message);
                    }
                }).catch((err) => {
                    this.setState({ loading: false })
                    alert('Network error');
                })
            } catch (err) {
                this.setState({ loading: false })
                alert('Network error');
            }
        }, 0);
    }

    handlePageIndexChange(index) {
        this.setState({
            pagerSetting: {
                ...this.state.pagerSetting,
                pageIndex: index,
            }
        })
        this.requestGetData();
    }

    requestFindDonorById(donorIDs) {
    }

    handleRevoke(item) {
        if (!window.confirm("Are you sure to revoke '" + item.announcementID + "'?")) return;
        setTimeout(() => {
            this.setState({
                loading: true
            });
            var pathstring = '/announcement/stick/inactive';
            var formData = {
                "staffId": this.state.staffId,
                "announcementObjectID": item._id,
            };
            try {
                bioApi.post(pathstring, formData).then(response => {
                    if (response.data.status === 200) {
                        this.setState({
                            loading: false,
                        })
                        this.requestGetData();
                    } else {
                        this.setState({ loading: false })
                        alert(response.data.message);
                    }
                }).catch((err) => {
                    this.setState({ loading: false })
                    alert('Network error');
                })
            } catch (err) {
                this.setState({ loading: false })
                alert('Network error');
            }
        }, 0);
    }

    mapItem(item, index) {
        var controlArray = [];
        controlArray.push(<td key={"announcement 1" + index}>
            <div onClick={() => {
                this.setState({
                    reviewSelectIndex: index,
                    showReviewModal: true
                })
            }}>
                <img src={reviewIcon} alt="edit" className="icon-edit" />
            </div>
        </td>)
        if (item.isActive) {
            controlArray.push(<td key={"announcement 1" + index}>
                <div onClick={this.handleRevoke.bind(this, item)}
                    className="btn btn-blue btn-sm"
                    style={{ padding: 5, backgroundImage: "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 80%)", color: "#e52d47" }}>
                    Revoke
                </div>
            </td>)
        } else {
            controlArray.push(<td key={"announcement 2" + index}>
            </td>)
        }

        var publishto = '';
        var createby = 'Headquarter';
        // if (item.operateLevel.toLowerCase() === "plasma") {
        //     if (item.plasmaData && item.plasmaData.length > 0 && item.plasmaData[0].name) {
        //         createby = item.plasmaData[0].name;
        //         publishto = item.plasmaData[0].name;
        //     } else {
        //         createby = "Plasma";
        //         publishto = "Plasma";
        //     }
        // } else {
            // if (item.publish_to.trim().toLowerCase() === 'all') {
            //     publishto = 'All Stations';
            // } else {
            //     publishto = item.publish_to + ' Station(s)';
            // }
        // }

        return <tr key={'itemyour' + item._id}>
            <td>{FormatDateToString(item.createDate)}</td>
            <td>{item.announcementID}</td>
            <td>{item.title}</td>
            <td>{item.subTitle}</td>
            <td>{FormatDateToString(item.validStart, true) + ' - ' + FormatDateToString(item.validEnd, true)}</td>
            <td>{item.isActive ? 'Active' : 'Inactive'}</td>
            <td>{createby}</td>
            <td>{publishto}</td>
            {controlArray}
        </tr>
    }

    render() {
        return (
            <div>
                <div className="box">
                    <BoxLoader show={this.state.loading} />
                    <div className="box">
                        <div className="box-marketing-bd box-body table-responsive" style={{ padding: "0 40px 0 40px" }}>
                            <table className="table table-marketing" style={{fontSize: 14}}>
                                <tbody>
                                    <tr>
                                        <th>Send Date(EST)</th>
                                        <th>Announcement ID</th>
                                        <th>Title</th>
                                        <th>Subtitle</th>
                                        <th>Validate</th>
                                        <th>Status</th>
                                        <th>Create By</th>
                                        <th>Publish To</th>
                                        <th colSpan={2}>Action</th>
                                    </tr>
                                    {
                                        this.state.promotionData.map(this.mapItem)
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ flex: "1" }}></div>
                            <div className="panel-body" style={{ padding: "10px 15px" }}>
                                <Pager {...this.state.pagerSetting} />
                            </div>
                        </div>
                    </div>
                </div>

                {
                    this.state.showReviewModal ?
                        <AnnouncementModal
                            {...this.state.promotionData[this.state.reviewSelectIndex]}
                            showReviewModal={true}
                            centers={this.props.loggedInUser.specifyAreas}
                            handleReviewModalClose={() => {
                                this.setState({
                                    showReviewModal: false
                                })
                            }}
                            handleReviewModalSubmit={() => {

                            }}
                        /> : null
                }
            </div>
        )
    }
};

export default WithBio(NotificationList);
